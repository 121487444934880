import { batch } from "react-redux";
import { PartnerService } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import PartnerTypes from "./partner.types";
import { ImageServices } from "../../_services";
export const getPartnerBalance = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({ type: PartnerTypes.GET_PARTNER_BALANCE });
                dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
            });
            const partnerBalance = await PartnerService.partnerBalance(token);
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_PARTNER_BALANCE_SUCCESS,
                    payload: partnerBalance.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_PARTNER_BALANCE_FAILURE,
                    payload: error.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        }
    };
};

export const getPartnerSetting = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({ type: PartnerTypes.GET_PARTNER_SETTING });
                dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
            });
            const partnerSetting = await PartnerService.getPartnerSetting(
                token
            );
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_PARTNER_SETTING_SUCCESS,
                    payload: partnerSetting.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_PARTNER_SETTING_FAILURE,
                    payload: error.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        }
    };
};

export const updatePartnerSetting = (data) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({ type: PartnerTypes.UPDATE_PARTNER_SETTING });
                dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
            });
            const partnerSetting = await PartnerService.editPartnerSetting(
                token,
                data
            );
            batch(() => {
                dispatch({
                    type: PartnerTypes.UPDATE_PARTNER_SETTING_SUCCESS,
                    payload: partnerSetting.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.UPDATE_PARTNER_SETTING_FAILURE,
                    payload: error.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        }
    };
};

export const getPartnerList = (businessName, city, status, page, size, orderBy) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({ type: PartnerTypes.GET_PARTNER_LIST });
                dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
            });
            const partnerList = await PartnerService.partnerList(
                token,
                businessName,
                city,
                status,
                page,
                size,
                orderBy
            );
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_PARTNER_LIST_SUCCESS,
                    payload: partnerList.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_PARTNER_LIST_FAILURE,
                    payload: error.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        }
    };
};

export const getTransactionPartner = (page, size, searchQuery) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({ type: PartnerTypes.GET_PARTNER_TRANSACTION });
                dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
            });
            const partnerList = await PartnerService.partnerTransaction(
                token,
                page,
                size,
                searchQuery
            );
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_PARTNER_TRANSACTION_SUCCESS,
                    payload: partnerList.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_PARTNER_TRANSACTION_FAILURE,
                    payload: error.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        }
    };
};

export const getTransactionPartnerById = (id) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;

        try {
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_TRANSACTION_BY_ID,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_LOADING_TABLE,
                });
            });

            const dataById = await PartnerService.partnerTransactionById(token, id);

            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_TRANSACTION_BY_ID_SUCCESS,
                    payload: {
                        ...dataById.data,
                    },
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_TRANSACTION_BY_ID_FAILURE,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: error.message,
                        alertType: "error",
                    },
                });
            });
        }
    };
};

export const getUserPartner = (role, searchQuery, page, size) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({ type: PartnerTypes.GET_USER_PARTNER });
                dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
            });
            const partnerList = await PartnerService.userPartner(
                token,
                role,
                searchQuery,
                page,
                size
            );
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_USER_PARTNER_SUCCESS,
                    payload: partnerList.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_USER_PARTNER_FAILURE,
                    payload: error.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        }
    };
};

export const createUserPartner = (data) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({ type: PartnerTypes.CREATE_USER_PARTNER });
                dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
            });
            const createUserPartner = await PartnerService.createUserPartner(
                token,
                data
            );
            batch(() => {
                dispatch({
                    type: PartnerTypes.CREATE_USER_PARTNER_SUCCESS,
                    payload: createUserPartner.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.CREATE_USER_PARTNER_FAILED,
                    payload: error.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        }
    };
};

export const getDataById = (id) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;

        try {
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_DATA_BY_ID,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_LOADING_TABLE,
                });
            });

            const dataById = await PartnerService.getDataById(id, token);
            console.log('databyid', dataById);
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_DATA_BY_ID_SUCCESS,
                    payload: {
                        ...dataById.data,
                        contactEmail: dataById.data.email,
                        phoneNumber: dataById.data.phoneNo,

                        topupFeePercent:
                            dataById.data.partnerFee.TopupFeePercent,
                        trxFeePercent: dataById.data.partnerFee.TrxFeePercent,
                        trxFee: dataById.data.partnerFee.TrxFee,
                        topupFee: dataById.data.partnerFee.TopupFee,

                        allowAccountLinked:
                            dataById.data.permission.AllowAccountLinked,
                        allowCreateTrx: dataById.data.permission.AllowCreateTrx,
                        allowFullRefundTrx:
                            dataById.data.permission.AllowFullRefundTrx,
                        allowPartialRefundTrx:
                            dataById.data.permission.AllowPartialRefundTrx,
                        allowTopUp: dataById.data.permission.AllowTopUp,
                        allowVoidTrx: dataById.data.permission.AllowVoidTrx,

                        imageSppkp: dataById.data.imageSppkpUrl,
                        imageNpwp: dataById.data.imageNpwpUrl,
                        imageSkt: dataById.data.imageSktUrl,
                    },
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.GET_DATA_BY_ID_FAILURE,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: error.message,
                        alertType: "error",
                    },
                });
            });
        }
    };
};

export const createPartner = (data) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        console.log("data", data);
        const processedData = {
            ...data,
            trxFee: parseFloat(data.trxFee),
            topupFee: parseFloat(data.topupFee),
        };

        try {
            batch(() => {
                dispatch({ type: PartnerTypes.CREATE_PARTNER });
                dispatch({ type: ActionsActionTypes.SHOW_LOADING });
            });

            if (typeof data.imageNpwp !== "string") {
                const uploadImageNpwp = await ImageServices.uploadImagePartner(
                    data.imageNpwp,
                    "partner/npwp",
                    token
                );
                processedData.imageNpwp = `${uploadImageNpwp.data.fullPath}`;
            }

            if (typeof data.imageSkt !== "string") {
                const uploadimageSkt = await ImageServices.uploadImagePartner(
                    data.imageSkt,
                    "partner/skt",
                    token
                );
                processedData.imageSkt = `${uploadimageSkt.data.fullPath}`;
            }
            if (typeof data.imageSppkp !== "string") {
                const uploadimageSppkp = await ImageServices.uploadImagePartner(
                    data.imageSppkp,
                    "partner/sppkp",
                    token
                );
                processedData.imageSppkp = `${uploadimageSppkp.data.fullPath}`;
            }

            const createPartner = await PartnerService.createPartner(
                token,
                processedData
            );
            batch(() => {
                dispatch({
                    type: PartnerTypes.CREATE_PARTNER_SUCCESS,
                    payload: createPartner.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING,
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.CREATE_PARTNER_FAILED,
                    payload: error.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING,
                });
            });
        }
    };
};

export const updatePartner = (id, data) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        const processedData = {
            ...data,
            trxFee: parseFloat(data.trxFee),
            topupFee: parseFloat(data.topupFee),
        };
        try {
            batch(() => {
                dispatch({
                    type: PartnerTypes.UPDATE_PARTNER,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_LOADING,
                });
            });

            if (typeof data.imageNpwp === "string") {
                processedData.imageNpwp = processedData.imageNpwp.replace(
                    `${process.env.REACT_APP_BASE_URL2}/v1/file/`,
                    ""
                );
            } else {
                const uploadImageNpwp = await ImageServices.uploadImagePartner(
                    data.imageNpwp,
                    "partner/npwp",
                    token
                );
                processedData.imageNpwp = `${uploadImageNpwp.data.fullPath}`;
            }

            if (typeof data.imageSkt === "string") {
                processedData.imageSkt = processedData.imageSkt.replace(
                    `${process.env.REACT_APP_BASE_URL2}/v1/file/`,
                    ""
                );
            } else {
                const uploadimageSkt = await ImageServices.uploadImagePartner(
                    data.imageSkt,
                    "partner/skt",
                    token
                );
                processedData.imageSkt = `${uploadimageSkt.data.fullPath}`;
            }
            if (typeof data.imageSppkp === "string") {
                processedData.imageSppkp = processedData.imageSppkp.replace(
                    `${process.env.REACT_APP_BASE_URL2}/v1/file/`,
                    ""
                );
            } else {
                const uploadimageSppkp = await ImageServices.uploadImagePartner(
                    data.imageSppkp,
                    "partner/sppkp",
                    token
                );
                processedData.imageSppkp = `${uploadimageSppkp.data.fullPath}`;
            }

            const updating = await PartnerService.updatePartner(
                id,
                processedData,
                token
            );
            if (updating.status === "success") {
                batch(() => {
                    dispatch({
                        type: PartnerTypes.UPDATE_PARTNER_SUCCESS,
                    });
                    dispatch({
                        type: ActionsActionTypes.HIDE_LOADING,
                    });
                    dispatch({
                        type: ActionsActionTypes.SHOW_ALERT,
                        payload: {
                            alertMessage: "Update data success",
                            alertType: "success",
                        },
                    });
                });
            } else {
                batch(() => {
                    dispatch({
                        type: PartnerTypes.UPDATE_PARTNER_FAILED,
                    });
                    dispatch({
                        type: ActionsActionTypes.HIDE_LOADING,
                    });
                    dispatch({
                        type: ActionsActionTypes.SHOW_ALERT,
                        payload: {
                            alertMessage: updating.data,
                            alertType: "error",
                        },
                    });
                });
            }
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.UPDATE_PARTNER_FAILED,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: "Update Partner error",
                        alertType: "error",
                    },
                });
            });
        }
    };
};

export const clearStatus = () => {
    return async (dispatch) => {
        dispatch({
            type: PartnerTypes.DEFAULT_STATUS,
        });
    };
};

export const deletePartner = (id) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({
                    type: PartnerTypes.DELETE_PARTNER,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_LOADING,
                });
            });
            const deleting = await PartnerService.deletePartner(id, token);
            batch(() => {
                dispatch({
                    type: PartnerTypes.DELETE_PARTNER_SUCCESS,
                    payload: deleting.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: "Success delete Partner",
                        alertType: "success",
                    },
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.DELETE_PARTNER_FAILED,
                    payload: error.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: "Failure delete Partner",
                        alertType: "error",
                    },
                });
            });
        }
    };
};

export const banPartner = (id) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({
                    type: PartnerTypes.BAN_PARTNER,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_LOADING,
                });
            });
            const deleting = await PartnerService.banPartner(id, token);
            batch(() => {
                dispatch({
                    type: PartnerTypes.BAN_PARTNER_SUCCESS,
                    payload: deleting.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: "Success Ban Partner",
                        alertType: "success",
                    },
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.BAN_PARTNER_FAILED,
                    payload: error.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: "Failure Ban Partner",
                        alertType: "error",
                    },
                });
            });
        }
    };
};


export const approveRefund = (data ) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({
                    type: PartnerTypes.APPROVE_REFUND,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_LOADING,
                });
            });
            const deleting = await PartnerService.approveRefund(token, data);
            batch(() => {
                dispatch({
                    type: PartnerTypes.APPROVE_REFUND_SUCCESS,
                    payload: deleting.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: `Success ${data.refundStatus} Refund`,
                        alertType: "success",
                    },
                });
            });
        } catch (error) {
            batch(() => {
                dispatch({
                    type: PartnerTypes.APPROVE_REFUND_FAILED,
                    payload: error.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: `Failure ${data.refundStatus} Refund`,
                        alertType: "error",
                    },
                });
            });
        }
    };
};

export const exportTransaction = (partnerId, status, trxType, startDate, endDate) => {
    return async (dispatch, getState) => {
      const state = getState();
      const token = state.auth.token;
  
      try {
        batch(() => {
          dispatch({ type: PartnerTypes.EXPORT_TRANSACTION });
          dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
        });
  
        const exportTrans = await PartnerService.exportTransaction(
          token, partnerId, status, trxType, startDate, endDate
        );
  
        batch(() => {
          dispatch({
            type: PartnerTypes.EXPORT_TRANSACTION_SUCCESS,
            payload: exportTrans,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING_TABLE,
          });
        });
      } catch (error) {
        batch(() => {
          dispatch({
            type: PartnerTypes.EXPORT_TRANSACTION_FAILED,
            payload: error.data,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING_TABLE,
          });
        });
      }
    };
  };

