const MerchantManagementTypes = {
  GET_DATA_MERCHANT: "GET_DATA_MERCHANT",
  GET_DATA_MERCHANT_SUCCESS: "GET_DATA_MERCHANT_SUCCESS",
  GET_DATA_MERCHANT_FAILURE: "GET_DATA_MERCHANT_FAILURE",
  DELETE_MERCHANT: "DELETE_MERCHANT",
  DELETE_MERCHANT_FAILURE: "DELETE_MERCHANT_FAILURE",
  DELETE_MERCHANT_SUCCESS: "DELETE_MERCHANT_SUCCESS",
  ACTIVATE_MERCHANT: "ACTIVATE_MERCHANT",
  ACTIVATE_MERCHANT_FAILURE: "ACTIVATE_MERCHANT_FAILURE",
  ACTIVATE_MERCHANT_SUCCESS: "ACTIVATE_MERCHANT_SUCCESS",
  GET_MASTER_DATA_MERCHANT: "GET_MASTER_DATA_MERCHANT",
  GET_MASTER_DATA_MERCHANT_FAILURE: "GET_MASTER_DATA_MERCHANT_FAILURE",
  GET_MASTER_DATA_MERCHANT_SUCCESS: "GET_MASTER_DATA_MERCHANT_SUCCESS",
  GET_MERCHANT_SETTING: "GET_MERCHANT_SETTING",
  GET_MERCHANT_SETTING_FAILURE: "GET_MERCHANT_SETTING_FAILURE",
  GET_MERCHANT_SETTING_SUCCESS: "GET_MERCHANT_SETTING_SUCCESS",
  CREATE_MERCHANT: "CREATE_MERCHANT",
  CREATE_MERCHANT_FAILURE: "CREATE_MERCHANT_FAILURE",
  CREATE_MERCHANT_SUCCESS: "CREATE_MERCHANT_SUCCESS",
  GET_DATA_BY_ID: "GET_DATA_BY_ID",
  GET_DATA_BY_ID_SUCCESS: "GET_DATA_BY_ID_SUCCESS",
  GET_DATA_BY_ID_FAILURE: "GET_DATA_BY_ID_FAILURE",
  UPDATE_DATA: "UPDATE_DATA",
  UPDATE_DATA_SUCCESS: "UPDATE_DATA_SUCCESS",
  UPDATE_DATA_FAILURE: "UPDATE_DATA_FAILURE",
  DEFAULT_STATUS: "DEFAULT_STATUS",
  GET_CITIES: "GET_CITIES",
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
  GET_CITIES_FAILURE: "GET_CITIES_FAILURE",
  GET_MG_LIST: "GET_MG_LIST",
  GET_MG_LIST_FAILURE: "GET_MG_LIST_FAILURE",
  GET_MG_LIST_SUCCESS: "GET_MG_LIST_SUCCESS",
  GET_TEMPLATE: "GET_TEMPLATE",
  GET_TEMPLATE_SUCCESS: "GET_TEMPLATE_SUCCESS",
  GET_TEMPLATE_FAILURE: "GET_TEMPLATE_FAILURE",
  UPLOAD_EXCEL: "UPLOAD_EXCEL",
  UPLOAD_EXCEL_SUCCESS: "UPLOAD_EXCEL_SUCCESS",
  UPLOAD_EXCEL_FAILURE: "UPLOAD_EXCEL_FAILURE",
  UPLOAD_DEFAULT: "UPLOAD_DEFAULT",
  RESEND_EMAIL: "RESEND_EMAIL",
  RESEND_EMAIL_SUCCESS: "RESEND_EMAIL_SUCCESS",
  RESEND_EMAIL_FAILURE: "RESEND_EMAIL_FAILURE",
  GET_CATEGORY_CODE: "GET_CATEGORY_CODE",
  GET_CATEGORY_CODE_SUCCESS: "GET_CATEGORY_CODE_SUCCESS",
  GET_CATEGORY_CODE_FAILURE: "GET_CATEGORY_CODE_FAILURE",
  UPDATE_URL_CALLBACK: "UPDATE_URL_CALLBACK",
  UPDATE_URL_CALLBACK_SUCCESS: "UPDATE_URL_CALLBACK_SUCCESS",
  UPDATE_URL_CALLBACK_FAILURE: "UPDATE_URL_CALLBACK_FAILURE",
};

export default MerchantManagementTypes;
