import { useDispatch, useSelector } from "react-redux";
import { hideAlert } from "../../redux/actions/actions.actions";

const Alert = () => {
  const dispatch = useDispatch();
  const {showAlert, alertMessage, alertType} = useSelector(state => state.actionsUser);

  const generateStyle = (type) => {
    switch (type) {
      case "success":
        return "bg-success";
      case "error":
        return "bg-error";
      default:
        return "bg-primary";
    }
  }

  return (
    <div
      className={`toast align-items-center text-white ${alertType === "error" ? "bg-danger" : "bg-success" } border-0`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      style={{
        opacity: showAlert ? 1 : 0,
        position: "fixed",
        margin: "10px",
        zIndex: showAlert ? 9999 : -1,
        right: showAlert ? 0 : "-1000px",
        top: 0,
      }}
    >
      <div className="d-flex">
        <div className="toast-body">{alertMessage}</div>
        <button
          type="button"
          className="btn-close border-0 me-2 m-auto"
          style={{background: "none", color: "#fff"}}
          aria-label="Close"
          onClick={() => dispatch(hideAlert())}
        ><i className="fas fa-times"></i></button>
      </div>
    </div>
  );
};

export default Alert;