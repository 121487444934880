import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showAlert, showLoading } from "../../redux/actions/actions.actions";
import { HotelServices } from "../../_services";

const useHotelForm = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  const [data, setData] = useState({});

  const getData = async (id) => {
    try {
      dispatch(showLoading());
      const res = await HotelServices.getHotelDetail(id, token);

      setData(res.data);
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
    }
  }

  const saveData = async (data, id) => {
    try {
      dispatch(showLoading());
      if (id !== 'null') {
        await HotelServices.updateHotels(id, data, token);
      }
      else {
        await HotelServices.createHotels(data, token);
      }
      dispatch(hideLoading());
      dispatch(showAlert("success", "Success save data"));
      window.history.back();
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("error", "Failed to save data"));    }
  }
  
  const deleteData = async (id) => {
    try {
      dispatch(showLoading());
      await HotelServices.deleteHotels(id, token);
      dispatch(showAlert("success", "Success delete data"));
      dispatch(hideLoading());
      window.history.back();
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert("error", "Failed to delete data"));
    }
  }

  return [data, getData, saveData, deleteData];
}

export default useHotelForm;