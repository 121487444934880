/**
 * Created by f.putra on 13/06/18.
 */
export * from './profile.services'
export * from './master-menu.services'
export * from './master-role.services'
export * from './master-user.services'
export * from './merchant-management.services'
export * from './transaction.services'
export * from './master-data.services'
export * from './user-management.services'
export * from './merchant-group.services'
export * from './settlement-jalin.services'
export * from './report.services'
export * from './bi-report.services'
export * from './dashboard.services'
export * from './activate.services'
export * from './ref-code.services'
export * from './consumer.services'
export * from './banner.services'
export * from './image.services'
export * from './consumer-transaction.services'
export * from './product-category.services'
export * from './operator.services'
export * from './product.services'
export * from './parameter.services'
export * from './disbursement.services'
export * from './flight.services'
export * from './hotel.services'
export * from './partner.services'
