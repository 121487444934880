const SettlementJalinTypes = {
  GET_JALIN_SETTLEMENT: "GET_JALIN_SETTLEMENT",
  GET_JALIN_SETTLEMENT_SUCCESS: "GET_JALIN_SETTLEMENT_SUCCESS",
  GET_JALIN_SETTLEMENT_FAILURE: "GET_JALIN_SETTLEMENT_FAILURE",
  GET_SETTLEMENT_BY_ID: "GET_SETTLEMENT_BY_ID",
  GET_SETTLEMENT_BY_ID_SUCCESS: "GET_SETTLEMENT_BY_ID_SUCCESS",
  GET_SETTLEMENT_BY_ID_FAILURE: "GET_SETTLEMENT_BY_ID_FAILURE",
};

export default SettlementJalinTypes;
