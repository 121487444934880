import request from "../_network/request"

const dashboardUrl = "/v1/dashboard";

const getTotalAmount = (token, merchantId) => {
  const url = merchantId === "" ? `${dashboardUrl}/total-amount-settlement` : `${dashboardUrl}/total-amount-settlement/by-merchantId/${merchantId}`;
  return request({
    url: url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })  
}

const getTotalMerchant = (token) => {
  return request({
    url: `${dashboardUrl}/total-merchant`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const getTotalPendingMerchant = (token) => {
  return request({
    url: `${dashboardUrl}/total-pending-merchant`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const getTotalTransaction = (token, merchantId) => {
  const url = merchantId === "" ? `${dashboardUrl}/total-transaction` : `${dashboardUrl}/total-transaction/by-merchantId/${merchantId}`;
  return request({
    url: url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const getMonthlySettlement = (token, merchantId) => {
  const url = merchantId === "" ? `${dashboardUrl}/monthly-settlement` : `${dashboardUrl}/monthly-settlement/by-merchantId/${merchantId}`;
  return request({
    url: url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const getMonthlyTransaction = (token, merchantId) => {
  const url = merchantId === "" ? `${dashboardUrl}/monthly-transaction` : `${dashboardUrl}/monthly-transaction/by-merchantId/${merchantId}`;
  return request({
    url: url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export const DashboardServices = {
  getTotalAmount,
  getTotalMerchant,
  getTotalPendingMerchant,
  getTotalTransaction,
  getMonthlySettlement,
  getMonthlyTransaction,
}