import { useState } from "react";

const SearchMerchant = ({ searchQuery, handleSearch }) => {
  const defaultValue = {
    merchantName: "",
    phoneOwner: "",
    email: "",
    phoneStore: "",
    address: "",
    bankName: "",
    "city.cityName": "",
    bankAccount: "",
    "province.provinceName": "",
    mdr: "",
    status: "",
    lastModifiedDate: "",
  };
  const [searchFields, setSearchField] = useState(searchQuery);
  const [formSearch, setFormSearch] = useState(defaultValue);

  const onSearch = () => {
    const searchTemp = [];

    for (const key in formSearch) {
      if (Object.hasOwnProperty.call(formSearch, key)) {
        const element = formSearch[key];
        if (element !== "") searchTemp.push({ param: key, value: element });
      }
    }

    setSearchField(searchTemp);
    handleSearch(searchTemp);
  };

  const handleChange = (e) => {
    let newForm = { ...formSearch };
    newForm[e.target.name] = e.target.value;
    setFormSearch(newForm);
  };

  const onReset = () => {
    setFormSearch(defaultValue);
    setSearchField([]);
    handleSearch([]);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="form-horizontal">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-1">
                  <label for="inputMerchantName">Merchant Name</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    className="form-control"
                    name="merchantName"
                    placeholder="Merchant Name"
                    onChange={handleChange}
                    value={formSearch.merchantName}
                  />
                </div>
                <div className="col-sm-1">
                  <label for="inputPhoneOwner">Phone Owner</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    className="form-control"
                    name="phoneOwner"
                    placeholder="Phone Owner"
                    onChange={handleChange}
                    value={formSearch.phoneOwner}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-1">
                  <label for="inputEmail">Email</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    value={formSearch.email}
                  />
                </div>
                <div className="col-sm-1">
                  <label for="inputPhoneStore">Phone Store</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    className="form-control"
                    name="phoneStore"
                    placeholder="Phone Store"
                    onChange={handleChange}
                    value={formSearch.phoneStore}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-1">
                  <label for="inputAddress">Address</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    placeholder="Address"
                    onChange={handleChange}
                    value={formSearch.address}
                  />
                </div>
                <div className="col-sm-1">
                  <label for="inputBankName">Bank Name</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    className="form-control"
                    name="bankName"
                    placeholder="Bank Name"
                    onChange={handleChange}
                    value={formSearch.bankName}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-1">
                  <label for="inputCity">City</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    className="form-control"
                    name="city.cityName"
                    placeholder="City"
                    onChange={handleChange}
                    value={formSearch["city.cityName"]}
                  />
                </div>
                <div className="col-sm-1">
                  <label for="inputBankAccount">Bank Account</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    className="form-control"
                    name="bankAccount"
                    placeholder="Bank Account"
                    onChange={handleChange}
                    value={formSearch.bankAccount}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-1">
                  <label for="inputProvince">Province</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    className="form-control"
                    name="province.provinceName"
                    placeholder="Province"
                    onChange={handleChange}
                    value={formSearch["province.provinceName"]}
                  />
                </div>
                <div className="col-sm-1">
                  <label for="inputMDR">MDR (%)</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    className="form-control"
                    name="mdr"
                    placeholder="MDR (%)"
                    onChange={handleChange}
                    value={formSearch.mdr}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-1">
                  <label for="inputStatus">Status</label>
                </div>
                <div className="col-sm-5">
                  <select
                    className="form-control select2"
                    style={{ width: "100%" }}
                    name="status"
                    onChange={handleChange}
                    value={formSearch.status}
                  >
                    <option value=""></option>
                    <option value="true">Active</option>
                    <option value="false">Non Active</option>
                  </select>
                </div>
                <div className="col-sm-1">
                  <label for="inputActDate">Activation Date</label>
                </div>
                <div className="col-sm-5">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-calendar-alt"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control float-right"
                      name="lastModifiedDate"
                      onChange={handleChange}
                      value={formSearch.lastModifiedDate}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="pull-right" style={{ width: "100%" }}>
                  <button
                    type="button"
                    onClick={onSearch}
                    className="btn btn-info"
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    onClick={onReset}
                    className="btn btn-info"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchMerchant;
