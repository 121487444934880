import React from "react";

const EmailField = ({
  label,
  name,
  required = false,
  type = "email",
  onChange,
  value,
  disabled = false,
  maxLength = 255,
  labelClassName = "col-sm-2",
  inputClassName = "col-sm-6",
  min,
  max,
  step,
  suffix,
  requiredMsg,
}) => {
  return (
    <div className="form-group row">
      <div className={labelClassName}>
        <label for="inputMerchantName">{`${label}${
          required ? "*" : ""
        }`}</label>
      </div>
      <div className={inputClassName}>
        <input
          type={type}
          name={name}
          id={name}
          className={`form-control ${
            value.includes("@") &&
            required &&
            !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
              ? ""
              : "is-invalid"
          }`}
          placeholder={
            label.includes("Date") || label.includes("Period")
              ? "YYYY-MM-DD"
              : label
          }
          value={value ?? ""}
          onChange={onChange}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
          min={min}
          max={max}
          step={step}
          pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"
        />
        {suffix && (
          <div class="input-group-append" style={{ height: "38px" }}>
            <span class="input-group-text" id="basic-addon2">
              {suffix}
            </span>
          </div>
        )}
        {requiredMsg && <div class="valid-feedback">{requiredMsg}</div>}
      </div>
    </div>
  );
};

export default EmailField;
