const ProductTypes = {
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "GET_PRODUCTS_FAILURE",
  GET_AGENTS: "GET_AGENTS",
  GET_AGENTS_SUCCESS: "GET_AGENTS_SUCCESS",
  GET_AGENTS_FAILURE: "GET_AGENTS_FAILURE",
  GET_PROVIDER_OPTIONS: "GET_PROVIDER_OPTIONS",
  GET_PROVIDER_OPTIONS_SUCCESS: "GET_PROVIDER_OPTIONS_SUCCESS",
  GET_PROVIDER_OPTIONS_FAILURE: "GET_PROVIDER_OPTIONS_FAILURE",
  GET_PRODUCT_DETAIL: "GET_PRODUCT_DETAIL",
  GET_PRODUCT_DETAIL_SUCCESS: "GET_PRODUCT_DETAIL_SUCCESS",
  GET_PRODUCT_DETAIL_FAILURE: "GET_PRODUCT_DETAIL_FAILURE",
  CREATE_PRODUCT: "CREATE_PRODUCT",
  CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_FAILURE: "CREATE_PRODUCT_FAILURE",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  UPDATE_PRODUCT_FAILURE: "UPDATE_PRODUCT_FAILURE",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",
  DEFAULT_PRODUCT_STATE: "DEFAULT_PRODUCT_STATE",
  UPLOAD_PRODUCTS: "UPLOAD_PRODUCTS",
  UPLOAD_PRODUCTS_SUCCESS: "UPLOAD_PRODUCTS_SUCCESS",
  UPLOAD_PRODUCTS_FAILURE: "UPLOAD_PRODUCTS_FAILURE",
  UPLOAD_PRODUCTS_DEFAULT: "UPLOAD_PRODUCTS_DEFAULT",
};

export default ProductTypes;
