import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import SearchGrid from "../../components/search-grid/index.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { defaultRefCodeState, getRefCode } from "../../redux/refcode/refCode.actions";

const RefCode = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [orderBy, setOrderBy] = useState({
    field: "",
    direction: false,
  });
  const [searchValue, setSearchValue] = useState([]);
  const { refGroup, refCodesPhase2 } = useSelector((state) => state.masterData);
  const { refCodeList, first, last, totalData, totalPage, empty } =
    useSelector((state) => state.refCodes);
  const history = useHistory();
  const [displayData, setDisplayData] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);

  const headerActionButton = [
    <button
      type="button"
      name="save"
      className="btn btn-success"
      onClick={() => history.push("/ref-group-form/null")}
    >
      <i className="fas fa-plus-square"></i>
      Add Group
    </button>,
    <button
      type="button"
      name="save"
      className="btn btn-success"
      onClick={() => history.push("/ref-code-form/null")}
    >
      <i className="fas fa-plus-square"></i>
      Add
    </button>,
  ];

  const tableHeaders = [
    {
      text: "Group Ref Code",
      sort: true,
      name: "refGroup",
      search: true,
      type: "text",
      position: "left",
      searchType: "select",
      options: searchOptions,
      index: 0,
    },
    {
      text: "Ref Code",
      sort: true,
      name: "refCode",
      search: true,
      type: "text",
      position: "left",
      searchType: "text",
      index: 1,
    },
    {
      text: "Ref Name",
      sort: true,
      name: "refName",
      search: true,
      type: "text",
      position: "left",
      searchType: "text",
      index: 2,
    },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-edit" aria-hidden="true"></span>,
      description: "Edit",
      action: (val) => { history.push(`/ref-code-form/${val.id}`) },
      condition: (val) => true,
    },
  ];

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Reference Code",
        hasCustomButton: true,
        customButton: headerActionButton,
      })
    );
    dispatch(defaultRefCodeState());
  }, []);

  useEffect(() => {
    dispatch(getRefCode(page, size, searchValue, orderBy));
  }, [page, size, orderBy, searchValue]);

  useEffect(() => {
    let tempOptions = [];
    refGroup.forEach(item => {
      tempOptions.push({
        value: item.refGroup,
        label: item.refGroup,
      });
    });
    setSearchOptions(tempOptions);
  }, [refGroup])

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (val) => {
    setPage(0);
    setSearchValue(val);
  }

  return (
    <div class="container-fluid">
      <SearchGrid searchField={tableHeaders} handleSearch={handleSearch} />
      <DataTables
        headers={tableHeaders}
        listData={refCodeList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default RefCode;
