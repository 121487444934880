import ConsumerTypes from "./consumer.types";

const INITIAL_STATE = {
  consumerList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  error: false,

  consumerDetail: null,
  histories: [],
  sendingStatus: "",

  selectRoles: [],
  selectStatus: [],
  selectUpgrade: [],
  cities: [],
};

const ConsumerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConsumerTypes.GET_DATA_CONSUMER:
      return {
        ...state,
        error: false,
      };
    case ConsumerTypes.GET_DATA_CONSUMER_SUCCESS:
      return {
        ...state,
        consumerList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case ConsumerTypes.GET_DATA_CONSUMER_FAILURE:
      return {
        ...state,
        error: true,
      };
    case ConsumerTypes.GET_CONSUMER_DETAIL:
      return {
        ...state,
        consumerDetail: null,
        histories: [],
      };
    case ConsumerTypes.GET_CONSUMER_DETAIL_SUCCESS:
      return {
        ...state,
        consumerDetail: action.payload.detail,
        histories: action.payload.histories
      };
    case ConsumerTypes.GET_CONSUMER_DETAIL_FAILURE:
      return {
        ...state,
        consumerDetail: null,
        histories: [],
      };
    case ConsumerTypes.UPDATE_DATA_CONSUMER:
      return {
        ...state,
        sendingStatus: "send",
      };
    case ConsumerTypes.UPDATE_DATA_CONSUMER_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case ConsumerTypes.UPDATE_DATA_CONSUMER_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case ConsumerTypes.GET_CONSUMER_MASTER_DATA:
      return {
        ...state,
      }
    case ConsumerTypes.GET_CONSUMER_MASTER_DATA_SUCCESS:
      return {
        ...state,
        selectRoles: action.payload.selectRoles,
        selectStatus: action.payload.selectStatus,
        selectUpgrade: action.payload.selectUpgrade
      }
    case ConsumerTypes.GET_CONSUMER_MASTER_DATA_FAILURE:
      return {
        ...state,
      }
    case ConsumerTypes.GET_CONSUMER_CITIES:
      return {
        ...state,
      }
    case ConsumerTypes.GET_CONSUMER_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload
      }
    case ConsumerTypes.GET_CONSUMER_CITIES_FAILURE:
      return {
        ...state,
      }
    case ConsumerTypes.DEFAULT_CONSUMER_STATE:
      return {
        ...state,
        sendingStatus: "",
        consumerDetail: null,
      };
    default:
      return state;
  }
};

export default ConsumerReducer;