import { batch } from "react-redux";
import { DashboardServices } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import DashboardTypes from "./dashboard.types";

export const getDashboardData = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const user = state.auth.user;
    try {
      batch(() => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING_TABLE,
        });
      });
      const merchantId = user.merchant ? user.merchant.merchantId : "";

      const totalAmount = await DashboardServices.getTotalAmount(token, merchantId);
      const totalMerchant = user.role.roleName !== "MERCHANT" ? await DashboardServices.getTotalMerchant(token) : {data: null};
      const totalPendingMerchant = user.role.roleName !== "MERCHANT" ? await DashboardServices.getTotalPendingMerchant(token) : {data: null};
      const totalTransaction = await DashboardServices.getTotalTransaction(token, merchantId);
      const monthlySettlement = await DashboardServices.getMonthlySettlement(token, merchantId);
      const monthlyTransaction = await DashboardServices.getMonthlyTransaction(token, merchantId);

      batch(() => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_SUCCESS,
          payload: {
            totalAmount: totalAmount.data,
            totalMerchant: totalMerchant.data,
            totalPendingMerchant: totalPendingMerchant.data,
            totalTransaction: totalTransaction.data,
            monthlySettlement: monthlySettlement.data,
            monthlyTransaction: monthlyTransaction.data,
          },
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    } catch (err) {
      batch(() => {
        dispatch({
          type: DashboardTypes.GET_DASHBOARD_FAILURE,
          payload: err.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    }
  };
};