import { batch } from "react-redux";
import { MasterDataService, MerchantManagementService } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import MerchantManagementTypes from "./merchant-management.types";

export const getDataList = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.GET_DATA_MERCHANT,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING_TABLE,
        });
      });

      const listMerchant = await MerchantManagementService.getListData(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: MerchantManagementTypes.GET_DATA_MERCHANT_SUCCESS,
          payload: listMerchant.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    } catch (err) {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.GET_DATA_MERCHANT_FAILURE,
          payload: err.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    }
  };
};

export const getMerchantSetting = (page, size, searchQuery, order) => {
return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
    batch(() => {
        dispatch({
        type: MerchantManagementTypes.GET_MERCHANT_SETTING,
        });
        dispatch({
        type: ActionsActionTypes.SHOW_LOADING_TABLE,
        });
    });

    const listMerchant = await MerchantManagementService.getMerchantSetting(
        page,
        size,
        searchQuery,
        order,
        token
    );

    batch(() => {
        dispatch({
        type: MerchantManagementTypes.GET_MERCHANT_SETTING_SUCCESS,
        payload: listMerchant.data,
        });
        dispatch({
        type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
    });
    } catch (err) {
    batch(() => {
        dispatch({
        type: MerchantManagementTypes.GET_MERCHANT_SETTING_FAILURE,
        payload: err.data,
        });
        dispatch({
        type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
    });
    }
};
};

export const deleteMerchant = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.DELETE_MERCHANT,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING,
        });
      });
      const deleting = await MerchantManagementService.deleteMerchantData(
        id,
        token
      );
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.DELETE_MERCHANT_SUCCESS,
          payload: deleting.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Success delete merchant",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.DELETE_MERCHANT_FAILURE,
          payload: error.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Failure delete merchant",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const activateMerchant = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.ACTIVATE_MERCHANT,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING,
        });
      });
      const activating = await MerchantManagementService.activatingMerchant(
        id,
        token
      );
      if (activating.statusCode === 200) {
        batch(() => {
          dispatch({
            type: MerchantManagementTypes.ACTIVATE_MERCHANT_SUCCESS,
            payload: activating.data,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Activated merchant success",
              alertType: "success",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: MerchantManagementTypes.ACTIVATE_MERCHANT_FAILURE,
            payload: activating.data,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: activating.data,
              alertType: "error",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.ACTIVATE_MERCHANT_FAILURE,
          payload: error.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Activated merchant failed",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const resendMail = (email) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.RESEND_EMAIL,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING,
        });
      });
      const activating = await MerchantManagementService.resendEmail(
        email,
        token
      );
      if (activating.statusCode === 200) {
        batch(() => {
          dispatch({
            type: MerchantManagementTypes.RESEND_EMAIL_SUCCESS,
            payload: activating.data,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Resend activation email success",
              alertType: "success",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: MerchantManagementTypes.RESEND_EMAIL_FAILURE,
            payload: activating.data,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: activating.data,
              alertType: "error",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.ACTIVATE_MERCHANT_FAILURE,
          payload: error.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Resend activation email failed",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const getMasterData = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({
        type: MerchantManagementTypes.GET_MASTER_DATA_MERCHANT,
      });
      const panOptions = await MerchantManagementService.merchantPanOption(
        token
      );
      const criteriaOptions = await MerchantManagementService.criteriaOption(
        token
      );
      dispatch({
        type: MerchantManagementTypes.GET_MASTER_DATA_MERCHANT_SUCCESS,
        payload: {
          panOptions: panOptions.data.content,
          criteriaOptions: criteriaOptions.data.content,
        },
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Error loading master data",
            alertType: "error",
          },
        });
        dispatch({
          type: MerchantManagementTypes.GET_MASTER_DATA_MERCHANT_FAILURE,
        });
      });
    }
  };
};

export const getCategory = (categoryValue) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({ type: MerchantManagementTypes.GET_CATEGORY_CODE });

      const categoryOptions = await MerchantManagementService.categoryOption(
        categoryValue,
        token
      );

      dispatch({
        type: MerchantManagementTypes.GET_CATEGORY_CODE_SUCCESS,
        payload: categoryOptions.data.content,
      });
    } catch (error) {
      dispatch({ type: MerchantManagementTypes.GET_CATEGORY_CODE_FAILURE });
    }
  };
};

export const getCities = (provinceId, type) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({
        type: MerchantManagementTypes.GET_CITIES,
      });
      const cities = await MasterDataService.getCities(provinceId, token);
      dispatch({
        type: MerchantManagementTypes.GET_CITIES_SUCCESS,
        payload: { cities: cities.data.content, type: type },
      });
    } catch (error) {
      dispatch({
        type: MerchantManagementTypes.GET_CITIES_FAILURE,
      });
    }
  };
};

export const getMerchantGroup = (merchantName) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({ type: MerchantManagementTypes.GET_MG_LIST });
      const merchantGroupList =
        await MerchantManagementService.getMerchantGroup(merchantName, token);
      if (merchantGroupList.statusCode === 200) {
        dispatch({
          type: MerchantManagementTypes.GET_MG_LIST_SUCCESS,
          payload: merchantGroupList.data,
        });
      } else {
        dispatch({ type: MerchantManagementTypes.GET_MG_LIST_FAILURE });
      }
    } catch (error) {
      dispatch({ type: MerchantManagementTypes.GET_MG_LIST_FAILURE });
    }
  };
};

export const createMerchant = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const processedData = { ...data };
    try {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.CREATE_MERCHANT,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING,
        });
      });

      if (typeof data.imageIdentity !== "string") {
        const uploadImageIdentity = await MerchantManagementService.uploadImage(
          data.imageIdentity,
          token
        );
        processedData.imageIdentity = `${uploadImageIdentity.data.path}/${uploadImageIdentity.data.filename}`;
      }
      if (typeof data.imageNpwp !== "string") {
        const uploadImageNpwp = await MerchantManagementService.uploadImage(
          data.imageNpwp,
          token
        );
        processedData.imageNpwp = `${uploadImageNpwp.data.path}/${uploadImageNpwp.data.filename}`;
      }
      if (typeof data.imageSiup !== "string") {
        const uploadImageSiup = await MerchantManagementService.uploadImage(
          data.imageSiup,
          token
        );
        processedData.imageSiup = `${uploadImageSiup.data.path}/${uploadImageSiup.data.filename}`;
      }
      if (processedData.merchantGroupId === "") {
        delete processedData.merchantGroup;
      }
      processedData.merchantPan = processedData.digitNine;
      const requestCreateMerchant =
        await MerchantManagementService.createMerchant(processedData, token);

      if (requestCreateMerchant.statusCode === 200) {
        batch(() => {
          dispatch({
            type: MerchantManagementTypes.CREATE_MERCHANT_SUCCESS,
            // payload: requestCreateMerchant.data,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Success add new merchant",
              alertType: "success",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: MerchantManagementTypes.CREATE_MERCHANT_FAILURE,
            payload: requestCreateMerchant.data,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: requestCreateMerchant.data,
              alertType: "error",
            },
          });
        });
      }
    } catch (err) {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.CREATE_MERCHANT_FAILURE,
          payload: err.message,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Create new merchant failed",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const initCreate = () => {
  return async (dispatch) => {
    dispatch({
      type: MerchantManagementTypes.DEFAULT_STATUS,
    });
  };
};

export const getDataById = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;

    try {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.GET_DATA_BY_ID,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING_TABLE,
        });
      });

      const dataById = await MerchantManagementService.getDataById(id, token);

      batch(() => {
        dispatch({
          type: MerchantManagementTypes.GET_DATA_BY_ID_SUCCESS,
          payload: dataById.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.GET_DATA_BY_ID_FAILURE,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error.message,
            alertType: "error",
          },
        });
      });
    }
  };
};

export const updateMerchant = (id, data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const processedData = { ...data };
    try {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.UPDATE_DATA,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING,
        });
      });

      if (processedData.merchantGroupId === "") {
        delete processedData.merchantGroup;
      } else {
        processedData.merchantGroupId = processedData.merchantGroup.merchantGroupId;
      }
      delete processedData.activationDate;
      delete processedData.id;
      delete processedData.digitNine;
      delete processedData.merchantQrisList;
      delete processedData.imageStaticQris;
      delete processedData.merchantId;
      if (
        data.imageIdentity !== null &&
        typeof data.imageIdentity !== "string"
      ) {
        const uploadImageIdentity = await MerchantManagementService.uploadImage(
          data.imageIdentity,
          token
        );
        processedData.imageIdentity = `${uploadImageIdentity.data.path}/${uploadImageIdentity.data.filename}`;
      }
      if (data.imageNpwp !== null && typeof data.imageNpwp !== "string") {
        const uploadImageNpwp = await MerchantManagementService.uploadImage(
          data.imageNpwp,
          token
        );
        processedData.imageNpwp = `${uploadImageNpwp.data.path}/${uploadImageNpwp.data.filename}`;
      }
      if (data.imageSiup !== null && typeof data.imageSiup !== "string") {
        const uploadImageSiup = await MerchantManagementService.uploadImage(
          data.imageSiup,
          token
        );
        processedData.imageSiup = `${uploadImageSiup.data.path}/${uploadImageSiup.data.filename}`;
      }
      processedData.imageIdentity = processedData.imageIdentity ?? "";
      processedData.imageNpwp = processedData.imageNpwp ?? "";
      processedData.imageSiup = processedData.imageSiup ?? "";

      const updating = await MerchantManagementService.updateMerchant(
        id,
        processedData,
        token
      );

      if (updating.statusCode === 200) {
        batch(() => {
          dispatch({
            type: MerchantManagementTypes.UPDATE_DATA_SUCCESS,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Update data success",
              alertType: "success",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: MerchantManagementTypes.UPDATE_DATA_FAILURE,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: updating.data,
              alertType: "error",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.UPDATE_DATA_FAILURE,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update merchant error",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const updateUrlCallback = (id, data) => {
    return async (dispatch, getState) => {
      const state = getState();
      const token = state.auth.token;
      const processedData = {
            "merchantId": id,
            "urlCallbackQris": data.urlCallbackQris
      };
      try {
        batch(() => {
          dispatch({
            type: MerchantManagementTypes.UPDATE_URL_CALLBACK,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_LOADING,
          });
        });
  
  
        const updating = await MerchantManagementService.updateUrlCallback(
          processedData,
          token
        );
  
        if (updating.statusCode === 200) {
          batch(() => {
            dispatch({
              type: MerchantManagementTypes.UPDATE_URL_CALLBACK_SUCCESS,
            });
            dispatch({
              type: ActionsActionTypes.HIDE_LOADING,
            });
            dispatch({
              type: ActionsActionTypes.SHOW_ALERT,
              payload: {
                alertMessage: "Update data success",
                alertType: "success",
              },
            });
          });
        } else {
          batch(() => {
            dispatch({
              type: MerchantManagementTypes.UPDATE_URL_CALLBACK_FAILURE,
            });
            dispatch({
              type: ActionsActionTypes.HIDE_LOADING,
            });
            dispatch({
              type: ActionsActionTypes.SHOW_ALERT,
              payload: {
                alertMessage: updating.data,
                alertType: "error",
              },
            });
          });
        }
      } catch (error) {
        batch(() => {
          dispatch({
            type: MerchantManagementTypes.UPDATE_URL_CALLBACK_FAILURE,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Update merchant error",
              alertType: "error",
            },
          });
        });
      }
    };
  };

export const getTemplate = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({ type: MerchantManagementTypes.GET_TEMPLATE });

      const template = await MerchantManagementService.getTemplate(token);

      dispatch({
        type: MerchantManagementTypes.GET_TEMPLATE_SUCCESS,
        payload: template,
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: MerchantManagementTypes.GET_TEMPLATE_FAILURE });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Get template upload batch error",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const uploadFile = (file) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: MerchantManagementTypes.UPLOAD_EXCEL });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      await MerchantManagementService.uploadFile(file, token);

      batch(() => {
        dispatch({ type: MerchantManagementTypes.UPLOAD_EXCEL_SUCCESS });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Success upload file",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: MerchantManagementTypes.UPLOAD_EXCEL_FAILURE,
          payload: error.data.data || error.data.message,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Upload file error",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const defaultUploadState = () => {
  return async (dispatch) => {
    dispatch({ type: MerchantManagementTypes.UPLOAD_DEFAULT });
  };
};
