import CategoryTypes from "./product-category.types";

const INITIAL_STATE = {
  categoriesList: [],
  categoryByName: [],
  loading: false,
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  error: false,

  categoryDetail: null,

  sendingStatus: "",
};

const CategoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoryTypes.GET_CATEGORY_BY_NAME:
      return {
        ...state,
        loading: true,
        categoryByName: [],
      };
    case CategoryTypes.GET_CATEGORY_BY_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryByName: action.payload,
      };
    case CategoryTypes.GET_CATEGORY_BY_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        categoryByName: [],
      };
    case CategoryTypes.GET_CATEGORIES:
      return {
        ...state,
        error: false,
      };
    case CategoryTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case CategoryTypes.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        error: true,
      };
    case CategoryTypes.GET_CATEGORY_DETAIL:
      return {
        ...state,
        categoryDetail: null,
      };
    case CategoryTypes.GET_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        categoryDetail: action.payload,
      };
    case CategoryTypes.GET_CATEGORY_DETAIL_FAILURE:
      return {
        ...state,
        categoryDetail: null,
      };
    case CategoryTypes.CREATE_CATEGORY:
      return {
        ...state,
        sendingStatus: "send",
      };
    case CategoryTypes.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case CategoryTypes.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case CategoryTypes.UPDATE_CATEGORY:
      return {
        ...state,
        sendingStatus: "send",
      };
    case CategoryTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case CategoryTypes.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case CategoryTypes.DELETE_CATEGORY:
      return {
        ...state,
        sendingStatus: "send",
      };
    case CategoryTypes.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case CategoryTypes.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case CategoryTypes.DEFAULT_CATEGORY_STATE:
      return {
        ...state,
        sendingStatus: "",
        categoryDetail: null,
      };
    default:
      return state;
  }
};

export default CategoryReducer;
