import request from "../_network/request";

const urlRefCode = "/v1/ref-code/";
const urlRefGroup = "/v1/ref-groups";
const urlRefCodePhase2 = "/v1/ref-code/";
const urlProvinces = "/v1/provinces";
const urlCities = "/v1/cities";
const urlCities2 = "/v1/cities";

const getRefCode = (groupName, token) => {
  return request({
    url: `${urlRefCode}${groupName}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getCities = (provinceId, token) => {
  return request({
    url: `${urlCities}/${provinceId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getProvinces = (token) => {
  return request({
    url: `${urlProvinces}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRefCodeGroupPhase2 = (groupName, token) => {
  return request({
    url: `${urlRefGroup}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRefCodePhase2 = (groupName, token) => {
  return request({
    url: `${urlRefCodePhase2}/${groupName}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getAllCity = (token) => {
  return request({
    url: `${urlCities2}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const MasterDataService = {
  getRefCode,
  getCities,
  getProvinces,
  getRefCodeGroupPhase2,
  getRefCodePhase2,
  getAllCity,
};
