const RefCodeTypes = {
  GET_PARAM_REF_CODE: "GET_PARAM_REF_CODE",
  GET_PARAM_REF_CODE_SUCCESS: "GET_PARAM_REF_CODE_SUCCESS",
  GET_PARAM_REF_CODE_FAILURE: "GET_PARAM_REF_CODE_FAILURE",
  CREATE_REF_CODE: "CREATE_REF_CODE",
  CREATE_REF_CODE_SUCCESS: "CREATE_REF_CODE_SUCCESS",
  CREATE_REF_CODE_FAILURE: "CREATE_REF_CODE_FAILURE",
  UPDATE_REF_CODE: "UPDATE_REF_CODE",
  UPDATE_REF_CODE_SUCCESS: "UPDATE_REF_CODE_SUCCESS",
  UPDATE_REF_CODE_FAILURE: "UPDATE_REF_CODE_FAILURE",
  DELETE_REF_CODE: "DELETE_REF_CODE",
  DELETE_REF_CODE_SUCCESS: "DELETE_REF_CODE_SUCCESS",
  DELETE_REF_CODE_FAILURE: "DELETE_REF_CODE_FAILURE",
  CREATE_GROUP_REF_CODE: "CREATE_GROUP_REF_CODE",
  CREATE_GROUP_REF_CODE_SUCCESS: "CREATE_GROUP_REF_CODE_SUCCESS",
  CREATE_GROUP_REF_CODE_FAILURE: "CREATE_GROUP_REF_CODE_FAILURE",
  DEFAULT_REF_CODE_STATE: "DEFAULT_REF_CODE_STATE",
}

export default RefCodeTypes;