/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import InputField from "../../components/input-field/input-field.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { createNotification, deleteNotification, getDetailNotification, updateNotification } from "../../redux/notification/notification.actions";
import request from "../../_network/request";

const NotificationForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { notificationDetail, sendingStatus } = useSelector(
    (state) => state.notifications
  );
  const { token } = useSelector((state) => state.auth);
  const [notificationImage, setNotificationImage] = useState("");
  const [dateSelected, setDateSelected] = useState({
    date: "",
    time: "",
  });
  const [formData, setFormData] = useState({
    subject: "",
    body: "",
    contentType: "",
    plannedDate: "",
    limitedToRoles: "",
    imageNotif: "",
  });

  const headerActionButton =
    params.id !== "null"
      ? [
          <button
            type="button"
            name="save"
            className="btn btn-danger"
            onClick={() => {
              if (window.confirm("Are you sure want to delete?")) {
                dispatch(deleteNotification(params.id));
              }
            }}
          >
            <i className="fas fa-trash"></i>
            Delete
          </button>,
          <button
            type="button"
            name="save"
            className="btn btn-success"
            onClick={() => onSave()}
          >
            <i className="fas fa-save"></i>
            Save
          </button>,
        ]
      : [
          <button
            type="button"
            name="save"
            className="btn btn-success"
            onClick={() => onSave()}
          >
            <i className="fas fa-save"></i>
            Save
          </button>,
        ];

  const onSave = useCallback(() => {
    if (params.id !== "null") {
      dispatch(updateNotification(formData));
    } else {
      dispatch(createNotification(formData));
    }
  }, [formData]);

  useEffect(() => {
    dispatch(
      setHeaderButton({
          titleHeader: "Edit Notification",
        isChild: true,
        hasCustomButton: true,
        customButton: headerActionButton
    })
    );
  }, [onSave]);

  useEffect(() => {
    if (params.id) dispatch(getDetailNotification(params.id));
  }, []);

  useEffect(() => {
    setFormData(temp => ({...temp, plannedDate: `${dateSelected.date}T${dateSelected.time}.000+00:00`}));
  }, [dateSelected])

  useEffect(() => {
    if (notificationDetail) {
      console.log(notificationDetail.plannedDate);
      const gmt = moment(notificationDetail.plannedDate);
      const dateOnly = gmt.local().format("DD-MM-YYYY");
      const timeOnly = gmt.local().format("HH:mm:ss");
      setDateSelected({
        date: dateOnly,
        time: timeOnly,
      });
      setFormData(notificationDetail);
      gettingImage(notificationDetail.imageNotif);
    }
  }, [notificationDetail]);

  const gettingImage = async (fileName) => {
    try {
      const fetchImage = await request({
        url: `/v1/upload-image/?filename=${fileName}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      });
      const imageBanner = new Buffer(fetchImage).toString("base64");
      setNotificationImage(imageBanner);
    } catch (error) {
      setFormData((temp) => ({ ...temp, imageBanner: "" }));
    }
  };

  const handleChange = (e) => {
    setFormData((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  };

  const deleteImage = () => {
    setFormData((temp) => ({ ...temp, imageNotif: "" }));
  };

  const handleChangeImage = (e) => {
    setFormData((temp) => ({ ...temp, imageNotif: e.target.files[0] }));
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Notification Info</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <InputField
                type="text"
                label="Subject"
                name="subject"
                value={formData?.subject}
                onChange={handleChange}
                required={true}
              />
              <div className="form-group row">
                <div className="col-sm-2">
                  <label for="inputStatus">Image Banner</label>
                </div>
                <div className="col-sm-10">
                  <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                    <li>
                      <span
                        className="mailbox-attachment-icon has-img"
                        style={{ minHeight: "132.25px" }}
                      >
                        {formData?.imageNotif === null ||
                        formData?.imageNotif === "" ? (
                          <i
                            className="fas fa-camera"
                            style={{ marginTop: "30px" }}
                          ></i>
                        ) : (
                          <img
                            src={
                              formData?.imageNotif
                                ? typeof formData?.imageNotif === "string"
                                  ? `data:image/png;base64,${notificationImage}`
                                  : URL.createObjectURL(formData?.imageNotif)
                                : ""
                            }
                            style={{
                              objectFit: "cover",
                              maxHeight: "132.25px",
                            }}
                            alt="Attachment"
                          />
                        )}
                      </span>

                      <div className="mailbox-attachment-info">
                        {formData?.imageNotif === null ||
                        formData?.imageNotif === "" ? (
                          <input
                            className="form-control-sm"
                            type="file"
                            name="imageNotif"
                            onChange={handleChangeImage}
                            accept="image/*"
                            required
                          />
                        ) : (
                          <a
                            onClick={() => {
                              deleteImage("imageNotif");
                            }}
                            className="mailbox-attachment-name"
                          >
                            <i className="fas fa-camera"></i>{" "}
                            {formData.imageNotif.name}
                          </a>
                        )}
                        <span className="mailbox-attachment-size clearfix mt-1">
                          <span>Banner</span>
                        </span>
                        <span className="mailbox-attachment-size clearfix mt-1">
                          <span>Max 5MB</span>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Content Type</label>
                </div>
                <div className="col-sm-6">
                  <div className="select2-primary">
                    <select
                      className="form-control select2"
                      data-dropdown-css-className="select2-primary"
                      style={{ width: "100%" }}
                      name="contentType"
                      value={formData?.contentType}
                      onChange={handleChange}
                    >
                      <option>....</option>
                      <option value="BODY_MESSAGE">Body Message</option>
                      <option value="URL">URL</option>
                      <option value="MOBILE_PAGE">Mobile Page</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Body</label>
                </div>
                <div className="col-sm-6">
                  <textarea id="summernote" value={formData?.body} name="body" onChange={handleChange}/>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Planned Date Sending</label>
                </div>
                <div className="col-sm-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-calendar-alt"></i>
                      </span>
                    </div>
                    <input
                      type="date"
                      className="form-control"
                      data-inputmask-alias="datetime"
                      data-inputmask-inputformat="dd/mm/yyyy"
                      data-mask
                      name="date"
                      value={dateSelected.date}
                      onChange={(e) => {
                        setDateSelected((temp) => ({
                          ...temp,
                          date: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div
                    className="input-group date"
                    id="timepicker"
                    data-target-input="nearest"
                  >
                    <input
                      type="time"
                      className="form-control"
                      value={dateSelected.time}
                      onChange={(e) => {
                        setDateSelected((temp) => ({
                          ...temp,
                          time: e.target.value,
                        }));
                      }}
                    />
                    {/* <div
                      className="input-group-append"
                      data-target="#timepicker"
                      data-toggle="datetimepicker"
                    >
                      <div className="input-group-text">
                        <i className="far fa-clock"></i>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Limited To Roles</label>
                </div>
                <div className="col-sm-6">
                  <div className="select2-primary">
                    <select
                      className="form-control select2"
                      data-dropdown-css-className="select2-primary"
                      style={{ width: "100%" }}
                      value={formData.limitedToRoles}
                      name="limitedToRoles"
                      onChange={handleChange}
                    >
                      <option value="">All</option>
                      <option value="Basic">Basic</option>
                      <option value="Premium">Premium</option>
                    </select>
                  </div>
                </div>
              </div>
              {formData.createdDate && (
                <div className="form-group row">
                  <div className="col-sm-2">
                    <label>Created On</label>
                  </div>
                  <div className="col-sm-6">
                    <label>{formData?.createdDate}</label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationForm;
