const BannerTypes = {
  GET_BANNERS: "GET_BANNERS",
  GET_BANNERS_SUCCESS: "GET_BANNERS_SUCCESS",
  GET_BANNERS_FAILURE: "GET_BANNERS_FAILURE",
  GET_BANNER_DETAIL: "GET_BANNER_DETAIL",
  GET_BANNER_DETAIL_SUCCESS: "GET_BANNER_DETAIL_SUCCESS",
  GET_BANNER_DETAIL_FAILURE: "GET_BANNER_DETAIL_FAILURE",
  CREATE_BANNER: "CREATE_BANNER",
  CREATE_BANNER_SUCCESS: "CREATE_BANNER_SUCCESS",
  CREATE_BANNER_FAILURE: "CREATE_BANNER_FAILURE",
  UPDATE_BANNER: "UPDATE_BANNER",
  UPDATE_BANNER_SUCCESS: "UPDATE_BANNER_SUCCESS",
  UPDATE_BANNER_FAILURE: "UPDATE_BANNER_FAILURE",
  DELETE_BANNER: "DELETE_BANNER",
  DELETE_BANNER_SUCCESS: "DELETE_BANNER_SUCCESS",
  DELETE_BANNER_FAILURE: "DELETE_BANNER_FAILURE",
  DEFAULT_BANNER_STATE: "DEFAULT_BANNER_STATE",
};

export default BannerTypes;
