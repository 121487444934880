import RefCodeTypes from "./refCode.types";

const INITIAL_STATE = {
  sendingStatus: "",
  refCodeList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
};

const RefCodeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RefCodeTypes.GET_PARAM_REF_CODE:
      return {
        ...state,
      };
    case RefCodeTypes.GET_PARAM_REF_CODE_SUCCESS:
      return {
        ...state,
        refCodeList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
      };
    case RefCodeTypes.GET_PARAM_REF_CODE_FAILURE:
      return {
        ...state,
        refCodeList: [],
      };
    case RefCodeTypes.CREATE_REF_CODE:
      return {
        ...state,
        sendingStatus: "sending"
      };
    case RefCodeTypes.CREATE_REF_CODE_SUCCESS:
      return {
        ...state,
        sendingStatus: "success"
      };
    case RefCodeTypes.CREATE_REF_CODE_FAILURE:
      return {
        ...state,
        sendingStatus: "failure"
      };
    case RefCodeTypes.UPDATE_REF_CODE:
      return {
        ...state,
        sendingStatus: "sending"
      };
    case RefCodeTypes.UPDATE_REF_CODE_SUCCESS:
      return {
        ...state,
        sendingStatus: "success"
      };
    case RefCodeTypes.UPDATE_REF_CODE_FAILURE:
      return {
        ...state,
        sendingStatus: "failure"
      };
    case RefCodeTypes.DELETE_REF_CODE:
      return {
        ...state,
        sendingStatus: "sending"
      };
    case RefCodeTypes.DELETE_REF_CODE_SUCCESS:
      return {
        ...state,
        sendingStatus: "success"
      };
    case RefCodeTypes.DELETE_REF_CODE_FAILURE:
      return {
        ...state,
        sendingStatus: "failure"
      };
    case RefCodeTypes.CREATE_GROUP_REF_CODE:
      return {
        ...state,
        sendingStatus: "sending"
      };
    case RefCodeTypes.CREATE_GROUP_REF_CODE_SUCCESS:
      return {
        ...state,
        sendingStatus: "success"
      };
    case RefCodeTypes.CREATE_GROUP_REF_CODE_FAILURE:
      return {
        ...state,
        sendingStatus: "failure"
      };
    case RefCodeTypes.DEFAULT_REF_CODE_STATE:
      return {
        ...state,
        sendingStatus: "",
      }
    default:
      return state;
  }
};

export default RefCodeReducer;
