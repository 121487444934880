const CategoryTypes = {
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",
  GET_CATEGORY_BY_NAME: "GET_CATEGORY_BY_NAME",
  GET_CATEGORY_BY_NAME_SUCCESS: "GET_CATEGORY_BY_NAME_SUCCESS",
  GET_CATEGORY_BY_NAME_FAILURE: "GET_CATEGORY_BY_NAME_FAILURE",
  GET_CATEGORY_DETAIL: "GET_CATEGORY_DETAIL",
  GET_CATEGORY_DETAIL_SUCCESS: "GET_CATEGORY_DETAIL_SUCCESS",
  GET_CATEGORY_DETAIL_FAILURE: "GET_CATEGORY_DETAIL_FAILURE",
  CREATE_CATEGORY: "CREATE_CATEGORY",
  CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
  CREATE_CATEGORY_FAILURE: "CREATE_CATEGORY_FAILURE",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAILURE: "UPDATE_CATEGORY_FAILURE",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",
  DEFAULT_CATEGORY_STATE: "DEFAULT_CATEGORY_STATE",
};

export default CategoryTypes;
