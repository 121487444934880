import request from "../_network/request";

const summaryUrl = "/v1/settlement-jalin";
const jalinReconUrl = "/v1/qr-recon-settlement";

const getSettlement = (page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`${i === 0 ? "" : "&"}${key.param}=${key.value}`);
    });
  }
  let order = `${
    orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;
  return request({
    url: `${jalinReconUrl}?${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
const getJalinRecon = (id, page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`${i === 0 ? "" : "&"}${key.param}=${key.value}`);
    });
  }
  let order = `${
    orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;
  return request({
    url: `${jalinReconUrl}/${id}?${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getReconHeader = (id, token) => {
  return request({
    url: `${jalinReconUrl}/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const downloadSettlement = (id, token) => {
  return request({
    url: `${jalinReconUrl}/${id}/document`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const SettlementJalinService = {
  getSettlement,
  getJalinRecon,
  getReconHeader,
  downloadSettlement,
};
