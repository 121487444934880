import request from "../_network/request";
const imageUrl = "/v1/upload-image";

const uploadImage = (file, path, token) => {
  let formdt = new FormData();
  formdt.append("file", file);
  return request({
    url: `${imageUrl}/?path=${path}`,
    method: "POST",
    data: formdt,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    },
  });
};

const uploadImagePartner = (file, path, token) => {
    let formdt = new FormData();
    formdt.append("file", file);
    return request({
      url: `${imageUrl}/?path=${path}`,
      method: "POST",
      data: formdt,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      },
    });
  };

export const ImageServices = {
  uploadImage,
  uploadImagePartner
}