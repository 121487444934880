import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import MultipleSearch from "../../components/multiple-search/multiple-search.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import {
  getDataSettlement,
  getJalinReconById,
} from "../../redux/settlement-jalin/settlement-jalin.action";

const JalinRecon = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [dataDisplay, setDataDisplay] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [formValue, setFormValue] = useState({
    field: "",
    val: "",
  });
  const { dataById } = useSelector((state) => state.settlementJalin);

  useEffect(() => {
    // set action button
    dispatch(
      setHeaderButton({
        titleHeader: "Jalin Recon",
        isChild: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getJalinReconById(params.id));
  }, []);

  useEffect(() => {
    setDataDisplay(dataById?.qrReconHeader?.reconDetailList);
  }, [dataById]);

  useEffect(() => {
    let tempData = [...dataDisplay];
    if (searchQuery.length !== 0) {
      let searched = tempData.filter((e) => {
        let query = searchQuery[searchQuery.length - 1];
        return e[query.field].includes(query.val);
      });
      setDataDisplay(searched);
    } else {
      setDataDisplay(dataById?.qrReconHeader?.reconDetailList);
    }
    setFormValue({
      field: "",
      val: "",
    });
  }, [searchQuery]);

  const searchFunction = () => {
    let tempQuery = [...searchQuery];
    tempQuery.push(formValue);
    setSearchQuery(tempQuery);
  };

  const deleteFilter = (i) => {
    const searchTemp = [...searchQuery];
    const newSearchFields = searchTemp.filter((_, id) => id !== i);
    setSearchQuery(newSearchFields);
  };

  const handleChange = (e) => {
    let tempForm = { ...formValue };
    tempForm[e.target.name] = e.target.value;
    setFormValue(tempForm);
  };

  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="form-horizontal">
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-sm-3">
                    <select
                      class="form-control select2"
                      style={{ width: "100%" }}
                      name="field"
                      value={formValue.field}
                      onChange={handleChange}
                    >
                      <option value="">-------</option>
                      <option value="retrievalRefNumber">RRN</option>
                      <option value="merchantPan">Merchant PAN</option>
                      <option value="processingCode">Processing Code</option>
                      <option value="customerPan">Customer PAN</option>
                      <option value="invoiceData">Invoice Data</option>
                    </select>
                  </div>
                  <div class="col-sm-3">
                    <input
                      type="text"
                      class="form-control"
                      id="inputCriteria"
                      placeholder="Search Criteria"
                      name="val"
                      value={formValue.val}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-sm-3">
                    <button
                      type="submit"
                      onClick={searchFunction}
                      class="btn btn-info"
                    >
                      Search
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setSearchQuery([]);
                      }}
                      class="btn btn-info"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                {searchQuery.map((query, i) => (
                  <div class="row">
                    <div class="col-sm-12">
                      <label>
                        ** {query.field} contains "{query.val}"
                      </label>
                      <button
                        type="button"
                        class="btn btn-outline-danger btn-xs"
                        onClick={() => deleteFilter(i)}
                      >
                        <span class="fas fa-minus" aria-hidden="true"></span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="card card-primary card-outline card-tabs">
            <div class="card-header p-0 pt-1 border-bottom-0">
              <ul
                class="nav nav-tabs"
                id="custom-tabs-three-tab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="custom-tabs-three-detail-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-detail"
                    role="tab"
                    aria-controls="custom-tabs-three-detail"
                    aria-selected="true"
                  >
                    Detail
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="custom-tabs-three-header-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-header"
                    role="tab"
                    aria-controls="custom-tabs-three-header"
                    aria-selected="true"
                  >
                    Header/Footer
                  </a>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <div class="tab-content" id="custom-tabs-three-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="custom-tabs-three-detail"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-detail-tab"
                >
                  <table
                    id="example1"
                    class="table table-bordered table-striped table-responsive"
                    style={{
                      display: "inherit",
                    }}
                  >
                    <thead style={{ backgroundColor: "#007bff" }}>
                      <tr>
                        <th>No</th>
                        <th>Recon Header</th>
                        <th>Terminal Id</th>
                        <th>RRN</th>
                        <th>Merchant PAN</th>
                        <th>Trx Date</th>
                        <th>Trx Time</th>
                        <th>Processing Code</th>
                        <th>Trx Amount</th>
                        <th>Conv Fee</th>
                        <th>Currency</th>
                        <th>Merchant Type</th>
                        <th>Merchant Criteria</th>
                        <th>Acq Bank</th>
                        <th>Issuer Bank</th>
                        <th>Fwd Inst</th>
                        <th>Resp Code</th>
                        <th>Cust PAN</th>
                        <th>Invoice Data</th>
                        <th>Appr Code</th>
                        <th>Msg Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataDisplay?.map((data, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{data.reconHeader}</td>
                          <td>{data.terminalId}</td>
                          <td>{data.retrievalRefNumber}</td>
                          <td>{data.merchantPan}</td>
                          <td>{data.transactionDate}</td>
                          <td>{data.transactionTime}</td>
                          <td>{data.processingCode}</td>
                          <td>{data.transactionAmount}</td>
                          <td>{data.convenienceFee}</td>
                          <td>{data.transactionCurrency}</td>
                          <td>{data.merchantType}</td>
                          <td>{data.merchantCriteria}</td>
                          <td>{data.acquiringBankCode}</td>
                          <td>{data.issuerBankCode}</td>
                          <td>{data.forwardingInstitutionId}</td>
                          <td>{data.responseCode}</td>
                          <td>{data.customerPan}</td>
                          <td>{data.invoiceData}</td>
                          <td>{data.approvalCode}</td>
                          <td>{data.msgTypeIndicator}</td>
                        </tr>
                      ))}
                      {dataDisplay?.length === 0 && (
                        <tr>
                          <td colSpan={21} style={{ textAlign: "center" }}>
                            No Data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div
                  class="tab-pane fade"
                  id="custom-tabs-three-header"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-header-tab"
                >
                  <table
                    id="example1"
                    class="table table-bordered table-striped"
                  >
                    <thead style={{ backgroundColor: "#007bff" }}>
                      <tr>
                        <th>Record Header</th>
                        <th>Recon File Creator</th>
                        <th>Recon File Receiver</th>
                        <th>Recon Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{dataById?.qrReconHeader?.recordHeader}</td>
                        <td>{dataById?.qrReconHeader?.reconFileCreator}</td>
                        <td>{dataById?.qrReconHeader?.reconFileReceiver}</td>
                        <td>{dataById?.qrReconHeader?.reconDate}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    id="example1"
                    class="table table-bordered table-striped"
                  >
                    <thead style={{ backgroundColor: "#007bff" }}>
                      <tr>
                        <th>Record Trailer</th>
                        <th>Recon File Creator</th>
                        <th>Recon File Receiver</th>
                        <th>Recon File Date</th>
                        <th>Recon Record Count</th>
                        <th>Recon Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {dataById?.qrReconHeader?.reconFooter?.recordTrailer}
                        </td>
                        <td>
                          {
                            dataById?.qrReconHeader?.reconFooter
                              ?.reconFileCreator
                          }
                        </td>
                        <td>
                          {
                            dataById?.qrReconHeader?.reconFooter
                              ?.reconFileReceiver
                          }
                        </td>
                        <td>
                          {dataById?.qrReconHeader?.reconFooter?.reconFileDate}
                        </td>
                        <td>
                          {
                            dataById?.qrReconHeader?.reconFooter
                              ?.reconRecordCount
                          }
                        </td>
                        <td>{dataById?.reconAmount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JalinRecon;
