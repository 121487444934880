const SelectField = ({ label, name, value, options = [], handleChange, multiple = false }) => {
  return (
    <>
      <div className="col-sm-1">
        <label>{label}</label>
      </div>
      <div className="col-sm-3">
        <div className="select2-primary">
          <select
            className="form-control select2"
            data-dropdown-css-className="select2-primary"
            style={{ width: "100%" }}
            name={name}
            value={value}
            onChange={handleChange}
          >
            <option></option>
            {options.map(item =>
              <option value={item.value}>{item.label}</option>
            )}
          </select>
        </div>
      </div>
    </>
  )
}

export default SelectField;