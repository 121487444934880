import AuthActionTypes from "./auth.types";

const INITIAL_STATE = {
  token: "",
  role: "auth",
  error: false,
  success: false,
  account: null,
  user: null,
  status: 200,
  kc: null,

  changePasswordState: "",
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return {
        ...state,
        error: false,
        success: false,
        status: 200,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        error: false,
        token: action.payload.token,
        user: action.payload.user,
        kc: action.payload.kc,
        success: true,
        status: 200,
        account: null,
      };
    case AuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        token: action.payload.token,
      };
    case AuthActionTypes.LOGOUT:
      return INITIAL_STATE;
    case AuthActionTypes.ACTIVATE:
      return {
        ...state,
      };
    case AuthActionTypes.ACTIVATE_SUCCESS:
      return {
        ...state,
      };
    case AuthActionTypes.ACTIVATE_FAILURE:
      return {
        ...state,
      };
    case AuthActionTypes.CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordState: "",
      }
    case AuthActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordState: "success",
      }
    case AuthActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordState: "failure",
      }
    default:
      return state;
  }
};

export default AuthReducer;
