import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import MultipleSearch from "../../components/multiple-search/multiple-search.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { getDataSettlement } from "../../redux/settlement-jalin/settlement-jalin.action";

const SettlementJalin = () => {
  const history = useHistory();
  const tableHeaders = [
    {
      text: "File Name",
      sort: true,
      name: "fileName",
      search: true,
      type: "text",
    },
    {
      text: "File Date",
      sort: true,
      name: "createdDate",
      search: true,
      type: "date",
    },
    {
      text: "Record Count",
      sort: true,
      name: "reconRecordCount",
      search: false,
      type: "text",
      specialFunc: (val) => val !== null ? val : "-",
    },
    {
      text: "Total Trx Amount",
      sort: true,
      name: "reconAmount",
      search: false,
      type: "text",
    },
    {
      text: "Status",
      sort: true,
      name: "status",
      search: true,
      type: "text",
      specialFunc: (val) => (val ? "Success" : "Failed"),
    },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-search" aria-hidden="true"></span>,
      description: "View",
      action: (val) => history.push(`/jalin-recon/${val.switchingFileId}`),
      condition: (val) => true,
    },
    {
      icon: <span className="fas fa-file-download" aria-hidden="true"></span>,
      description: "Download File",
      action: (val) => {
        // dispatch(updateSettlement(val.transactionId));
        window.open(`${process.env.REACT_APP_BASE_URL}v1/qr-recon-settlement/${val.switchingFileId}/document`)
      },
      condition: (val) => true,
    },
    // {
    //   icon: <span className="fas fa-receipt" aria-hidden="true"></span>,
    //   action: async (val) => {
    //     dispatch(updateSettlement(val.transactionId));
    //   },
    //   condition: (val) => true,
    // },
  ];

  const {
    // status,
    settlementJalinList,
    first,
    last,
    totalData,
    totalPage,
    // error,
    empty,
  } = useSelector((state) => state.settlementJalin);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState([]);
  const [orderBy, setOrderBy] = useState({
    field: "createdDate",
    direction: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDataSettlement(page, size, searchQuery, orderBy));
  }, [page, searchQuery, orderBy, dispatch, size]);

  useEffect(() => {
    // set action button
    dispatch(
      setHeaderButton({
        titleHeader: "Settlement Jalin",
      })
    );
  }, [dispatch]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div className="container-fluid">
      <MultipleSearch
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        headers={tableHeaders}
        showStatus={true}
        statusActive={false}
      />
      <DataTables
        headers={tableHeaders}
        listData={settlementJalinList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default SettlementJalin;
