import BIReportTypes from "./bi-report.types";

const INITIAL_STATE = {
    biReportList: [],
    first: true,
    last: true,
    totalData: 0,
    totalPage: 0,
    empty: true,
    status: null,
    errorMessage: "",
    error: false,

    biReportListById: [],
    firstById: true,
    lastById: true,
    totalDataById: 0,
    totalPageById: 0,
    emptyById: true,
    statusById: null,
    errorMessageById: "",
    errorById: false,

    fileTemplate: "",
    reportTypes: [],
    uploadStatus: "",
    errorUploadFile: "",
    uploadReportStatus: "",

};

const BIReportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BIReportTypes.GET_BI_REPORT:
            return {
                ...state,
                uploadStatus: "",
            };
        case BIReportTypes.GET_BI_REPORT_SUCCESS:
            return {
                ...state,
                status: 200,
                biReportList: action.payload.content,
                first: action.payload.first,
                last: action.payload.last,
                totalData: action.payload.totalElements,
                totalPage: action.payload.totalPages,
                empty: action.payload.empty,
                error: false,
            };
        case BIReportTypes.GET_BI_REPORT_FAILURE:
            return {
                ...state,
                status: action.payload.status,
                errorMessage: action.payload.message,
                error: true,
            };
        case BIReportTypes.GET_BI_REPORT_BY_ID:
            return {
                ...state,
            };
        case BIReportTypes.GET_BI_REPORT_BY_ID_SUCCESS:
            return {
                ...state,
                statusById: 200,
                biReportListById: action.payload.content,
                firstById: action.payload.first,
                lastById: action.payload.last,
                totalDataById: action.payload.totalElements,
                totalPageById: action.payload.totalPages,
                emptyById: action.payload.empty,
                errorById: false,
            };
        case BIReportTypes.GET_BI_REPORT_BY_ID_FAILURE:
            return {
                ...state,
                statusById: action.payload.status,
                errorMessageById: action.payload.message,
                errorById: true,
            };
        case BIReportTypes.UPLOAD_BI_REPORT:
            return {
                ...state,
                uploadStatus: "uploading",
            };
        case BIReportTypes.UPLOAD_BI_REPORT_SUCCESS:
            return {
                ...state,
                uploadStatus: "uploaded",
            };
        case BIReportTypes.UPLOAD_BI_REPORT_FAILURE:
            return {
                ...state,
                uploadStatus: "failed",
            };
        case BIReportTypes.GET_TEMPLATE:
            return {
                ...state,
                errorUploadFile: "",
            };
        case BIReportTypes.GET_TEMPLATE_FAILURE:
            return {
                ...state,
            };
        case BIReportTypes.GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                fileTemplate: action.payload,
            };
        case BIReportTypes.GET_REPORT_TYPES:
            return {
                ...state,
                errorUploadFile: "",
            };
        case BIReportTypes.GET_REPORT_TYPES_FAILURE:
            return {
                ...state,
            };
        case BIReportTypes.GET_REPORT_TYPES_SUCCESS:
            return {
                ...state,
                reportTypes: action.payload,
            };
            case BIReportTypes.UPLOAD_CSV:
            return {
                ...state,
                uploadReportStatus: 'uploading'
            };
        case BIReportTypes.UPLOAD_CSV_FAILURE:
            return {
                ...state,
                errorUploadFile: action.payload,
                uploadReportStatus: 'failed'
            };
        case BIReportTypes.UPLOAD_CSV_SUCCESS:
            return {
                ...state,
                uploadReportStatus: 'success'
            };
        case BIReportTypes.UPLOAD_DEFAULT:
            return {
                ...state,
                errorUploadFile: "",
                uploadReportStatus: ""
            };
        default:
            return state;
    }
};

export default BIReportReducer;
