import { batch } from "react-redux";
import { ParameterServices } from "../../_services/parameter.services";
import ActionsActionTypes from "../actions/actions.types";
import ParameterTypes from "./parameter.types";

export const getDataParameters = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ParameterTypes.GET_DATA_PARAMETER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const parameters = await ParameterServices.getParameterData(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: ParameterTypes.GET_DATA_PARAMETER_SUCCESS,
          payload: parameters.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ParameterTypes.GET_DATA_PARAMETER_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });

    }
  }
}

export const getDetailParameter = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ParameterTypes.GET_DETAIL_PARAMETER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const detail = await ParameterServices.getDetailParameter(id, token);

      batch(() => {
        dispatch({
          type: ParameterTypes.GET_DETAIL_PARAMETER_SUCCESS,
          payload: detail.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ParameterTypes.GET_DETAIL_PARAMETER_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    }
  }
}

export const updateParameter = (id, data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ParameterTypes.UPDATE_PARAMETER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      if(data.paramValue === "") {
        batch(() => {
          dispatch({ type: ParameterTypes.UPDATE_PARAMETER_FAILURE, payload: "Fill all required data before save." });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Fill all required data before save.",
              alertType: "error",
            },
          });
        });
      } else {
        const update = await ParameterServices.updateParameter(id, data, token);
  
        batch(() => {
          dispatch({ type: ParameterTypes.UPDATE_PARAMETER_SUCCESS, payload: update });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Update parameter success",
              alertType: "success",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({ type: ParameterTypes.UPDATE_PARAMETER_FAILURE, payload: error });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update parameter failure",
            alertType: "error",
          },
        });
      });
    }
  }
}

export const defaultParameter = () => async (dispatch) =>
  dispatch({ type: ParameterTypes.DEFAULT_PARAMETER_STATE });
