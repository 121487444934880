import request from "../_network/request";
const disbursementURL = "/v1/disbursements";

const getDisbursementData = (page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
    }`;

  return request({
    url: `${disbursementURL}?${searchArray.length > 0 ? searchArray.join("") : ""
      }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const DisbursementServices = {
  getDisbursementData,
}