import request from '../_network/request';

const merchantReportUrl = "/v1/reports/merchant-list";

const getMerchantReport = (page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`${i === 0 ? "" : "&"}${key.param}=${key.value}`);
    });
  }
  let order = `${
    orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;
  return request({
    url: `${merchantReportUrl}?${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export const ReportServices = {
  getMerchantReport,
}