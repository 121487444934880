import SettlementJalinTypes from "./settlement-jalin.types";

const INITIAL_STATE = {
  settlementJalinList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  errorMessage: "",
  error: false,

  dataById: {},
};

const SettlementJalinReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SettlementJalinTypes.GET_JALIN_SETTLEMENT:
      return {
        ...state,
      };
    case SettlementJalinTypes.GET_JALIN_SETTLEMENT_SUCCESS:
      return {
        ...state,
        status: 200,
        settlementJalinList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case SettlementJalinTypes.GET_JALIN_SETTLEMENT_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        errorMessage: action.payload.message,
        error: true,
      }
    case SettlementJalinTypes.GET_SETTLEMENT_BY_ID:
      return {
        ...state,
      };
    case SettlementJalinTypes.GET_SETTLEMENT_BY_ID_FAILURE:
      return {
        ...state,
      };
    case SettlementJalinTypes.GET_SETTLEMENT_BY_ID_SUCCESS:
      return {
        ...state,
        dataById: action.payload,
      };
    default:
      return state;
  }
};

export default SettlementJalinReducer;
