import request from "../_network/request";

const urlGet = "/v1/users";
const urlRoles = "/role/list?offset=0&limit=10000";

const getListData = (
  merchantId,
  type,
  page,
  size,
  searchQuery = [],
  orderBy,
  token
) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${
    orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;
  const filterMerchant =
    merchantId === "" ? "" : `&merchant.merchantId=${merchantId}`;
  return request({
    url: `${urlGet}?role.roleName=${type}${filterMerchant}${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRoles = (token) => {
  return request({
    url: urlRoles,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createUser = (data, token) => {
  return request({
    url: `${urlGet}/register`,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteUser = (id, token) => {
  return request({
    url: `${urlGet}/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateUser = (id, data, token) => {
  return request({
    url: `${urlGet}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getHistory = (id, token) => {
  return request({
    url: `${urlGet}/histories/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const UserManagementService = {
  getListData,
  getRoles,
  createUser,
  deleteUser,
  updateUser,
  getHistory,
};
