import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { getDashboardData } from "../../redux/dashboard/dashboard.action";

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    totalAmount,
    totalMerchant,
    totalPendingMerchant,
    totalTransaction,
    monthlySettlement,
    monthlyTransaction,
  } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.auth);
  const [labelTransaction, setLabelTransaction] = useState([]);
  const [dataTransaction, setDataTransaction] = useState([]);
  const [labelSettlement, setLabelSettlement] = useState([]);
  const [dataSettlement, setDataSettlement] = useState([]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    dispatch(setHeaderButton({ titleHeader: "Dashboard" }));
    dispatch(getDashboardData());
  }, [dispatch]);

  useEffect(() => {
    let tempLT = [];
    let tempLS = [];
    let tempDT = [];
    let tempDS = [];
    monthlyTransaction.forEach((trans) => {
      let newDate = new Date(trans.dateThisMonth);
      tempLT.push(months[newDate.getMonth()]);
      tempDT.push(trans.totalTrx);
    });
    monthlySettlement.forEach((sett) => {
      let newDate = new Date(sett.dateThisMonth);
      tempLS.push(months[newDate.getMonth()]);
      tempDS.push(sett.totalSettlement);
    });
    setLabelTransaction(tempLT);
    setLabelSettlement(tempLS);
    setDataTransaction(tempDT);
    setDataSettlement(tempDS);
  }, [monthlyTransaction, monthlySettlement]);

  // const areaChartData = {
  //   labels: ["January", "February", "March", "April", "May", "June", "July"],
  //   datasets: [
  //     {
  //       label: "Digital Goods",
  //       fill: true,
  //       lineTension: 0.4,
  //       backgroundColor: "rgba(60,141,188,0.7)",
  //       borderColor: "rgba(60,141,188,0.8)",
  //       pointRadius: false,
  //       pointColor: "#3b8bba",
  //       pointStrokeColor: "rgba(60,141,188,1)",
  //       pointHighlightFill: "#fff",
  //       pointHighlightStroke: "rgba(60,141,188,1)",
  //       data: [28, 48, 40, 19, 86, 27, 90],
  //     },
  // {
  //   label: "Electronics",
  //   fill: true,
  //   lineTension: 0.4,
  //   backgroundColor: "rgba(210, 214, 222, 1)",
  //   borderColor: "rgba(255, 255, 255, 1)",
  //   pointRadius: false,
  //   pointColor: "rgba(210, 214, 222, 1)",
  //   pointStrokeColor: "#c1c7d1",
  //   pointHighlightFill: "#fff",
  //   pointHighlightStroke: "rgba(220,220,220,1)",
  //   data: [65, 59, 80, 81, 56, 55, 40],
  // },
  //   ],
  // };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-3 col-6">
          <div className="small-box bg-info">
            <div className="inner">
              <h3>{totalTransaction}</h3>

              <p>Total Transaction</p>
            </div>
            <div className="icon">
              <i className="ion ion-bag"></i>
            </div>
            {/* <a href="/#" className="small-box-footer">
              More info <i className="fas fa-arrow-circle-right"></i>
            </a> */}
          </div>
        </div>

        {user.merchant === null && (
          <div className="col-lg-3 col-6">
            <div className="small-box bg-success">
              <div className="inner">
                <h3>{totalMerchant}</h3>

                <p>Total Merchant</p>
              </div>
              <div className="icon">
                <i className="ion ion-stats-bars"></i>
              </div>
              {/* <a href="/#" className="small-box-footer">
                More info <i className="fas fa-arrow-circle-right"></i>
              </a> */}
            </div>
          </div>
        )}

        {user.merchant === null && (
          <div className="col-lg-3 col-6">
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{totalPendingMerchant}</h3>

                <p>Total Pending Merchant QRIS</p>
              </div>
              <div className="icon">
                <i className="ion ion-person-add"></i>
              </div>
              {/* <a href="/#" className="small-box-footer">
              More info <i className="fas fa-arrow-circle-right"></i>
            </a> */}
            </div>
          </div>
        )}

        <div className="col-lg-3 col-6">
          <div className="small-box bg-danger">
            <div className="inner">
              <h3>{`${totalAmount}`
							.replace('.', ',')
							.replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0}</h3>

              <p>Total Amount Settlement (Rp)</p>
            </div>
            <div className="icon">
              <i className="ion ion-pie-graph"></i>
            </div>
            {/* <a href="/#" className="small-box-footer">
              More info <i className="fas fa-arrow-circle-right"></i>
            </a> */}
          </div>
        </div>
      </div>

      <div className="row">
        <section className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1"></i>
                Transactions
              </h3>
              <div className="card-tools">
                <ul className="nav nav-pills ml-auto">
                  {/* <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#areaChart"
                      data-toggle="tab"
                    >
                      Weekly
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#areaChart"
                      data-toggle="tab"
                    >
                      Monthly
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body">
              <Line
                data={{
                  labels: labelTransaction,
                  datasets: [
                    {
                      label: "Transactions",
                      fill: true,
                      lineTension: 0.4,
                      backgroundColor: "rgba(60,141,188,0.7)",
                      borderColor: "rgba(60,141,188,0.8)",
                      pointRadius: false,
                      pointColor: "#3b8bba",
                      pointStrokeColor: "rgba(60,141,188,1)",
                      pointHighlightFill: "#fff",
                      pointHighlightStroke: "rgba(60,141,188,1)",
                      data: dataTransaction,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  legend: { display: false },
                  scales: { xAxes: [{ gridLines: false }] },
                }}
                height={280}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                <i className="fas fa-chart-pie mr-1"></i>
                Settlements
              </h3>
              <div className="card-tools">
                <ul className="nav nav-pills ml-auto">
                  {/* <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#settlementChart"
                      data-toggle="tab"
                    >
                      Weekly
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      href="#settlementChart"
                      data-toggle="tab"
                    >
                      Monthly
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body">
              <Line
                data={{
                  labels: labelSettlement,
                  datasets: [
                    {
                      label: "Settlements",
                      fill: true,
                      lineTension: 0.4,
                      backgroundColor: "rgba(60,141,188,0.7)",
                      borderColor: "rgba(60,141,188,0.8)",
                      pointRadius: false,
                      pointColor: "#3b8bba",
                      pointStrokeColor: "rgba(60,141,188,1)",
                      pointHighlightFill: "#fff",
                      pointHighlightStroke: "rgba(60,141,188,1)",
                      data: dataSettlement,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  legend: { display: false },
                  scales: { xAxes: [{ gridLines: false }] },
                }}
                height={280}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
