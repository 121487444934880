import ActionsActionTypes from "./actions.types";

export const showLoading = () => {
  return async (dispatch) => {
    dispatch({ type: ActionsActionTypes.SHOW_LOADING });
  };
};

export const hideLoading = () => {
  return async (dispatch) => {
    dispatch({ type: ActionsActionTypes.HIDE_LOADING });
  };
};

export const showLoadingTable = () => {
  return async (dispatch) => {
    dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
  };
};

export const hideLoadingTable = () => {
  return async (dispatch) => {
    dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
  };
};

export const setHeaderButton = ({
  add = false,
  pathAdd = "",
  upload = false,
  pathUpload = "",
  buttonWithFunction = false,
  buttonText= "",
  buttonFunction = () => {},
  buttonIcon = "",
  titleHeader = "",
  isChild = false,
  hasCustomButton = false,
  customButton = () => {},
}) => {
  return async (dispatch) => {
    dispatch({
      type: ActionsActionTypes.SET_HEADER,
      payload: {
        add: add,
        pathAdd: pathAdd,
        upload: upload,
        pathUpload: pathUpload,
        buttonWithFunction: buttonWithFunction,
        buttonText: buttonText,
        buttonIcon: buttonIcon,
        buttonFunction: buttonFunction,
        titleHeader: titleHeader,
        isChild: isChild,
        hasCustomButton: hasCustomButton,
        customButton: customButton,
      },
    });
  };
};

export const showAlert = (alertType, alertMessage) => {
  return async (dispatch) => {
    dispatch({
      type: ActionsActionTypes.SHOW_ALERT,
      payload: {
        alertMessage: alertMessage,
        alertType: alertType,
      },
    });
  }
}

export const hideAlert = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionsActionTypes.HIDE_ALERT,
    })
  }
}
