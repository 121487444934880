import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select, { components } from "react-select";
import InputField from "../../components/input-field/input-field.component";
import TextArea from "../../components/text-area/text-area.component";
import {
  setHeaderButton,
  showAlert,
} from "../../redux/actions/actions.actions";
import {
  getDataById,
  createPartner,
  updatePartner,
  banPartner,
} from "../../redux/partner/partner.actions";
import request from "../../_network/request";
import EmailField from "../../components/input-field/input-email-field.component";
import PercentageField from "../../components/input-field/percentage-field.component";
// import request4 from "../../_network/request4";

const PartnerForm = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const {
    dataById,
    createPartnerSuccess,
    updatePartnerSuccess,
    banPartnerSuccess,
  } = useSelector((state) => state.partnerList);

  const { token, user } = useSelector((state) => state.auth);

  const [managementForm, setManagementForm] = useState({
    businessName: "",
    businessAddress: "",
    city: "",
    contactName: "",
    contactEmail: "",
    phoneNumber: "",
    imageSkt: "",
    imageNpwp: "",
    imageSppkp: "",
    allowAccountLinked: false,
    allowCreateTrx: false,
    allowFullRefundTrx: false,
    allowPartialRefundTrx: false,
    allowTopUp: false,
    allowVoidTrx: false,
    topupFee: 0,
    topupFeePercent: false,
    trxFee: 0,
    trxFeePercent: false,
  });
  const [name, setName] = useState({
    imageSppkp: "",
    imageNpwp: "",
    imageSkt: "",
  });

  const [isDisabled, setIsDisable] = useState(false);

  const headerActionButton = [
    <button
      type="button"
      name="save"
      className="btn btn-primary"
      onClick={() => onSave()}
    >
      <i className="fas fa-save"></i>
      Save
    </button>,
    params.id ? (
      <button
        type="button"
        name="save"
        className="btn btn-warning"
        onClick={() => doBanPartner()}
      >
        <i className="fas fa-user-lock"></i>
        Ban
      </button>
    ) : null,
  ];

  const [images, setImages] = useState({
    sppkp: null,
    npwp: null,
    skt: null,
  });

  useEffect(() => {
    setImages({
      sppkp: null,
      npwp: null,
      skt: null,
    });
    if (params.id) dispatch(getDataById(params.id));
  }, [dispatch, params.id, user.role.roleName]);

  useEffect(() => {
    if (params.id && dataById && params.id === dataById.partnerId) {
      const _form = { ...managementForm };
      Object.keys(dataById).forEach(function (key) {
        if (key in _form) {
          // or obj1.hasOwnProperty(key)
          _form[key] = dataById[key];
        }
      });

      setManagementForm(_form);
      gettingImage();
    }
    console.log("databyid", dataById);
  }, [params.id, dataById]);

  const gettingImage = async () => {
    let tempImage = {
      sppkp: null,
      npwp: null,
      skt: null,
    };
    const imageSkt = await request({
      url: `${dataById.imageSkt}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    });
    const imageNpwp = await request({
      url: `${dataById.imageNpwp}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    });
    const imageSppkp = await request({
      url: `${dataById.imageSppkp}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    });
    console.log("imageSppkp", dataById.imageSppkp);

    tempImage.skt = btoa(
      new Uint8Array(imageSkt).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    tempImage.npwp = btoa(
      new Uint8Array(imageNpwp).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    tempImage.sppkp = new Buffer(imageSppkp).toString("base64");
    console.log("temimage", tempImage);
    setImages(tempImage);
  };

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: params.id ? "Edit Partner" : "Add Partner",
        isChild: true,
        hasCustomButton: true,
        customButton:
          user?.role?.roleName === "PARTNER" ? "" : headerActionButton,
      })
    );
    user?.role?.roleName === "PARTNER" && setIsDisable(true);
  }, [dispatch, managementForm, params.id, user.role.roleName]);

  // useEffect(() => {},[user?.role?.roleName])

  useEffect(() => {
    if (
      createPartnerSuccess === "success" ||
      updatePartnerSuccess === "success" ||
      banPartnerSuccess === "success"
    )
      window.history.back();
  }, [createPartnerSuccess, updatePartnerSuccess, banPartnerSuccess]);

  const handleChange = async (e) => {
    let formTemp = { ...managementForm };
    let imageNameTemp = { ...name };
    if (e.target.type === "file") {
      if (e.target.files[0].size > 5242880) {
        dispatch(showAlert("error", "Max file size allowed 5MB!"));
      } else {
        formTemp[e.target.name] = e.target.files[0];
        imageNameTemp[e.target.name] = e.target.files[0].name;
        setName(imageNameTemp);
      }
    } else {
      formTemp[e.target.name] = e.target.value;
    }
    setManagementForm(formTemp);
  };

  const handleCheckBox = (e) => {
    let formTemp = { ...managementForm };
    const arrTemp = e.target.name.split(".");
    if (arrTemp.length > 1) {
      formTemp[arrTemp[0]][arrTemp[1]] = e.target.checked;
    } else {
      formTemp[e.target.name] = e.target.checked;
    }
    setManagementForm(formTemp);
    console.log("test", formTemp);
  };

  const onSave = () => {
    console.log("form", params.id, managementForm);

    if (params.id) {
      if (window.confirm("Are you sure want to save edit?")) {
        dispatch(updatePartner(params.id, managementForm));
      }
    } else {
      dispatch(createPartner(managementForm));
    }
  };

  const deleteImage = (name) => {
    let formTemp = { ...managementForm };
    formTemp[name] = "";
    setManagementForm(formTemp);
  };

  const doBanPartner = () => {
    if (window.confirm("Are you sure want to ban?")) {
      dispatch(banPartner(params.id));
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-sm-12">
          <div className="card card-primary card-outline card-tabs">
            <div className="card-header p-0 pt-1 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-three-tab"
                role="tablist"
                style={{ flex: 1 }}
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-three-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-home"
                    role="tab"
                    aria-controls="custom-tabs-three-home"
                    aria-selected="true"
                  >
                    Partner Info
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-fee-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-fee"
                    role="tab"
                    aria-controls="custom-tabs-three-fee"
                    aria-selected="true"
                  >
                    Partner Fee
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-permission-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-permission"
                    role="tab"
                    aria-controls="custom-tabs-three-permission"
                    aria-selected="true"
                  >
                    Permission
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-three-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-three-home"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-home-tab"
                >
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Partner ID *</label>
                      </div>
                      <div className="col-sm-6">
                        <label>{params.id || "Auto Generate"}</label>
                      </div>
                    </div>
                    <InputField
                      type="text"
                      label="Business Name"
                      name="businessName"
                      value={managementForm.businessName}
                      onChange={handleChange}
                      required={true}
                      maxLength={25}
                      disabled={isDisabled}
                    />
                    <TextArea
                      label="Business Address"
                      name="businessAddress"
                      value={managementForm.businessAddress}
                      onChange={handleChange}
                      required={true}
                      disabled={isDisabled}
                    />
                    <InputField
                      type="text"
                      label="City"
                      name="city"
                      value={managementForm.city}
                      onChange={handleChange}
                      required={true}
                      disabled={isDisabled}
                    />
                    <InputField
                      label="Contact Name"
                      name="contactName"
                      value={managementForm.contactName}
                      onChange={handleChange}
                      required={true}
                      disabled={isDisabled}
                    />
                    <EmailField
                      label="Contact Email"
                      name="contactEmail"
                      value={managementForm.contactEmail}
                      onChange={handleChange}
                      required={true}
                      disabled={isDisabled}
                    />

                    <InputField
                      label="Contact Phone No"
                      name="phoneNumber"
                      value={managementForm.phoneNumber}
                      onChange={handleChange}
                      required={true}
                      disabled={isDisabled}
                    />

                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputStatus">Upload Image *</label>
                      </div>
                      <div className="col-sm-10">
                        <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                          <li>
                            <span
                              className="mailbox-attachment-icon has-img"
                              style={{
                                minHeight: "132.25px",
                              }}
                            >
                              {managementForm.imageSkt === null ||
                              managementForm.imageSkt === "" ? (
                                <i
                                  className="fas fa-camera"
                                  style={{
                                    marginTop: "30px",
                                  }}
                                ></i>
                              ) : (
                                <img
                                  src={
                                    managementForm.imageSkt
                                      ? typeof managementForm.imageSkt ===
                                        "string"
                                        ? `data:image/png;base64,${images.skt}`
                                        : URL.createObjectURL(
                                            managementForm.imageSkt
                                          )
                                      : ""
                                  }
                                  style={{
                                    objectFit: "cover",
                                    maxHeight: "132.25px",
                                  }}
                                  alt="Attachment"
                                />
                              )}
                            </span>

                            <div className="mailbox-attachment-info">
                              {managementForm.imageSkt === null ||
                              managementForm.imageSkt === "" ? (
                                <input
                                  className="form-control-sm"
                                  type="file"
                                  name="imageSkt"
                                  onChange={handleChange}
                                  accept="image/*"
                                  required
                                  disabled={isDisabled}
                                />
                              ) : (
                                <a
                                  onClick={() => {
                                    deleteImage("imageSkt");
                                  }}
                                  className="mailbox-attachment-name"
                                >
                                  <i className="fas fa-camera"></i>{" "}
                                  {name.imageSkt}
                                </a>
                              )}
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>Surat Keterangan Terdaftar</span>
                              </span>
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>Max 5MB</span>
                              </span>
                            </div>
                          </li>
                          <li>
                            <span
                              className="mailbox-attachment-icon has-img"
                              style={{
                                minHeight: "132.25px",
                              }}
                            >
                              {managementForm.imageNpwp === null ||
                              managementForm.imageNpwp === "" ? (
                                <i
                                  className="fas fa-camera"
                                  style={{
                                    marginTop: "30px",
                                  }}
                                ></i>
                              ) : (
                                <img
                                  // src={generateImage(managementForm.imageNpwp)}
                                  src={
                                    managementForm.imageNpwp
                                      ? typeof managementForm.imageNpwp ===
                                        "string"
                                        ? `data:image/png;base64,${images.npwp}`
                                        : URL.createObjectURL(
                                            managementForm.imageNpwp
                                          )
                                      : ""
                                  }
                                  style={{
                                    objectFit: "cover",
                                    maxHeight: "132.25px",
                                  }}
                                  alt="Attachment"
                                />
                              )}
                            </span>

                            <div className="mailbox-attachment-info">
                              {managementForm.imageNpwp === null ||
                              managementForm.imageNpwp === "" ? (
                                <input
                                  className="form-control-sm"
                                  type="file"
                                  name="imageNpwp"
                                  onChange={handleChange}
                                  accept="image/*"
                                  required
                                  disabled={isDisabled}
                                />
                              ) : (
                                <a
                                  onClick={() => {
                                    deleteImage("imageNpwp");
                                  }}
                                  className="mailbox-attachment-name"
                                >
                                  <i className="fas fa-camera"></i>{" "}
                                  {name.imageNpwp}
                                </a>
                              )}
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>NPWP</span>
                              </span>
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>Max 5MB</span>
                              </span>
                            </div>
                          </li>
                          <li>
                            <span
                              className="mailbox-attachment-icon has-img"
                              style={{
                                minHeight: "132.25px",
                              }}
                            >
                              {managementForm.imageSppkp === null ||
                              managementForm.imageSppkp === "" ? (
                                <i
                                  className="fas fa-camera"
                                  style={{
                                    marginTop: "30px",
                                  }}
                                ></i>
                              ) : (
                                <img
                                  // src={generateImage(managementForm.imageSppkp)}
                                  src={
                                    managementForm.imageSppkp
                                      ? typeof managementForm.imageSppkp ===
                                        "string"
                                        ? `data:image/png;base64,${images.sppkp}`
                                        : URL.createObjectURL(
                                            managementForm.imageSppkp
                                          )
                                      : ""
                                  }
                                  style={{
                                    objectFit: "cover",
                                    maxHeight: "132.25px",
                                  }}
                                  alt="Attachment"
                                />
                              )}
                            </span>

                            <div className="mailbox-attachment-info">
                              {managementForm.imageSppkp === null ||
                              managementForm.imageSppkp === "" ? (
                                <input
                                  className="form-control-sm"
                                  type="file"
                                  name="imageSppkp"
                                  onChange={handleChange}
                                  accept="image/*"
                                  required
                                  disabled={isDisabled}
                                />
                              ) : (
                                <a
                                  onClick={() => {
                                    deleteImage("imageSppkp");
                                  }}
                                  className="mailbox-attachment-name"
                                >
                                  <i className="fas fa-camera"></i>{" "}
                                  {name.imageSppkp}
                                </a>
                              )}
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>
                                  Surat Pengukuhan Pengusaha Kerja Pajak
                                </span>
                              </span>
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>Max 5MB</span>
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-fee"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-fee-tab"
                >
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-6">
                        {managementForm?.trxFeePercent ? (
                          <PercentageField
                            type="number"
                            label="Transaction Fee"
                            name="trxFee"
                            value={managementForm?.trxFee}
                            onChange={handleChange}
                            required={true}
                            labelClassName="col-sm-4"
                            inputClassName="col-sm-8 d-flex"
                            min={0}
                            max={100}
                            maxLength="3"
                            suffix={"%"}
                            disabled={isDisabled}
                          />
                        ) : (
                          <InputField
                            label="Transaction Fee"
                            name="trxFee"
                            value={managementForm?.trxFee}
                            onChange={handleChange}
                            required={true}
                            labelClassName="col-sm-4"
                            inputClassName="col-sm-8"
                            disabled={isDisabled}
                          />
                        )}
                      </div>
                      <div className="col-sm-2">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="trxFeePercent"
                            checked={managementForm?.trxFeePercent || false}
                            onChange={handleCheckBox}
                            disabled={isDisabled}
                          />
                          <label className="form-check-label">
                            Is Percentage?
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6">
                        {managementForm?.topupFeePercent ? (
                          <PercentageField
                            type="number"
                            label="Top Up Fee"
                            name="topupFee"
                            value={managementForm?.topupFee}
                            onChange={handleChange}
                            required={true}
                            labelClassName="col-sm-4"
                            inputClassName="col-sm-8 d-flex"
                            min={0}
                            max={100}
                            maxLength="3"
                            suffix={"%"}
                            disabled={isDisabled}
                          />
                        ) : (
                          <InputField
                            type="text"
                            label="Top Up Fee"
                            name="topupFee"
                            value={managementForm?.topupFee}
                            onChange={handleChange}
                            required={true}
                            labelClassName="col-sm-4"
                            inputClassName="col-sm-8"
                            disabled={isDisabled}
                          />
                        )}
                      </div>
                      <div className="col-sm-2">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="topupFeePercent"
                            checked={managementForm?.topupFeePercent || false}
                            onChange={handleCheckBox}
                            disabled={isDisabled}
                          />
                          <label className="form-check-label">
                            Is Percentage?
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-permission"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-permission-tab"
                >
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-3">
                        <label>Allow Create Transaction</label>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="allowCreateTrx"
                            checked={managementForm?.allowCreateTrx || false}
                            onChange={handleCheckBox}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-3">
                        <label>Allow Void Transaction</label>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="allowVoidTrx"
                            checked={managementForm?.allowVoidTrx || false}
                            onChange={handleCheckBox}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-3">
                        <label>Allow Full Refund Transaction</label>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="allowFullRefundTrx"
                            checked={
                              managementForm?.allowFullRefundTrx || false
                            }
                            onChange={handleCheckBox}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-3">
                        <label>Allow Partial Refund Transaction</label>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="allowPartialRefundTrx"
                            checked={
                              managementForm?.allowPartialRefundTrx || false
                            }
                            onChange={handleCheckBox}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-3">
                        <label>Allow Top Up</label>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="allowTopUp"
                            checked={managementForm?.allowTopUp || false}
                            onChange={handleCheckBox}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-3">
                        <label>Allow Account Linked</label>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="allowAccountLinked"
                            checked={
                              managementForm?.allowAccountLinked || false
                            }
                            onChange={handleCheckBox}
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerForm;
