import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import SearchGrid from "../../components/search-grid/index.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { getAgents, getDataConsumerTransaction, getStatusTransaction } from "../../redux/consumer-transaction/consumer-transaction.action";

const ConsumerTransactionPage = () => {
  const now = new Date();
  const from = new Date();
  from.setDate(from.getDate() - 7);
  const defaultDateRange = [
    { param: "fromDate", value: from.toISOString().split("T")[0] },
    { param: "toDate", value: now.toISOString().split("T")[0] }
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [orderBy, setOrderBy] = useState({
    field: "transactionDate",
    direction: false,
  });
  const [searchValue, setSearchValue] = useState(defaultDateRange);
  const { consumerTransactionList, first, last, totalData, totalPage, empty, statusTransaction, agents } = useSelector(
    (state) => state.dataConsumerTransaction
  );
  const [optionStatus, setOptionStatus] = useState([]);
  const [optionAgent, setOptionAgent] = useState([]);

  const tableHeaders = [
    {
      text: "Trx No",
      sort: true,
      name: "referenceNo",
      search: true,
      type: "text",
      position: "right",
      index: 0,
      searchType: "text",
    },
    {
      text: "Trx Date",
      sort: true,
      name: "transactionDate",
      search: true,
      type: "date",
    },
    {
      text: "Consumer",
      sort: true,
      name: "email",
      search: true,
      type: "text",
      position: "right",
      index: 2,
      searchType: "text",
    },
    {
      text: "Agent",
      sort: true,
      name: "agentName",
      searchName: "agentNameList",
      search: true,
      type: "text",
      position: "right",
      index: 1,
      searchType: "multiple",
      options: optionAgent,
    },
    {
      text: "Product",
      sort: true,
      name: "agentDisplayName",
      search: true,
      type: "text",
    },
    {
      text: "Trx Status",
      sort: false,
      name: "transactionStatusName",
      searchName: "transactionStatusList",
      search: false,
      type: "text",
      position: "left",
      index: 2,
      searchType: 'multiple',
      options: optionStatus,
    },
    {
      text: "Total",
      sort: true,
      name: "amount",
      search: true,
      type: "text",
      specialFunc: (val) => val.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })
    },
  ];

  const customSearchField = [
    {
      text: "Start Date",
      name: "fromDate",
      position: "left",
      index: 0,
      searchType: "date",
      defaultValue: from.toISOString().split("T")[0]
    },
    {
      text: "End Date",
      name: "toDate",
      position: "left",
      index: 1,
      searchType: "date",
      defaultValue: now.toISOString().split("T")[0]
    },
  ]

  const actionButton = [
    {
      icon: <span className="fas fa-edit" aria-hidden="true"></span>,
      description: "Edit",
      action: (val) => history.push(`/consumer-transaction-edit/${val.transactionId}`),
      condition: (val) => true,
    },
  ];

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Transaction Consumer",
      })
    );
    dispatch(getStatusTransaction());
    dispatch(getAgents());
  }, []);

  useEffect(() => {
    if (statusTransaction.length > 0) {
      let tempStatus = [];
      statusTransaction.forEach(item => {
        tempStatus.push({ value: item.transactionStatus, label: item.transactionStatusName });
      });
      setOptionStatus(tempStatus);
    }
  }, [statusTransaction]);

  useEffect(() => {
    if (agents.length > 0) {
      let tempAgents = [];
      agents.forEach(item => {
        tempAgents.push({ value: item.agentName, label: item.agentName})
      });
      setOptionAgent(tempAgents);
    }
  }, [agents]);

  useEffect(() => {
    dispatch(getDataConsumerTransaction(
      page,
      size,
      searchValue,
      orderBy,
    ));
  }, [page, size, orderBy, searchValue]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (val) => {
    setPage(0);
    if (val.length > 0) setSearchValue(val)
    else setSearchValue(defaultDateRange);
  }

  return (
    <div className="container-fluid">
      <SearchGrid
        searchField={[...customSearchField, ...tableHeaders]}
        handleSearch={handleSearch}
        value={searchValue}
      />
      <DataTables
        headers={tableHeaders}
        listData={consumerTransactionList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={[]}
      />
    </div>
  );
};

export default ConsumerTransactionPage;
