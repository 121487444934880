
import EditAirlines from "../modules/airlines/airlines-form";
import AirlinesPage from "../modules/airlines/airlines.page";
import BannerForm from "../modules/banner/banner.form";
import Banner from "../modules/banner/banner.page";
import BIReportFiles from "../modules/bi-report/bi-report-files.page";
import BIReport from "../modules/bi-report/bi-report.page";
import BiReportUpload from "../modules/bi-report/bi-report-upload";
import Categories from "../modules/categories/categories.page";
import CategoryForm from "../modules/categories/category.form";
import ConsumerTransactionForm from "../modules/consumer-transaction/consumer-transaction.form";
import ConsumerTransactionPage from "../modules/consumer-transaction/consumer-transaction.page";
import EditConsumer from "../modules/consumer/consumer-form";
import ConsumerPage from "../modules/consumer/consumer.page";
import Dashboard from "../modules/dashboard/dashboard.page";
import DisbursementPage from "../modules/disbursement/disbursement.page";
import FlightPage from "../modules/flight-tier/flight";
import EditFlight from "../modules/flight-tier/flight-form";
import EditHotel from "../modules/hotel-tier/hotel-form";
import HotelTierPage from "../modules/hotel-tier/hotel-tier.page";
import MerchantGroupForm from "../modules/merchant-group/merchant-group-form.page";
import MerchantGroup from "../modules/merchant-group/merchant-group.page";
import MerchantGroupSetting from "../modules/merchant-management/merchant-group-setting.page";
import MerchantManagementForm from "../modules/merchant-management/merchant-management-form.page";
import MerchantManagementUpload from "../modules/merchant-management/merchant-management-upload";
import MerchantManagement from "../modules/merchant-management/merchant-management.page";
import MerchantSettingForm from "../modules/merchant-management/merchant-setting.form";
import NotificationForm from "../modules/notification/notification.form";
import Notification from "../modules/notification/notification.page";
import OperatorForm from "../modules/operator/operator.form";
import Operator from "../modules/operator/operator.page";
import ParameterForm from "../modules/parameter/parameter.form";
import Parameter from "../modules/parameter/parameter.page";
import ProductEdit from "../modules/products/products.form";
import Product from "../modules/products/products.page";
import ProductsUpload from "../modules/products/products.upload";
import Profile from "../modules/profile/profile.page";
import RefCodeForm from "../modules/refcode/refcode.form";
import RefCode from "../modules/refcode/refcode.page";
import RefGroupForm from "../modules/refcode/refgroup.form";
import MerchantList from "../modules/report/merchant-list.page";
import JalinRecon from "../modules/settlement-jalin/jalin-recon.page";
import SettlementJalin from "../modules/settlement-jalin/settlement-jalin.page";
import TransactionForm from "../modules/transaction/transaction-form.page";
import Transaction from "../modules/transaction/transaction.page";
import AddDuwit from "../modules/user-management/add-duwit.page";
import UserHistory from "../modules/user-management/user-history.page";
import UserManagement from "../modules/user-management/user-management.page";
import partnerTransaction from "../modules/partner-management/partner-transaction.page";
import partnerTransactionForm from "../modules/partner-management/partner-transaction-form.page";
import PartnerSettings from "../modules/partner-management/partner-settings.page";
import PartnerList from "../modules/partner-management/partner-list.page";
import PartnerForm from "../modules/partner-management/partner-form.page";
const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    group: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/merchant",
    name: "merchant-management",
    group: "/merchant",
    component: MerchantManagement,
  },
  {
    path: "/merchant-group-settings",
    name: "merchant-group-settings",
    group: "/merchant",
    component: MerchantGroupSetting,
  },
  {
    path: "/add-merchant",
    name: "add-merchant",
    group: "/merchant",
    component: MerchantManagementForm,
  },
  {
    path: "/edit-merchant/:id",
    name: "edit-merchant",
    group: "/merchant",
    component: MerchantManagementForm,
  },
  {
    path: "/merchant-settings",
    name: "merchant-settings",
    group: "/merchant-group-settings",
    component: MerchantSettingForm,
  },
  {
    path: "/merchant-settings/:id",
    name: "merchant-settings",
    group: "/merchant-group-settings",
    component: MerchantSettingForm,
  },
  {
    path: "/upload-merchant",
    name: "upload-merchant",
    group: "/merchant",
    component: MerchantManagementUpload,
  },
  {
    path: "/transaction",
    name: "transaction",
    group: "/transaction",
    component: Transaction,
  },
  {
    path: "/transaction-detail/:id",
    name: "transaction-detail",
    group: "/transaction",
    component: TransactionForm,
  },
  {
    path: "/user-merchant",
    name: "user-merchant",
    group: "/user-merchant",
    component: UserManagement,
  },
  {
    path: "/user-merchant-group",
    name: "/user-merchant-group",
    group: "/user-merchant-group",
    component: UserManagement,
  },
  {
    path: "/user-duwit",
    name: "user-duwit",
    group: "/user-duwit",
    component: UserManagement,
  },
  {
    path: "/user-switching",
    name: "user-duwit",
    group: "/user-switching",
    component: UserManagement,
  },
  {
    path: "/user-partner",
    name: "user-partner",
    group: "/user-partner",
    component: UserManagement,
  },
  {
    path: "/add-user-merchant",
    name: "add-user",
    group: "/user-merchant",
    component: AddDuwit,
  },
  {
    path: "/add-user-merchant-group",
    name: "add-user",
    group: "/user-merchant-group",
    component: AddDuwit,
  },
  {
    path: "/add-user-duwit",
    name: "add-user",
    group: "/user-duwit",
    component: AddDuwit,
  },
  {
    path: "/add-user-switching",
    name: "add-user",
    group: "/user-switching",
    component: AddDuwit,
  },
  {
    path: "/add-user-partner",
    name: "add-user",
    group: "/user-partner",
    component: AddDuwit,
  },
  {
    path: "/edit-user-duwit/:id",
    name: "edit-user",
    group: "/user-duwit",
    component: AddDuwit,
  },
  {
    path: "/edit-user-merchant/:id",
    name: "edit-user",
    group: "/user-merchant",
    component: AddDuwit,
  },
  {
    path: "/edit-user-merchant-group/:id",
    name: "edit-user-group",
    group: "/user-merchant-group",
    component: AddDuwit,
  },
  {
    path: "/edit-user-switching/:id",
    name: "edit-user",
    group: "/user-switching",
    component: AddDuwit,
  },
  {
    path: "/merchant-group",
    name: "merchant-group",
    group: "/merchant-group",
    component: MerchantGroup,
  },
  {
    path: "/add-merchant-group",
    name: "add-merchant-group",
    group: "/merchant-group",
    component: MerchantGroupForm,
  },
  {
    path: "/edit-merchant-group/:id",
    name: "edit-merchant-group",
    group: "/merchant-group",
    component: MerchantGroupForm,
  },
  {
    path: "/settlement-jalin",
    name: "settlement-jalin",
    group: "/settlement-jalin",
    component: SettlementJalin,
  },
  {
    path: "/jalin-recon/:id",
    name: "jalin-recon",
    group: "/settlement-jalin",
    component: JalinRecon,
  },
  {
    path: "/report-merchant",
    name: "report-merchant",
    group: "/report-merchant",
    component: MerchantList,
  },
  {
    path: "/bi-report",
    name: "bi-report",
    group: "/bi-report",
    component: BIReport,
  },
  {
    path: "/bi-report-files/:id",
    name: "bi-report-files",
    group: "/bi-report",
    component: BIReportFiles,
  },
  {
    path: "/upload-bi-report",
    name: "upload-bi-report",
    group: "/bi-report",
    component: BiReportUpload,
  },
  {
    path: "/profile",
    name: "profile",
    group: "/profile",
    component: Profile,
  },
  {
    path: "/user-history/:role/:id",
    name: "user-history",
    group: "/user-history/:role/:id",
    component: UserHistory,
  },
  {
    path: "/consumer",
    name: "user-history",
    group: "/consumer",
    component: ConsumerPage,
  },
  {
    path: "/edit-consumer/:id",
    name: "user-history",
    group: "/consumer",
    component: EditConsumer,
  },
  {
    path: "/consumer-trx",
    name: "consumer-transaction",
    group: "/consumer-trx",
    component: ConsumerTransactionPage,
  },
  {
    path: "/consumer-transaction-edit",
    name: "consumer-transaction-edit",
    group: "/consumer-transaction-edit",
    component: ConsumerTransactionForm,
  },
  {
    path: "/product",
    name: "product",
    group: "/product",
    component: Product,
  },
  {
    path: "/product-form/:id",
    name: "product-form",
    group: "/product",
    component: ProductEdit,
  },
  {
    path: "/provider",
    name: "operator",
    group: "/provider",
    component: Operator,
  },
  {
    path: "/provider-edit/:id",
    name: "provider-edit",
    group: "/provider",
    component: OperatorForm,
  },
  {
    path: "/categories",
    name: "categories",
    group: "/categories",
    component: Categories,
  },
  {
    path: "/category-form/:id",
    name: "category-form",
    group: "/categories",
    component: CategoryForm,
  },
  {
    path: "/banner",
    name: "banner",
    group: "/banner",
    component: Banner,
  },
  {
    path: "/banner-form/:id",
    name: "banner-form",
    group: "/banner",
    component: BannerForm,
  },
  {
    path: "/notification",
    name: "notification",
    group: "/notification",
    component: Notification,
  },
  {
    path: "/notification-form/:id",
    name: "notification-form",
    group: "/notification",
    component: NotificationForm,
  },
  {
    path: "/parameter",
    name: "parameter",
    group: "/parameter",
    component: Parameter,
  },
  {
    path: "/parameter-form/:id",
    name: "parameter-form",
    group: "/parameter",
    component: ParameterForm,
  },
  {
    path: "/ref-code",
    name: "ref-code",
    group: "/ref-code",
    component: RefCode,
  },
  {
    path: "/ref-code-form/:id",
    name: "ref-code-form",
    group: "/ref-code",
    component: RefCodeForm,
  },
  {
    path: "/ref-group-form/:id",
    name: "ref-group-form",
    group: "/ref-code",
    component: RefGroupForm,
  },
  {
    path: "/disbursement",
    name: "disbursement",
    group: "/disbursement",
    component: DisbursementPage,
  },
  {
    path: "/upload-product",
    name: "upload-product",
    group: "/upload-product",
    component: ProductsUpload,
  },
  {
    path: "/airlines",
    name: "airlines",
    group: "/airlines",
    component: AirlinesPage,
  },
  {
    path: "/airlines-form/:id",
    name: "airlines-form",
    group: "/airlines",
    component: EditAirlines,
  },
  {
    path: "/flight-tier",
    name: "flight-tier",
    group: "/flight-tier",
    component: FlightPage,
  },
  {
    path: "/flight-tier-form/:id",
    name: "flight-tier-form",
    group: "/flight-tier",
    component: EditFlight,
  },
  {
    path: "/hotel-tier",
    name: "hotel-tier",
    group: "/hotel-tier",
    component: HotelTierPage,
  },
  {
    path: "/hotel-tier-form/:id",
    name: "hotel-tier-form",
    group: "/hotel-tier",
    component: EditHotel,
  },
  {
    path: "/partner-transaction",
    name: "partner-transaction",
    group: "/partner",
    component: partnerTransaction,
  },
  {
    path: "/partner-transaction-detail/:id",
    name: "partner-transaction-detail",
    group: "/transaction",
    component: partnerTransactionForm,
  },
  {
    path: "/partner",
    name: "partner-list",
    group: "/partner",
    component: PartnerList,
  },
  {
    path: "/partner-settings",
    name: "partner-settings",
    group: "/partner",
    component: PartnerSettings,
  },
  {
    path: "/partner-add",
    name: "partner-add",
    group: "/partner",
    component: PartnerForm,
  },
  {
    path: "/partner-edit/:id",
    name: "partner-edit",
    group: "/partner",
    component: PartnerForm,
  },
  {
    path: "/partner-view/:id",
    name: "partner-view",
    group: "/partner",
    component: PartnerForm,
  },
];

export default routes;