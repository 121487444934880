import { batch } from "react-redux";
import { UserManagementService } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import UserManagementTypes from "./user-management.types";

export const getData = (type, page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const user = state.auth.user;
    try {
      batch(() => {
        dispatch({
          type: UserManagementTypes.GET_DATA,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING_TABLE,
        });
      });

      const merchantId = user.merchant?.merchantId ? user.merchant.merchantId : "";

      const listUser = await UserManagementService.getListData(
        merchantId,
        type,
        page,
        size,
        searchQuery,
        order,
        token
      );

      // data.content
      batch(() => {
        dispatch({
          type: UserManagementTypes.GET_DATA_SUCCESS,
          payload: listUser.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    } catch (err) {
      batch(() => {
        dispatch({
          type: UserManagementTypes.GET_DATA_FAILURE,
          payload: err.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    }
  };
};

export const getHistory = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
        dispatch({ type: UserManagementTypes.GET_HISTORY });
      });

      const historyData = await UserManagementService.getHistory(id, token);

      batch(() => {
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({ type: UserManagementTypes.GET_HISTORY_SUCCESS, payload: historyData.data});
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({ type: UserManagementTypes.GET_HISTORY_FAILURE });
      });
    }
  };
};

export const getRoles = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({
        type: UserManagementTypes.GET_ROLES,
      });
      const roles = await UserManagementService.getRoles(token);
      dispatch({
        type: UserManagementTypes.GET_ROLES_SUCCESS,
        payload: roles.data,
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: UserManagementTypes.GET_ROLES_FAILURE,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Get role list failed",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const createUser = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: UserManagementTypes.CREATE_USER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });
      // data.fullName = data.username;
      data.username = data.email;
      const createProcess = await UserManagementService.createUser(data, token);
      if (createProcess.statusCode === 200) {
        batch(() => {
          dispatch({
            type: UserManagementTypes.CREATE_USER_SUCCESS,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Create new user success",
              alertType: "success",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: UserManagementTypes.CREATE_USER_FAILURE,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Create new user failed",
              alertType: "error",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: UserManagementTypes.CREATE_USER_FAILURE,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Create new user failed",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const defaultingState = () => {
  return (dispatch) => {
    dispatch({ type: UserManagementTypes.DEFAULT_CREATE_STATE });
  };
};

export const deleteUser = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;

    try {
      batch(() => {
        dispatch({ type: UserManagementTypes.DELETE_USER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const deleting = await UserManagementService.deleteUser(id, token);

      if (deleting.statusCode === 200) {
        batch(() => {
          dispatch({ type: UserManagementTypes.DELETE_USER_SUCCESS });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Delete user success",
              alertType: "success",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({ type: UserManagementTypes.DELETE_USER_FAILURE });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Delete user failed",
              alertType: "error",
            },
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        });
      }
    } catch (err) {
      batch(() => {
        dispatch({ type: UserManagementTypes.DELETE_USER_FAILURE });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete user failed",
            alertType: "error",
          },
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const editUser = (id, data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: UserManagementTypes.UPDATE_USER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const createProcess = await UserManagementService.updateUser(
        id,
        data,
        token
      );

      if (createProcess.statusCode === 200) {
        batch(() => {
          dispatch({
            type: UserManagementTypes.UPDATE_USER_SUCCESS,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Update user success",
              alertType: "success",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: UserManagementTypes.UPDATE_USER_FAILURE,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Update user failed",
              alertType: "error",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: UserManagementTypes.UPDATE_USER_FAILURE,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update user failed",
            alertType: "error",
          },
        });
      });
    }
  };
};
