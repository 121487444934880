/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import SearchGrid from "../../components/search-grid/index.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { defaultNotification, getDataNotification } from "../../redux/notification/notification.actions";

const Notification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [orderBy, setOrderBy] = useState({
    field: "createdDate",
    direction: false,
  });
  const [searchValue, setSearchValue] = useState([]);
  const { notificationList, first, last, totalData, totalPage, empty } =
    useSelector((state) => state.notifications);

  const tableHeaders = [
    {
      text: "Subject",
      sort: true,
      name: "subject",
      search: true,
      type: "text",
      position: "left",
      index: 0,
      searchType: "text",
    },
    {
      text: "Body",
      sort: true,
      name: "body",
      search: true,
      type: "text",
    },
    {
      text: "Planned Date Sending",
      sort: true,
      name: "plannedDate",
      search: true,
      type: "date",
      position: "left",
      index: 1,
      searchType: "date",
    },
    {
      text: "Limited To",
      sort: true,
      name: "limitedToRoles",
      search: true,
      type: "text",
      position: "left",
      index: 2,
      searchType: "select",
      options: [],
      specialFunc: (val) => (val ? val : ""),
    },
    {
      text: "Status",
      sort: false,
      name: "isActive",
      search: false,
      type: "text",
      position: "right",
      index: 0,
      searchType: "select",
      options: [],
      specialFunc: (val) => (val ? "Success" : "Pending"),
    },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-search" aria-hidden="true"></span>,
      description: "View",
      action: (val) => history.push(`/notification-form/${val.notificationId}`),
      condition: (val) => true,
    },
    {
      icon: <span className="fas fa-edit" aria-hidden="true"></span>,
      description: "Edit",
      action: (val) => history.push(`/notification-form/${val.notificationId}`),
      condition: (val) => true,
    },
  ];

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Notification",
        add: true,
        pathAdd: `/notification-form/null`
      })
    );
    dispatch(defaultNotification());
  }, []);

  useEffect(() => {
    dispatch(getDataNotification(page, size, searchValue, orderBy));
  }, [page, size, orderBy, searchValue]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (val) => {
    setPage(0);
    setSearchValue(val);
  }

  return (
    <div className="container-fluid">
      <SearchGrid searchField={tableHeaders} handleSearch={handleSearch} value={searchValue} />
      <DataTables
        headers={tableHeaders}
        listData={notificationList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default Notification;
