import TransactionTypes from "./transaction.types";

const INITIAL_STATE = {
  // get data state
  transactionList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  error: false,
  errorMessage: "",

  // update settlement state
  statusUpdate: "",
  successUpdate: false,

  //export state
  statusExport: "",
  exportData: null
}

const INITIAL_STATE_Merchant = {
  data:null,
  status: null,
  error: false,
  errorMessage: "",
}

const INITIAL_STATE_EXPORT_TRANSACTION = {
  data:null,
  status: null,
  error: false,
  errorMessage: "",
}

export const TransactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TransactionTypes.GET_DATA_TRANSACTION:
      return {
        ...state,
      }
    case TransactionTypes.GET_DATA_TRANSACTION_SUCCESS:
      return {
        ...state,
        status: 200,
        transactionList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      }
    case TransactionTypes.GET_DATA_TRANSACTION_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        errorMessage: action.payload.message,
        error: true,
      }
    case TransactionTypes.UPDATE_SETTLEMENT:
      return {
        ...state,
        statusUpdate: "fetching",
        successUpdate: false,
      }
    case TransactionTypes.UPDATE_SETTLEMENT_SUCCESS:
      return {
        ...state,
        statusUpdate: "success",
        successUpdate: true,
      }
    case TransactionTypes.UPDATE_SETTLEMENT_FAILURE:
      return {
        ...state,
        statusUpdate: "failed",
        successUpdate: false,
      }
      case TransactionTypes.GET_DATA_BALANCE:
        return {
          ...state,
        }
      case TransactionTypes.GET_DATA_BALANCE_SUCCESS:
        return {
          ...state,
          status: 200,
          data: action.payload,
          error: false,
        }
      case TransactionTypes.GET_DATA_BALANCE_FAILURE:
        return {
          ...state,
          status: action.payload.status,
          errorMessage: action.payload.message,
          error: true,
        }
    default:
      return state;
  }
}

export const MerchantBalanceReducer = (state = INITIAL_STATE_Merchant, action) => {
  switch (action.type) {
    case TransactionTypes.GET_DATA_BALANCE:
      return {
        ...state,
      }
    case TransactionTypes.GET_DATA_BALANCE_SUCCESS:
      return {
        ...state,
        status: 200,
        data: action.payload,
        error: false,
      }
    case TransactionTypes.GET_DATA_BALANCE_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        errorMessage: action.payload.message,
        error: true,
      }
    default:
      return state;
    }
  }

  export const ExportTransactionReducer = (state = INITIAL_STATE_EXPORT_TRANSACTION, action) => {
    switch (action.type) {
      case TransactionTypes.EXPORT_TRANSACTION:
        return {
          ...state,
          statusExport: "fetching",
          exportData: null,
          error: false
        }
      case TransactionTypes.EXPORT_TRANSACTION_SUCCESS:
        return {
            ...state,
            statusExport: "success",
            exportData: action.payload,
            error: false,
        }
        case TransactionTypes.EXPORT_TRANSACTION_FAILURE:
        return {
          ...state,
          statusExport: "error",
          exportData: null,
          errorMessage: action.payload.message,
          error: true,
        }
      default:
        return state;
      }
    }

export default TransactionReducer;