/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import {
    defaultUploadState,
    getTemplate,
    uploadReport,
    getReportTypes,
} from "../../redux/bi-report/bi-report.action";
import Select from "react-select";
import ActionsActionTypes from "../../redux/actions/actions.types";

const BiReportUpload = () => {
    const dispatch = useDispatch();
    const { fileTemplate, errorUploadFile, reportTypes, uploadReportStatus } = useSelector(
        (state) => state.biReport
    );
    const [file, setFile] = useState(null);
    const [formVal, setFormVal] = useState();
    useEffect(() => {
        dispatch(
            setHeaderButton({
                titleHeader: "Create Report",
                isChild: true,
            })
        );
        dispatch(getReportTypes());
    }, [dispatch]);

    useEffect(() => {
        console.log("reportType", formVal?.reportType);
        if (formVal?.reportType) {
            dispatch(getTemplate(formVal.reportType.templateUrl));
        }
    }, [dispatch, formVal?.reportType]);

    useEffect(() => {
        if ( uploadReportStatus === "success" ) window.history.back();
    }, [uploadReportStatus]);

    const handleUpload = () => {
        if (!formVal?.reportType) {
            dispatch({
                type: ActionsActionTypes.SHOW_ALERT,
                payload: {
                    alertMessage: "Please choose report type",
                    alertType: "error",
                },
            });
            return;
        }

        if (!file) {
            dispatch({
                type: ActionsActionTypes.SHOW_ALERT,
                payload: {
                    alertMessage: "Please choose file",
                    alertType: "error",
                },
            });
            return;
        }
        console.log("do upload");
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
            const fileContent = reader.result;
            const base64Data = btoa(fileContent);
            console.log(base64Data);
            const data = {
                fileBase64: base64Data,
                reportType: formVal?.reportType?.reportType,
            };
            dispatch(uploadReport(data));
        };
    };

    const handleChange = (e) => {
        if (e.target.files.length === 0) return;

        const value = e.target.files;
        setFile(value[0]);
        dispatch(defaultUploadState());
    };

    const handleChangeType = (e) => {
        dispatch({ type: ActionsActionTypes.HIDE_ALERT });
        setFormVal({ ...formVal, reportType: e });
    };

    const doDownload = () => {
        if (fileTemplate === "") {
            dispatch({
                type: ActionsActionTypes.SHOW_ALERT,
                payload: {
                    alertMessage: "Please choose report type",
                    alertType: "error",
                },
            });
            return;
        }
        const csvData =
            "data:text/csv;charset=utf-8," + fileTemplate.replaceAll("||", ",");

        const encodedUri = encodeURI(csvData);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "template.csv");
        document.body.appendChild(link); // Required for Firefox(?)
        link.click();
    };

    return (
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-12">
                    <div class="card card-primary card-outline card-tabs">
                        <div class="card-header p-0 pt-1 border-bottom-0">
                            <ul
                                class="nav nav-tabs"
                                id="custom-tabs-three-tab"
                                role="tablist"
                            >
                                <li class="nav-item">
                                    <a
                                        class="nav-link active"
                                        id="custom-tabs-three-home-tab"
                                        data-toggle="pill"
                                        href="#custom-tabs-three-home"
                                        role="tab"
                                        aria-controls="custom-tabs-three-home"
                                        aria-selected="true"
                                    >
                                        Info Report
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div
                                class="tab-content"
                                id="custom-tabs-three-tabContent"
                            >
                                <div
                                    class="tab-pane fade show active"
                                    id="custom-tabs-three-home"
                                    role="tabpanel"
                                    aria-labelledby="custom-tabs-three-home-tab"
                                >
                                    <form class="form-horizontal">
                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label>
                                                    Choose Report Type
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <Select
                                                    isSearchable={true}
                                                    onChange={handleChangeType}
                                                    options={reportTypes.map(
                                                        (opt) => {
                                                            return {
                                                                ...opt,
                                                                label: opt.reportType,
                                                                value: opt.biReportTypeId,
                                                            };
                                                        }
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-2">
                                                <label>Download Template</label>
                                            </div>
                                            <div class="col-sm-6">
                                                <i class="fas fa-link"></i>
                                                <a
                                                    href={`#`}
                                                    onClick={doDownload}
                                                >
                                                    template.csv
                                                </a>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-2">
                                                <label>Upload Template</label>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="exampleInputFile"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <label
                                                            class="custom-file-label"
                                                            for="exampleInputFile"
                                                        >
                                                            {file?.name ??
                                                                "Choose file"}
                                                        </label>
                                                    </div>
                                                    <div class="input-group-append">
                                                        <span
                                                            class="input-group-text"
                                                            onClick={
                                                                handleUpload
                                                            }
                                                        >
                                                            Upload
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="form-group row">
                      <div class="col-sm-2">
                        <span class="badge badge-success">Success Message</span>
                      </div>
                      <div class="col-sm-6">
                        <label>Successfully upload 100 records</label>
                      </div>
                    </div> */}
                                        {errorUploadFile !== "" && (
                                            <div class="form-group row">
                                                <div class="col-sm-2">
                                                    <label class="badge badge-danger">
                                                        Error Message
                                                    </label>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>
                                                        ** {errorUploadFile}
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                        {/* <div class="row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-6">
                        <label>
                          ** Row 2: MDR is percentage value (1-100)!
                        </label>
                      </div>
                    </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BiReportUpload;
