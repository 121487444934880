import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTables from "../../components/datatables/datatables.component";
import MultipleSearch from "../../components/multiple-search/multiple-search.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import TransactionTypes from "../../redux/transaction/transaction.types";

import {
  getData,
  getMerchantData,
  refundSettlement,
} from "../../redux/transaction/transaction.action";
import { exportTransaction } from "../../redux/transaction/transaction.action";
import { getDataList } from "../../redux/merchat-management/merchant-management.action";
import Select from "react-select";
import { currency } from "../../components/utils/helper";
import moment from "moment";

import * as FileSaver from "file-saver";

const Transaction = () => {
  const history = useHistory();
  const tableHeaders = [
    {
      text: "Merchant Name",
      sort: true,
      name: "merchantName",
      search: true,
      type: "text",
    },
    {
      text: "Merchant PAN",
      sort: true,
      name: "merchantPan",
      search: true,
      type: "text",
    },
    {
      text: "RRN",
      sort: true,
      name: "paymentDTO.rrn",
      search: true,
      type: "text",
    },
    {
      text: "No Invoice",
      sort: true,
      name: "invoiceNo",
      search: true,
      type: "text",
    },
    {
      text: "Transaction Date",
      sort: true,
      name: "trxDate",
      search: true,
      type: "text",
    },
    {
      text: "Transaction Status",
      sort: true,
      name: "status",
      search: true,
      type: "text",
    },
    {
      text: "Transaction Type",
      sort: true,
      name: "trxType",
      search: true,
      type: "text",
    },
    {
      text: "Amount (Rp)",
      sort: true,
      name: "amount",
      search: false,
      type: "text",
    },
    {
      text: "MDR Amount (Rp)",
      sort: true,
      name: "settlementDTO.mdrAmount",
      search: false,
      type: "text",
    },
    {
      text: "Settlement Status",
      sort: true,
      name: "settlementDTO.settlementStatus",
      search: true,
      type: "text",
    },
  ];

  const options = [
    { value: "PENDING", label: "PENDING" },
    { value: "PAID", label: "PAID" },
    { value: "CANCELLED", label: "CANCELLED" },
    { value: "REFUND", label: "REFUND" },
  ];
  const options2 = [
    { value: "", label: "ALL" },
    { value: "SETTLED", label: "SETTLED" },
    { value: "NOTSETTLED", label: "NOT SETTLED" },
  ];

  const optionsType = [
    { value: "ON", label: "ON-US" },
    { value: "OFD", label: "OFF-US" },
  ];
  const { user } = useSelector((state) => state.auth);

  const actionButton = [
    {
      icon: <span className="fas fa-search" aria-hidden="true"></span>,
      description: "View",
      action: (val) => history.push(`/transaction-detail/${val.transactionId}`),
      condition: (val) => true,
    },
    {
      icon: <span className="fas fa-undo-alt" aria-hidden="true"></span>,
      description: "Refund",
      action: async (val) => {
        dispatch(refundSettlement(val.transactionId));
      },
      condition: (val) => val.settlementDTO?.settlementStatus === "SETTLED",
    },
  ];

  const {
    // status,
    transactionList,
    first,
    last,
    totalData,
    totalPage,
    // error,
    empty,
    statusUpdate,
    successUpdate,
  } = useSelector((state) => state.transactions);

  const { statusExport, exportData } = useSelector(
    (state) => state.exportTransaction
  );

  const {
    // status,
    data,
    // error,
  } = useSelector((state) => state.merchantBalance);

  const {
    // status,
    merchantList,
    // first,
    // last,
    // totalData,
    totalPageMerchant,
    // error,
    // empty,
    errorDelete,
    errorActivate,
  } = useSelector((state) => state.merchantManagement);
  // const {
  //   status,
  // } = useSelector((state) => state.exportTrans)

  var d = new Date();
  var currMonth = d.getMonth();
  var currYear = d.getFullYear();
  var firstDate = new Date(currYear, currMonth, 1);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [page2, setPage2] = useState(0);
  const [size2, setSize2] = useState(5);
  const [searchQuery, setSearchQuery] = useState([]);
  const [searchQuery2, setSearchQuery2] = useState([]);
  const [searchQueryMerchant, setSearchQueryMerchant] = useState([]);
  const [orderBy, setOrderBy] = useState({
    field: "trxDate",
    direction: false,
  });
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [invoice, setInvoice] = useState("");
  const [trxVal, setTrxVal] = useState([options[1].value]);
  const [trxTypeVal, setTrxTypeVal] = useState([]);
  const [settleVal, setSettleVal] = useState(options2[0]);
  const [merchantName, setMerchantName] = useState(
    user.role.roleName === "DUWIT" ? "" : user.fullName
  );
  const [searchMerchantName, setSearchMerchantName] = useState("");
  const [searchMerchantPan, setSearchMerchantPan] = useState("");
  const [rrn, setRrn] = useState("");

  const dispatch = useDispatch();
  let sDate = startDate !== "" ? moment(startDate).format("l") : "";
  let eDate = endDate !== "" ? moment(endDate).format("l") : "";

  useEffect(() => {
    dispatch(getMerchantData());
  }, []);

  useEffect(() => {
    dispatch(
      getDataList(page2, size2, searchQuery2, {
        field: "lastModifiedDate",
        direction: false,
      })
    );
    // console.log(totalPageMerchant);
  }, [page2, searchQuery2, size2]);

  useEffect(() => {
    dispatch(getData(page, size, searchQuery, orderBy));
  }, [page, searchQuery, size]);

  // useEffect(() => {
  //   if ((statusUpdate === "success") & successUpdate) {
  //     dispatch(getData(0, 5, [], { field: "createdDate", direction: false }));
  //   }
  // }, [statusUpdate, successUpdate, dispatch]);

  useEffect(() => {
    if (statusExport === "success" && exportData) {
      FileSaver.saveAs(exportData, "report.xlsx");
      dispatch({
        type: TransactionTypes.EXPORT_TRANSACTION_SUCCESS,
        payload: null,
      });
    }
  }, [statusExport, exportData, dispatch]);

  useEffect(() => {
    // set action button
    dispatch(
      setHeaderButton({
        titleHeader: "Transaction",
      })
    );
  }, [dispatch]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  // const handleSearch = () => {
  //   setSearchQuery([
  //       {param: 'startTrxDate', value: sDate},
  //       {param: 'endTrxDate', value: eDate},
  //       {param: 'transactionStatusList', value: trxVal},
  //       {param: 'settlementStatusList', value: settleVal.value},
  //       {param: 'invoiceNo', value: invoice}
  //   ]);

  //   console.log(searchQuery);
  //   dispatch(getData(page, size, searchQuery, orderBy));
  // };

  // useEffect(() => {
  //   setSearchQueryMerchant([])
  // }, [searchQueryMerchant]);

  // const findMerchant = () => {
  //   setSearchMerchantName(searchMerchantName ? searchMerchantName : searchMerchantName)
  //   setSearchMerchantPan(searchMerchantPan ? searchMerchantPan : searchMerchantPan)
  //   setSearchQueryMerchant([
  //       {param: 'merchantName', value: searchMerchantName},
  //       {param: 'merchantPan', value: searchMerchantPan},
  //   ]);
  //   console.log(searchQueryMerchant);
  //   dispatch(getDataList(page, size, searchQueryMerchant, {field: "lastModifiedDate", direction: false}))
  // };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleInvoice = (e) => {
    setInvoice(e.target.value);
  };

  const handleTrx = (e) => {
    setTrxVal(Array.isArray(e) ? e.map((x) => x.value).join() : []);
    console.log(trxVal);
  };

  const handleTrxType = (e) => {
    setTrxTypeVal(Array.isArray(e) ? e.map((x) => x.value).join() : []);
    console.log(trxTypeVal);
  };

  const handleSettle = (e) => {
    // Array.isArray(e) ? e.map((x) => x.value).join() : []
    setSettleVal(e);
  };

  const handleRrn = (e) => {
    setRrn(e.target.value);
  };

  // const handleChangeName = (e) => {
  //   setSearchMerchantName(e.target.value);
  // }

  // const handleChangePan = (e) => {
  //   setSearchMerchantPan(e.target.value);
  //   console.log(searchMerchantPan);
  // }

  const handleChangeMerchant = (e) => {
    setMerchantName(e.target.value);
  };

  const reset = () => {
    setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setInvoice("");
    setTrxVal([options[1].value]);
    setTrxTypeVal([]);
    setSettleVal(options2[0]);
    setSearchQuery([]);
    setRrn("");
  };

  const exportToExcel = () => {
    dispatch(
      exportTransaction(
        trxVal,
        sDate,
        eDate,
        settleVal,
        invoice,
        trxTypeVal,
        rrn
      )
    );
    // console.log(trxVal + startDate + endDate + settleVal + invoice);
  };

  return (
    <div className="container-fluid">
      {user?.role?.roleName === "DUWIT" ? null : (
        <div className="row">
          <div className="col-lg-3 col-6">
            <div className="small-box bg-success">
              <div className="inner">
                <h3>Rp {currency(data?.currentBalance)}</h3>

                <p>Current Balance (Rp)</p>
              </div>
              <div className="icon">
                <i className="ion ion-card"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{data?.countPaid}</h3>

                <p>Total Transactions Paid</p>
              </div>
              <div className="icon">
                <i className="ion ion-bag"></i>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{data?.countSettled}</h3>
                <p>Total Transactions Settled</p>
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph"></i>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Search</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
              {/* <!-- /.card-tools --> */}
            </div>
            {/* <!-- /.card-header --> */}
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Merchant</label>
                </div>
                <div className="col-sm-2">
                  <input
                    type="text"
                    className="form-control"
                    id="trxNo"
                    disabled
                    value={merchantName}
                    onChange={handleChangeMerchant}
                  />
                </div>
                <div className="col-sm-1">
                  {user.role.roleName === "MERCHANT" ? null : (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#modal-default"
                    >
                      <i className="fas fa-search"></i>
                      Find
                    </button>
                  )}
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-2">
                  <label>Trx Status</label>
                </div>
                <div className="col-sm-3">
                  {/* <div class="select2-primary">
                    <select class="form-control select2" multiple="multiple" data-dropdown-css-className="select2-primary" style={{width:"100%"}}>
                      <option>PENDING</option>
                      <option selected>PAID</option>
                      <option>CANCELLED</option>
                      <option>REFUND</option>
                    </select>
                  </div> */}
                  <Select
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={options}
                    isMulti
                    onChange={handleTrx}
                    value={
                      options.filter((obj) => trxVal.includes(obj.value))
                      // trxVal
                    }
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Start Trx Date</label>
                </div>
                <div className="col-sm-3">
                  <input
                    type="date"
                    className="form-control"
                    value={startDate}
                    name={"StartDate"}
                    onChange={handleStartDate}
                  />
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-2">
                  <label>Settlement Status</label>
                </div>
                <div className="col-sm-3">
                  {/* <div className="select2-primary">
                    <select className="multiple select2-primary" style={{width:"100%"}}>
                      <option selected>SETTLED</option>
                      <option>NOT SETTLED</option>
                    </select>
                  </div> */}
                  <Select
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={options2}
                    // isMulti
                    isClearable={false}
                    onChange={handleSettle}
                    value={
                      // options2.filter(obj => settleVal.includes(obj.value))
                      settleVal
                    }
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>End Trx Date</label>
                </div>
                <div className="col-sm-3">
                  <input
                    type="date"
                    className="form-control"
                    value={endDate}
                    onChange={handleEndDate}
                  />
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-2">
                  <label>Invoice No</label>
                </div>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    id="trxNo"
                    value={invoice}
                    onChange={handleInvoice}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Trx Type</label>
                </div>
                <div className="col-sm-3">
                  {/* <div class="select2-primary">
                    <select class="form-control select2" multiple="multiple" data-dropdown-css-className="select2-primary" style={{width:"100%"}}>
                      <option>PENDING</option>
                      <option selected>PAID</option>
                      <option>CANCELLED</option>
                      <option>REFUND</option>
                    </select>
                  </div> */}
                  <Select
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={optionsType}
                    isMulti
                    onChange={handleTrxType}
                    value={
                      optionsType.filter((obj) =>
                        trxTypeVal.includes(obj.value)
                      )
                      // trxVal
                    }
                  />
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-2">
                  <label>RRN</label>
                </div>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    id="rrn"
                    value={rrn}
                    onChange={handleRrn}
                  />
                </div>
              </div>
              <div className="form-group row"></div>
              <div className="form-group row" style={{ textAlign: "center" }}>
                <div className="col-sm-10">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      setPage(0);
                      setSearchQuery([
                        { param: "merchantName", value: merchantName },
                        { param: "startTrxDate", value: sDate },
                        { param: "endTrxDate", value: eDate },
                        { param: "transactionStatusList", value: trxVal },
                        { param: "trxTypeList", value: trxTypeVal },
                        {
                          param: "settlementStatusList",
                          value: settleVal.value,
                        },
                        { param: "invoiceNo", value: invoice },
                        { param: "rrn", value: rrn },
                      ]);
                      // dispatch(getData(page, size, searchQuery, orderBy));
                    }}
                  >
                    <i className="fas fa-search"></i>
                    Search
                  </button>
                  <button
                    type="submit"
                    className="btn btn-info"
                    onClick={reset}
                  >
                    <i className="fas fa-ban"></i>
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(
                        exportTransaction(
                          trxVal,
                          sDate,
                          eDate,
                          settleVal.value,
                          invoice,
                          rrn
                        )
                      );
                    }}
                  >
                    <i className="fas fa-download"></i>
                    Export To Excel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="modal-default">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Find Merchant</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group row">
                <div class="col-sm-1">
                  <label>Merchant Name</label>
                </div>
                <div class="col-sm-3">
                  <input
                    type="text"
                    class="form-control"
                    id="trxNo"
                    onChange={(e) => {
                      setSearchMerchantName(e.target.value);
                    }}
                    value={searchMerchantName}
                  />
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-1">
                  <label>Merchant PAN</label>
                </div>
                <div class="col-sm-3">
                  <input
                    type="text"
                    class="form-control"
                    id="trxNo"
                    onChange={(e) => {
                      setSearchMerchantPan(e.target.value);
                    }}
                    value={searchMerchantPan}
                  />
                </div>
                <div class="col-sm-2">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    onClick={() => {
                      // setSearchQuery2([
                      //   {param: 'merchantName', value: searchMerchantName},
                      //   {param: 'merchantPan', value: searchMerchantPan},
                      // ])
                      dispatch(
                        getDataList(
                          page2,
                          size2,
                          [
                            {
                              param: "merchantName",
                              value: searchMerchantName,
                            },
                            { param: "merchantPan", value: searchMerchantPan },
                          ],
                          { field: "lastModifiedDate", direction: false }
                        )
                      );
                    }}
                  >
                    <i class="fas fa-search"></i>
                    Search
                  </button>
                </div>
              </div>
              <table id="example1" class="table table-bordered table-striped">
                <thead style={{ backgroundColor: "#007bff" }}>
                  <tr>
                    <th class="no-sort"></th>
                    <th>No</th>
                    <th>Merchant Name</th>
                    <th>Merchant PAN</th>
                    <th>Email</th>
                    <th>City</th>
                    <th>Province</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {merchantList.map((data, i) => (
                    <tr key={i}>
                      <td>
                        <button
                          type="button"
                          class="btn btn-default btn-xs"
                          data-dismiss="modal"
                          onClick={() => {
                            setMerchantName(data.merchantName);
                          }}
                        >
                          Select
                        </button>
                      </td>
                      <td>{page2 * size2 + i + 1}</td>
                      <td>{data.merchantName}</td>
                      <td>{data.merchantPan}</td>
                      <td>{data.email}</td>
                      <td>{data.city.cityName}</td>
                      <td>{data.city.province.provinceName}</td>
                      <td>{data.status ? "Active" : "Not Active"}</td>
                    </tr>
                  ))}
                  {/* <tr>
                    <td>
                      <button type="button" class="btn btn-default btn-xs">
                        Select
                      </button>
                    </td>
                    <td>2</td>
                    <td>Merchant HIJ</td>
                    <td>12345678929291</td>
                    <td>usera@merchant.hij</td>
                    <td>Jakarta Pusat</td>
                    <td>DKI Jakarta</td>
                    <td>Active</td>
                  </tr>
                  <tr>
                    <td>
                      <button type="button" class="btn btn-default btn-xs">
                        Select
                      </button>
                    </td>
                    <td>3</td>
                    <td>Merchant DEF</td>
                    <td>-</td>
                    <td>usera@merchant.def</td>
                    <td>Surabaya</td>
                    <td>Jawa Timur</td>
                    <td>Not Active</td>
                  </tr> */}
                </tbody>
              </table>
              <div className="pagination-container">
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example1_paginate"
                >
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        page2 === 0 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => {
                          setPage2(page2 - 1);
                        }}
                      >
                        Previous
                      </button>
                    </li>
                    <li
                      className={`paginate_button page-item next ${
                        last ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => {
                          setPage2(page2 + 1);
                        }}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
          {/* <!-- /.modal-content --> */}
        </div>
        {/* <!-- /.modal-dialog --> */}
      </div>
      {/* <MultipleSearch
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        headers={tableHeaders}
      /> */}
      <DataTables
        headers={tableHeaders}
        listData={transactionList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default Transaction;
