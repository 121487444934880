import { batch } from "react-redux";
import { ReportServices } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import ReportTypes from "./report.types";

export const getReportMerchant = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({
          type: ReportTypes.GET_REPORT_MERCHANT,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING_TABLE,
        });
      });

      const listMerchant = await ReportServices.getMerchantReport(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: ReportTypes.GET_REPORT_MERCHANT_SUCCESS,
          payload: listMerchant.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    } catch (err) {
      batch(() => {
        dispatch({
          type: ReportTypes.GET_REPORT_MERCHANT_FAILURE,
          payload: err.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    }
  };
};