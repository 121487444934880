import DisbursementTypes from "./disbursement.types";

const INITIAL_STATE = {
  disbursementList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  error: false,

  consumerDetail: null,
  histories: [],
};

const DisbursementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DisbursementTypes.GET_DISBURSEMENT:
      return {
        ...state,
        error: false,
      };
    case DisbursementTypes.GET_DISBURSEMENT_SUCCESS:
      return {
        ...state,
        disbursementList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case DisbursementTypes.GET_DISBURSEMENT_FAILURE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default DisbursementReducer;