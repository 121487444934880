/**
 * created by f.putra 29/10/2015
 *
 */
import axios from "axios";

/**
 * Create an Axios Client with defaults
 *
 * base url untuk setiap request
 *
 */
let client = axios.create({
    /**
     * `baseURL` will be prepended to `url` unless `url` is absolute.
     * It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
     * to methods of that instance.
     */

    baseURL: process.env.REACT_APP_BASE_URL3,
    // baseURL: "http://localhost:9100/",

    /**
     * `timeout` specifies the number of milliseconds before the request times out.
     * If the request takes longer than `timeout`, the request will be aborted.
     */
    // timeout: 500000,

    /**
     * `maxRedirects` defines the maximum number of redirects to follow in node.js.
     * If set to 0, no redirects will be followed.
     */

    maxRedirects: 5 // default
});
// client.interceptors.response.use(function (response) {
//     console.log(response)
//     return response.data;
// });

// Set the AUTH token for any request
// client.interceptors.request.use(function (config) {
//     let token = load("tc-sm");
//     config.headers.Authorization =  token ? `Bearer ${token}` : '';
//     return config;
// });

/**
 * Request Wrapper dengan default success/error
 */
const request3 = function (options) {
    const onSuccess = function (response) {
        // console.debug('Request Successful!', response);
        return response.data;
    };

    const onError = function (error) {
        // console.error('Request Failed:', error.config);

        if (error.response) {
            // if (error.response.status === 401) {
            //     logout();
            // }

            // Request was made but server responded with something
            // other than 2xx
            // console.error('Status:', error.response.status);
            // console.error('Data:', error.response.data);
            // console.error('Headers:', error.response.headers);
        } else {
            // Something else happened while setting up the request
            // triggered the error
            // console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default request3;
