import { batch } from "react-redux";
import { BIReportServices } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import BIReportTypes from "./bi-report.types";

export const getBiReportList = (page, size, searchQuery, order) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({
                    type: BIReportTypes.GET_BI_REPORT,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_LOADING_TABLE,
                });
            });

            const fetchData = await BIReportServices.getBiReport(
                page,
                size,
                searchQuery,
                order,
                token
            );

            batch(() => {
                dispatch({
                    type: BIReportTypes.GET_BI_REPORT_SUCCESS,
                    payload: fetchData.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        } catch (err) {
            batch(() => {
                dispatch({
                    type: BIReportTypes.GET_BI_REPORT_FAILURE,
                    payload: err.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        }
    };
};

export const getBiReportDetails = (id, page, size, searchQuery, order) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({
                    type: BIReportTypes.GET_BI_REPORT_BY_ID,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_LOADING_TABLE,
                });
            });

            const fetchData = await BIReportServices.getBiReportById(
                id,
                page,
                size,
                searchQuery,
                order,
                token
            );

            batch(() => {
                dispatch({
                    type: BIReportTypes.GET_BI_REPORT_BY_ID_SUCCESS,
                    payload: fetchData.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        } catch (err) {
            batch(() => {
                dispatch({
                    type: BIReportTypes.GET_BI_REPORT_BY_ID_FAILURE,
                    payload: err.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING_TABLE,
                });
            });
        }
    };
};

export const uploadToBI = (id) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            batch(() => {
                dispatch({
                    type: BIReportTypes.UPLOAD_BI_REPORT,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_LOADING,
                });
            });

            await BIReportServices.uploadFileBI(id, token);

            batch(() => {
                dispatch({
                    type: BIReportTypes.UPLOAD_BI_REPORT_SUCCESS,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: "Upload Success",
                        alertType: "success",
                    },
                });
            });
        } catch (err) {
            batch(() => {
                dispatch({
                    type: BIReportTypes.UPLOAD_BI_REPORT_FAILURE,
                    payload: err.data,
                });
                dispatch({
                    type: ActionsActionTypes.HIDE_LOADING,
                });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: "Upload Failed",
                        alertType: "error",
                    },
                });
            });
        }
    };
};

export const getTemplate = (url) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            dispatch({ type: BIReportTypes.GET_TEMPLATE });

            const template = await BIReportServices.getTemplate(token, url);

            dispatch({
                type: BIReportTypes.GET_TEMPLATE_SUCCESS,
                payload: template,
            });
        } catch (error) {
            batch(() => {
                dispatch({ type: BIReportTypes.GET_TEMPLATE_FAILURE });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: "Get template upload batch error",
                        alertType: "error",
                    },
                });
            });
        }
    };
};

export const getReportTypes = () => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.auth.token;
        try {
            dispatch({ type: BIReportTypes.GET_REPORT_TYPES });
            const template = await BIReportServices.getReportTypes(token);

            dispatch({
                type: BIReportTypes.GET_REPORT_TYPES_SUCCESS,
                payload: template?.content,
            });
        } catch (error) {
            batch(() => {
                dispatch({ type: BIReportTypes.GET_REPORT_TYPES_FAILURE });
                dispatch({
                    type: ActionsActionTypes.SHOW_ALERT,
                    payload: {
                        alertMessage: "Get Report Types error",
                        alertType: "error",
                    },
                });
            });
        }
    };
};

export const uploadReport = (data) => {
    return async (dispatch, getState) => {
      const state = getState();
      const token = state.auth.token;
      try {
        batch(() => {
          dispatch({ type: BIReportTypes.UPLOAD_CSV });
          dispatch({ type: ActionsActionTypes.SHOW_LOADING });
        });
  
        await BIReportServices.uploadReport(data, token);
  
        batch(() => {
          dispatch({ type: BIReportTypes.UPLOAD_CSV_SUCCESS });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Success upload file",
              alertType: "success",
            },
          });
        });
      } catch (error) {
        batch(() => {
          dispatch({
            type: BIReportTypes.UPLOAD_CSV_FAILURE,
            payload: "Upload file error",
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Upload file error",
              alertType: "error",
            },
          });
        });
      }
    };
  };
  
  export const defaultUploadState = () => {
    return async (dispatch) => {
      dispatch({ type: BIReportTypes.UPLOAD_DEFAULT });
    };
  };
