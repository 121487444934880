import { batch } from "react-redux";
import { ImageServices } from "../../_services";
import { BannerServices } from "../../_services/banner.services";
import ActionsActionTypes from "../actions/actions.types";
import BannerTypes from "./banner.types";

export const getDataBanner = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: BannerTypes.GET_BANNERS });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const consumerRequest = await BannerServices.getBannerList(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: BannerTypes.GET_BANNERS_SUCCESS,
          payload: consumerRequest.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: BannerTypes.GET_BANNERS_FAILURE });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    }
  };
};

export const getDetailBanner = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: BannerTypes.GET_BANNER_DETAIL });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const bannerDetail = await BannerServices.getBannerDetail(id, token);

      batch(() => {
        dispatch({
          type: BannerTypes.GET_BANNER_DETAIL_SUCCESS,
          payload: bannerDetail.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: BannerTypes.GET_BANNER_DETAIL_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const createBanner = (dataForm) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const data = { ...dataForm };
    try {
      batch(() => {
        dispatch({ type: BannerTypes.CREATE_BANNER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const uploadImage = await ImageServices.uploadImage(
        data.imageBanner,
        "banner",
        token
      );
      data.imageBanner = uploadImage.data.fullPath;
      data.body = data.body === "<p></p>\n" ? "" : data.body;
      const newBanner = await BannerServices.createBanner(data, token);

      if (newBanner.status === "Error") {
        batch(() => {
          dispatch({ type: BannerTypes.CREATE_BANNER_FAILURE, payload: newBanner.data });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: newBanner.data,
              alertType: "error",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: BannerTypes.CREATE_BANNER_SUCCESS,
            payload: newBanner,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Add new banner success",
              alertType: "success",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({ type: BannerTypes.CREATE_BANNER_FAILURE, payload: error });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Add new banner failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const updateBanner = (dataForm) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    let data = { ...dataForm };
    const id = data.bannerId;
    try {
      batch(() => {
        dispatch({ type: BannerTypes.UPDATE_BANNER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });
      data.body = data.body === "<p></p>\n" ? "" : data.body;
      delete data.bannerId;
      delete data.createdDate;
      delete data.isDeleted;
      delete data.modifiedDate;

      if (typeof data.imageBanner !== "string") {
        const uploadImage = await ImageServices.uploadImage(
          data.imageBanner,
          "banner",
          token
        );
        data.imageBanner = uploadImage.data.fullPath;
      }
      const update = await BannerServices.updateBanner(id, data, token);

      if (update.status === "Error") {
        batch(() => {
          dispatch({ type: BannerTypes.UPDATE_BANNER_FAILURE, payload: update.data });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: update.data,
              alertType: "error",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({ type: BannerTypes.UPDATE_BANNER_SUCCESS, payload: update });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Update banner success",
              alertType: "success",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({ type: BannerTypes.UPDATE_BANNER_FAILURE, payload: error });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update banner failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const deleteBanner = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: BannerTypes.DELETE_BANNER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const deleteBanner = await BannerServices.deleteBanner(id, token);

      batch(() => {
        dispatch({
          type: BannerTypes.DELETE_BANNER_SUCCESS,
          payload: deleteBanner,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete banner success",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: BannerTypes.DELETE_BANNER_FAILURE, payload: error });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete banner failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const defaultBanner = () => async (dispatch) =>
  dispatch({ type: BannerTypes.DEFAULT_BANNER_STATE });
