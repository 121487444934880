import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import SearchGrid from "../../components/search-grid/index.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { defaultParameter, getDataParameters } from "../../redux/parameter/parameter.actions";

const Parameter = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [orderBy, setOrderBy] = useState({
    field: "",
    direction: false,
  });
  const [searchValue, setSearchValue] = useState([]);
  const { parameterList, first, last, totalData, totalPage, empty } =
    useSelector((state) => state.dataParameter);

  const tableHeaders = [
    {
      text: "Param Name",
      sort: true,
      name: "paramName",
      search: true,
      type: "text",
      position: "left",
      index: 0,
      searchType: "text",
    },
    {
      text: "Param Value",
      sort: true,
      name: "paramValue",
      search: true,
      type: "text",
      position: "left",
      index: 1,
      searchType: "text",
    },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-edit" aria-hidden="true"></span>,
      description: "Edit",
      action: (val) => history.push(`/parameter-form/${val.paramId}`),
      condition: (val) => true,
    },
  ];

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Parameter",
      })
    );
    dispatch(defaultParameter());
  }, []);

  useEffect(() => {
    dispatch(getDataParameters(page, size, searchValue, orderBy));
  }, [page, size, orderBy, searchValue]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (val) => {
    setPage(0);
    setSearchValue(val);
  }

  return (
    <div class="container-fluid">
      <SearchGrid searchField={tableHeaders} handleSearch={handleSearch} value={searchValue} />
      <DataTables
        headers={tableHeaders}
        listData={parameterList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default Parameter;
