import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select, { components } from "react-select";
import InputField from "../../components/input-field/input-field.component";
import TextArea from "../../components/text-area/text-area.component";
import {
  setHeaderButton,
  showAlert,
} from "../../redux/actions/actions.actions";
import {
  createMerchant,
  getCategory,
  getCities,
  getDataById,
  getMerchantGroup,
  updateMerchant,
  updateUrlCallback,
} from "../../redux/merchat-management/merchant-management.action";

import {
  getPartnerSetting,
  updatePartnerSetting,
} from "../../redux/partner/partner.actions";
import request from "../../_network/request";

const PartnerSettings = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const {
    panOptions,
    categoryOptions,
    categoryLoading,
    criteriaOptions,
    createMerchantSuccess,
    updateMerchantSuccess,
    updateUrlCallbackSuccess,
    dataById,
    cities,
    citiesOwner,
    merchantGroupList,
    mgLoading,
  } = useSelector((state) => state.merchantManagement);
  const { token, user } = useSelector((state) => state.auth);
  const {
    provinces,
    identity,
    bank,
    businessEntity,
    businessSector,
    businessCategory,
  } = useSelector((state) => state.masterData);

  const [managementForm, setManagementForm] = useState({
    activateMerchant: false,
    activationDate: "",
    address: "",
    administratorName: "",
    administratorPosition: "",
    bankAccount: "",
    bankName: "",
    businessCategoryId: "",
    businessEntityId: "",
    businessSectorId: "",
    businessValidDate: "",
    city: {
      cityId: "",
      cityName: "",
      province: {
        provinceId: "",
        provinceName: "",
      },
    },
    digitNine: "",
    email: "",
    endCoopDate: "",
    establishmentDate: "",
    establishmentPlace: "",
    id: "",
    imageIdentity: "",
    imageNpwp: "",
    imageSiup: "",
    imageStaticQris: "",
    lastModifiedBy: "",
    lastModifiedDate: "",
    lmid: "",
    mdr: 0,
    merchantCategoryCode: "",
    merchantCategoryValue: "",
    merchantCriteriaCode: "",
    merchantCriteriaValue: "",
    merchantGroupId: "",
    merchantId: "",
    merchantIdentityId: "",
    merchantIdentityNo: "",
    merchantName: "",
    merchantPan: "",
    nmid: "",
    ownerAddress: "",
    ownerCity: {
      cityId: "",
      cityName: "",
      province: {
        provinceId: "",
        provinceName: "",
      },
    },
    ownerEmail: "",
    ownerIdentityId: "",
    ownerIdentityNo: "",
    ownerName: "",
    phoneOwner: "",
    phoneStore: "",
    postal: "",
    province: {
      provinceId: "",
      provinceName: "",
    },
    siupNo: "",
    startCoopDate: "",
    status: false,
    terminalCount: "",
    verificationStatus: "",
    urlCallbackQris: "",
  });
  const [name, setName] = useState({
    imageSiup: "",
    imageNpwp: "",
    imageIdentity: "",
  });
  const [options, setOptions] = useState({
    provinces: [],
    cities: [],
    citiesOwner: [],
    identity: [],
    bank: [],
    businessEntity: [],
    businessSector: [],
    businessCategory: [],
    mgList: [],
    categoryOptions: [],
  });

  useEffect(() => {
    if (params.id) dispatch(getDataById(params.id));
    if (user.role.roleName === "MERCHANT_GROUP")
      setManagementForm((mf) => ({
        ...mf,
        merchantGroupId: user.merchantGroup.merchantGroupId,
      }));
    dispatch(
      getMerchantGroup(
        user.role.roleName === "MERCHANT_GROUP"
          ? user.merchantGroup.merchantGroupName
          : ""
      )
    );
    dispatch(getCategory(""));
  }, [dispatch]);

  useEffect(() => {
    let optionTemp = { ...options };
    optionTemp.mgList = [];
    merchantGroupList?.forEach((mg) => {
      optionTemp.mgList.push({
        value: mg.merchantGroupId,
        label: mg.merchantGroupName,
      });
    });
    setOptions(optionTemp);
  }, [merchantGroupList]);

  useEffect(() => {
    let optionTemp = { ...options };
    optionTemp.categoryOptions = [];
    categoryOptions?.forEach((opt) => {
      optionTemp.categoryOptions.push({
        value: opt.merchantCategoryCode,
        label: `${opt.merchantCategoryCode} - ${opt.merchantCategoryValue}`,
      });
    });
    setOptions(optionTemp);
  }, [categoryOptions]);

  useEffect(() => {
    mappingOptions();
  }, [
    provinces,
    identity,
    bank,
    businessEntity,
    businessSector,
    businessCategory,
    cities,
    citiesOwner,
  ]);

  const [images, setImages] = useState({
    identity: "",
    npwp: "",
    siup: "",
  });

  useEffect(() => {
    if (params.id && dataById) {
      setManagementForm(dataById);
      if (dataById.merchantGroup && dataById.merchantGroup !== null) {
        dispatch(getMerchantGroup(dataById.merchantGroup.merchantGroupName));
      }
      dispatch(getCategory(dataById.merchantCategoryValue));
      gettingImage();
    }
  }, [dataById]);

  const gettingImage = async () => {
    let tempImage = { ...images };
    const imageIdentity = await request({
      url: `/v1/merchants/image/merchant?filename=${dataById.imageIdentity}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    });
    const imageNpwp = await request({
      url: `/v1/merchants/image/merchant?filename=${dataById.imageNpwp}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    });
    const imageSiup = await request({
      url: `/v1/merchants/image/merchant?filename=${dataById.imageSiup}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    });
    tempImage.identity = btoa(
      new Uint8Array(imageIdentity).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    tempImage.npwp = btoa(
      new Uint8Array(imageNpwp).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    // tempImage.siup = btoa(
    //   new Uint8Array(imageSiup).reduce(
    //     (data, byte) => data + String.fromCharCode(byte),
    //     ""
    //   )
    // );
    tempImage.siup = new Buffer(imageSiup).toString("base64");
    setImages(tempImage);
  };

  const [formInput, setFormInput] = useState({
    trxCbUrl: "",
    trxSuccessRdrUrl: "",
    trxFailRdrUrl: "",
    refundTrxCbUrl: "",
    accLinkCbUrl: "",
    accLinkSuccessRdrUrl: "",
    accLinkFailRdrUrl: "",
    topUpCbUrl: "",
    accLinkIsRdr: "",
  });
  const [trxIsRdr, setTrxIsRdr] = useState(false);
  const [accLinkIsRdr, setAccLinkIsRdr] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  console.log("isEdit", isEdit);

  // const headerActionButton = [
  //   isEdit ? (
  //     <button
  //       type="button"
  //       name="save"
  //       className="btn btn-primary"
  //       onClick={() => onSave()}
  //     >
  //       <i className="fas fa-save"></i>
  //       Save
  //     </button>
  //   ) : (
  //     <button
  //       type="button"
  //       name="edit"
  //       className="btn btn-warning"
  //       onClick={() => setIsEdit(!isEdit)}
  //     >
  //       <i className="fas fa-user-lock"></i>
  //       Edit
  //     </button>
  //   ),
  // ];
  useEffect(() => {
    // dispatch(
    //   setHeaderButton({
    //     buttonWithFunction: true,
    //     buttonFunction: onSave,
    //     buttonText: "Edit",
    //     buttonIcon: <i className="fas fa-edit"></i>,
    //     titleHeader: "Partner - Settings",
    //     // params.id ? "Edit Partner" : "Add Partner"
    //     isChild: true,
    //   })
    // );
    dispatch(
      setHeaderButton({
        titleHeader: "Partner - Settings",
        isChild: true,
        hasCustomButton: true,
        customButton: isEdit ? (
          <button
            type="button"
            name="save"
            className="btn btn-primary"
            onClick={(e) => onSave(e)}
          >
            <i className="fas fa-save"></i>
            Save
          </button>
        ) : (
          <button
            type="button"
            name="edit"
            className="btn btn-warning"
            onClick={() => setIsEdit(!isEdit)}
          >
            <i className="fas fa-user-lock"></i>
            Edit
          </button>
        ),
      })
    );
  }, [dispatch, formInput, trxIsRdr, accLinkIsRdr, isEdit]);

  useEffect(() => {
    if (
      createMerchantSuccess === "success" ||
      updateMerchantSuccess === "success"
    )
      window.history.back();
  }, [createMerchantSuccess, updateMerchantSuccess]);

  useEffect(() => {
    if (updateUrlCallbackSuccess === "success")
      //   window.history.back();
      console.log("url update");
  }, [updateUrlCallbackSuccess]);

  const mappingOptions = () => {
    let newOptions = {
      provinces: [],
      cities: [],
      identity: [],
      bank: [],
      businessEntity: [],
      businessSector: [],
      businessCategory: [],
      citiesOwner: [],
    };
    provinces?.forEach((prov) => {
      newOptions.provinces.push({
        label: prov.provinceName,
        value: prov.provinceId,
      });
    });
    cities?.forEach((city) => {
      newOptions.cities.push({
        label: city.cityName,
        value: city.cityId,
      });
    });
    citiesOwner?.forEach((city) => {
      newOptions.citiesOwner.push({
        label: city.cityName,
        value: city.cityId,
      });
    });
    businessEntity?.forEach((bse) => {
      newOptions.businessEntity.push({
        label: bse.refName,
        value: bse.refCode,
      });
    });
    businessSector?.forEach((bss) => {
      newOptions.businessSector.push({
        label: `${bss.refCode} - ${bss.refName}`,
        value: bss.refCode,
      });
    });
    businessCategory?.forEach((bsc) => {
      newOptions.businessCategory.push({
        label: `${bsc.refCode} - ${bsc.refName}`,
        value: bsc.refCode,
      });
    });
    bank?.forEach((bnk) => {
      newOptions.bank.push({
        label: `${bnk.refCode} - ${bnk.refName}`,
        value: bnk.refCode,
      });
    });
    setOptions(newOptions);
  };

  const handleChange = async (e) => {
    let formTemp = { ...managementForm };
    let imageNameTemp = { ...name };
    if (e.target.type === "file") {
      if (e.target.files[0].size > 5242880) {
        dispatch(showAlert("error", "Max file size allowed 5MB!"));
      } else {
        formTemp[e.target.name] = e.target.files[0];
        imageNameTemp[e.target.name] = e.target.files[0].name;
        setName(imageNameTemp);
      }
    } else {
      formTemp[e.target.name] = e.target.value;
    }
    setManagementForm(formTemp);
  };

  const handleCheckBox = (e) => {
    let formTemp = { ...managementForm };
    formTemp.status = e.target.checked;
    setManagementForm(formTemp);
  };

  const handleSelectChange = (e, name) => {
    let newForm = { ...managementForm };
    newForm[name] = e === null ? null : e.value;
    setManagementForm(newForm);
  };

  const handleCityProv = (e, name, type) => {
    let newForm = { ...managementForm };
    const searched =
      name === "province"
        ? provinces.filter((val) => val.provinceId === (e?.value || ""))
        : type === "store"
        ? cities.filter((val) => val.cityId === e.value)
        : citiesOwner.filter((val) => val.cityId === e.value);
    if (type === "store") {
      if (e) {
        newForm[name] = searched[0];
        dispatch(getCities(e.value, type));
      } else {
        let newOptions = { ...options };
        newForm.city = {
          cityId: "",
          cityName: "",
          province: {
            provinceId: "",
            provinceName: "",
          },
        };
        if (name === "province") {
          newForm.province = {
            provinceId: "",
            provinceName: "",
          };
          newOptions.cities = [];
          setOptions(newOptions);
        }
      }
    } else {
      if (e) {
        dispatch(getCities(e.value, type));
        if (name === "province") {
          newForm.ownerCity = { ...newForm.ownerCity };
          newForm.ownerCity.province = searched[0];
        } else {
          newForm.ownerCity = { ...newForm.ownerCity, ...searched[0] };
        }
      } else {
        if (name === "province") {
          newForm.ownerCity = {
            cityId: "",
            cityName: "",
            province: {
              provinceId: "",
              provinceName: "",
            },
          };
          let newOptions = { ...options };
          newOptions.citiesOwner = [];
          setOptions(newOptions);
        } else {
          newForm.ownerCity.cityId = "";
          newForm.ownerCity.cityName = "";
        }
      }
    }
    setManagementForm(newForm);
  };

  const generateSelectVal = (val, options) => {
    if (options) {
      const selected = options.filter((option) => option.value === val);
      return selected[0];
    }
  };

  const onSaveUrlCallback = () => {
    if (window.confirm("Are you sure want to save url?")) {
      dispatch(updateUrlCallback(params.id, managementForm));
    }
  };

  const deleteImage = (name) => {
    let formTemp = { ...managementForm };
    formTemp[name] = "";
    setManagementForm(formTemp);
  };

  //===

  useEffect(() => {
    dispatch(getPartnerSetting());
  }, []);

  const { partnerSetting } = useSelector((state) => state);

  useEffect(() => {
    setFormInput({
      trxCbUrl: partnerSetting?.data?.trxCbUrl,
      trxSuccessRdrUrl: partnerSetting?.data?.trxSuccessRdrUrl,
      trxFailRdrUrl: partnerSetting?.data?.trxFailRdrUrl,
      refundTrxCbUrl: partnerSetting?.data?.refundTrxCbUrl,
      accLinkCbUrl: partnerSetting?.data?.accLinkCbUrl,
      accLinkSuccessRdrUrl: partnerSetting?.data?.accLinkSuccessRdrUrl,
      accLinkFailRdrUrl: partnerSetting?.data?.accLinkFailRdrUrl,
      topUpCbUrl: partnerSetting?.data?.topUpCbUrl,
      accLinkIsRdr: partnerSetting?.data?.accLinkIsRdr,
    });
    setTrxIsRdr(partnerSetting?.data?.trxIsRdr);
    setAccLinkIsRdr(partnerSetting?.data?.accLinkIsRdr);
  }, [partnerSetting?.data]);

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };
  const handleIsRdrTrx = () => {
    setTrxIsRdr(!trxIsRdr);
  };
  const handleAccLinkIsRdr = () => {
    setAccLinkIsRdr(!accLinkIsRdr);
  };
  const onSave = (e) => {
    e.preventDefault();
    // if (params.id) {
    //   if (window.confirm("Are you sure want to save edit?")) {
    //     dispatch(updateMerchant(params.id, managementForm));
    //   }
    // } else {
    //   dispatch(createMerchant(managementForm));
    // }
    const data = { ...formInput };
    data.trxIsRdr = trxIsRdr;
    data.accLinkIsRdr = accLinkIsRdr;
    console.log(data);
    if (window.confirm("Are you sure want to save edit?")) {
      dispatch(updatePartnerSetting(data));
    }
    console.log(data);
    setIsEdit(!isEdit);
  };

  //===

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-sm-12">
          <div className="card card-primary card-outline card-tabs">
            <div className="card-header p-0 pt-1 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-three-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-three-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-home"
                    role="tab"
                    aria-controls="custom-tabs-three-home"
                    aria-selected="true"
                  >
                    Key
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-business-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-business"
                    role="tab"
                    aria-controls="custom-tabs-three-owner"
                    aria-selected="true"
                  >
                    Transaction
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-owner-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-owner"
                    role="tab"
                    aria-controls="custom-tabs-three-owner"
                    aria-selected="true"
                  >
                    Account Linked
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-qris-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-qris"
                    role="tab"
                    aria-controls="custom-tabs-three-qris"
                    aria-selected="true"
                  >
                    Top up
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-three-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-three-home"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-home-tab"
                >
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Server Key</label>
                      </div>
                      <div className="col-sm-6">
                        <label>
                          {partnerSetting?.data?.serverKey || "Auto Generate"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-business"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-business-tab"
                >
                  <div className="form-horizontal">
                    <InputField
                      label="Create Transaction Callback URL"
                      name="trxCbUrl"
                      value={formInput.trxCbUrl}
                      onChange={handleInput}
                      required={true}
                      disabled={isEdit ? false : true}
                    />
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Is Redirect Transaction?</label>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="trxIsRdr"
                            checked={trxIsRdr}
                            onChange={handleIsRdrTrx}
                            disabled={isEdit ? false : true}
                          />
                          <label className="form-check-label">Active</label>
                        </div>
                      </div>
                    </div>
                    <InputField
                      label="Transaction Success Redirect URL"
                      name="trxSuccessRdrUrl"
                      value={formInput.trxSuccessRdrUrl}
                      onChange={handleInput}
                      disabled={isEdit ? false : true}
                    />
                    <InputField
                      label="Transaction Failed Redirect URL"
                      name="trxFailRdrUrl"
                      value={formInput.trxFailRdrUrl}
                      onChange={handleInput}
                      disabled={isEdit ? false : true}
                    />
                    <InputField
                      label="Refund Callback URL"
                      name="refundTrxCbUrl"
                      value={formInput.refundTrxCbUrl}
                      onChange={handleInput}
                      disabled={isEdit ? false : true}
                    />
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-owner"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-owner-tab"
                >
                  <div className="form-horizontal">
                    <InputField
                      label="Account Linked Callback URL"
                      name="accLinkCbUrl"
                      value={formInput.accLinkCbUrl}
                      onChange={handleInput}
                      disabled={isEdit ? false : true}
                    />
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Is Redirect Account Linked?</label>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="accLinkIsRdr"
                            checked={accLinkIsRdr}
                            onChange={handleAccLinkIsRdr}
                            disabled={isEdit ? false : true}
                          />
                          <label className="form-check-label">Active</label>
                        </div>
                      </div>
                    </div>
                    <InputField
                      label="Account Linked Success Redirect URL"
                      name="accLinkSuccessRdrUrl"
                      value={formInput.accLinkSuccessRdrUrl}
                      onChange={handleInput}
                      disabled={isEdit ? false : true}
                    />
                    <InputField
                      label="Account Linked Failed Redirect URL"
                      name="accLinkFailRdrUrl"
                      value={formInput.accLinkFailRdrUrl}
                      onChange={handleInput}
                      disabled={isEdit ? false : true}
                    />
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-qris"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-qris-tab"
                >
                  <div className="form-horizontal">
                    <InputField
                      label="Top up Callback URL"
                      name="topUpCbUrl"
                      value={formInput.topUpCbUrl}
                      onChange={handleInput}
                      disabled={isEdit ? false : true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerSettings;
