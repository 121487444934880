import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DataTables from "../../components/datatables/datatables.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { getHistory } from "../../redux/user-management/user-management.action";

const UserHistory = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { userHistoryList } = useSelector((state) => state.userManagement);
  const [dataDisplay, setDataDisplay] = useState([]);
  const tableHeaders =
    params.role === "user-duwit" || params.role === "user-switching"
      ? [
          {
            text: "User Name",
            sort: true,
            name: "username",
            search: false,
            type: "text",
          },
          {
            text: "Login DateTime",
            sort: true,
            name: "loginAt",
            search: false,
            type: "date",
          },
        ]
      : [
          {
            text: "User Name",
            sort: true,
            name: "username",
            search: false,
            type: "text",
          },
          {
            text: "Merchant",
            sort: true,
            name: "merchant",
            search: false,
            type: "text",
          },
          {
            text: "Login DateTime",
            sort: true,
            name: "loginAt",
            search: false,
            type: "date",
          },
        ];

  useEffect(() => {
    dispatch(getHistory(params.id));
    dispatch(
      setHeaderButton({
        titleHeader: "History Login",
        isChild: true,
      })
    );
  }, []);

  useEffect(() => {
    setDataDisplay((dt) => {
      dt = [];
      userHistoryList.forEach((element) => {
        element.merchant = params.role;
        dt.push(element);
      });
      return dt;
    });
  }, [userHistoryList]);

  const handleAction = (pages, order) => {
    let orderedData = dataDisplay.sort((a, b) => {
      return order.direction
        ? (a[order.field] > b[order.field]) ? 1 : ((b[order.field] > a[order.field]) ? -1 : 0)
        : (a[order.field] < b[order.field]) ? 1 : ((b[order.field] < a[order.field]) ? -1 : 0)
    });
    console.log(orderedData);
    setDataDisplay(orderedData);
  };

  return (
    <div className="container-fluid">
      <DataTables
        headers={tableHeaders}
        listData={dataDisplay}
        first={true}
        last={true}
        totalData={dataDisplay.length}
        totalPage={1}
        empty={dataDisplay.length === 0}
        page={0}
        handleAction={handleAction}
        size={10}
        listAction={[]}
      />
    </div>
  );
};

export default UserHistory;
