import request from "../_network/request";
import {load} from "react-cookies";

const getMasterMenu = (payload) => {
  let token = load("tc-sm");
  return request({
    url: `/plc-msa-auth/menu/list?${payload}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const searchMasterMenu = (name) => {
  let token = load("tc-sm");
  return request({
    url: `/plc-msa-auth/menu/search?name=${name}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const saveMasterMenu = (payload) => {
  let token = load("tc-sm");
  return request({
        url: `/plc-msa-auth/menu/create`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: payload
    })
}

const deleteMasterMenu = (id) => {
  let token = load("tc-sm");
  return request({
        url: `/plc-msa-auth/menu/delete/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        },
    })
}

const updateMasterMenu = (payload) => {
  let token = load("tc-sm");
  return request({
        url: `/plc-msa-auth/menu/update`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: payload
    })
}

export const MasterMenuService = {
  getMasterMenu,
  searchMasterMenu,
  saveMasterMenu,
  deleteMasterMenu,
  updateMasterMenu
};
