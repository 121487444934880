import request from "../_network/request";

const activateUrl = "/v1/users/create-password";

const activateUser = (key, password) => {
  return request({
    url: `${activateUrl}`,
    method: "POST",
    data: {
      key: key,
      password: password,
    },
    // headers: {
    //   Authorization: `Bearer ${token}`
    // }
  });
};

export const ActivateServices = {
  activateUser,
};
