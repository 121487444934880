const ActionsActionTypes = {
    SHOW_LOADING: "SHOW_LOADING",
    HIDE_LOADING: "HIDE_LOADING",
    SHOW_LOADING_TABLE: "SHOW_LOADING_TABLE",
    HIDE_LOADING_TABLE: "HIDE_LOADING_TABLE",
    SET_HEADER: "SET_HEADER",
    SHOW_ALERT: "SHOW_ALERT",
    HIDE_ALERT: "HIDE_ALERT",
  };
  
  export default ActionsActionTypes;
  