import request from "../_network/request";

const urlHotelTier = "/v1/hotel-tiers";

const getListData = (
  page,
  size,
  searchQuery = [],
  orderBy,
  token
) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
    }`;

  return request({
    url: `${urlHotelTier}?${searchArray.length > 0 ? searchArray.join("") : ""
      }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getHotelDetail = (
  id,
  token
) => {
  return request({
    url: `${urlHotelTier}/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createHotels = (data, token) => {
  return request({
    url: `${urlHotelTier}`,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateHotels = (id, data, token) => {
  return request({
    url: `${urlHotelTier}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteHotels = (id, token) => {
  return request({
    url: `${urlHotelTier}/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const HotelServices = {
  getListData,
  createHotels,
  getHotelDetail,
  updateHotels,
  deleteHotels,
};
