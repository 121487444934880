import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InputField from "../../components/input-field/input-field.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { getDetailParameter, updateParameter } from "../../redux/parameter/parameter.actions";

const ParameterForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [formData, setFormData] = useState({
    paramName: "",
    paramValue: "",
  });
  const { parameterDetail, sendingStatus } = useSelector(state => state.dataParameter)

  const headerActionButton = [
    <button
      type="button"
      name="save"
      className="btn btn-success"
      onClick={() => onSave()}
    >
      <i className="fas fa-save"></i>
      Save
    </button>,
  ];

  const onSave = useCallback(() => {
    dispatch(updateParameter(params.id, formData));
  }, [formData]);

  useEffect(() => {
    dispatch(setHeaderButton({
      titleHeader: "Edit Parameter",
      isChild: true,
      hasCustomButton: true,
      customButton: headerActionButton,
    }));
  }, [onSave]);

  useEffect(() => {
    dispatch(getDetailParameter(params.id));
  }, []);

  useEffect(() => {
    setFormData(parameterDetail);
  }, [parameterDetail]);

  useEffect(() => {
    if (sendingStatus === "success") window.history.back();
  }, [sendingStatus]);

  const handleChange = (e) => {
    console.log(e.target.value);
    setFormData((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  };

  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card card-outline card-primary">
            <div class="card-header">
              <h3 class="card-title">Parameter Info</h3>

              <div class="card-tools">
                <button
                  type="button"
                  class="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i class="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <InputField
                type="text"
                label="Param Name"
                name="paramName"
                value={formData?.paramName}
                onChange={handleChange}
                required={true}
                disabled={true}
              />
              <InputField
                type="text"
                label="Param Value"
                name="paramValue"
                value={formData?.paramValue}
                onChange={handleChange}
                required={true}
              />
              <div class="form-group row">
                <div class="col-sm-2">
                  <label>Last Modified</label>
                </div>
                <div class="col-sm-6">
                  <label>02-10-2021 10:34:23</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParameterForm;
