const OperatorTypes = {
  GET_OPERATORS: "GET_OPERATORS",
  GET_OPERATORS_SUCCESS: "GET_OPERATORS_SUCCESS",
  GET_OPERATORS_FAILURE: "GET_OPERATORS_FAILURE",
  GET_OPERATOR_DETAIL: "GET_OPERATOR_DETAIL",
  GET_OPERATOR_DETAIL_SUCCESS: "GET_OPERATOR_DETAIL_SUCCESS",
  GET_OPERATOR_DETAIL_FAILURE: "GET_OPERATOR_DETAIL_FAILURE",
  CREATE_OPERATOR: "CREATE_OPERATOR",
  CREATE_OPERATOR_SUCCESS: "CREATE_OPERATOR_SUCCESS",
  CREATE_OPERATOR_FAILURE: "CREATE_OPERATOR_FAILURE",
  UPDATE_OPERATOR: "UPDATE_OPERATOR",
  UPDATE_OPERATOR_SUCCESS: "UPDATE_OPERATOR_SUCCESS",
  UPDATE_OPERATOR_FAILURE: "UPDATE_OPERATOR_FAILURE",
  DELETE_OPERATOR: "DELETE_OPERATOR",
  DELETE_OPERATOR_SUCCESS: "DELETE_OPERATOR_SUCCESS",
  DELETE_OPERATOR_FAILURE: "DELETE_OPERATOR_FAILURE",
  DEFAULT_OPERATOR_STATE: "DEFAULT_OPERATOR_STATE",
};

export default OperatorTypes;
