import request from "../_network/request2";
import request2 from "../_network/request3";
const transactionUrl = "/v1/transaction";
const partnerBalanceUrl = "/v1/transaction/current-balance";
const partnerSettingUrl = "v1/partner-setting";
const partnerUrl = "/v1/partner";
const userPartnerUrl = "/v1/user";
const getListTransaction = (
    merchantId,
    page,
    size,
    searchQuery = [],
    orderBy,
    token
) => {
    let searchArray = [];
    let filterMerchant = merchantId === "" ? "" : `merchantId=${merchantId}`;
    if (searchQuery.length > 0) {
        searchQuery.forEach((key, i) => {
            searchArray.push(`${i === 0 ? "" : "&"}${key.param}=${key.value}`);
        });
    }
    const field =
        orderBy.field === "merchantName" || orderBy.field === "merchantPan"
            ? `merchant.${orderBy.field}`
            : orderBy.field;
    let order = `${
        orderBy.field !== ""
            ? `&sort=${field},${orderBy.direction ? "asc" : "desc"}`
            : ""
    }`;
    return request({
        url: `${transactionUrl}?${filterMerchant}${
            searchArray.length > 0 ? searchArray.join("") : ""
        }&page=${page}&size=${size}${order}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

// const updateSettlementPaid = (transactionId, token) => {
//   return request({
//     url: `${paymentUrl}`,
//     method: "PATCH",
//     data: {
//       transactionId: transactionId,
//     },
//     headers: {
//       Authorization: `Bearer ${token}`
//     }
//   });
// }

const partnerBalance = (token) => {
    return request({
        url: `${partnerBalanceUrl}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getPartnerSetting = (token) => {
    return request({
        url: `${partnerSettingUrl}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const editPartnerSetting = (token, data) => {
    return request({
        url: `${partnerSettingUrl}`,
        method: "POST",
        data: data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const partnerList = (token, businessName, city, status, page, size, orderBy) => {
    let order = `${
        orderBy && orderBy?.field !== ""
          ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
          : ""
      }`;
    return request({
        url: `${partnerUrl}?businessName=${businessName}&city=${city}&status=${status}&page=${page}&size=${size}&sort=${order}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const partnerTransaction = (token, page, size, searchQuery = []) => {
    let searchArray = [];
    if (searchQuery.length > 0) {
        searchQuery.forEach((key, i) => {
            searchArray.push(`${i === 0 ? "" : "&"}${key.param}=${key.value}`);
        });
    }
    return request({
        url: `${transactionUrl}?${
            searchArray.length > 0 ? searchArray.join("") : ""
        }&page=${page}&size=${size}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const partnerTransactionById = (token, id) => {
    return request({
        url: `${transactionUrl}/${id}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

// const exportTransaction = (token, transactionStatusList, startTrxDate, endTrxDate, settlementStatusList, invoiceNo) => {
//   return request({
//     url: `${url}/export?transactionStatusList=${transactionStatusList}&startTrxDate=${startTrxDate}&endTrxDate=${endTrxDate}&settlementStatusList=${settlementStatusList}&invoiceNo=${invoiceNo}`,
//     method: "GET",
//     responseType: 'blob',
//     headers: {
//       Authorization: `Bearer ${token}`
//     }
//   });
// }

const userPartner = (token, role, searchQuery = [], page, size) => {
    let searchArray = [];
    if (searchQuery.length > 0) {
        searchQuery.forEach((key, i) => {
            searchArray.push(`&${key.param}=${key.value}`);
        });
    }
    return request2({
        url: `${userPartnerUrl}?role=${role}&${
            searchArray.length > 0 ? searchArray.join("") : ""
        }&page=${page}&size=${size}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const createUserPartner = (token, data) => {
    return request2({
        url: `${userPartnerUrl}/register`,
        method: "POST",
        data: data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getDataById = (id, token) => {
    return request({
        url: `${partnerUrl}/${id}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const createPartner = (token, data) => {
    return request({
        url: `${partnerUrl}`,
        method: "POST",
        data: data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const updatePartner = (id, data, token) => {
    return request({
        url: `${partnerUrl}/${id}`,
        method: "PUT",
        data: data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const deletePartner = (id, token) => {
    return request({
        url: `${partnerUrl}/${id}`,
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const banPartner = (id, token) => {
    return request({
        url: `${partnerUrl}/banned/${id}`,
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const approveRefund = (token, data) => {
    return request({
        url: `${transactionUrl}/refund-approve`,
        method: "POST",
        data: data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const exportTransaction = (token, partnerId, status, trxType, startDate, endDate) => {
    return request({
      url: `${transactionUrl}/export-excel?status=${status}&trxType=${trxType}&startDate=${startDate}&endDate=${endDate}&partnerId=${partnerId}`,
      method: "GET",
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

export const PartnerService = {
    //   getListTransaction,
    //   updateSettlementPaid,
    partnerBalance,
    getPartnerSetting,
    editPartnerSetting,
    partnerList,
    partnerTransaction,
    partnerTransactionById,
    userPartner,
    createUserPartner,
    getDataById,
    createPartner,
    updatePartner,
    deletePartner,
    banPartner,
    approveRefund,
    exportTransaction
};
