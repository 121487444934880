import { batch } from "react-redux";
import ActionsActionTypes from "../actions/actions.types";
import AuthActionTypes from "./auth.types";
import { ActivateServices, profileService } from "../../_services";

export const login = (keycloak) => {
  return async (dispatch) => {
    try {
      batch(() => {
        dispatch({
          type: AuthActionTypes.LOGIN,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING,
        });
      });

      const profile = await profileService.getProfile(keycloak.token);

      if (profile.statusCode === 200) {
        batch(() => {
          dispatch({
            type: AuthActionTypes.LOGIN_SUCCESS,
            payload: {
              token: keycloak.token,
              user: profile.data,
              kc: keycloak,
            },
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: AuthActionTypes.LOGIN_FAILURE,
            payload: profile.status,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
        });
      }
    } catch (err) {
      batch(() => {
        dispatch({
          type: AuthActionTypes.LOGIN_FAILURE,
          // payload: err.status,
          payload: {
            token: keycloak.token,
            error: err.status,
          }
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
      });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: AuthActionTypes.LOGOUT,
      });
    } catch (err) {}
  };
};

export const doActivate = (key, password, retype) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AuthActionTypes.ACTIVATE });

      if (password !== retype) {
        batch(() => {
          dispatch({ type: AuthActionTypes.ACTIVATE_FAILURE });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Password not match",
              alertType: "error",
            },
          });
        });
      } else {
        const activating = await ActivateServices.activateUser(key, password);
        dispatch({ type: AuthActionTypes.ACTIVATE_SUCCESS });
        window.location.pathname = "/";
      }
    } catch (error) {
      batch(() => {
        dispatch({ type: AuthActionTypes.ACTIVATE_FAILURE });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error.data.data || "Unknown error",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const changePassword = (dataPassword) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const user = state.auth.user;
    try {
      dispatch({ type: AuthActionTypes.CHANGE_PASSWORD });

      if (dataPassword.newPassword !== dataPassword.retype) {
        batch(() => {
          dispatch({ type: AuthActionTypes.CHANGE_PASSWORD_FAILURE });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Retype password not match",
              alertType: "error",
            },
          });
        });
      } else {
        await profileService.changePassword(
          dataPassword.newPassword,
          dataPassword.oldPassword,
          user.userId,
          user.username,
          token
        );
        dispatch({ type: AuthActionTypes.CHANGE_PASSWORD_SUCCESS });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Change password success",
            alertType: "success",
          },
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({ type: AuthActionTypes.CHANGE_PASSWORD_FAILURE });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error.data.data || "Unknown error",
            alertType: "error",
          },
        });
      });
    }
  };
};
