import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import SearchGrid from "../../components/search-grid/index.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { defaultOperator, getDataOperator } from "../../redux/operator/operator.actions";

const Operator = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [orderBy, setOrderBy] = useState({
    field: "createdDate",
    direction: false,
  });
  const [searchValue, setSearchValue] = useState([]);
  const { operatorList, first, last, totalData, totalPage, empty } = useSelector(
    (state) => state.operators
  );

  const tableHeaders = [
    {
      text: "Operator",
      sort: true,
      name: "providerName",
      search: true,
      type: "text",
      position: "left",
      index: 0,
      searchType: "text",
    },
    {
      text: "Associated No",
      sort: true,
      name: "assocNo",
      search: true,
      type: "text",
      position: "left",
      index: 1,
      searchType: "text",
    },
    // {
    //   text: "Status",
    //   sort: false,
    //   name: "category.isActive",
    //   search: false,
    //   type: "text",
    //   position: "left",
    //   index: 2,
    //   searchType: 'select',
    //   options: [
    //     {value: true, label: "Active"},
    //     {value: false, label: "Not active"},
    //   ],
    //   specialFunc: (val) => val ? "Active" : "Not active"
    // },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-edit" aria-hidden="true"></span>,
      description: "Edit",
      action: (val) => history.push(`/operator-edit/${val.providerId}`),
      condition: (val) => true,
    },
  ];

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Provider",
        add: true,
        pathAdd: '/operator-edit/null'
      })
    );
    dispatch(defaultOperator());
  }, []);

  useEffect(() => {
    dispatch(getDataOperator(page, size, searchValue, orderBy));
  }, [page, size, orderBy, searchValue]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (val) => {
    setPage(0);
    setSearchValue(val);
  }

  return (
    <div className="container-fluid">
      <SearchGrid searchField={tableHeaders} handleSearch={handleSearch} value={searchValue} />
      <DataTables
        headers={tableHeaders}
        listData={operatorList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default Operator;
