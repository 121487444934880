import request from "../_network/request";
const operatorUrl = "/v1/providers";

const getOperatorList = (page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${
    orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;

  return request({
    url: `${operatorUrl}?${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getOperatorDetail = (id, token) => {
  return request({
    url: `${operatorUrl}/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createOperator = (data, token) => {
  return request({
    url: operatorUrl,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateOperator = (id, data, token) => {
  return request({
    url: `${operatorUrl}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteOperator = (id, token) => {
  return request({
    url: `${operatorUrl}/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const OperatorServices = {
  getOperatorList,
  getOperatorDetail,
  createOperator,
  updateOperator,
  deleteOperator,
};
