import request from "../_network/request";
import {load} from "react-cookies";

const getRole = (payload) => {
    let token = load("tc-sm");
    return request({
        url: `/plc-msa-auth/role/list?${payload}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

const searchRole = (name) => {
    let token = load("tc-sm");
    return request({
        url: `/plc-msa-auth/role/search?name=${name}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};


const saveRole = (payload) => {
    let token = load("tc-sm");
    return request({
        url: `/plc-msa-auth/role/create`,
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: payload
    })
}

const deleteRole = (id) => {
    let token = load("tc-sm");
    return request({
        url: `/plc-msa-auth/role/delete/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`
        },
    })
}

const updateRole = (payload) => {
    let token = load("tc-sm");
    return request({
        url: `/plc-msa-auth/role/update`,
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: payload
    })
}

const updateRoleAccess = (payload) => {
    let token = load("tc-sm");
    return request({
        url: `/plc-msa-auth//access/update-access`,
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: payload
    })
}


const getRoleAccess = (payload) => {
    let token = load("tc-sm");
    return request({
        url: `/plc-msa-auth//access/list?${payload}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
};

const saveRoleAccess = (payload) => {
    let token = load("tc-sm");
    return request({
        url: `/plc-msa-auth//access/add-access`,
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: payload
    })
}

export const MasterRoleService = {
    getRole,
    searchRole,
    saveRole,
    deleteRole,
    updateRole,
    getRoleAccess,
    saveRoleAccess,
    updateRoleAccess
};
