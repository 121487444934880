const TextArea = ({ label, name, required = false, onChange, value, disabled }) => {
  return (
    <div className="form-group row">
      <div className="col-sm-2">
        <label for="inputAddress">{`${label}${required ? " *" : ""}`}</label>
      </div>
      <div className="col-sm-6">
        <textarea
          className="form-control"
          rows="3"
          name={name}
          value={value}
          placeholder={label}
          required={required}
          onChange={onChange}
          disabled={disabled}
        ></textarea>
      </div>
    </div>
  );
};

export default TextArea;
