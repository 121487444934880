const ParameterTypes = {
  GET_DATA_PARAMETER: "GET_DATA_PARAMETER",
  GET_DATA_PARAMETER_SUCCESS: "GET_DATA_PARAMETER_SUCCESS",
  GET_DATA_PARAMETER_FAILURE: "GET_DATA_PARAMETER_FAILURE",
  GET_DETAIL_PARAMETER: "GET_DETAIL_PARAMETER",
  GET_DETAIL_PARAMETER_SUCCESS: "GET_DETAIL_PARAMETER_SUCCESS",
  GET_DETAIL_PARAMETER_FAILURE: "GET_DETAIL_PARAMETER_FAILURE",
  UPDATE_PARAMETER: "UPDATE_PARAMETER",
  UPDATE_PARAMETER_SUCCESS: "UPDATE_PARAMETER_SUCCESS",
  UPDATE_PARAMETER_FAILURE: "UPDATE_PARAMETER_FAILURE",
  DEFAULT_PARAMETER_STATE: "DEFAULT_PARAMETER_STATE"
}

export default ParameterTypes;