/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import InputField from "../../components/input-field/input-field.component";
import ReasonPopup from "../../components/reason-popup/reason-popup.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { affectConsumer } from "../../redux/consumer/consumer.action";
import request from "../../_network/request";
import useAirlineForm from "./userAirlineForm";

const EditAirlines = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { identity, refCodesPhase2, marriageStatus } = useSelector(state => state.masterData);
  const { COUNTRY } = refCodesPhase2;
  const { consumerDetail, histories, sendingStatus, cities } = useSelector(state => state.dataConsumer);
  const [data, getData, saveData, deleteData] = useAirlineForm();
  const { token } = useSelector(state => state.auth);
  const [formData, setFormData] = useState({
    airlineIcon: "",
    airlineType: "",
    airlineCode: "",
    vendorCode: "",
    airlineName: "",
    isActive: false,

  });
  const [images, setImages] = useState({
    airlineIcon: '',
  });
  const [popupData, setPopupData] = useState({
    show: false,
    header: "",
    type: "",
  })

  const onSave = useCallback(() => {
    saveData(formData, params.id);
  }, [formData, params.id]);

  const headerActionButton = [
    <button
      type="button"
      name="save"
      className="btn btn-primary"
      onClick={() => {
        onSave();
      }}
    >
      <i className="fas fa-floppy-o"></i>
      Save
    </button>,
    <button
      type="button"
      name="save"
      className="btn btn-danger"
      onClick={() => {
        // setPopupData({ show: true, header: "Delete Data", type: "close" });
        deleteData(params.id);
      }}
      style={{ display: params.id === "null" ? "none" : "block" }}
    >
      <i className="fas fa-door-closed"></i>
      Delete
    </button>,
  ]

  useEffect(() => {
    dispatch(setHeaderButton({
      titleHeader: "Edit Airline",
      isChild: true,
      hasCustomButton: true,
      customButton: headerActionButton,
    }));
  }, [onSave]);

  useEffect(() => {
    if (params.id !== 'null')
      getData(params.id);
  }, []);

  useEffect(() => {
    if (params.id !== 'null') {
      setFormData(data);
      gettingImage(data);
    }
  }, [data]);

  useEffect(() => {
    if (sendingStatus === "success") window.history.back();
  }, [sendingStatus]);

  const gettingImage = async (data) => {
    try {
      const url = "/v1/upload-image/?filename="
      const fetchAirlineIcon = await request({
        url: `${url}${data?.airlineIcon}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: "arraybuffer",
      });
      const airlineIcon = new Buffer(fetchAirlineIcon).toString("base64");
      setImages({ airlineIcon: airlineIcon });
    } catch (error) {
      console.log(error);
    }

  }

  const handleChange = (e) => {
    setFormData((temp) => ({ ...temp, [e.target.name]: e.target.type === "file" ? e.target.files[0] : e.target.value }));
  };

  const submitReason = (reason) => {
    dispatch(affectConsumer(formData, popupData.type, reason));
    setPopupData(temp => ({ ...temp, show: false }));
    // dispatch(affectConsumer(formData, "ban"));
  }

  const handleCheck = (e) => {
    setFormData(temp => ({ ...temp, isActive: e.target.checked }));
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Airline Info</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Airline Type*</label>
                </div>
                <div className="col-sm-6">
                  <select
                    className="form-control select2"
                    id="airlineType"
                    name="airlineType"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    value={formData?.airlineType}
                    required
                  >
                    <option value="">Choose One</option>
                    <option value="domestic">Domestik</option>
                    <option value="international">International</option>
                  </select>
                </div>
              </div>
              <InputField
                type="text"
                label="Carrier Code"
                name="airlineCode"
                value={formData?.airlineCode}
                maxLength={2}
                onChange={handleChange}
                required={true}
              />
              <InputField
                type="text"
                label="Vendor Code"
                name="vendorCode"
                value={formData?.vendorCode}
                maxLength={3}
                onChange={handleChange}
                required={true}
              />
              <InputField
                type="text"
                label="Airline Name"
                name="airlineName"
                value={formData?.airlineName}
                maxLength={50}
                onChange={handleChange}
                required={true}
              />
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Status</label>
                </div>
                <div className="col-sm-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="status"
                      checked={formData?.isActive}
                      onChange={handleCheck}
                    />
                    <label className="form-check-label">Active</label>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label for="inputStatus">Upload Image *</label>
                </div>
                <div className="cols-sm-6 mailbox-attachment-info">
                  <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                    <li>
                      <span
                        className="mailbox-attachment-icon has-img"
                        style={{ minHeight: "132.25px" }}
                      >
                        {formData?.airlineIcon === null ||
                          formData?.airlineIcon === "" ? (
                          <i
                            className="fas fa-camera"
                            style={{ marginTop: "30px" }}
                          ></i>
                        ) : (
                          <img
                            src={
                              formData?.airlineIcon
                                ? typeof formData?.airlineIcon ===
                                  "string"
                                  ? `data:image/png;base64,${images.airlineIcon}`
                                  : URL.createObjectURL(
                                    formData?.airlineIcon
                                  )
                                : ""
                            }
                            style={{
                              objectFit: "cover",
                              maxHeight: "132.25px",
                            }}
                            alt="Attachment"
                          />
                        )}
                      </span>
                      {formData?.airlineIcon === null ||
                        formData.airlineIcon === "" ? (
                        <input
                          className="form-control-sm"
                          type="file"
                          name="airlineIcon"
                          onChange={handleChange}
                          accept="image/*"
                          required
                        />
                      ) : (
                        <a
                          onClick={() => {
                            setFormData(temp => ({ ...temp, airlineIcon: "" }))
                          }}
                          className="mailbox-attachment-name"
                        >
                          <i className="fas fa-camera"></i>{" "}
                          {"Airline Icon"}
                        </a>
                      )}
                      <span className="mailbox-attachment-size clearfix mt-1">
                        <span>Icon</span>
                      </span>
                      <span className="mailbox-attachment-size clearfix mt-1">
                        <span>Max 5MB</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupData.show &&
        <ReasonPopup
          header={popupData.header}
          handleSave={submitReason}
          handleClose={() => setPopupData(temp => ({ ...temp, show: false }))}
        />
      }
    </div>
  );
};

export default EditAirlines;
