import ProductTypes from "./product.types";

const INITIAL_STATE = {
  productList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  error: false,

  productDetail: null,

  agentList: [],
  loadingAgent: false,
  providerList: [],
  loadingProvider: false,

  sendingStatus: "",
  errorUploadFile: "",
};

const ProductReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductTypes.GET_PRODUCTS:
      return {
        ...state,
        error: false,
      };
    case ProductTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case ProductTypes.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case ProductTypes.GET_AGENTS:
      return {
        ...state,
        loadingAgent: true,
      };
    case ProductTypes.GET_AGENTS_SUCCESS:
      return {
        ...state,
        agentList: action.payload,
        loadingAgent: false,
      };
    case ProductTypes.GET_AGENTS_FAILURE:
      return {
        ...state,
        agentList: [],
        loadingAgent: false,
      };
    case ProductTypes.GET_PROVIDER_OPTIONS:
      return {
        ...state,
        loadingProvider: true,
      };
    case ProductTypes.GET_PROVIDER_OPTIONS_SUCCESS:
      return {
        ...state,
        providerList: action.payload,
        loadingProvider: false,
      };
    case ProductTypes.GET_PROVIDER_OPTIONS_FAILURE:
      return {
        ...state,
        providerList: [],
        loadingProvider: false,
      };
    case ProductTypes.GET_PRODUCT_DETAIL:
      return {
        ...state,
        productDetail: null,
      };
    case ProductTypes.GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        productDetail: action.payload,
      };
    case ProductTypes.GET_PRODUCT_DETAIL_FAILURE:
      return {
        ...state,
        productDetail: null,
      };
    case ProductTypes.CREATE_PRODUCT:
      return {
        ...state,
        sendingStatus: "send",
      };
    case ProductTypes.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case ProductTypes.CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case ProductTypes.UPDATE_PRODUCT:
      return {
        ...state,
        sendingStatus: "send",
      };
    case ProductTypes.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case ProductTypes.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case ProductTypes.DELETE_PRODUCT:
      return {
        ...state,
        sendingStatus: "send",
      };
    case ProductTypes.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case ProductTypes.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case ProductTypes.DEFAULT_PRODUCT_STATE:
      return {
        ...state,
        sendingStatus: "",
        productDetail: null,
      };
    case ProductTypes.UPLOAD_PRODUCTS:
      return {
        ...state,
      };
    case ProductTypes.UPLOAD_PRODUCTS_FAILURE:
      return {
        ...state,
        errorUploadFile: action.payload,
      };
    case ProductTypes.UPLOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
      };
    case ProductTypes.UPLOAD_PRODUCTS_DEFAULT:
      return {
        ...state,
        errorUploadFile: "",
      }
    default:
      return state;
  }
};

export default ProductReducer;
