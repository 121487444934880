import NotificationTypes from "./notification.types";

const INITIAL_STATE = {
  notificationList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  error: false,

  notificationDetail: null,

  sendingStatus: "",
};

const NotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NotificationTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        error: false,
      };
    case NotificationTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case NotificationTypes.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case NotificationTypes.GET_NOTIFICATION_DETAIL:
      return {
        ...state,
        notificationDetail: null,
      };
    case NotificationTypes.GET_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        notificationDetail: action.payload,
      };
    case NotificationTypes.GET_NOTIFICATION_DETAIL_FAILURE:
      return {
        ...state,
        notificationDetail: null,
      };
    case NotificationTypes.CREATE_NOTIFICATION:
      return {
        ...state,
        sendingStatus: "send",
      };
    case NotificationTypes.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case NotificationTypes.CREATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case NotificationTypes.UPDATE_NOTIFICATION:
      return {
        ...state,
        sendingStatus: "send",
      };
    case NotificationTypes.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case NotificationTypes.UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case NotificationTypes.DELETE_NOTIFICATION:
      return {
        ...state,
        sendingStatus: "send",
      };
    case NotificationTypes.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case NotificationTypes.DELETE_NOTIFICATION_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case NotificationTypes.DEFAULT_NOTIFICATION_STATE:
      return {
        ...state,
        sendingStatus: "",
        notificationDetail: null,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
