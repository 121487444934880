import request from "../_network/request";

const getProfile = (token) => {
  return request({
    url: `/v1/users/profile`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const changePassword = (newPassword, oldPassword, userId, username, token) => {
  return request({
    url: "/v1/users/change-password",
    method: "POST",
    data: {
      newPassword: newPassword,
      oldPassword: oldPassword,
      userId: userId,
      username: username,
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export const profileService = {
  getProfile,
  changePassword,
};
