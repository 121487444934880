const UserManagementTypes = {
  GET_DATA: "GET_DATA",
  GET_DATA_SUCCESS: "GET_DATA_SUCCESS",
  GET_DATA_FAILURE: "GET_DATA_FAILURE",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  CREATE_USER: "CREATE_USER",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  GET_ROLES: "GET_ROLES",
  GET_ROLES_FAILURE: "GET_ROLES_FAILURE",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_HISTORY: "GET_HISTORY",
  GET_HISTORY_SUCCESS: "GET_HISTORY_SUCCESS",
  GET_HISTORY_FAILURE: "GET_HISTORY_FAILURE",
  DEFAULT_CREATE_STATE: "DEFAULT_CREATE_STATE",
};

export default UserManagementTypes;
