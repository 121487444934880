import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTables from "../../components/datatables/datatables.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import TransactionTypes from "../../redux/transaction/transaction.types";

import {
  getPartnerBalance,
  getTransactionPartner,
  clearStatus,
} from "../../redux/partner/partner.actions";
import Select from "react-select";
import { currency } from "../../components/utils/helper";
import moment from "moment";
import * as FileSaver from "file-saver";
import {
  getPartnerList,
  exportTransaction,
} from "../../redux/partner/partner.actions";
// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

const Transaction = () => {
  const history = useHistory();
  const tableHeaders = [
    {
      text: "Status",
      sort: false,
      name: "status",
      search: true,
      type: "text",
    },
    {
      text: "Partner",
      sort: false,
      name: "businessName",
      search: true,
      type: "text",
    },
    {
      text: "Date Created",
      sort: false,
      name: "createdDate",
      search: true,
      type: "date",
    },
    {
      text: "Transaction Id",
      sort: false,
      name: "transactionId",
      search: true,
      type: "text",
    },
    {
      text: "Transaction Type",
      sort: false,
      name: "transactionType",
      search: true,
      type: "text",
    },
    {
      text: "Request Id",
      sort: false,
      name: "requestId",
      search: false,
      type: "text",
    },
    {
      text: "Amount",
      sort: false,
      name: "amount",
      search: false,
      type: "text",
    },
  ];

  const options = [
    { value: "", label: "ALL" },
    { value: "SUCCEED", label: "SUCCEED" },
    { value: "PENDING", label: "PENDING" },
  ];
  const options2 = [
    { value: "", label: "ALL" },
    { value: "TOP UP", label: "TOP UP" },
    { value: "CHARGE PAYMENT", label: "CHARGE PAYMENT" },
    { value: "REFUND", label: "REFUND" },
  ];
  const { user } = useSelector((state) => state.auth);
  console.log(user.role.roleName);
  const actionButton = [
    {
      icon: <span className="fas fa-search" aria-hidden="true"></span>,
      description: "View",
      action: (val) =>
        history.push(`/partner-transaction-detail/${val.transactionId}`),
      condition: (val) => true,
    },
  ];

  const { statusExport, exportData } = useSelector(
    (state) => state.exportTransaction
  );

  const { partnerList } = useSelector((state) => state);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [page2, setPage2] = useState(0);
  const [size2, setSize2] = useState(5);
  const [searchQuery, setSearchQuery] = useState([]);
  const [orderBy, setOrderBy] = useState({
    field: "trxDate",
    direction: false,
  });
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [trxStatus, setTrxStatus] = useState(options[0]);
  const [trxType, setTrxType] = useState(options2[0]);
  const [partnerName, setPartnerName] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [searchPartnerName, setSearchPartnerName] = useState("");
  const [searchMerchantPan, setSearchMerchantPan] = useState("");

  const dispatch = useDispatch();
  let sDate = startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
  let eDate = endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";

  useEffect(() => {
    dispatch(getPartnerBalance());
    dispatch(clearStatus());
    dispatch(getPartnerList(searchPartnerName, "", "", page2, size2));
  }, []);

  useEffect(() => {
    dispatch(getTransactionPartner(page, size, searchQuery));
  }, [searchQuery, page, size]);

  const { partnerBalance } = useSelector((state) => state);

  const {
    // status,
    transactionPartner,
    first,
    last,
    totalData,
    totalPage,
    // error,
    empty,
  } = useSelector((state) => state.partnerTransaction);

  useEffect(() => {
    if (statusExport === "success" && exportData) {
      FileSaver.saveAs(exportData, "report.xlsx");
      dispatch({
        type: TransactionTypes.EXPORT_TRANSACTION_SUCCESS,
        payload: null,
      });
    }
  }, [statusExport, exportData, dispatch]);

  useEffect(() => {
    // set action button
    dispatch(
      setHeaderButton({
        titleHeader: "Transaction Partner",
      })
    );
  }, [dispatch]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleTrxStatus = (e) => {
    console.log("e", e);
    setTrxStatus(e);
  };

  const handleTrxType = (e) => {
    setTrxType(e);
  };

  const handleChangePartnerName = (name) => {
    setPartnerName(name);
  };

  const reset = () => {
    setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setPartnerName("");
    setPartnerId("");
    setTrxStatus(options[0]);
    setTrxType(options2[0]);
    setSearchPartnerName("");
    dispatch(getPartnerList("", "", "", page2, size2));
    setSearchQuery([]);
  };

  return (
    <div className="container-fluid">
      {user?.role?.roleName === "DUWIT" ? null : (
        <div className="row">
          <div className="col-lg-3 col-6">
            <div className="small-box bg-success">
              <div className="inner">
                <h3>Rp {currency(partnerBalance?.data?.balanceAfter)}</h3>

                <p>Current Balance (Rp)</p>
              </div>
              <div className="icon">
                <i className="ion ion-card"></i>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Search</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
              {/* <!-- /.card-tools --> */}
            </div>
            {/* <!-- /.card-header --> */}
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Partner</label>
                </div>
                <div className="col-sm-2">
                  <input
                    type="text"
                    className="form-control"
                    id="trxNo"
                    disabled
                    value={partnerName}
                    onChange={handleChangePartnerName}
                  />
                </div>
                <div className="col-sm-1">
                  {user.role.roleName === "MERCHANT" ? null : (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#modal-default"
                    >
                      <i className="fas fa-search"></i>
                      Find
                    </button>
                  )}
                </div>

                <div className="col-sm-1"></div>
                <div className="col-sm-2">
                  <label>Trx Type</label>
                </div>
                <div className="col-sm-3">
                  <Select
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={options2}
                    isClearable={false}
                    onChange={handleTrxType}
                    value={trxType}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Start Trx Date test</label>
                </div>
                <div className="col-sm-3">
                  <input
                    type="date"
                    className="form-control"
                    value={startDate}
                    name={"StartDate"}
                    onChange={handleStartDate}
                  />
                  {/* <DatePicker
                    className="form-control"
                    value={moment(startDate).format("DD-MM-YYYY")}
                    selected={new Date(startDate)}
                    onChange={handleStartDate}
                    format="DD-MM-YYYY"
                    dateFormat="DD-MM-YYYY"
                  /> */}
                </div>

                <div className="col-sm-1"></div>
                <div className="col-sm-2">
                  <label>Status</label>
                </div>
                <div className="col-sm-3">
                  <Select
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    options={options}
                    onChange={handleTrxStatus}
                    value={trxStatus}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>End Trx Date</label>
                </div>
                <div className="col-sm-3">
                  <input
                    type="date"
                    className="form-control"
                    value={endDate}
                    onChange={handleEndDate}
                  />
                  {/* <DatePicker
                    className="form-control"
                    value={moment(endDate).format("DD-MM-YYYY")}
                    selected={new Date(endDate)}
                    onChange={handleEndDate}
                    format="DD-MM-YYYY"
                    dateFormat="DD-MM-YYYY"
                  /> */}
                </div>
              </div>
              <div className="form-group row"></div>
              <div className="form-group row" style={{ textAlign: "center" }}>
                <div className="col-sm-10">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      setPage(0);
                      setSearchQuery([
                        {
                          param: "partnerId",
                          value: partnerId,
                        },
                        {
                          param: "status",
                          value: trxStatus?.value,
                        },
                        {
                          param: "trxType",
                          value: trxType?.value,
                        },
                        {
                          param: "startDate",
                          value: sDate,
                        },
                        {
                          param: "endDate",
                          value: eDate,
                        },
                      ]);
                      console.log("trxval", trxStatus);
                      // dispatch(getData(page, size, searchQuery , orderBy));
                    }}
                  >
                    <i className="fas fa-search"></i>
                    Search
                  </button>
                  <button
                    type="submit"
                    className="btn btn-info"
                    onClick={reset}
                  >
                    <i className="fas fa-ban"></i>
                    Reset
                  </button>
                  <button
                    // disabled
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      dispatch(
                        exportTransaction(
                          partnerId,
                          trxStatus.value,
                          trxType?.value,
                          startDate,
                          endDate
                        )
                      );
                    }}
                  >
                    <i className="fas fa-download"></i>
                    Export To Excel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="modal-default">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Find Merchant</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group row">
                <div class="col-sm-1">
                  <label>Business Name</label>
                </div>
                <div class="col-sm-3">
                  <input
                    type="text"
                    class="form-control"
                    id="trxNo"
                    onChange={(e) => {
                      setSearchPartnerName(e.target.value);
                    }}
                    value={searchPartnerName}
                  />
                </div>
                <div class="col-sm-2">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    onClick={() => {
                      // setSearchQuery2([
                      //   {param: 'merchantName', value: searchMerchantName},
                      //   {param: 'merchantPan', value: searchMerchantPan},
                      // ])
                      dispatch(
                        getPartnerList(searchPartnerName, "", "", page2, size2)
                      );
                    }}
                  >
                    <i class="fas fa-search"></i>
                    Search
                  </button>
                </div>
              </div>
              <table id="example1" class="table table-bordered table-striped">
                <thead style={{ backgroundColor: "#007bff" }}>
                  <tr>
                    <th class="no-sort"></th>
                    <th>No</th>
                    <th>Business Name</th>
                    <th>City</th>
                  </tr>
                </thead>
                <tbody>
                  {partnerList?.partnerList?.map((data, i) => (
                    <tr>
                      <td>
                        <button
                          type="button"
                          class="btn btn-default btn-xs"
                          data-dismiss="modal"
                          onClick={() => {
                            setPartnerName(data.businessName);
                            setPartnerId(data.partnerId);
                          }}
                        >
                          Select
                        </button>
                      </td>
                      <td>{page2 * size2 + i + 1}</td>
                      <td>{data.businessName}</td>
                      <td>{data.city}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-container">
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example1_paginate"
                >
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        page2 === 0 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => {
                          setPage2(page2 - 1);
                        }}
                      >
                        Previous
                      </button>
                    </li>
                    <li
                      className={`paginate_button page-item next ${
                        partnerList.last ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => {
                          setPage2(page2 + 1);
                        }}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <DataTables
        headers={tableHeaders}
        listData={transactionPartner}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default Transaction;
