import PartnerTypes from "./partner.types";

const INITIAL_STATE_BALANCE = {
    data: null,
    status: null,
    error: false,
    errorMessage: "",
};

const INITIAL_STATE_PARTNER_SETTING = {
    data: null,
    status: null,
    error: false,
    errorMessage: "",
};

const INITIAL_STATE_CREATE_USER = {
    data: null,
    status: null,
    error: false,
    errorMessage: "",
};

const INITIAL_STATE_PARTNER_LIST = {
    partnerList: [],
    first: true,
    last: true,
    totalData: 0,
    totalPage: 0,
    empty: true,
    status: null,
    error: false,
    errorMessage: "",

    dataById: null,
    createPartnerSuccess: "",
    updatePartnerSuccess: "",
    banPartnerSuccess: "",
    deletePartnerSuccess: "",
};

const INITIAL_STATE_TRANSACTION = {
    transactionPartner: [],
    first: true,
    last: true,
    totalData: 0,
    totalPage: 0,
    empty: true,
    status: null,
    error: false,
    errorMessage: "",

    // update settlement state
    statusUpdate: "",
    successUpdate: false,

    //export state
    statusExport: "",
    exportData: null,
    transactionPartnerByid: {},
    approveRefundSuccess: "",
};

const INITIAL_STATE_USER = {
    userPartner: [],
    first: true,
    last: true,
    totalData: 0,
    totalPage: 0,
    empty: true,
    status: null,
    error: false,
    errorMessage: "",

    // update settlement state
    statusUpdate: "",
    successUpdate: false,
};

export const partnerBalanceReducer = (
    state = INITIAL_STATE_BALANCE,
    action
) => {
    switch (action.type) {
        case PartnerTypes.GET_PARTNER_BALANCE:
            return {
                ...state,
            };
        case PartnerTypes.GET_PARTNER_BALANCE_SUCCESS:
            return {
                ...state,
                status: 200,
                data: action.payload,
                error: false,
            };
        case PartnerTypes.GET_PARTNER_BALANCE_FAILURE:
            return {
                ...state,
                status: action.payload.status,
                errorMessage: action.payload.message,
                error: true,
            };
        default:
            return state;
    }
};

export const getPartnerSettingReducer = (
    state = INITIAL_STATE_PARTNER_SETTING,
    action
) => {
    switch (action.type) {
        case PartnerTypes.GET_PARTNER_SETTING:
            return {
                ...state,
            };
        case PartnerTypes.GET_PARTNER_SETTING_SUCCESS:
            return {
                ...state,
                status: 200,
                data: action.payload,
                error: false,
            };
        case PartnerTypes.GET_PARTNER_SETTING_FAILURE:
            return {
                ...state,
                status: action.payload.status,
                errorMessage: action.payload.message,
                error: true,
            };
        default:
            return state;
    }
};

export const updatePartnerSettingReducer = (
    state = INITIAL_STATE_PARTNER_SETTING,
    action
) => {
    switch (action.type) {
        case PartnerTypes.UPDATE_PARTNER_SETTING:
            return {
                ...state,
            };
        case PartnerTypes.UPDATE_PARTNER_SETTING_SUCCESS:
            return {
                ...state,
                status: 200,
                data: action.payload,
                error: false,
            };
        case PartnerTypes.UPDATE_PARTNER_SETTING_FAILURE:
            return {
                ...state,
                status: action.payload.status,
                errorMessage: action.payload.message,
                error: true,
            };
        default:
            return state;
    }
};

// export const getPartnerListReducer = (state = INITIAL_STATE_PARTNER_LIST, action) => {
//   switch (action.type) {
//     case PartnerTypes.GET_PARTNER_LIST:
//       return {
//         ...state,
//       }
//     case PartnerTypes.GET_PARTNER_LIST_SUCCESS:
//       return {
//         ...state,
//         status: 200,
//         partnerList: action.payload.content,
//         first: action.payload.first,
//         last: action.payload.last,
//         totalData: action.payload.totalElements,
//         totalPage: action.payload.totalPages,
//         empty: action.payload.empty,
//         error: false,
//       }
//     case PartnerTypes.GET_PARTNER_BALANCE_FAILURE:
//       return {
//         ...state,
//         status: action.payload.status,
//         errorMessage: action.payload.message,
//         error: true,
//       }
//     default:
//       return state;
//     }
// }

export const getPartnerTransactionReducer = (
    state = INITIAL_STATE_TRANSACTION,
    action
) => {
    switch (action.type) {
        case PartnerTypes.GET_PARTNER_TRANSACTION:
            return {
                ...state,
            };
        case PartnerTypes.GET_PARTNER_TRANSACTION_SUCCESS:
            return {
                ...state,
                status: 200,
                transactionPartner: action.payload.content,
                first: action.payload.first,
                last: action.payload.last,
                totalData: action.payload.totalElements,
                totalPage: action.payload.totalPages,
                empty: action.payload.empty,
                error: false,
            };
        case PartnerTypes.GET_PARTNER_TRANSACTION_FAILURE:
            return {
                ...state,
                status: action.payload.status,
                errorMessage: action.payload.message,
                error: true,
            };
        case PartnerTypes.GET_TRANSACTION_BY_ID:
            return {
                ...state,
            };
        case PartnerTypes.GET_TRANSACTION_BY_ID_SUCCESS:
            return {
                ...state,
                transactionPartnerByid: action.payload,
            };
        case PartnerTypes.GET_TRANSACTION_BY_ID_FAILURE:
            return {
                ...state,
            };
        case PartnerTypes.APPROVE_REFUND:
            return {
                ...state,
                approveRefundSuccess: "",
            };
        case PartnerTypes.APPROVE_REFUND_FAILED:
            return {
                ...state,
                approveRefundSuccess: "failed",
            };
        case PartnerTypes.APPROVE_REFUND_SUCCESS:
            return {
                ...state,
                approveRefundSuccess: "success",
            };
        case PartnerTypes.EXPORT_TRANSACTION:
            return {
                ...state,
                statusExport: "fetching",
                exportData: null,
                error: false,
            };
        case PartnerTypes.EXPORT_TRANSACTION_SUCCESS:
            return {
                ...state,
                statusExport: "success",
                exportData: action.payload,
                error: false,
            };
        case PartnerTypes.EXPORT_TRANSACTION_FAILURE:
            return {
                ...state,
                statusExport: "error",
                exportData: null,
                errorMessage: action.payload.message,
                error: true,
            };
        default:
            return state;
    }
};

export const getUserPartnerReducer = (state = INITIAL_STATE_USER, action) => {
    switch (action.type) {
        case PartnerTypes.GET_USER_PARTNER:
            return {
                ...state,
            };
        case PartnerTypes.GET_USER_PARTNER_SUCCESS:
            return {
                ...state,
                status: 200,
                userPartner: action.payload.content,
                first: action.payload.first,
                last: action.payload.last,
                totalData: action.payload.totalElements,
                totalPage: action.payload.totalPages,
                empty: action.payload.empty,
                error: false,
            };
        case PartnerTypes.GET_USER_PARTNER_FAILURE:
            return {
                ...state,
                status: action.payload.status,
                errorMessage: action.payload.message,
                error: true,
            };
        default:
            return state;
    }
};

export const createUserPartnerReducer = (
    state = INITIAL_STATE_CREATE_USER,
    action
) => {
    switch (action.type) {
        case PartnerTypes.CREATE_USER_PARTNER:
            return {
                ...state,
            };
        case PartnerTypes.CREATE_USER_PARTNER_SUCCESS:
            return {
                ...state,
                status: 200,
                data: action.payload,
                error: false,
            };
        case PartnerTypes.CREATE_USER_PARTNER_FAILED:
            return {
                ...state,
                status: action.payload.status,
                errorMessage: action.payload.message,
                error: true,
            };
        default:
            return state;
    }
};

export const partnerListReducer = (
    state = INITIAL_STATE_PARTNER_LIST,
    action
) => {
    switch (action.type) {
        case PartnerTypes.GET_PARTNER_LIST:
            return {
                ...state,
            };
        case PartnerTypes.GET_PARTNER_LIST_SUCCESS:
            return {
                ...state,
                status: 200,
                partnerList: action.payload.content,
                first: action.payload.first,
                last: action.payload.last,
                totalData: action.payload.totalElements,
                totalPage: action.payload.totalPages,
                empty: action.payload.empty,
                error: false,
            };
        case PartnerTypes.GET_PARTNER_BALANCE_FAILURE:
            return {
                ...state,
                status: action.payload.status,
                errorMessage: action.payload.message,
                error: true,
            };
        case PartnerTypes.GET_DATA_BY_ID:
            return {
                ...state,
            };
        case PartnerTypes.GET_DATA_BY_ID_FAILURE:
            return {
                ...state,
            };
        case PartnerTypes.GET_DATA_BY_ID_SUCCESS:
            return {
                ...state,
                dataById: action.payload,
            };
        case PartnerTypes.CREATE_PARTNER:
            return {
                ...state,
                createPartnerSuccess: "creating",
            };
        case PartnerTypes.CREATE_PARTNER_FAILED:
            return {
                ...state,
                createPartnerSuccess: "failed",
            };
        case PartnerTypes.CREATE_PARTNER_SUCCESS:
            return {
                ...state,
                createPartnerSuccess: "success",
            };
        case PartnerTypes.UPDATE_PARTNER:
            return {
                ...state,
                updatePartnerSuccess: "updating",
            };
        case PartnerTypes.UPDATE_PARTNER_FAILED:
            return {
                ...state,
                updatePartnerSuccess: "failed",
            };
        case PartnerTypes.UPDATE_PARTNER_SUCCESS:
            return {
                ...state,
                updatePartnerSuccess: "success",
            };
        case PartnerTypes.BAN_PARTNER:
            return {
                ...state,
                banPartnerSuccess: "updating",
            };
        case PartnerTypes.BAN_PARTNER_FAILED:
            return {
                ...state,
                banPartnerSuccess: "failed",
            };
        case PartnerTypes.BAN_PARTNER_SUCCESS:
            return {
                ...state,
                banPartnerSuccess: "success",
            };
        case PartnerTypes.DELETE_PARTNER:
            return {
                ...state,
                deletePartnerSuccess: "deleting",
            };
        case PartnerTypes.DELETE_PARTNER_FAILED:
            return {
                ...state,
                deletePartnerSuccess: "failed",
            };
        case PartnerTypes.DELETE_PARTNER_SUCCESS:
            return {
                ...state,
                deletePartnerSuccess: "success",
            };
        case PartnerTypes.DEFAULT_STATUS:
            return {
                ...state,
                createPartnerSuccess: "",
                updatePartnerSuccess: "",
                banPartnerSuccess: "",
                approveRefundSuccess: "",
            };

        default:
            return state;
    }
};
