import request from "../_network/request";
// import { load } from "react-cookies";

const url = "/v1/merchant-groups";

const getListDataGroup = (page, size, searchQuery = [], orderBy, token) => {
  // let token = load("tc-sm");
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`${i === 0 ? "" : "&"}${key.param}=${key.value}`);
    });
  }
  let order = `${
    orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;
  return request({
    url: `${url}?${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getDataGroupById = (id, token) => {
  return request({
    url: `${url}/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const deleteGroupData = (merchantId, token) => {
  return request({
    url: `${url}/${merchantId}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const createGroup = (data, token) => {
  return request({
    url: url,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const updateGroup = (id, data, token) => {
  return request({
    url: `${url}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const MerchantGroupService = {
  getListDataGroup,
  getDataGroupById,
  deleteGroupData,
  createGroup,
  updateGroup,
};
