import { batch } from "react-redux";
import { RefCodeServices } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import RefCodeTypes from "./refCode.types";

export const getRefCode = (page, size, searchQuery, orderBy,) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: RefCodeTypes.GET_PARAM_REF_CODE });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const refCodes = await RefCodeServices.getRefCode(page, size, searchQuery, orderBy, token);

      batch(() => {
        dispatch({
          type: RefCodeTypes.GET_PARAM_REF_CODE_SUCCESS,
          payload: refCodes.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      })
    } catch (error) {
      batch(() => {
        dispatch({ type: RefCodeTypes.GET_PARAM_REF_CODE_FAILURE, payload: error });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    }
  }
}

export const createRefCode = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: RefCodeTypes.CREATE_REF_CODE });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const refCode = await RefCodeServices.inputRefCode(data, token);

      batch(() => {
        dispatch({ type: RefCodeTypes.CREATE_REF_CODE_SUCCESS, payload: refCode });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Create Ref Code success",
            alertType: "success",
          },
        });
      });
    } catch (err) {
      batch(() => {
        dispatch({ type: RefCodeTypes.CREATE_REF_CODE_FAILURE });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Create Ref Code failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const updateRefCode = (dataForm) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const data = { ...dataForm };
    try {
      batch(() => {
        dispatch({ type: RefCodeTypes.CREATE_REF_CODE });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      delete data.id;
      const refCode = await RefCodeServices.editRefCode(dataForm.id, data, token);

      batch(() => {
        dispatch({ type: RefCodeTypes.CREATE_REF_CODE_SUCCESS, payload: refCode });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update ref code success",
            alertType: "success",
          },
        });
      });
    } catch (err) {
      batch(() => {
        dispatch({ type: RefCodeTypes.CREATE_REF_CODE_FAILURE });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update ref code failure",
            alertType: "error",
          },
        });
      });
    }
  };
}

export const deleteRefCode = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: RefCodeTypes.DELETE_REF_CODE });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const refCode = await RefCodeServices.deleteRefCode(id, token);

      batch(() => {
        dispatch({ type: RefCodeTypes.DELETE_REF_CODE_SUCCESS, payload: refCode });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete ref code success",
            alertType: "success",
          },
        });
      });
    } catch (err) {
      batch(() => {
        dispatch({ type: RefCodeTypes.DELETE_REF_CODE_FAILURE });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete ref code failure",
            alertType: "error",
          },
        });
      });
    }
  };
}

export const createGroupRefCode = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: RefCodeTypes.CREATE_GROUP_REF_CODE });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const refCode = await RefCodeServices.inputRefGroup(data, token);

      batch(() => {
        dispatch({ type: RefCodeTypes.CREATE_GROUP_REF_CODE_SUCCESS, payload: refCode });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Create group ref code success",
            alertType: "success",
          },
        });
      });
    } catch (err) {
      batch(() => {
        dispatch({ type: RefCodeTypes.CREATE_GROUP_REF_CODE_FAILURE });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Create group ref code failure",
            alertType: "failure",
          },
        });
      });
    }
  };
};

export const defaultRefCodeState = () => async (dispatch) =>
  dispatch({ type: RefCodeTypes.DEFAULT_REF_CODE_STATE });