const MerchantGroupTypes = {
  GET_MERCHANT_GROUP: "GET_MERCHANT_GROUP",
  GET_MERCHANT_GROUP_SUCCESS: "GET_MERCHANT_GROUP_SUCCESS",
  GET_MERCHANT_GROUP_FAILURE: "GET_MERCHANT_GROUP_FAILURE",
  DELETE_MERCHANT_GROUP: "DELETE_MERCHANT_GROUP",
  DELETE_MERCHANT_GROUP_FAILURE: "DELETE_MERCHANT_GROUP_FAILURE",
  DELETE_MERCHANT_GROUP_SUCCESS: "DELETE_MERCHANT_GROUP_SUCCESS",
  CREATE_MERCHANT_GROUP: "CREATE_MERCHANT_GROUP",
  CREATE_MERCHANT_GROUP_FAILURE: "CREATE_MERCHANT_GROUP_FAILURE",
  CREATE_MERCHANT_GROUP_SUCCESS: "CREATE_MERCHANT_GROUP_SUCCESS",
  UPDATE_MERCHANT_GROUP: "UPDATE_MERCHANT_GROUP",
  UPDATE_MERCHANT_GROUP_SUCCESS: "UPDATE_MERCHANT_GROUP_SUCCESS",
  UPDATE_MERCHANT_GROUP_FAILURE: "UPDATE_MERCHANT_GROUP_FAILURE",
  GET_GROUP_BY_ID: "GET_GROUP_BY_ID",
  GET_GROUP_BY_ID_SUCCESS: "GET_GROUP_BY_ID_SUCCESS",
  GET_GROUP_BY_ID_FAILURE: "GET_GROUP_BY_ID_FAILURE",
};

export default MerchantGroupTypes;
