import { batch } from "react-redux";
import { TransactionService } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import TransactionTypes from "./transaction.types";

export const getData = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const user = state.auth.user;
    try {
      batch(() => {
        dispatch({ type: TransactionTypes.GET_DATA_TRANSACTION });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const merchantId = user.merchant ? user.merchant.merchantId : "";

      const listTransactions = await TransactionService.getListTransaction(
        merchantId,
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: TransactionTypes.GET_DATA_TRANSACTION_SUCCESS,
          payload: listTransactions.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: TransactionTypes.GET_DATA_TRANSACTION_FAILURE,
          payload: error.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    }
  };
};

export const refundSettlement = (transactionId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: TransactionTypes.UPDATE_SETTLEMENT });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const settlementUpdate = await TransactionService.updateSettlementPaid(
        transactionId,
        token
      );

      if (settlementUpdate.statusCode === 200) {
        batch(() => {
          dispatch({
            type: TransactionTypes.UPDATE_SETTLEMENT_SUCCESS,
            payload: settlementUpdate,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Success refund settlement",
              alertType: "success",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: TransactionTypes.UPDATE_SETTLEMENT_FAILURE,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Error refund settlement",
              alertType: "error",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: TransactionTypes.UPDATE_SETTLEMENT_FAILURE,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Error update settlement paid",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const getMerchantData = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: TransactionTypes.GET_DATA_BALANCE });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const merchantBalance = await TransactionService.merchantBalance(
        token
      );

      batch(() => {
        dispatch({
          type: TransactionTypes.GET_DATA_BALANCE_SUCCESS,
          payload: merchantBalance.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: TransactionTypes.GET_DATA_BALANCE_FAILURE,
          payload: error.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    }
  };
};

export const exportTransaction = (transactionStatusList, startTrxDate, endTrxDate, settlementStatusList, invoiceNo) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;

    try {
      batch(() => {
        dispatch({ type: TransactionTypes.EXPORT_TRANSACTION });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const exportTrans = await TransactionService.exportTransaction(
        token, transactionStatusList, startTrxDate, endTrxDate, settlementStatusList, invoiceNo
      );

      batch(() => {
        dispatch({
          type: TransactionTypes.EXPORT_TRANSACTION_SUCCESS,
          payload: exportTrans,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: TransactionTypes.EXPORT_TRANSACTION_FAILURE,
          payload: error.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    }
  };
};