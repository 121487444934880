import MerchantManagementTypes from "./merchant-management.types";

const INITIAL_STATE = {
    merchantList: [],
    merchantSettingList: [],
    first: true,
    last: true,
    totalData: 0,
    totalPageMerchant: 0,
    empty: true,
    status: null,
    errorMessage: "",
    error: false,

    panOptions: [],
    categoryOptions: [],
    criteriaOptions: [],
    getMasterDataSuccess: false,
    getMerchantSettingSuccess: false,

    dataById: null,

    createMerchantSuccess: "",
    updateMerchantSuccess: "",
    updateUrlCallbackSuccess: "",

    errorDelete: "",
    errorActivate: "",

    merchantGroupList: [],
    mgLoading: false,
    categoryLoading: false,

    cities: [],
    citiesOwner: [],

    fileTemplate: "",

    errorUploadFile: "",
};

const MerchantReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MerchantManagementTypes.GET_DATA_MERCHANT:
            return {
                ...state,
                dataById: null,
            };
        case MerchantManagementTypes.GET_DATA_MERCHANT_SUCCESS:
            return {
                ...state,
                status: 200,
                merchantList: action.payload.content,
                first: action.payload.first,
                last: action.payload.last,
                totalData: action.payload.totalElements,
                totalPageMerchant: action.payload.totalPages,
                empty: action.payload.empty,
                error: false,
            };
        case MerchantManagementTypes.GET_DATA_MERCHANT_FAILURE:
            return {
                ...state,
                status: action.payload.status,
                errorMessage: action.payload.message,
                error: true,
            };
        case MerchantManagementTypes.DELETE_MERCHANT:
            return {
                ...state,
                errorDelete: "",
            };
        case MerchantManagementTypes.DELETE_MERCHANT_FAILURE:
            return {
                ...state,
                errorDelete: true,
            };
        case MerchantManagementTypes.DELETE_MERCHANT_SUCCESS:
            return {
                ...state,
                errorDelete: false,
            };
        case MerchantManagementTypes.ACTIVATE_MERCHANT:
            return {
                ...state,
                errorActivate: "",
            };
        case MerchantManagementTypes.ACTIVATE_MERCHANT_FAILURE:
            return {
                ...state,
                errorActivate: true,
            };
        case MerchantManagementTypes.ACTIVATE_MERCHANT_SUCCESS:
            return {
                ...state,
                errorActivate: false,
            };
        case MerchantManagementTypes.RESEND_EMAIL:
            return {
                ...state,
                errorActivate: "",
            };
        case MerchantManagementTypes.RESEND_EMAIL_FAILURE:
            return {
                ...state,
                errorActivate: true,
            };
        case MerchantManagementTypes.RESEND_EMAIL_SUCCESS:
            return {
                ...state,
                errorActivate: false,
            };
        case MerchantManagementTypes.GET_MASTER_DATA_MERCHANT:
            return {
                ...state,
            };
        case MerchantManagementTypes.GET_MASTER_DATA_MERCHANT_FAILURE:
            return {
                ...state,
                getMasterDataSuccess: false,
            };
        case MerchantManagementTypes.GET_MASTER_DATA_MERCHANT_SUCCESS:
            return {
                ...state,
                getMasterDataSuccess: true,
                panOptions: action.payload.panOptions,
                criteriaOptions: action.payload.criteriaOptions,
            };

        case MerchantManagementTypes.GET_MERCHANT_SETTING:
            return {
                ...state,
                dataById: null,
            };
        case MerchantManagementTypes.GET_MERCHANT_SETTING_SUCCESS:
            return {
                ...state,
                status: 200,
                merchantSettingList: action.payload.content,
                first: action.payload.first,
                last: action.payload.last,
                totalData: action.payload.totalElements,
                totalPage: action.payload.totalPages,
                empty: action.payload.empty,
                error: false,
            };
        case MerchantManagementTypes.GET_MERCHANT_SETTING_FAILURE:
            return {
                ...state,
                status: action.payload.status,
                errorMessage: action.payload.message,
                error: true,
            };

        case MerchantManagementTypes.CREATE_MERCHANT:
            return {
                ...state,
                createMerchantSuccess: "creating",
            };
        case MerchantManagementTypes.CREATE_MERCHANT_FAILURE:
            return {
                ...state,
                createMerchantSuccess: "failed",
            };
        case MerchantManagementTypes.CREATE_MERCHANT_SUCCESS:
            return {
                ...state,
                createMerchantSuccess: "success",
            };
        case MerchantManagementTypes.UPDATE_DATA:
            return {
                ...state,
                updateMerchantSuccess: "updating",
            };
        case MerchantManagementTypes.UPDATE_DATA_FAILURE:
            return {
                ...state,
                updateMerchantSuccess: "failed",
            };
        case MerchantManagementTypes.UPDATE_DATA_SUCCESS:
            return {
                ...state,
                updateMerchantSuccess: "success",
            };
        case MerchantManagementTypes.UPDATE_URL_CALLBACK:
            return {
                ...state,
                updateUrlCallbackSuccess: "updating",
            };
        case MerchantManagementTypes.UPDATE_URL_CALLBACK_FAILURE:
            return {
                ...state,
                updateUrlCallbackSuccess: "failed",
            };
        case MerchantManagementTypes.UPDATE_URL_CALLBACK_SUCCESS:
            return {
                ...state,
                updateUrlCallbackSuccess: "success",
            };
        case MerchantManagementTypes.GET_DATA_BY_ID:
            return {
                ...state,
            };
        case MerchantManagementTypes.GET_DATA_BY_ID_FAILURE:
            return {
                ...state,
            };
        case MerchantManagementTypes.GET_DATA_BY_ID_SUCCESS:
            return {
                ...state,
                dataById: action.payload,
            };
        case MerchantManagementTypes.DEFAULT_STATUS:
            return {
                ...state,
                createMerchantSuccess: "",
                updateMerchantSuccess: "",
                updateUrlCallbackSuccess: "",
            };
        case MerchantManagementTypes.GET_CITIES:
            return {
                ...state,
                cities: [],
            };
        case MerchantManagementTypes.GET_CITIES_SUCCESS:
            if (action.payload.type === "store") {
                return {
                    ...state,
                    cities: action.payload.cities,
                };
            } else {
                return {
                    ...state,
                    citiesOwner: action.payload.cities,
                };
            }
        case MerchantManagementTypes.GET_CITIES_FAILURE:
            return {
                ...state,
                cities: [],
            };
        case MerchantManagementTypes.GET_MG_LIST:
            return {
                ...state,
                merchantGroupList: [],
                mgLoading: true,
            };
        case MerchantManagementTypes.GET_MG_LIST_SUCCESS:
            return {
                ...state,
                merchantGroupList: action.payload.content,
                mgLoading: false,
            };
        case MerchantManagementTypes.GET_MG_LIST_FAILURE:
            return {
                ...state,
                merchantGroupList: [],
                mgLoading: false,
            };
        case MerchantManagementTypes.GET_TEMPLATE:
            return {
                ...state,
                errorUploadFile: "",
            };
        case MerchantManagementTypes.GET_TEMPLATE_FAILURE:
            return {
                ...state,
            };
        case MerchantManagementTypes.GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                fileTemplate: action.payload,
            };
        case MerchantManagementTypes.UPLOAD_EXCEL:
            return {
                ...state,
            };
        case MerchantManagementTypes.UPLOAD_EXCEL_FAILURE:
            return {
                ...state,
                errorUploadFile: action.payload,
            };
        case MerchantManagementTypes.UPLOAD_EXCEL_SUCCESS:
            return {
                ...state,
            };
        case MerchantManagementTypes.UPLOAD_DEFAULT:
            return {
                ...state,
                errorUploadFile: "",
            };
        case MerchantManagementTypes.GET_CATEGORY_CODE:
            return {
                ...state,
                categoryLoading: true,
            };
        case MerchantManagementTypes.GET_CATEGORY_CODE_FAILURE:
            return {
                ...state,
                categoryOptions: [],
                categoryLoading: false,
            };
        case MerchantManagementTypes.GET_CATEGORY_CODE_SUCCESS:
            return {
                ...state,
                categoryOptions: action.payload,
                categoryLoading: false,
            };
        default:
            return state;
    }
};

export default MerchantReducer;
