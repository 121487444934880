import DashboardTypes from "./dashboard.types";

const INITIAL_STATE = {
  totalAmount: 0,
  totalMerchant: 0,
  totalPendingMerchant: 0,
  totalTransaction: 0,
  monthlySettlement: [],
  monthlyTransaction: [],

  error: false,
};

const DashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashboardTypes.GET_DASHBOARD:
      return {
        ...state,
      };
    case DashboardTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        totalAmount: action.payload.totalAmount,
        totalMerchant: action.payload.totalMerchant,
        totalPendingMerchant: action.payload.totalPendingMerchant,
        totalTransaction: action.payload.totalTransaction,
        monthlySettlement: action.payload.monthlySettlement,
        monthlyTransaction: action.payload.monthlyTransaction,
        error: false,
      };
    case DashboardTypes.GET_DASHBOARD_FAILURE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default DashboardReducer;
