import UserManagementTypes from "./user-management.types";

const INITIAL_STATE = {
  userList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  errorMessage: '',
  error: false,

  createUserSuccess: "",
  updateUserSuccess: "",

  errorDelete: false,
  errorActivate: false,

  roles:[],
  merchantList: [],
  userHistoryList: [],
};

const UserManagementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserManagementTypes.GET_DATA:
      return {
        ...state,
        errorDelete: "",
      }
    case UserManagementTypes.GET_DATA_SUCCESS:
      return {
        ...state,
        status: 200,
        userList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
        createUserSuccess: "",
      }
    case UserManagementTypes.GET_DATA_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        errorMessage: action.payload.message,
        error: true,
      }
    case UserManagementTypes.DELETE_USER:
      return {
        ...state,
        errorDelete: "",
      }
    case UserManagementTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        errorDelete: true,
      }
    case UserManagementTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        errorDelete: false,
      }
    case UserManagementTypes.CREATE_USER:
      return {
        ...state,
        createUserSuccess: "creating",
      }
    case UserManagementTypes.CREATE_USER_FAILURE:
      return {
        ...state,
        createUserSuccess: "failed",
      }
    case UserManagementTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserSuccess: "success",
      }
    case UserManagementTypes.UPDATE_USER:
      return {
        ...state,
        createUserSuccess: "updating",
      }
    case UserManagementTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        createUserSuccess: "failed",
      }
    case UserManagementTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        createUserSuccess: "success",
      }
    case UserManagementTypes.GET_ROLES:
      return {
        ...state,
      }
    case UserManagementTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.content,
      }
    case UserManagementTypes.GET_ROLES_FAILURE:
      return {
        ...state,
      }
    case UserManagementTypes.DEFAULT_CREATE_STATE:
      return {
        ...state,
        createUserSuccess: "",
      }
    case UserManagementTypes.GET_HISTORY:
      return {
        ...state,
        userHistoryList: [],
      }
    case UserManagementTypes.GET_HISTORY_SUCCESS:
      return {
        ...state,
        userHistoryList: action.payload,
      }
    case UserManagementTypes.GET_HISTORY_FAILURE:
      return {
        ...state,
        userHistoryList: [],
      }
    default:
      return state;
  }
};

export default UserManagementReducer;
