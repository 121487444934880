/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import Logo from "../assets/AdminLTELogo.png";
import UserImage from "../assets/user2-160x160.jpg";
import Alert from "../components/alert/alert.component";
import Profile from "../modules/profile/profile.page";
import { login, logout } from "../redux/auth/auth.actions";
import { getMasterData } from "../redux/masterData/masterData.actions";
import routes from "./route.routes";

const UserRoute = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    loading,
    add,
    pathAdd,
    upload,
    pathUpload,
    buttonWithFunction,
    buttonFunction,
    buttonText,
    buttonIcon,
    titleHeader,
    isChild,
    hasCustomButton,
    customButton,
  } = useSelector((state) => state.actionsUser);
  const { user } = useSelector((state) => state.auth);
  const [protectedRoute, setProtectedRoute] = useState([]);

  const [openNestedMenu, setOpenNestedMenu] = useState({
    User: false,
    Report: false,
  });

  useEffect(() => {
    dispatch(login(props.keycloak));
  }, [dispatch, props.keycloak]);

  useEffect(() => {
    if (
      user?.role?.roleName === "DUWIT" ||
      user?.role?.roleName === "MERCHANT_GROUP"
    ) {
      dispatch(getMasterData());
    }
    const menus = [];
    user?.role?.menus.forEach(item => {
      if (item.collapse) {
        item.views.forEach(view => {
          menus.push(view.layout);
        });
      } else {
        menus.push(item.layout);
      }
    });
    const tempRoute = routes.filter(item => {
      return menus.includes(item.group);
    });
    setProtectedRoute(tempRoute);
  }, [user]);

  const onLogout = () => {
    dispatch(logout());
    props.keycloak.logout({ redirectUri: window.location.origin });
  };

  const generateActive = (page) => {
    let active = "";
    for (let i = 0; i <= page.length; i++) {
      if (location.pathname === page[i]) {
        active = "active";
        break;
      }
    }
    return active;
  };

  return user ? (
    <div>
      <Helmet>
        <title>Duwit | {titleHeader}</title>
      </Helmet>
      <div
        className={`loading-fullscreen ${!loading && props.keycloak ? "hide" : ""
          }`}
      >
        <img
          className="animation__shake"
          src={Logo}
          alt="AdminLTELogo"
          height="60"
          width="60"
        />
      </div>
      <div className="hold-transition sidebar-mini layout-fixed">
        <div className="wrapper">
          <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
              <li className="nav-item">
                <div className="nav-link" data-widget="pushmenu" role="button">
                  <i className="fas fa-bars"></i>
                </div>
              </li>
            </ul>

            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <div className="nav-link" role="button" onClick={onLogout}>
                  <i className="fas fa-sign-out-alt"></i>Logout
                </div>
              </li>
            </ul>
          </nav>

          <aside
            className="main-sidebar sidebar-dark-primary elevation-4"
            style={{ position: "fixed" }}
          >
            <div href="index.html" className="brand-link">
              <img
                src={Logo}
                alt="AdminLTE Logo"
                className="brand-image"
                style={{ opacity: 0.8 }}
              />
              <span className="brand-text font-weight-light">Duwit</span>
            </div>

            <div className="sidebar">
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img
                    src={UserImage}
                    className="img-circle elevation-2"
                    alt="User"
                  />
                </div>
                <div className="info">
                  <Link to="/profile" className="d-block">
                    {user?.fullName}
                  </Link>
                </div>
              </div>

              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {user?.role?.menus?.map((menu, i) =>
                    menu.collapse ? (
                      <li
                        key={i}
                        className={`nav-item ${openNestedMenu[menu.component] ? "menu-open" : ""
                          }`}
                      >
                        <a
                          className="nav-link"
                          onClick={() => {
                            let prevVal = { ...openNestedMenu };
                            prevVal[menu.component] =
                              !openNestedMenu[menu.component];
                            setOpenNestedMenu(prevVal);
                          }}
                        >
                          <i className="nav-icon fas fa-th"></i>
                          <p>
                            {menu.name}
                            <i className="right fas fa-angle-left"></i>
                          </p>
                        </a>
                        <ul className="nav nav-treeview">
                          {menu.views.map((nested, j) => (
                            <li key={j} className="nav-item">
                              <Link
                                to={nested.layout}
                                className={`nav-link ${generateActive([
                                  nested.layout,
                                ])}`}
                              >
                                <i className="far fa-circle nav-icon"></i>
                                <p>{nested.name}</p>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ) : (
                      <li className="nav-item" key={i}>
                        <Link
                          key={i}
                          to={menu.layout}
                          className={`nav-link ${generateActive([
                            menu.layout,
                          ])}`}
                        >
                          <i className="nav-icon fas fa-th"></i>
                          <p>{menu.name}</p>
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </nav>
            </div>
          </aside>

          <form
            className="content-wrapper"
            onSubmit={(e) => {
              e.preventDefault();
              if (buttonWithFunction) buttonFunction(e);
            }}
          >
            <Alert />
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6" style={{ display: "flex" }}>
                    <h1 className="m-0" style={{ textTransform: "capitalize" }}>
                      {titleHeader}
                    </h1>
                    {isChild && (
                      <h6
                        style={{
                          marginTop: "10px",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.history.back();
                        }}
                      >
                        &nbsp;
                        <i className="fas fa-arrow-circle-left"></i>
                        <a>back to previous page</a>
                      </h6>
                    )}
                  </div>
                  <div className="col-sm-6">
                    {user?.role?.roleName !== "SWITCHING" && (
                      <div className="pull-right">
                        {add && (
                          <Link to={pathAdd}>
                            <button
                              type="button"
                              name="save"
                              className="btn btn-success"
                            >
                              <i className="fas fa-plus-square"></i>
                              Add
                            </button>
                          </Link>
                        )}
                        {upload && (
                          <Link to={pathUpload}>
                            <button
                              type="button"
                              name="save"
                              className="btn btn-success"
                            >
                              <i className="fas fa-file-upload"></i>
                              Upload
                            </button>
                          </Link>
                        )}
                        {buttonWithFunction && (
                          <button
                            type="submit"
                            name="save"
                            className="btn btn-success"
                          // onClick={buttonFunction}
                          >
                            {buttonIcon}
                            {buttonText}
                          </button>
                        )}
                        {hasCustomButton ? customButton : ""}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <section className="content">
              <Switch>
                {protectedRoute.map((item, i) => (
                  <Route
                    key={i}
                    path={item.path}
                    name={item.name}
                    component={item.component}
                  />
                ))}
                <Route
                  path="/profile"
                  name="profile"
                  component={Profile}
                />
                <Redirect exact from="**" to="/" />
              </Switch>
            </section>
          </form>

          <footer className="main-footer">
            <strong>Copyright &copy; 2021 Duwit.</strong>
            <div className="float-right d-none d-sm-inline-block">
              <b>Version</b> 1.0.0
            </div>
          </footer>

          <aside className="control-sidebar control-sidebar-dark"></aside>
        </div>
      </div>
    </div>
  ) : (
    <div className={`loading-fullscreen`}>
      <img
        className="animation__shake"
        src={Logo}
        alt="AdminLTELogo"
        height="60"
        width="60"
      />
    </div>
  );
};

export default UserRoute;
