import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTables from "../../components/datatables/datatables.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import TransactionTypes from "../../redux/transaction/transaction.types";
import SearchGrid from "../../components/search-grid/index.component";

import {
  getPartnerList,
  clearStatus,
  deletePartner,
} from "../../redux/partner/partner.actions";

import { login } from "../../redux/auth/auth.actions";

import * as FileSaver from "file-saver";

const PartnerList = () => {
  const history = useHistory();
  const statusOption = [
    { value: 701, label: "Active" },
    { value: 703, label: "Banned" },
    { value: 706, label: "Not Active" },
  ];
  const tableHeaders = [
    {
      text: "Business Name",
      sort: true,
      name: "businessName",
      search: true,
      type: "text",
      searchType: "text",
      position: "left",
      index: 0,
    },
    {
      text: "City",
      sort: true,
      name: "city",
      search: true,
      type: "text",
      searchType: "text",
      position: "left",
      index: 1,
    },
    {
      text: "Contact Name",
      sort: true,
      name: "contactName",
      search: true,
      type: "text",
    },
    {
      text: "Status",
      sort: true,
      name: "partnerStatus",
      search: true,
      type: "text",
      position: "right",
      index: 0,
      searchType: "select",
      options: statusOption,
      specialFunc: (val) =>
        val === 701 ? "Active" : val === 706 ? "Not Active" : "Banned",
    },
    {
      text: "Modified Date",
      sort: true,
      name: "modifiedDate",
      search: true,
      type: "date",
    },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-edit" aria-hidden="true"></span>,
      description: "Edit",
      action: (val) => {
        console.log("vel", val);
        history.push(`/partner-edit/${val.partnerId}`);
      },
      condition: (val) => true,
    },
    {
      icon: <span className="fas fa-trash" aria-hidden="true"></span>,
      description: "Delete",
      action: (val) => {
        if (window.confirm("Are you sure want to delete?")) {
          dispatch(deletePartner(val.partnerId));
        }
      },
      condition: (val) => true,
    },
  ];

  const actionButtonView = [
    {
      icon: <span className="fas fa-search" aria-hidden="true"></span>,
      description: "View",
      action: (val) => {
        console.log("vel", val);
        history.push(`/partner-view/${val.partnerId}`);
      },
      condition: (val) => true,
    },
  ];

  const {
    // status,
    partnerList,
    first,
    last,
    totalData,
    totalPage,
    // error,
    empty,
    deletePartnerSuccess,
  } = useSelector((state) => state.partnerList);
  const { user } = useSelector((state) => state.auth);

  const { statusExport, exportData } = useSelector(
    (state) => state.exportTransaction
  );

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [orderBy, setOrderBy] = useState({
    field: "trxDate",
    direction: false,
  });
  const [searchValue, setSearchValue] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const search = {};
    searchValue.forEach((item) => {
      search[item.param] = item.value;
    });
    const { businessName = "", city = "", partnerStatus = "" } = search;
    console.log("businessName", businessName);
    console.log("city", city);
    console.log("order", orderBy);

    dispatch(
      getPartnerList(businessName, city, partnerStatus, page, size, orderBy)
    );
    dispatch(clearStatus());
  }, [dispatch, page, size, deletePartnerSuccess, searchValue, orderBy]);

  useEffect(() => {
    if (statusExport === "success" && exportData) {
      FileSaver.saveAs(exportData, "report.xlsx");
      dispatch({
        type: TransactionTypes.EXPORT_TRANSACTION_SUCCESS,
        payload: null,
      });
    }
  }, [statusExport, exportData, dispatch]);

  useEffect(() => {
    // set action button
    dispatch(
      setHeaderButton({
        titleHeader: "Partner",
        add: true,
        pathAdd: "/partner-add",
      })
    );
  }, [dispatch]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (val) => {
    console.log("searc", val);
    setPage(0);
    if (val.length > 0) setSearchValue(val);
    else setSearchValue([]);
  };

  return (
    <div className="container-fluid">
      <SearchGrid
        searchField={tableHeaders}
        handleSearch={handleSearch}
        value={searchValue}
      />

      <DataTables
        headers={tableHeaders}
        listData={partnerList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={user?.role?.roleName === "PARTNER" ? actionButtonView : actionButton}
      />
    </div>
  );
};

export default PartnerList;
