import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import MultipleSearch from "../../components/multiple-search/multiple-search.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import {
  deleteMerchantGroup,
  getDataGroup,
} from "../../redux/merchant-group/merchant-group.action";

const MerchantGroup = () => {
  const history = useHistory();
  const tableHeaders = [
    {
      text: "Merchant Id",
      sort: true,
      name: "merchantGroupId",
      search: true,
      type: "text",
    },
    {
      text: "Merchant Name",
      sort: true,
      name: "merchantGroupName",
      search: true,
      type: "text",
    },
    {
      text: "Email",
      sort: true,
      name: "email",
      search: true,
      type: "text",
    },
    {
      text: "Modified Date",
      sort: true,
      name: "lastModifiedDate",
      search: false,
      type: "date",
    },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-edit" aria-hidden="true"></span>,
      description: "Edit",
      action: (val) =>
        history.push(`/edit-merchant-group/${val.merchantGroupId}`),
      condition: (val) => true,
    },
    {
      icon: <span className="fas fa-trash" aria-hidden="true"></span>,
      description: "Delete",
      action: (val) => {
        if (window.confirm("Are you sure want to delete?")) {
          dispatch(deleteMerchantGroup(val.merchantGroupId));
        }
      },
      condition: (val) => true,
    },
  ];

  const {
    // status,
    merchantGroupList,
    first,
    last,
    totalData,
    totalPage,
    // error,
    empty,
    errorDelete,
    errorActivate,
  } = useSelector((state) => state.merchantGroup);
  const { user } = useSelector((state) => state.auth);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState([]);
  const [orderBy, setOrderBy] = useState({
    field: "lastModifiedDate",
    direction: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDataGroup(page, size, searchQuery, orderBy));
  }, [page, searchQuery, orderBy, dispatch, size, errorDelete, errorActivate]);

  useEffect(() => {
    // set action button
    user?.role.roleName === "MERCHANT_GROUP"
      ? dispatch(setHeaderButton({ titleHeader: "Merchant" }))
      : dispatch(
          setHeaderButton({
            add: true,
            pathAdd: "/add-merchant-group",
            titleHeader: "Merchant Group",
          })
        );
  }, [dispatch]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div className="container-fluid">
      <MultipleSearch
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        headers={tableHeaders}
      />
      <DataTables
        headers={tableHeaders}
        listData={merchantGroupList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default MerchantGroup;
