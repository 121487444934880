import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import SearchGrid from "../../components/search-grid/index.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { getCategoryByName } from "../../redux/product-category/product-category.actions";
import { defaultProduct, getDataProduct, getProviderList } from "../../redux/product/product.actions";

const Product = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [orderBy, setOrderBy] = useState({
    field: "createdDate",
    direction: false,
  });
  const [searchValue, setSearchValue] = useState([]);
  const { productList, first, last, totalData, totalPage, empty, providerList, loadingProvider } = useSelector(
    (state) => state.products
  );
  const [optionsProvider, setOptionsProvider] = useState([]);
  const { categoryByName, loading } = useSelector((state) => state.categories);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const tableHeaders = [
    {
      text: "Category",
      sort: true,
      name: "agent.category.categoryName",
      searchName: "agent.category.categoryName",
      search: true,
      type: "text",
      position: "left",
      index: 0,
      searchType: "searchable",
      options: categoryOptions,
      handleSearch: (e) => dispatch(getCategoryByName(e)),
      loading: loading,
    },
    {
      text: "Product Code",
      sort: true,
      name: "productCode",
      search: true,
      type: "text",
      position: "left",
      index: 1,
      searchType: "text",
    },
    {
      text: "Product Name",
      sort: true,
      name: "productName",
      search: true,
      type: "text",
      position: "left",
      index: 2,
      searchType: "text",
    },
    {
      text: "Product Type",
      sort: true,
      name: "agent.paymentType.paymentTypeName",
      search: true,
      type: "text",
    },
    {
      text: "Operator",
      sort: true,
      name: "provider.providerName",
      search: true,
      type: "text",
      position: "right",
      index: 0,
      searchType: "searchable",
      options: optionsProvider,
      handleSearch: (e) => dispatch(getProviderList(e)),
      loading: loadingProvider,
    },
    {
      text: "Status",
      sort: false,
      name: "isActive",
      search: false,
      type: "text",
      position: "right",
      index: 1,
      searchType: "select",
      options: [
        { value: true, label: "Active" },
        { value: false, label: "Not active" },
      ],
      specialFunc: (val) => (val ? "Active" : "Not Active")
    },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-edit" aria-hidden="true"></span>,
      description: "Edit",
      action: (val) => history.push(`/product-form/${val.productId}`),
      condition: (val) => true,
    },
  ];

  useEffect(() => {
    let optionTemp = [];
    categoryByName?.forEach(item => {
      optionTemp.push({
        value: item.categoryName,
        label: item.categoryName
      })
    });
    setCategoryOptions(optionTemp);
  }, [categoryByName]);

  useEffect(() => {
    let optionTemp = [];
    providerList?.forEach(item => {
      optionTemp.push({
        value: item.providerName,
        label: item.providerName
      })
    });
    setOptionsProvider(optionTemp);
  }, [providerList]);

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Products",
        add: true,
        pathAdd: '/product-form/null',
        upload: true,
        pathUpload: "/upload-product",
      })
    );
    dispatch(defaultProduct());
  }, []);

  useEffect(() => {
    dispatch(getDataProduct(page, size, searchValue, orderBy));
  }, [page, size, orderBy, searchValue]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (val) => {
    setPage(0);
    setSearchValue(val);
  }

  return (
    <div className="container-fluid">
      <SearchGrid searchField={tableHeaders} handleSearch={handleSearch} value={searchValue} />
      <DataTables
        headers={tableHeaders}
        listData={productList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default Product;
