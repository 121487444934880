import MasterDataTypes from "./masterData.types";

const INITIAL_STATE = {
  identity: [],
  bank: [],
  businessEntity: [],
  businessSector: [],
  businessCategory: [],
  provinces: [],
  refCodesPhase2: [],
  refGroup: [],
  marriageStatus: [],
  success: false,
  error: false,
};

const MasterDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MasterDataTypes.GET_MASTER_DATA:
      return {
        ...state,
        success: false,
        error: false,
      };
    case MasterDataTypes.GET_MASTER_DATA_SUCCESS:
      return {
        ...state,
        success: true,
        identity: action.payload.identity,
        bank: action.payload.bank,
        businessEntity: action.payload.businessEntity,
        businessSector: action.payload.businessSector,
        businessCategory: action.payload.businessCategory,
        provinces: action.payload.provinces,
        refCodesPhase2: action.payload.refCodesPhase2,
        refGroup: action.payload.refGroup,
        marriageStatus: action.payload.marriageStatus,
      };
    case MasterDataTypes.GET_MASTER_DATA_FAILURE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default MasterDataReducer;
