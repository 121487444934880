import BannerTypes from "./banner.types";

const INITIAL_STATE = {
  bannerList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  error: false,

  bannerDetail: null,

  sendingStatus: "",
};

const BannerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BannerTypes.GET_BANNERS:
      return {
        ...state,
        error: false,
      };
    case BannerTypes.GET_BANNERS_SUCCESS:
      return {
        ...state,
        bannerList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case BannerTypes.GET_BANNERS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case BannerTypes.GET_BANNER_DETAIL:
      return {
        ...state,
        bannerDetail: null,
      };
    case BannerTypes.GET_BANNER_DETAIL_SUCCESS:
      return {
        ...state,
        bannerDetail: action.payload,
      };
    case BannerTypes.GET_BANNER_DETAIL_FAILURE:
      return {
        ...state,
        bannerDetail: null,
      };
    case BannerTypes.CREATE_BANNER:
      return {
        ...state,
        sendingStatus: "send",
      };
    case BannerTypes.CREATE_BANNER_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case BannerTypes.CREATE_BANNER_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case BannerTypes.UPDATE_BANNER:
      return {
        ...state,
        sendingStatus: "send",
      };
    case BannerTypes.UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case BannerTypes.UPDATE_BANNER_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case BannerTypes.DELETE_BANNER:
      return {
        ...state,
        sendingStatus: "send",
      };
    case BannerTypes.DELETE_BANNER_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case BannerTypes.DELETE_BANNER_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case BannerTypes.DEFAULT_BANNER_STATE:
      return {
        ...state,
        sendingStatus: "",
        bannerDetail: null,
      };
    default:
      return state;
  }
};

export default BannerReducer;
