/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { getMasterData } from "../../redux/masterData/masterData.actions";
import { createRefCode, deleteRefCode, updateRefCode } from "../../redux/refcode/refCode.actions";

const RefCodeForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { refGroup, refCodesPhase2 } = useSelector((state) => state.masterData);
  const [formData, setFormData] = useState({
    refCode: "",
    refGroup: "",
    refName: "",
  });
  const { sendingStatus } = useSelector(state => state.refCodes)

  const headerActionButton =
    params.id !== "null"
      ? [
          <button
            type="button"
            name="save"
            className="btn btn-danger"
            onClick={() => {
              if (window.confirm("Are you sure want to delete?")) {
                dispatch(deleteRefCode(params.id));
              }
            }}
          >
            <i className="fas fa-trash"></i>
            Delete
          </button>,
          <button
            type="button"
            name="save"
            className="btn btn-success"
            onClick={() => onSave()}
          >
            <i className="fas fa-save"></i>
            Save
          </button>,
        ]
      : [
          <button
            type="button"
            name="save"
            className="btn btn-success"
            onClick={() => onSave()}
          >
            <i className="fas fa-save"></i>
            Save
          </button>,
        ];

  const onSave = useCallback(() => {
    if (params.id !== "null") {
      dispatch(updateRefCode(formData));
    } else {
      dispatch(createRefCode(formData));
    }
  }, [formData]);

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Edit Reference Code",
        isChild: true,
        hasCustomButton: true,
        customButton: headerActionButton,
      })
    );
  }, [onSave]);

  useEffect(() => {
    if (sendingStatus === "success") {
      dispatch(getMasterData());
      window.history.back();
    }
  }, [sendingStatus]);

  useEffect(() => {
    if(params.id !== "null") {
      let tempData = [];
      for (const key in refCodesPhase2) {
        tempData = [...tempData, ...refCodesPhase2[key]];
      }
      const selectedData = tempData.filter(item => item.id == params.id);
      if(selectedData[0]) setFormData(selectedData[0]);
    }
  }, []);

  const handleChange = (e) => {
    setFormData((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Reference Code Info</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Group Ref Code*</label>
                </div>
                <div className="col-sm-6">
                  <div className="select2-primary">
                    <select
                      className="form-control select2"
                      data-dropdown-css-className="select2-primary"
                      style={{ width: "100%" }}
                      name="refGroup"
                      value={formData.refGroup}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Choose Ref Group</option>
                      {refGroup?.map((dt) => (
                        <option key={dt.refGroup} value={dt.refGroup}>
                          {dt.refGroup}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Ref Code*</label>
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="fullname"
                    name="refCode"
                    value={formData.refCode}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Ref Name*</label>
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="fullname"
                    name="refName"
                    value={formData.refName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Created On</label>
                </div>
                <div className="col-sm-6">
                  <label>02-10-2021 10:34:23</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefCodeForm;
