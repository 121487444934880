import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { defaultUploadProductState, uploadFileProducts } from "../../redux/product/product.actions";

const ProductsUpload = () => {
  const dispatch = useDispatch();
  const { errorUploadFile } = useSelector(
    (state) => state.products
  );
  const [file, setFile] = useState({});

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Upload File Products",
        isChild: true,
      })
    );
  }, []);

  const handleUpload = () => {
    console.log(file)
    if (file) dispatch(uploadFileProducts(file));
  };

  const handleChange = (e) => {
    const value = e.target.files;
    setFile(value[0]);
    dispatch(defaultUploadProductState());
  };

  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="card card-primary card-outline card-tabs">
            <div class="card-header p-0 pt-1 border-bottom-0">
              <ul
                class="nav nav-tabs"
                id="custom-tabs-three-tab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="custom-tabs-three-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-home"
                    role="tab"
                    aria-controls="custom-tabs-three-home"
                    aria-selected="true"
                  >
                    Info Merchant
                  </a>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <div class="tab-content" id="custom-tabs-three-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="custom-tabs-three-home"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-home-tab"
                >
                  <form class="form-horizontal">
                    <div class="form-group row">
                      <div class="col-sm-2">
                        <label>Download Template</label>
                      </div>
                      <div class="col-sm-6">
                        <i class="fas fa-link"></i>
                        <a
                          download="template_upload_batch_products.xlsx"
                          href={`${process.env.REACT_APP_BASE_URL}duwit-dashboard-api-2/v1/awd-products/download-template`}
                        >
                          template_upload_batch_products.xlsx
                        </a>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-2">
                        <label>Upload Template</label>
                      </div>
                      <div class="col-sm-6">
                        <div class="input-group">
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="exampleInputFile"
                              onChange={handleChange}
                            />
                            <label
                              class="custom-file-label"
                              for="exampleInputFile"
                            >
                              {file.name ?? "Choose file"}
                            </label>
                          </div>
                          <div class="input-group-append">
                            <span
                              class="input-group-text"
                              onClick={handleUpload}
                            >
                              Upload
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {errorUploadFile !== "" && (
                      <div class="form-group row">
                        <div class="col-sm-2">
                          <label class="badge badge-danger">
                            Error Message
                          </label>
                        </div>
                        <div class="col-sm-6">
                          <label>** {errorUploadFile}</label>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsUpload;
