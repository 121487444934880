import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select, { components } from "react-select";
import InputField from "../../components/input-field/input-field.component";
import TextArea from "../../components/text-area/text-area.component";
import {
  setHeaderButton,
  showAlert,
} from "../../redux/actions/actions.actions";
import {
  createMerchant,
  getCategory,
  getCities,
  getDataById,
  getMerchantGroup,
  updateMerchant,
  updateUrlCallback,
} from "../../redux/merchat-management/merchant-management.action";
import request from "../../_network/request";

const MerchantManagementForm = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const {
    panOptions,
    categoryOptions,
    categoryLoading,
    criteriaOptions,
    createMerchantSuccess,
    updateMerchantSuccess,
    updateUrlCallbackSuccess,
    dataById,
    cities,
    citiesOwner,
    merchantGroupList,
    mgLoading,
  } = useSelector((state) => state.merchantManagement);
  const { token, user } = useSelector((state) => state.auth);
  const {
    provinces,
    identity,
    bank,
    businessEntity,
    businessSector,
    businessCategory,
  } = useSelector((state) => state.masterData);
  const [managementForm, setManagementForm] = useState({
    activateMerchant: false,
    activationDate: "",
    address: "",
    administratorName: "",
    administratorPosition: "",
    bankAccount: "",
    bankName: "",
    businessCategoryId: "",
    businessEntityId: "",
    businessSectorId: "",
    businessValidDate: "",
    city: {
      cityId: "",
      cityName: "",
      province: {
        provinceId: "",
        provinceName: "",
      },
    },
    digitNine: "",
    email: "",
    endCoopDate: "",
    establishmentDate: "",
    establishmentPlace: "",
    id: "",
    imageIdentity: "",
    imageNpwp: "",
    imageSiup: "",
    imageStaticQris: "",
    lastModifiedBy: "",
    lastModifiedDate: "",
    lmid: "",
    mdr: 0,
    merchantCategoryCode: "",
    merchantCategoryValue: "",
    merchantCriteriaCode: "",
    merchantCriteriaValue: "",
    merchantGroupId: "",
    merchantId: "",
    merchantIdentityId: "",
    merchantIdentityNo: "",
    merchantName: "",
    merchantPan: "",
    nmid: "",
    ownerAddress: "",
    ownerCity: {
      cityId: "",
      cityName: "",
      province: {
        provinceId: "",
        provinceName: "",
      },
    },
    ownerEmail: "",
    ownerIdentityId: "",
    ownerIdentityNo: "",
    ownerName: "",
    phoneOwner: "",
    phoneStore: "",
    postal: "",
    province: {
      provinceId: "",
      provinceName: "",
    },
    siupNo: "",
    startCoopDate: "",
    status: false,
    terminalCount: "",
    verificationStatus: "",
    urlCallbackQris: "",
  });
  const [name, setName] = useState({
    imageSiup: "",
    imageNpwp: "",
    imageIdentity: "",
  });
  const [options, setOptions] = useState({
    provinces: [],
    cities: [],
    citiesOwner: [],
    identity: [],
    bank: [],
    businessEntity: [],
    businessSector: [],
    businessCategory: [],
    mgList: [],
    categoryOptions: [],
  });

  useEffect(() => {
    if (params.id) dispatch(getDataById(params.id));
    if (user.role.roleName === "MERCHANT_GROUP")
      setManagementForm((mf) => ({
        ...mf,
        merchantGroupId: user.merchantGroup.merchantGroupId,
      }));
    dispatch(
      getMerchantGroup(
        user.role.roleName === "MERCHANT_GROUP"
          ? user.merchantGroup.merchantGroupName
          : ""
      )
    );
    dispatch(getCategory(""));
  }, [dispatch, managementForm]);

  useEffect(() => {
    let optionTemp = { ...options };
    optionTemp.mgList = [];
    merchantGroupList?.forEach((mg) => {
      optionTemp.mgList.push({
        value: mg.merchantGroupId,
        label: mg.merchantGroupName,
      });
    });
    setOptions(optionTemp);
  }, [merchantGroupList]);

  useEffect(() => {
    let optionTemp = { ...options };
    optionTemp.categoryOptions = [];
    categoryOptions?.forEach((opt) => {
      optionTemp.categoryOptions.push({
        value: opt.merchantCategoryCode,
        label: `${opt.merchantCategoryCode} - ${opt.merchantCategoryValue}`,
      });
    });
    setOptions(optionTemp);
  }, [categoryOptions, managementForm]);

  useEffect(() => {
    mappingOptions();
  }, [
    provinces,
    identity,
    bank,
    businessEntity,
    businessSector,
    businessCategory,
    cities,
    citiesOwner,
  ]);

  const [images, setImages] = useState({
    identity: "",
    npwp: "",
    siup: "",
  });

  useEffect(() => {
    if (params.id && dataById) {
      setManagementForm(dataById);
      if (dataById.merchantGroup && dataById.merchantGroup !== null) {
        dispatch(getMerchantGroup(dataById.merchantGroup.merchantGroupName));
      }
      dispatch(getCategory(dataById.merchantCategoryValue));
      gettingImage();
    }
  }, [dataById]);

  const gettingImage = async () => {
    let tempImage = { ...images };
    const imageIdentity = await request({
      url: `/v1/merchants/image/merchant?filename=${dataById.imageIdentity}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    });
    const imageNpwp = await request({
      url: `/v1/merchants/image/merchant?filename=${dataById.imageNpwp}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    });
    const imageSiup = await request({
      url: `/v1/merchants/image/merchant?filename=${dataById.imageSiup}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    });
    tempImage.identity = btoa(
      new Uint8Array(imageIdentity).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    tempImage.npwp = btoa(
      new Uint8Array(imageNpwp).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    // tempImage.siup = btoa(
    //   new Uint8Array(imageSiup).reduce(
    //     (data, byte) => data + String.fromCharCode(byte),
    //     ""
    //   )
    // );
    tempImage.siup = new Buffer(imageSiup).toString("base64");
    setImages(tempImage);
  };

  useEffect(() => {
    dispatch(
      setHeaderButton({
        buttonWithFunction: true,
        buttonFunction: onSave,
        buttonText: "Save",
        buttonIcon: <i className="fas fa-save"></i>,
        titleHeader: params.id ? "Edit Merchant" : "Add Merchant",
        isChild: true,
      })
    );
  }, [dispatch, managementForm]);

  useEffect(() => {
    if (
      createMerchantSuccess === "success" ||
      updateMerchantSuccess === "success"
    )
      window.history.back();
  }, [createMerchantSuccess, updateMerchantSuccess]);

  useEffect(() => {
    if (updateUrlCallbackSuccess === "success")
      //   window.history.back();
      console.log("url update");
  }, [updateUrlCallbackSuccess]);

  const mappingOptions = () => {
    let newOptions = {
      provinces: [],
      cities: [],
      identity: [],
      bank: [],
      businessEntity: [],
      businessSector: [],
      businessCategory: [],
      citiesOwner: [],
    };
    provinces?.forEach((prov) => {
      newOptions.provinces.push({
        label: prov.provinceName,
        value: prov.provinceId,
      });
    });
    cities?.forEach((city) => {
      newOptions.cities.push({
        label: city.cityName,
        value: city.cityId,
      });
    });
    citiesOwner?.forEach((city) => {
      newOptions.citiesOwner.push({
        label: city.cityName,
        value: city.cityId,
      });
    });
    businessEntity?.forEach((bse) => {
      newOptions.businessEntity.push({
        label: bse.refName,
        value: bse.refCode,
      });
    });
    businessSector?.forEach((bss) => {
      newOptions.businessSector.push({
        label: `${bss.refCode} - ${bss.refName}`,
        value: bss.refCode,
      });
    });
    businessCategory?.forEach((bsc) => {
      newOptions.businessCategory.push({
        label: `${bsc.refCode} - ${bsc.refName}`,
        value: bsc.refCode,
      });
    });
    bank?.forEach((bnk) => {
      newOptions.bank.push({
        label: `${bnk.refCode} - ${bnk.refName}`,
        value: bnk.refCode,
      });
    });
    setOptions(newOptions);
  };

  const handleChange = async (e) => {
    let formTemp = { ...managementForm };
    let imageNameTemp = { ...name };
    if (e.target.type === "file") {
      if (e.target.files[0].size > 5242880) {
        dispatch(showAlert("error", "Max file size allowed 5MB!"));
      } else {
        formTemp[e.target.name] = e.target.files[0];
        imageNameTemp[e.target.name] = e.target.files[0].name;
        setName(imageNameTemp);
      }
    } else {
      formTemp[e.target.name] = e.target.value;
    }
    setManagementForm(formTemp);
  };

  const handleCheckBox = (e) => {
    let formTemp = { ...managementForm };
    formTemp.status = e.target.checked;
    setManagementForm(formTemp);
  };

  const handleSelectChange = (e, name) => {
    let newForm = { ...managementForm };
    newForm[name] = e === null ? null : e.value;
    setManagementForm(newForm);
  };

  const handleCityProv = (e, name, type) => {
    let newForm = { ...managementForm };
    const searched =
      name === "province"
        ? provinces.filter((val) => val.provinceId === (e?.value || ""))
        : type === "store"
        ? cities.filter((val) => val.cityId === e.value)
        : citiesOwner.filter((val) => val.cityId === e.value);
    if (type === "store") {
      if (e) {
        newForm[name] = searched[0];
        dispatch(getCities(e.value, type));
      } else {
        let newOptions = { ...options };
        newForm.city = {
          cityId: "",
          cityName: "",
          province: {
            provinceId: "",
            provinceName: "",
          },
        };
        if (name === "province") {
          newForm.province = {
            provinceId: "",
            provinceName: "",
          };
          newOptions.cities = [];
          setOptions(newOptions);
        }
      }
    } else {
      if (e) {
        dispatch(getCities(e.value, type));
        if (name === "province") {
          newForm.ownerCity = { ...newForm.ownerCity };
          newForm.ownerCity.province = searched[0];
        } else {
          newForm.ownerCity = { ...newForm.ownerCity, ...searched[0] };
        }
      } else {
        if (name === "province") {
          newForm.ownerCity = {
            cityId: "",
            cityName: "",
            province: {
              provinceId: "",
              provinceName: "",
            },
          };
          let newOptions = { ...options };
          newOptions.citiesOwner = [];
          setOptions(newOptions);
        } else {
          newForm.ownerCity.cityId = "";
          newForm.ownerCity.cityName = "";
        }
      }
    }
    setManagementForm(newForm);
  };

  const generateSelectVal = (val, options) => {
    if (options) {
      const selected = options.filter((option) => option.value === val);
      return selected[0];
    }
  };

  const onSave = () => {
    if (params.id) {
      if (window.confirm("Are you sure want to save edit?")) {
        dispatch(updateMerchant(params.id, managementForm));
      }
    } else {
      dispatch(createMerchant(managementForm));
    }
  };

  const onSaveUrlCallback = () => {
    if (window.confirm("Are you sure want to save url?")) {
      dispatch(updateUrlCallback(params.id, managementForm));
    }
  };

  const deleteImage = (name) => {
    let formTemp = { ...managementForm };
    formTemp[name] = "";
    setManagementForm(formTemp);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-sm-12">
          <div className="card card-primary card-outline card-tabs">
            <div className="card-header p-0 pt-1 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-three-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-three-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-home"
                    role="tab"
                    aria-controls="custom-tabs-three-home"
                    aria-selected="true"
                  >
                    Info Merchant
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-business-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-business"
                    role="tab"
                    aria-controls="custom-tabs-three-owner"
                    aria-selected="true"
                  >
                    Info Business
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-owner-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-owner"
                    role="tab"
                    aria-controls="custom-tabs-three-owner"
                    aria-selected="true"
                  >
                    Info Owner
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-qris-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-qris"
                    role="tab"
                    aria-controls="custom-tabs-three-qris"
                    aria-selected="true"
                  >
                    Info QRIS
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-url-qris-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-url-qris"
                    role="tab"
                    aria-controls="custom-tabs-three-url-qris"
                    aria-selected="true"
                  >
                    URL Callback QRIS
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-three-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-three-home"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-home-tab"
                >
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Merchant ID *</label>
                      </div>
                      <div className="col-sm-6">
                        <label>{params.id || "Auto Generate"}</label>
                      </div>
                    </div>
                    <InputField
                      type="text"
                      label="Merchant Name"
                      name="merchantName"
                      value={managementForm.merchantName}
                      onChange={handleChange}
                      required={true}
                      maxLength={25}
                    />
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Merchant Group</label>
                      </div>
                      <div className="col-sm-6">
                        <Select
                          isSearchable={true}
                          isClearable={true}
                          isLoading={mgLoading}
                          onInputChange={(e) => {
                            if (e !== "") dispatch(getMerchantGroup(e));
                          }}
                          value={generateSelectVal(
                            managementForm.merchantGroupId !== null
                              ? managementForm.merchantGroupId
                              : managementForm.merchantGroup?.merchantGroupId,
                            options.mgList
                          )}
                          onChange={(e) => {
                            handleSelectChange(e, "merchantGroupId");
                          }}
                          options={options.mgList}
                          isDisabled={user.role.roleName === "MERCHANT_GROUP"}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Merchant Identity*</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-control select2"
                          name="merchantIdentityId"
                          id="inputMerchantID"
                          style={{ width: "100%" }}
                          value={managementForm.merchantIdentityId}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Choose One</option>
                          {identity.map((val, i) => (
                            <option value={val.refCode} key={val.id}>
                              {val.refName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <InputField
                      label="Merchant Identity No"
                      name="merchantIdentityNo"
                      value={managementForm.merchantIdentityNo}
                      onChange={handleChange}
                      required={true}
                    />
                    <InputField
                      label="Email"
                      name="email"
                      value={managementForm.email}
                      onChange={handleChange}
                      required={true}
                    />
                    <TextArea
                      label="Address"
                      name="address"
                      value={managementForm.address}
                      onChange={handleChange}
                      required={true}
                    />
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputProvince">Province*</label>
                      </div>
                      <div className="col-sm-6">
                        <Select
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="province"
                          value={{
                            value: managementForm.province?.provinceId,
                            label: managementForm.province?.provinceName,
                          }}
                          components={{
                            Input: (props) => (
                              <components.Input
                                {...props}
                                value={managementForm.province?.provinceName}
                                required
                              />
                            ),
                          }}
                          options={options.provinces}
                          onChange={(e) => {
                            handleCityProv(e, "province", "store");
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>City*</label>
                      </div>
                      <div className="col-sm-6">
                        <Select
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="city"
                          value={{
                            value: managementForm.city?.cityId,
                            label: managementForm.city?.cityName,
                          }}
                          components={{
                            Input: (props) => (
                              <components.Input
                                {...props}
                                value={managementForm.city?.cityName}
                                required
                              />
                            ),
                          }}
                          options={options.cities}
                          onChange={(e) => {
                            handleCityProv(e, "city", "store");
                          }}
                        />
                      </div>
                    </div>
                    <InputField
                      type="text"
                      label="Postal Code"
                      name="postal"
                      value={managementForm.postal}
                      onChange={handleChange}
                      required={true}
                      maxLength={5}
                    />
                    <InputField
                      label="Phone Store"
                      name="phoneStore"
                      value={managementForm.phoneStore}
                      onChange={handleChange}
                      required={true}
                    />
                    <InputField
                      label="Admin Name"
                      name="administratorName"
                      value={managementForm.administratorName}
                      onChange={handleChange}
                    />
                    <InputField
                      label="Admin Position"
                      name="administratorPosition"
                      value={managementForm.administratorPosition}
                      onChange={handleChange}
                    />
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Bank Name*</label>
                      </div>
                      <div className="col-sm-6">
                        <Select
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="bankName"
                          value={generateSelectVal(
                            managementForm.bankName,
                            options.bank
                          )}
                          options={options.bank}
                          components={{
                            Input: (props) => (
                              <components.Input
                                {...props}
                                value={managementForm.bankName}
                                required
                              />
                            ),
                          }}
                          onChange={(e) => {
                            handleSelectChange(e, "bankName");
                          }}
                        />
                      </div>
                    </div>
                    <InputField
                      label="Bank Account"
                      name="bankAccount"
                      value={managementForm.bankAccount}
                      onChange={handleChange}
                      required={true}
                    />
                    <InputField
                      label="MDR (%)"
                      name="mdr"
                      value={managementForm.mdr}
                      onChange={handleChange}
                      required={true}
                    />
                    {params.id && managementForm.activationDate && (
                      <div className="form-group row">
                        <div className="col-sm-2">
                          <label htmlFor="inputStatus">Status</label>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="status"
                              checked={managementForm.status}
                              onChange={handleCheckBox}
                            />
                            <label className="form-check-label">Active</label>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputStatus">Upload Image *</label>
                      </div>
                      <div className="col-sm-10">
                        <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                          <li>
                            <span
                              className="mailbox-attachment-icon has-img"
                              style={{ minHeight: "132.25px" }}
                            >
                              {managementForm.imageIdentity === null ||
                              managementForm.imageIdentity === "" ? (
                                <i
                                  className="fas fa-camera"
                                  style={{ marginTop: "30px" }}
                                ></i>
                              ) : (
                                <img
                                  src={
                                    managementForm.imageIdentity
                                      ? typeof managementForm.imageIdentity ===
                                        "string"
                                        ? `data:image/png;base64,${images.identity}`
                                        : URL.createObjectURL(
                                            managementForm.imageIdentity
                                          )
                                      : ""
                                  }
                                  style={{
                                    objectFit: "cover",
                                    maxHeight: "132.25px",
                                  }}
                                  alt="Attachment"
                                />
                              )}
                            </span>

                            <div className="mailbox-attachment-info">
                              {managementForm.imageIdentity === null ||
                              managementForm.imageIdentity === "" ? (
                                <input
                                  className="form-control-sm"
                                  type="file"
                                  name="imageIdentity"
                                  onChange={handleChange}
                                  accept="image/*"
                                  required
                                />
                              ) : (
                                <a
                                  onClick={() => {
                                    deleteImage("imageIdentity");
                                  }}
                                  className="mailbox-attachment-name"
                                >
                                  <i className="fas fa-camera"></i>{" "}
                                  {name.imageIdentity}
                                </a>
                              )}
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>KTP/SIM/Passport</span>
                              </span>
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>Max 5MB</span>
                              </span>
                            </div>
                          </li>
                          <li>
                            <span
                              className="mailbox-attachment-icon has-img"
                              style={{ minHeight: "132.25px" }}
                            >
                              {managementForm.imageNpwp === null ||
                              managementForm.imageNpwp === "" ? (
                                <i
                                  className="fas fa-camera"
                                  style={{ marginTop: "30px" }}
                                ></i>
                              ) : (
                                <img
                                  // src={generateImage(managementForm.imageNpwp)}
                                  src={
                                    managementForm.imageNpwp
                                      ? typeof managementForm.imageNpwp ===
                                        "string"
                                        ? `data:image/png;base64,${images.npwp}`
                                        : URL.createObjectURL(
                                            managementForm.imageNpwp
                                          )
                                      : ""
                                  }
                                  style={{
                                    objectFit: "cover",
                                    maxHeight: "132.25px",
                                  }}
                                  alt="Attachment"
                                />
                              )}
                            </span>

                            <div className="mailbox-attachment-info">
                              {managementForm.imageNpwp === null ||
                              managementForm.imageNpwp === "" ? (
                                <input
                                  className="form-control-sm"
                                  type="file"
                                  name="imageNpwp"
                                  onChange={handleChange}
                                  accept="image/*"
                                  required
                                />
                              ) : (
                                <a
                                  onClick={() => {
                                    deleteImage("imageNpwp");
                                  }}
                                  className="mailbox-attachment-name"
                                >
                                  <i className="fas fa-camera"></i>{" "}
                                  {name.imageNpwp}
                                </a>
                              )}
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>NPWP</span>
                              </span>
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>Max 5MB</span>
                              </span>
                            </div>
                          </li>
                          <li>
                            <span
                              className="mailbox-attachment-icon has-img"
                              style={{ minHeight: "132.25px" }}
                            >
                              {managementForm.imageSiup === null ||
                              managementForm.imageSiup === "" ? (
                                <i
                                  className="fas fa-camera"
                                  style={{ marginTop: "30px" }}
                                ></i>
                              ) : (
                                <img
                                  // src={generateImage(managementForm.imageSiup)}
                                  src={
                                    managementForm.imageSiup
                                      ? typeof managementForm.imageSiup ===
                                        "string"
                                        ? `data:image/png;base64,${images.siup}`
                                        : URL.createObjectURL(
                                            managementForm.imageSiup
                                          )
                                      : ""
                                  }
                                  style={{
                                    objectFit: "cover",
                                    maxHeight: "132.25px",
                                  }}
                                  alt="Attachment"
                                />
                              )}
                            </span>

                            <div className="mailbox-attachment-info">
                              {managementForm.imageSiup === null ||
                              managementForm.imageSiup === "" ? (
                                <input
                                  className="form-control-sm"
                                  type="file"
                                  name="imageSiup"
                                  onChange={handleChange}
                                  accept="image/*"
                                  required
                                />
                              ) : (
                                <a
                                  onClick={() => {
                                    deleteImage("imageSiup");
                                  }}
                                  className="mailbox-attachment-name"
                                >
                                  <i className="fas fa-camera"></i>{" "}
                                  {name.imageSiup}
                                </a>
                              )}
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>SIUP</span>
                              </span>
                              <span className="mailbox-attachment-size clearfix mt-1">
                                <span>Max 5MB</span>
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-business"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-business-tab"
                >
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Business Entity *</label>
                      </div>
                      <div className="col-sm-6">
                        <Select
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="businessEntity"
                          value={generateSelectVal(
                            managementForm.businessEntityId,
                            options.businessEntity
                          )}
                          components={{
                            Input: (props) => (
                              <components.Input
                                {...props}
                                value={managementForm.businessEntityId}
                                required
                              />
                            ),
                          }}
                          options={options.businessEntity}
                          onChange={(e) => {
                            handleSelectChange(e, "businessEntityId");
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Business Sector *</label>
                      </div>
                      <div className="col-sm-6">
                        <Select
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="businessSector"
                          value={generateSelectVal(
                            managementForm.businessSectorId,
                            options.businessSector
                          )}
                          components={{
                            Input: (props) => (
                              <components.Input
                                {...props}
                                value={managementForm.businessSectorId}
                                required
                              />
                            ),
                          }}
                          options={options.businessSector}
                          onChange={(e) => {
                            handleSelectChange(e, "businessSectorId");
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Business Category *</label>
                      </div>
                      <div className="col-sm-6">
                        <Select
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="businessCategory"
                          value={generateSelectVal(
                            managementForm.businessCategoryId,
                            options.businessCategory
                          )}
                          components={{
                            Input: (props) => (
                              <components.Input
                                {...props}
                                value={managementForm.businessCategoryId}
                                required
                              />
                            ),
                          }}
                          options={options.businessCategory}
                          onChange={(e) => {
                            handleSelectChange(e, "businessCategoryId");
                          }}
                        />
                      </div>
                    </div>
                    <InputField
                      label="No SIUP"
                      name="siupNo"
                      value={managementForm.siupNo}
                      onChange={handleChange}
                      required={true}
                    />
                    <InputField
                      label="Establishment Place"
                      name="establishmentPlace"
                      value={managementForm.establishmentPlace}
                      onChange={handleChange}
                    />
                    <InputField
                      label="Establishment Date"
                      name="establishmentDate"
                      value={managementForm.establishmentDate}
                      onChange={handleChange}
                    />
                    <InputField
                      label="Business Validity Period"
                      name="businessValidDate"
                      value={managementForm.businessValidDate}
                      onChange={handleChange}
                    />
                    <InputField
                      label="Start Cooperation Date"
                      name="startCoopDate"
                      value={managementForm.startCoopDate}
                      onChange={handleChange}
                      required={true}
                    />
                    <InputField
                      label="End Cooperation Date"
                      name="endCoopDate"
                      value={managementForm.endCoopDate}
                      onChange={handleChange}
                      required={true}
                    />
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-owner"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-owner-tab"
                >
                  <div className="form-horizontal">
                    <InputField
                      label="Owner Name"
                      name="ownerName"
                      value={managementForm.ownerName}
                      onChange={handleChange}
                      required={true}
                    />
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Owner Identity*</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-control select2"
                          id="inputOwnerID"
                          name="ownerIdentityId"
                          value={managementForm.ownerIdentityId}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                          required
                        >
                          <option value="">Choose One</option>
                          {identity.map((val, i) => (
                            <option value={val.refCode} key={val.id}>
                              {val.refName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <InputField
                      label="Owner Identity No"
                      name="ownerIdentityNo"
                      value={managementForm.ownerIdentityNo}
                      onChange={handleChange}
                      required={true}
                    />
                    <InputField
                      label="Owner Address"
                      name="ownerAddress"
                      value={managementForm.ownerAddress}
                      onChange={handleChange}
                      required={true}
                    />
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputProvince">Province *</label>
                      </div>
                      <div className="col-sm-6">
                        <Select
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="province"
                          value={{
                            value:
                              managementForm.ownerCity?.province.provinceId,
                            label:
                              managementForm.ownerCity?.province.provinceName,
                          }}
                          components={{
                            Input: (props) => (
                              <components.Input
                                {...props}
                                value={
                                  managementForm.ownerCity?.province
                                    .provinceName
                                }
                                required
                              />
                            ),
                          }}
                          options={options.provinces}
                          onChange={(e) => {
                            handleCityProv(e, "province", "owner");
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>City *</label>
                      </div>
                      <div className="col-sm-6">
                        <Select
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="city"
                          value={{
                            value: managementForm.ownerCity?.cityId,
                            label: managementForm.ownerCity?.cityName,
                          }}
                          components={{
                            Input: (props) => (
                              <components.Input
                                {...props}
                                value={managementForm.ownerCity?.cityName}
                                required
                              />
                            ),
                          }}
                          options={options.citiesOwner}
                          onChange={(e) => {
                            handleCityProv(e, "city", "owner");
                          }}
                        />
                      </div>
                    </div>
                    <InputField
                      label="Owner Phone"
                      name="phoneOwner"
                      value={managementForm.phoneOwner}
                      onChange={handleChange}
                    />
                    <InputField
                      label="Owner Email"
                      name="ownerEmail"
                      value={managementForm.ownerEmail}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-qris"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-qris-tab"
                >
                  <div className="form-horizontal">
                    {params.id ? (
                      <div className="form-group row">
                        <div className="col-sm-2">
                          <label>Merchant PAN *</label>
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            id="inputNMID"
                            placeholder="Merchant PAN"
                            name="merchantPan"
                            onChange={handleChange}
                            value={managementForm.merchantPan}
                            disabled
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="form-group row">
                        <div className="col-sm-2">
                          <label>Merchant PAN *</label>
                        </div>
                        <div className="col-sm-2">
                          <input
                            type="text"
                            className="form-control"
                            id="inputMerchantPAN"
                            placeholder="NNS"
                            disabled
                          />
                        </div>
                        <div className="col-sm-2">
                          <select
                            className="form-control select2"
                            id="inputMCC"
                            style={{ width: "100%" }}
                            name="digitNine"
                            onChange={handleChange}
                            value={managementForm.digitNine}
                          >
                            <option value="">Choose One</option>
                            {panOptions?.map((dt) => (
                              <option key={dt.digitId} value={dt.digitId}>
                                {`${dt.digitId} - ${dt.digitName}`}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-2">
                          <input
                            type="text"
                            className="form-control"
                            id="inputMerchantPAN"
                            placeholder="Auto Generate"
                            disabled
                          />
                        </div>
                      </div>
                    )}
                    <InputField
                      type="text"
                      label="NMID"
                      name="nmid"
                      value={managementForm.nmid}
                      onChange={handleChange}
                      maxLength={15}
                    />
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Merchant Category Code *</label>
                      </div>
                      <div className="col-sm-6">
                        <Select
                          isSearchable={true}
                          isClearable={true}
                          isLoading={categoryLoading}
                          onInputChange={(e) => {
                            if (e !== "") dispatch(getCategory(e));
                          }}
                          value={generateSelectVal(
                            managementForm.merchantCategoryCode,
                            options.categoryOptions
                          )}
                          onChange={(e) => {
                            handleSelectChange(e, "merchantCategoryCode");
                          }}
                          options={options.categoryOptions}
                        />
                      </div>
                      {/* <div className="col-sm-6">
                        <select
                          className="form-control select2"
                          id="inputMCC"
                          style={{ width: "100%" }}
                          name="merchantCategoryCode"
                          onChange={handleChange}
                          value={managementForm.merchantCategoryCode}
                          required
                        >
                          <option value="">Choose One</option>
                          {categoryOptions?.map((dt) => (
                            <option
                              key={dt.merchantCategoryCode}
                              value={dt.merchantCategoryCode}
                            >
                              {`${dt.merchantCategoryCode} - ${dt.merchantCategoryValue}`}
                            </option>
                          ))}
                        </select>
                      </div> */}
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Merchant Criteria *</label>
                      </div>
                      <div className="col-sm-6">
                        <select
                          className="form-control select2"
                          id="inputMerchantCriteria"
                          style={{ width: "100%" }}
                          name="merchantCriteriaCode"
                          onChange={handleChange}
                          value={managementForm.merchantCriteriaCode}
                          required
                        >
                          <option value="">Choose One</option>
                          {criteriaOptions?.map((dt) => (
                            <option
                              key={dt.merchantCriteriaCode}
                              value={dt.merchantCriteriaCode}
                            >
                              {dt.merchantCriteriaCode} -{" "}
                              {dt.merchantCriteriaValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <InputField
                      type="number"
                      label="Number of Terminals"
                      name="terminalCount"
                      value={managementForm.terminalCount}
                      onChange={handleChange}
                    />
                    {/* <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Image QRIS</label>
                      </div>
                      <div className="col-sm-6">
                        <button
                          type="submit"
                          name="generateStaticQRIS"
                          className="btn btn-success"
                        >
                          <i className="fas fa-qrcode"></i>
                          Generate Static QRIS Image
                        </button>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-6">
                        <img
                          src="../../dist/img/qr_code.png"
                          className="img-fluid img-thumbnail"
                          alt="QRIS"
                          style={{ maxWidth: "40%" }}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-url-qris"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-url-qris-tab"
                >
                  <div className="form-horizontal">
                    <InputField
                      type="text"
                      label="URL Callback"
                      name="urlCallbackQris"
                      value={managementForm.urlCallbackQris}
                      onChange={handleChange}
                      maxLength={15}
                    />
                  </div>
                  <div className="form-group row">
                    <button
                      onClick={onSaveUrlCallback}
                      name="save"
                      className="btn btn-success"
                      type="button"
                      // disabled={
                      //   !formUser.retype ||
                      //   !formUser.oldPassword ||
                      //   !formUser.newPassword
                      // }
                    >
                      <i className="fas fa-floppy-o"></i>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantManagementForm;
