const PartnerTypes = {
    GET_PARTNER_BALANCE: "GET_PARTNER_BALANCE",
    GET_PARTNER_BALANCE_SUCCESS : "GET_PARTNER_BALANCE_SUCCESS",
    GET_PARTNER_BALANCE_FAILURE : "GET_PARTNER_BALANCE_FAILURE",
    GET_PARTNER_SETTING: "GET_PARTNER_SETTING",
    GET_PARTNER_SETTING_SUCCESS: "GET_PARTNER_SETTING_SUCCESS",
    GET_PARTNER_SETTING_FAILURE: "GET_PARTNER_SETTING_FAILURE",
    UPDATE_PARTNER_SETTING: "UPDATE_PARTNER_SETTING",
    UPDATE_PARTNER_SETTING_SUCCESS : "UPDATE_PARTNER_SETTING_SUCCESS",
    UPDATE_PARTNER_SETTING_FAILURE : "UPDATE_PARTNER_SETTING_FAILURE",
    GET_PARTNER_LIST : "GET_PARTNER_LIST",
    GET_PARTNER_LIST_SUCCESS : "GET_PARTNER_LIST_SUCCESS",
    GET_PARTNER_LIST_FAILURE : "GET_PARTNER_LIST_SUCCESS",
    GET_PARTNER_TRANSACTION : "GET_PARTNER_TRANSACTION",
    GET_PARTNER_TRANSACTION_SUCCESS : "GET_PARTNER_TRANSACTION_SUCCESS",
    GET_PARTNER_TRANSACTION_FAILURE : "GET_PARTNER_TRANSACTION_FAILURE",
    GET_USER_PARTNER : "GET_USER_PARTNER",
    GET_USER_PARTNER_SUCCESS : "GET_USER_PARTNER_SUCCESS",
    GET_USER_PARTNER_FAILURE : "GET_USER_PARTNER_FAILURE",
    CREATE_USER_PARTNER : "CREATE_USER_PARTNER",
    CREATE_USER_PARTNER_SUCCESS : "CREATE_USER_PARTNER_SUCCESS",
    CREATE_USER_PARTNER_FAILED : "CREATE_USER_PARTNER_FAILED",

    GET_DATA_BY_ID: "GET_DATA_BY_ID",
    GET_DATA_BY_ID_SUCCESS: "GET_DATA_BY_ID_SUCCESS",
    GET_DATA_BY_ID_FAILURE: "GET_DATA_BY_ID_FAILURE",

    CREATE_PARTNER : "CREATE_PARTNER",
    CREATE_PARTNER_SUCCESS : "CREATE_PARTNER_SUCCESS",
    CREATE_PARTNER_FAILED : "CREATE_PARTNER_FAILED",

    UPDATE_PARTNER : "UPDATE_PARTNER",
    UPDATE_PARTNER_SUCCESS : "UPDATE_PARTNER_SUCCESS",
    UPDATE_PARTNER_FAILED : "UPDATE_PARTNER_FAILED",

    DELETE_PARTNER: "DELETE_PARTNER",
    DELETE_PARTNER_FAILED: "DELETE_PARTNER_FAILED",
    DELETE_PARTNER_SUCCESS: "DELETE_PARTNER_SUCCESS",

    BAN_PARTNER: "BAN_PARTNER",
    BAN_PARTNER_FAILED: "BAN_PARTNER_FAILED",
    BAN_PARTNER_SUCCESS: "BAN_PARTNER_SUCCESS",
    
    DEFAULT_STATUS: "DEFAULT_STATUS",

    GET_TRANSACTION_BY_ID: "GET_TRANSACTION_BY_ID",
    GET_TRANSACTION_BY_ID_SUCCESS: "GET_TRANSACTION_BY_ID_SUCCESS",
    GET_TRANSACTION_BY_ID_FAILURE: "GET_TRANSACTION_BY_ID_FAILURE",

    APPROVE_REFUND: "APPROVE_REFUND",
    APPROVE_REFUND_FAILED: "APPROVE_REFUND_FAILED",
    APPROVE_REFUND_SUCCESS: "APPROVE_REFUND_SUCCESS",

    EXPORT_TRANSACTION: "EXPORT_TRANSACTION",
    EXPORT_TRANSACTION_SUCCESS: "EXPORT_TRANSACTION_SUCCESS",
    EXPORT_TRANSACTION_FAILED: "EXPORT_TRANSACTION_FAILED",

}
  
  export default PartnerTypes;