const NotificationTypes = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",
  GET_NOTIFICATION_DETAIL: "GET_NOTIFICATION_DETAIL",
  GET_NOTIFICATION_DETAIL_SUCCESS: "GET_NOTIFICATION_DETAIL_SUCCESS",
  GET_NOTIFICATION_DETAIL_FAILURE: "GET_NOTIFICATION_DETAIL_FAILURE",
  CREATE_NOTIFICATION: "CREATE_NOTIFICATION",
  CREATE_NOTIFICATION_SUCCESS: "CREATE_NOTIFICATION_SUCCESS",
  CREATE_NOTIFICATION_FAILURE: "CREATE_NOTIFICATION_FAILURE",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  UPDATE_NOTIFICATION_SUCCESS: "UPDATE_NOTIFICATION_SUCCESS",
  UPDATE_NOTIFICATION_FAILURE: "UPDATE_NOTIFICATION_FAILURE",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  DELETE_NOTIFICATION_SUCCESS: "DELETE_NOTIFICATION_SUCCESS",
  DELETE_NOTIFICATION_FAILURE: "DELETE_NOTIFICATION_FAILURE",
  DEFAULT_NOTIFICATION_STATE: "DEFAULT_NOTIFICATION_STATE",
};

export default NotificationTypes;
