import request from "../_network/request";
const categoryUrl = "/v1/awd-categories";

const getCategoryList = (page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${
    orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;

  return request({
    url: `${categoryUrl}?${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getCategoryDetail = (id, token) => {
  return request({
    url: `${categoryUrl}/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createCategory = (data, token) => {
  return request({
    url: categoryUrl,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateCategory = (id, data, token) => {
  return request({
    url: `${categoryUrl}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteCategory = (id, token) => {
  return request({
    url: `${categoryUrl}/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const CategoryServices = {
  getCategoryList,
  getCategoryDetail,
  createCategory,
  updateCategory,
  deleteCategory,
};
