import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import InputField from "../../components/input-field/input-field.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import {
  createMerchantGroup,
  getGroupById,
  updateMerchantGroup,
} from "../../redux/merchant-group/merchant-group.action";

const MerchantGroupForm = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { createMerchantGroupSuccess, updateMerchantGroupSuccess, dataById } =
    useSelector((state) => state.merchantGroup);
  const [groupForm, setGroupForm] = useState({
    merchantGroupName: "",
    email: "",
  });

  useEffect(() => {
    if (params.id) dispatch(getGroupById(params.id));
  }, []);

  useEffect(() => {
    if (params.id) setGroupForm(dataById);
  }, [dataById]);

  useEffect(() => {
    dispatch(
      setHeaderButton({
        buttonWithFunction: true,
        buttonFunction: onSave,
        buttonText: "Save",
        buttonIcon: <i className="fas fa-save"></i>,
        titleHeader: params.id ? "Edit Merchant Group" : "Add Merchant Group",
        isChild: true,
      })
    );
  }, [dispatch, groupForm]);

  useEffect(() => {
    if (
      createMerchantGroupSuccess === "success" ||
      updateMerchantGroupSuccess === "success"
    )
      window.history.back();
  }, [createMerchantGroupSuccess, updateMerchantGroupSuccess]);

  const handleChange = async (e) => {
    let formTemp = { ...groupForm };
    formTemp[e.target.name] = e.target.value;
    setGroupForm(formTemp);
  };

  const onSave = () => {
    if (params.id) {
      if(window.confirm("Are you sure want to save edit?")){
        dispatch(updateMerchantGroup(params.id, groupForm));
      }
    } else {
      dispatch(createMerchantGroup(groupForm));
    }
  };

  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="card card-primary card-outline card-tabs">
            <div class="card-header p-0 pt-1 border-bottom-0">
              <ul
                class="nav nav-tabs"
                id="custom-tabs-three-tab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="custom-tabs-three-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-home"
                    role="tab"
                    aria-controls="custom-tabs-three-home"
                    aria-selected="true"
                  >
                    Info Merchant
                  </a>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <div class="tab-content" id="custom-tabs-three-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="custom-tabs-three-home"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-home-tab"
                >
                  <form class="form-horizontal">
                    <div class="form-group row">
                      <div class="col-sm-2">
                        <label for="inputID">Merchant Group ID</label>
                      </div>
                      <div class="col-sm-6">
                        <label>
                          {params.id
                            ? groupForm.merchantGroupId
                            : "Auto Generate"}
                        </label>
                      </div>
                    </div>
                    <InputField
                      label="Merchant Group Name"
                      name="merchantGroupName"
                      value={groupForm.merchantGroupName}
                      onChange={handleChange}
                      required={true}
                    />
                    <InputField
                      label="Email"
                      name="email"
                      value={groupForm.email}
                      onChange={handleChange}
                      required={true}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantGroupForm;
