import { batch } from "react-redux";
import { ImageServices, OperatorServices, ProductServices } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import ProductTypes from "./product.types";

export const getDataProduct = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ProductTypes.GET_PRODUCTS });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const productRequest = await ProductServices.getProductList(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: ProductTypes.GET_PRODUCTS_SUCCESS,
          payload: productRequest.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: ProductTypes.GET_PRODUCTS_FAILURE });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    }
  };
};

export const getDetailProduct = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ProductTypes.GET_PRODUCT_DETAIL });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const productDetail =
        await ProductServices.getProductDetail(id, token);

      batch(() => {
        dispatch({
          type: ProductTypes.GET_PRODUCT_DETAIL_SUCCESS,
          payload: productDetail.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ProductTypes.GET_PRODUCT_DETAIL_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const createProduct = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ProductTypes.CREATE_PRODUCT });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const newProduct = await ProductServices.createProduct(
        data,
        token
      );

      if(newProduct.status === "Error") {
        batch(() => {
          dispatch({
            type: ProductTypes.CREATE_PRODUCT_FAILURE,
            payload: newProduct.data,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: newProduct.data,
              alertType: "error",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: ProductTypes.CREATE_PRODUCT_SUCCESS,
            payload: newProduct,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Add new product success",
              alertType: "success",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: ProductTypes.CREATE_PRODUCT_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Add new product failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const updateProduct = (dataForm) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const data = { ...dataForm };
    const id = data.productId;
    try {
      batch(() => {
        dispatch({ type: ProductTypes.UPDATE_PRODUCT });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });
      delete data.productId;
      delete data.createdDate;
      delete data.isDeleted;
      delete data.modifiedDate;

      const update = await ProductServices.updateProduct(
        id,
        data,
        token
      );

      if(update.status === "Error") {
        batch(() => {
          dispatch({
            type: ProductTypes.UPDATE_PRODUCT_FAILURE,
            payload: update.data,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: update.data,
              alertType: "error",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: ProductTypes.UPDATE_PRODUCT_SUCCESS,
            payload: update,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Update product success",
              alertType: "success",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: ProductTypes.UPDATE_PRODUCT_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update product failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const deleteProduct = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ProductTypes.DELETE_PRODUCT });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const deleteProduct = await ProductServices.deleteProduct(
        id,
        token
      );

      batch(() => {
        dispatch({
          type: ProductTypes.DELETE_PRODUCT_SUCCESS,
          payload: deleteProduct,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete product success",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ProductTypes.DELETE_PRODUCT_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete product failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const getAgentList = (agentName) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({ type: ProductTypes.GET_AGENTS });
      const agentReq = await ProductServices.getAgentList(agentName, token);
      dispatch({ type: ProductTypes.GET_AGENTS_SUCCESS, payload: agentReq.data.content });
    } catch (error) {
      dispatch({ type: ProductTypes.GET_AGENTS_FAILURE, payload: error });
    }
  }
}

export const getProviderList = (providerName) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({ type: ProductTypes.GET_PROVIDER_OPTIONS });
      const providerReq = await OperatorServices.getOperatorList(
        0,
        10,
        [{ param: "providerName", value: providerName }],
        { field: "providerName", direction: true },
        token,
      );
      dispatch({ type: ProductTypes.GET_PROVIDER_OPTIONS_SUCCESS, payload: providerReq.data.content });
    } catch (error) {
      dispatch({ type: ProductTypes.GET_PROVIDER_OPTIONS_FAILURE, payload: error });
    }
  }
}

export const defaultProduct = () => async (dispatch) =>
  dispatch({ type: ProductTypes.DEFAULT_PRODUCT_STATE });

export const uploadFileProducts = (file) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ProductTypes.UPLOAD_PRODUCTS });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const upload = await ProductServices.uploadFile(file, token);

      if (upload.status === "Error") {
        batch(() => {
          dispatch({
            type: ProductTypes.UPLOAD_PRODUCTS_FAILURE,
            payload: upload.data,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Upload file error",
              alertType: "error",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({ type: ProductTypes.UPLOAD_PRODUCTS_SUCCESS });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Success upload file",
              alertType: "success",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: ProductTypes.UPLOAD_PRODUCTS_FAILURE,
          payload: error.data.data.message || error.data.messages,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Upload file error",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const defaultUploadProductState = () => {
  return async (dispatch) => {
    dispatch({ type: ProductTypes.UPLOAD_PRODUCTS_DEFAULT });
  };
};
