import ActionsActionTypes from "./actions.types";

const INITIAL_STATE = {
  loading: false,
  loadingTable: false,
  add: false,
  pathAdd: "",
  upload: false,
  pathUpload: "",
  buttonWithFunction: false,
  buttonFunction: () => {},
  buttonText: "",
  buttonIcon: "",
  titleHeader: "",
  isChild: false,
  alertMessage: "",
  alertType: "",
  showAlert: false,
  hasCustomButton: false,
  customButton: () => {},
};

const ActionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionsActionTypes.SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionsActionTypes.HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };
    case ActionsActionTypes.SHOW_LOADING_TABLE:
      return {
        ...state,
        loadingTable: true,
      };
    case ActionsActionTypes.HIDE_LOADING_TABLE:
      return {
        ...state,
        loadingTable: false,
      };
    case ActionsActionTypes.SET_HEADER:
      return {
        ...state,
        add: action.payload.add,
        pathAdd: action.payload.pathAdd,
        upload: action.payload.upload,
        pathUpload: action.payload.pathUpload,
        buttonWithFunction: action.payload.buttonWithFunction,
        buttonIcon: action.payload.buttonIcon,
        buttonFunction: action.payload.buttonFunction,
        buttonText: action.payload.buttonText,
        titleHeader: action.payload.titleHeader,
        isChild: action.payload.isChild,
        hasCustomButton: action.payload.hasCustomButton,
        customButton: action.payload.customButton,
      }
    case ActionsActionTypes.SHOW_ALERT:
      return {
        ...state,
        showAlert: true,
        alertMessage: action.payload.alertMessage,
        alertType: action.payload.alertType,
      }
    case ActionsActionTypes.HIDE_ALERT:
      return {
        ...state,
        showAlert: false,
      }
    default:
      return state;
  }
};

export default ActionsReducer;
