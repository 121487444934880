import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Activate from "./modules/activate/activate.page";
import { configureStore, configurePersistor } from "./redux/store";
import UserRoute from "./route/user.routes";
import { keycloak } from "./_keycloak";

const store = configureStore();
const persistor = configurePersistor(store);

export default function App(props) {
  const [kc, setKc] = useState();
  const [authenticated, setAuthenticated] = useState();
  const [useKeycloak, setUseKeycloak] = useState(false);

  useEffect(() => {
    if (window.location.pathname !== "/activate-user") {
      setUseKeycloak(true);
      loginProcess();
    } else {
      setUseKeycloak(false);
    }
  }, []);

  const loginProcess = async () => {
    await keycloak.init({ checkLoginIframe: false, checkLoginIframeInterval: 10, onLoad: "login-required" }).then((auth) => {
      setAuthenticated(auth);
    });
    setKc(keycloak);
  };

  const onClickUserLogout = (event) => {
    kc.logout({ redirectUri: window.location.origin });
  };

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          {useKeycloak ? (
            authenticated &&
            kc && (
              <UserRoute {...props} logout={onClickUserLogout} keycloak={kc} />
            )
          ) : (
            <Activate />
          )}
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}
