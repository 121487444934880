import request from "../_network/request";
// import { load } from "react-cookies";

const url = "/v1/merchants";
const activateUrl = "/v1/merchants/activate";
const merchantPanOptionUrl = "/v1/merchant-digit-nine/";
const categoryOptionUrl = "/v1/merchant-category/";
const criteriaOptionUrl = "/v1/merchant-criteria/";
const merchantGroupUrl = "/v1/merchant-groups";
const getImageUrl = "/v1/merchants/image/merchant";
const uploadImageUrl = "/v1/merchants/upload/merchant";
const getTemplateUrl = "/v1/merchants/download-template";
const uploadFileUrl = "/v1/merchants/import";

const getTemplate = (token) => {
  return request({
    url: getTemplateUrl,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/octet-stream"
    }
  });
};

const uploadFile = (file, token) => {
  let formdt = new FormData();
  formdt.append("file", file);
  return request({
    url: uploadFileUrl,
    method: "POST",
    data: formdt,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getImage = (file, token) => {
  return request({
    url: `${getImageUrl}?filename=${file}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    },
  });
};

const uploadImage = (file, token) => {
  let formdt = new FormData();
  formdt.append("file", file);
  return request({
    url: uploadImageUrl,
    method: "POST",
    data: formdt,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

const getListData = (page, size, searchQuery = [], orderBy, token) => {
  // let token = load("tc-sm");
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`${i === 0 ? "" : "&"}${key.param}=${key.value}`);
    });
  }
  let order = `${
    orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;
  return request({
    url: `${url}?${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getMerchantSetting = (page, size, searchQuery = [], orderBy, token) => {
    // let token = load("tc-sm");
    let searchArray = [];
    if (searchQuery.length > 0) {
      searchQuery.forEach((key, i) => {
        searchArray.push(`${i === 0 ? "" : "&"}${key.param}=${key.value}`);
      });
    }
    let order = `${
      orderBy.field !== ""
        ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
        : ""
    }`;
    return request({
      url: `${url}/merchant-settings?${
        searchArray.length > 0 ? searchArray.join("") : ""
      }&page=${page}&size=${size}${order}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

const getDataById = (id, token) => {
  return request({
    url: `${url}/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const deleteMerchantData = (merchantId, token) => {
  return request({
    url: `${url}/${merchantId}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const activatingMerchant = (merchantId, token) => {
  return request({
    url: `${activateUrl}/${merchantId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const merchantPanOption = (token) => {
  return request({
    url: merchantPanOptionUrl,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const categoryOption = (value, token) => {
  return request({
    url: `${categoryOptionUrl}?merchantCategoryValue=${value}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const criteriaOption = (token) => {
  return request({
    url: criteriaOptionUrl,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const createMerchant = (data, token) => {
  return request({
    url: url,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const updateMerchant = (id, data, token) => {
  return request({
    url: `${url}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getMerchantGroup = (merchantName, token) => {
  return request({
    url: `${merchantGroupUrl}?merchantGroupName=${merchantName}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const resendEmail = (email, token) => {
  return request({
    url: `/v1/users/activate-link`,
    method: "POST",
    data: {
      email
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const updateUrlCallback = (data, token) => {
    return request({
      url: `${url}/merchant-settings`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

export const MerchantManagementService = {
  getListData,
  getMerchantSetting,
  deleteMerchantData,
  activatingMerchant,
  merchantPanOption,
  categoryOption,
  criteriaOption,
  createMerchant,
  getDataById,
  updateMerchant,
  getMerchantGroup,
  getImage,
  uploadImage,
  getTemplate,
  uploadFile,
  resendEmail,
  updateUrlCallback
};
