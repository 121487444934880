/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import SearchGrid from "../../components/search-grid/index.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import useAirlines from "./useAirlines";

const AirlinesPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [orderBy, setOrderBy] = useState({
    field: "modifiedDate",
    direction: false,
  });
  const [searchValue, setSearchValue] = useState([]);
  const [airlinesData, getData] = useAirlines();
  const tableHeaders = [
    {
      text: "Airline Type",
      sort: true,
      name: "airlineType",
      search: true,
      type: "text",
      position: "left",
      searchType: "select",
      options: [
        {value: "", label: "All"},
        {value: "domestic", label: "Domestik"},
        {value: "international", label: "International"}
      ],
      index: 0,
    },
    {
      text: "Carrier Code",
      sort: true,
      name: "airlineCode",
      search: true,
      type: "text",
      position: "left",
      index: 2,
      searchType: "text",
    },
    {
      text: "Vendor Code",
      sort: true,
      name: "vendorCode",
      search: true,
      type: "text",
      position: "left",
      index: 1,
      searchType: "text",
    },
    {
      text: "Airline Name",
      sort: true,
      name: "airlineName",
      search: true,
      type: "text",
      position: "right",
      index: 0,
      searchType: 'text',
    },
    {
      text: "Status",
      sort: true,
      name: "isActive",
      searchName: "isActive",
      search: true,
      type: "text",
      position: "right",
      index: 1,
      searchType: 'select',
      options: [],
      specialFunc: (val) => (val ? "Active" : "Not Active"),
    },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-edit" aria-hidden="true"></span>,
      description: "Edit",
      action: (val) => history.push(`/airlines-form/${val.airlineId}`),
      condition: (val) => true,
    },
  ];

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Airlines",
        add: true,
        pathAdd: '/airlines-form/null',
      })
    );
  }, []);

  useEffect(() => {
    getData(page, size, searchValue, orderBy);
  }, [page, size, orderBy, searchValue]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (val) => {
    setPage(0);
    if (val.length > 0) setSearchValue(val)
    else setSearchValue([]);
  }

  return (
    <div className="container-fluid">
      <SearchGrid
        searchField={tableHeaders}
        handleSearch={handleSearch}
        value={searchValue}
      />
      <DataTables
        headers={tableHeaders}
        listData={airlinesData?.content}
        first={airlinesData?.first}
        last={airlinesData?.last}
        totalData={airlinesData?.totalElements}
        totalPage={airlinesData?.totalPages}
        empty={airlinesData?.empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default AirlinesPage;
