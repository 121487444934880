import request from "../_network/request";
const urlRefGroup = "/v1/ref-groups";
const urlRefCode = "/v1/ref-code";

const inputRefGroup = (data, token) => {
  return request({
    url: `${urlRefGroup}`,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const inputRefCode = (data, token) => {
  return request({
    url: urlRefCode,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

const editRefCode = (id, data, token) => {
  return request({
    url: `${urlRefCode}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

const deleteRefCode = (id, token) => {
  return request({
    url: `${urlRefCode}/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

const getRefCode = (page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${
    orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;

  return request({
    url: `${urlRefCode}?${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const RefCodeServices = {
  inputRefGroup,
  inputRefCode,
  editRefCode,
  deleteRefCode,
  getRefCode,
}