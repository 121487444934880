import { useState } from "react";

const MultipleSearch = ({ searchQuery, handleSearch, headers, showStatus = false, columnStatus = "status", statusActive = true }) => {
  const [field, setField] = useState("");
  const [fieldStatus, setFieldStatus] = useState("true");
  const [searchText, setSearchText] = useState("");
  const [searchFields, setSearchField] = useState(searchQuery);
  const [fieldText, setFieldText] = useState("");

  const onSearch = () => {
    if (field !== ""){
      const searchTemp = [...searchFields];
      if (showStatus && field === columnStatus) {
        searchTemp.push({ param: field, value: fieldStatus, text: fieldText });
      } else {
        searchTemp.push({ param: field, value: searchText, text: fieldText });
      }
      setSearchField(searchTemp);
      handleSearch(searchTemp);
      setSearchText("");
      setField("");
      setFieldStatus("true");
    }
  };

  const onReset = () => {
    if(searchFields.length > 0){
      setSearchField([]);
      handleSearch([]);
      setSearchText("");
      setField("");
      setFieldStatus("true");
    }
  };

  const onChangeCriteria = (e) => {
    setField(e.target.value);
    setFieldText(e.target.selectedOptions[0].text);
  };

  const deleteFilter = (i) => {
    const searchTemp = [...searchFields];
    const newSearchFields = searchTemp.filter((_, id) => id !== i);
    setSearchField(newSearchFields);
    handleSearch(newSearchFields);
  };

  const reduceOption = (optionValue) => {
    const matched = searchFields.filter((field) => field.param === optionValue);
    return matched?.length === 0;
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="form-horizontal">
            <div className="card-body">
              <div
                className="form-group row"
                // onSubmit={(e) => {
                //   e.preventDefault();
                //   onSearch();
                // }}
              >
                <div className="col-sm-3">
                  <select
                    className="form-control select2"
                    style={{ width: "100%" }}
                    // onChange={(e) => setField(e.target.value)}
                    onChange={(e) => onChangeCriteria(e)}
                    value={field}
                  >
                    <option value="">Choose Criteria</option>
                    {headers?.map((dt) => {
                      if (dt.search && reduceOption(dt.name)) {
                        return (
                          <option key={dt.name} value={dt.name}>
                            {dt.text}
                          </option>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </select>
                </div>
                <div className="col-sm-3">
                  {                    
                    showStatus && field === columnStatus ? (
                      <select
                        className="form-control select2"
                        style={{ width: "100%" }}
                        onChange={(e) => setFieldStatus(e.target.value)}
                        value={fieldStatus}
                      >
                        <option value="true" selected="selected">{statusActive ? "Active" : "Success"}</option>
                        <option value="false">{statusActive ? "Not Active" : "Failed"}</option>
                      </select>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        id="inputCriteria"
                        placeholder="Search Criteria"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    )
                  }                  
                </div>
                <div className="col-sm-3">
                  <button onClick={onSearch} className="btn btn-info">
                    Search
                  </button>
                  <button className="btn btn-info" onClick={onReset}>
                    Reset
                  </button>
                </div>
              </div>
              {searchFields.map((field, i) => (
                <div className="row" key={field.param}>
                  <div className="col-sm-12">
                    {
                      showStatus && field.param === columnStatus ?
                        <label>{`** ${field.text} contains ` + (field.value === "true" ? (statusActive ? "\"Active\"" : "\"Success\"") : (statusActive ? "\"Not Active\"" : "\"Failed\""))}</label> :
                        <label>{`** ${field.text} contains "${field.value}"`}</label>
                    }                    
                    <button
                      type="button"
                      className="btn btn-outline-danger btn-xs"
                      onClick={() => {
                        deleteFilter(i);
                      }}
                    >
                      <span className="fas fa-minus" aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleSearch;
