import { batch } from "react-redux";
import { MerchantGroupService } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import MerchantGroupTypes from "./merchant-group.types";

export const getDataGroup = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({
          type: MerchantGroupTypes.GET_MERCHANT_GROUP,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING_TABLE,
        });
      });

      const listMerchant = await MerchantGroupService.getListDataGroup(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: MerchantGroupTypes.GET_MERCHANT_GROUP_SUCCESS,
          payload: listMerchant.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    } catch (err) {
      batch(() => {
        dispatch({
          type: MerchantGroupTypes.GET_MERCHANT_GROUP_FAILURE,
          payload: err.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    }
  };
};

export const deleteMerchantGroup = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({
          type: MerchantGroupTypes.DELETE_MERCHANT_GROUP,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING,
        });
      });
      const deleting = await MerchantGroupService.deleteGroupData(
        id,
        token
      );
      batch(() => {
        dispatch({
          type: MerchantGroupTypes.DELETE_MERCHANT_GROUP_SUCCESS,
          payload: deleting.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Success delete merchant group",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: MerchantGroupTypes.DELETE_MERCHANT_GROUP_FAILURE,
          payload: error.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Failure delete merchant group",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const createMerchantGroup = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({
          type: MerchantGroupTypes.CREATE_MERCHANT_GROUP,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING,
        });
      });

      const requestCreateMerchant =
        await MerchantGroupService.createGroup(data, token);

      // if (requestCreateMerchant.statusCode === 200) {
        batch(() => {
          dispatch({
            type: MerchantGroupTypes.CREATE_MERCHANT_GROUP_SUCCESS,
            // payload: requestCreateMerchant.data,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Create new merchant group success",
              alertType: "success",
            },
          });
        });
      // } else {
      //   batch(() => {
      //     dispatch({
      //       type: MerchantGroupTypes.CREATE_MERCHANT_GROUP_FAILURE,
      //       payload: requestCreateMerchant.data,
      //     });
      //     dispatch({
      //       type: ActionsActionTypes.HIDE_LOADING,
      //     });
      //     dispatch({
      //       type: ActionsActionTypes.SHOW_ALERT,
      //       payload: {
      //         alertMessage: requestCreateMerchant.data,
      //         alertType: "error",
      //       },
      //     });
      //   });
      // }
    } catch (err) {
      batch(() => {
        dispatch({
          type: MerchantGroupTypes.CREATE_MERCHANT_GROUP_FAILURE,
          payload: err.message,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Create new merchant group failed",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const updateMerchantGroup = (id, data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({
          type: MerchantGroupTypes.UPDATE_MERCHANT_GROUP,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING,
        });
      });

      const updating = await MerchantGroupService.updateGroup(
        id,
        data,
        token
      );

      if (updating.statusCode === 200) {
        batch(() => {
          dispatch({
            type: MerchantGroupTypes.UPDATE_MERCHANT_GROUP_SUCCESS,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Update data success",
              alertType: "success",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: MerchantGroupTypes.UPDATE_MERCHANT_GROUP_FAILURE,
          });
          dispatch({
            type: ActionsActionTypes.HIDE_LOADING,
          });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: updating.data,
              alertType: "error",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: MerchantGroupTypes.UPDATE_MERCHANT_GROUP_FAILURE,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update merchant error",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const getGroupById = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;

    try {
      batch(() => {
        dispatch({
          type: MerchantGroupTypes.GET_GROUP_BY_ID,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING,
        });
      });

      const dataById = await MerchantGroupService.getDataGroupById(id, token);

      batch(() => {
        dispatch({
          type: MerchantGroupTypes.GET_GROUP_BY_ID_SUCCESS,
          payload: dataById.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: MerchantGroupTypes.GET_GROUP_BY_ID_FAILURE,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error.message,
            alertType: "error",
          },
        });
      });
    }
  };
};