import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import MultipleSearch from "../../components/multiple-search/multiple-search.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { resendMail } from "../../redux/merchat-management/merchant-management.action";
import {
  deleteUser,
  getData,
} from "../../redux/user-management/user-management.action";

import { getUserPartner } from "../../redux/partner/partner.actions";

const UserManagement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const path = useLocation();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState([]);
  const [listType, setListType] = useState("");
  const [titleHeader, setTitleHeader] = useState("");
  const [orderBy, setOrderBy] = useState({
    field: "lastModifiedDate",
    direction: false,
  });
  const { userList, first, last, totalData, totalPage, empty, errorDelete } =
    useSelector((state) => state.userManagement);
  const { errorActivate } = useSelector((state) => state.merchantManagement);
  const { user } = useSelector((state) => state.auth);

  const { userPartner } = useSelector((state) => state.userPartner);

  useEffect(() => {
    if (path.pathname === "/user-duwit") {
      setListType("DUWIT");
      setTitleHeader("User Management Duwit");
    }
    if (path.pathname === "/user-merchant") {
      setListType("MERCHANT");
      setTitleHeader("User Management Merchant");
    }
    if (path.pathname === "/user-merchant-group") {
      setListType("MERCHANT_GROUP");
      setTitleHeader("User Management Merchant Group");
    }
    if (path.pathname === "/user-switching") {
      setListType("SWITCHING");
      setTitleHeader("User Management Switching");
    }
    if (path.pathname === "/user-partner") {
      setListType("Partner");
      setTitleHeader("User Management Partner");
    }
  }, [path.pathname]);

  const columnStatus = "active";

  const tableHeaders = [
    {
      text: "Name",
      sort: true,
      name: "fullName",
      search: true,
      type: "text",
    },
    {
      text: "Email(s)",
      sort: true,
      name: "email",
      search: true,
      type: "text",
    },
    {
      text: path.pathname.includes("merchant")
        ? "Merchant"
        : path.pathname.includes("partner")
        ? "Partner"
        : "Role",
      sort: true,
      name: path.pathname.includes("merchant")
        ? path.pathname.includes("group")
          ? "merchantGroup.merchantGroupName"
          : "merchant.merchantName"
        : path.pathname.includes("partner")
        ? "roleName"
        : "role.roleName",
      search: true,
      type: "text",
      // specialFunc: (val) => val?.[0],
    },
    {
      text: "Status",
      sort: true,
      name: "active",
      search: true,
      type: "text",
      specialFunc: (val) => (val ? "Active" : "Not Active"),
    },
    {
      text: "Modified Date",
      sort: true,
      name: "lastModifiedDate",
      search: false,
      type: "date",
    },
  ];

  const actionButton =
    user?.role.roleName === "DUWIT" || user?.role.roleName === "MERCHANT_GROUP"
      ? [
          {
            icon: <span className="fas fa-edit" aria-hidden="true"></span>,
            description: "Edit",
            action: (val) => {
              if (path.pathname.includes("merchant")) {
                if (path.pathname.includes("group")) {
                  history.push(`/edit-user-merchant-group/${val.userId}`);
                } else {
                  history.push(`/edit-user-merchant/${val.userId}`);
                }
              }
              if (path.pathname.includes("duwit"))
                history.push(`/edit-user-duwit/${val.userId}`);
              if (path.pathname.includes("switching"))
                history.push(`/edit-user-switching/${val.userId}`);
            },
            condition: (val) => true,
          },
          {
            icon: <span className="fas fa-trash" aria-hidden="true"></span>,
            description: "Delete",
            action: (val) => {
              if (window.confirm("Are you sure want to delete?")) {
                dispatch(deleteUser(val.userId));
              }
            },
            condition: (val) => true,
          },
          {
            icon: <span className="fas fa-history" aria-hidden="true"></span>,
            description: "View Login History",
            action: (val) => {
              if (path.pathname !== "/user-merchant") {
                history.push(`/user-history${path.pathname}/${val.userId}`);
              } else {
                history.push(
                  `/user-history/${val.merchant.merchantName}/${val.userId}`
                );
              }
            },
            condition: (val) => true,
          },
          {
            icon: <span className="fas fa-envelope" aria-hidden="true"></span>,
            description: "Resend Activation Email",
            action: (val) => dispatch(resendMail(val.email)),
            condition: (val) => !val.activate,
          },
        ]
      : [
          {
            icon: <span className="fas fa-edit" aria-hidden="true"></span>,
            description: "Edit",
            action: (val) => {
              if (path.pathname.includes("merchant")) {
                if (path.pathname.includes("group")) {
                  history.push(`/edit-user-merchant-group/${val.userId}`);
                } else {
                  history.push(`/edit-user-merchant/${val.userId}`);
                }
              }
              if (path.pathname.includes("duwit"))
                history.push(`/edit-user-duwit/${val.userId}`);
              if (path.pathname.includes("switching"))
                history.push(`/edit-user-switching/${val.userId}`);
            },
            condition: (val) => true,
          },
          {
            icon: <span className="fas fa-history" aria-hidden="true"></span>,
            description: "View Login History",
            action: (val) => {
              history.push(`/user-history${path.pathname}/${val.userId}`);
            },
            condition: (val) => true,
          },
        ];
  useEffect(() => {
    const pathAdd = () => {
      if (path.pathname === "/user-duwit") return "/add-user-duwit";
      if (path.pathname === "/user-merchant") return "/add-user-merchant";
      if (path.pathname === "/user-merchant-group")
        return "/add-user-merchant-group";
      if (path.pathname === "/user-switching") return "/add-user-switching";
      if (path.pathname === "/user-partner") return "/add-user-partner";
    };

    dispatch(
      setHeaderButton({
        add: true,
        pathAdd: pathAdd(),
        titleHeader: titleHeader,
      })
    );
  }, [dispatch, path.pathname, titleHeader]);

  useEffect(() => {
    if (listType !== "" && (errorActivate === false || errorActivate === ""))
      dispatch(getData(listType, page, size, searchQuery, orderBy));
  }, [
    dispatch,
    page,
    size,
    searchQuery,
    orderBy,
    listType,
    errorDelete,
    errorActivate,
  ]);

  useEffect(() => {
    if (path.pathname === "/user-partner")
      dispatch(getUserPartner("PARTNER", searchQuery, page, size));
  }, [dispatch, page, size, searchQuery, path.pathname]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  console.log(searchQuery);

  return (
    <div className="container-fluid">
      <MultipleSearch
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        headers={tableHeaders}
        showStatus={true}
        columnStatus={columnStatus}
      />
      <DataTables
        headers={tableHeaders}
        listData={path.pathname === "/user-partner" ? userPartner : userList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default UserManagement;
