import { batch } from "react-redux";
import { ImageServices } from "../../_services";
import { NotificationServices } from "../../_services/notification.services";
import ActionsActionTypes from "../actions/actions.types";
import NotificationTypes from "./notification.types";

export const getDataNotification = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: NotificationTypes.GET_NOTIFICATIONS });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const consumerRequest = await NotificationServices.getNotificationList(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: NotificationTypes.GET_NOTIFICATIONS_SUCCESS,
          payload: consumerRequest.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: NotificationTypes.GET_NOTIFICATIONS_FAILURE });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    }
  };
};

export const getDetailNotification = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: NotificationTypes.GET_NOTIFICATION_DETAIL });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const notificationDetail =
        await NotificationServices.getNotificationDetail(id, token);

      batch(() => {
        dispatch({
          type: NotificationTypes.GET_NOTIFICATION_DETAIL_SUCCESS,
          payload: notificationDetail.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: NotificationTypes.GET_NOTIFICATION_DETAIL_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const createNotification = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: NotificationTypes.CREATE_NOTIFICATION });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const uploadImage = await ImageServices.uploadImage(
        data.imageNotif,
        "notification",
        token
      );
      data.imageNotif = uploadImage.data.fullPath;
      const newNotification = await NotificationServices.createNotification(
        data,
        token
      );

      batch(() => {
        dispatch({
          type: NotificationTypes.CREATE_NOTIFICATION_SUCCESS,
          payload: newNotification,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Add new notification success",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: NotificationTypes.CREATE_NOTIFICATION_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Add new notification failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const updateNotification = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const id = data.notificationId;
    try {
      batch(() => {
        dispatch({ type: NotificationTypes.UPDATE_NOTIFICATION });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });
      delete data.notificationId;
      delete data.createdDate;
      delete data.isDeleted;
      delete data.modifiedDate;

      if (typeof data.imageNotification !== "string") {
        const uploadImage = await ImageServices.uploadImage(
          data.imageNotif,
          "notification",
          token
        );
        data.imageNotif = uploadImage.data.fullPath;
      }
      const update = await NotificationServices.updateNotification(
        id,
        data,
        token
      );

      batch(() => {
        dispatch({
          type: NotificationTypes.UPDATE_NOTIFICATION_SUCCESS,
          payload: update,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update notification success",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: NotificationTypes.UPDATE_NOTIFICATION_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update notification failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const deleteNotification = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: NotificationTypes.DELETE_NOTIFICATION });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const deleteNotification = await NotificationServices.deleteNotification(
        id,
        token
      );

      batch(() => {
        dispatch({
          type: NotificationTypes.DELETE_NOTIFICATION_SUCCESS,
          payload: deleteNotification,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete notification success",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: NotificationTypes.DELETE_NOTIFICATION_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete notification failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const defaultNotification = () => async (dispatch) =>
  dispatch({ type: NotificationTypes.DEFAULT_NOTIFICATION_STATE });
