import request from "../_network/request";

const BIReportUrl = "/v1/bi-report";
const getReportTypeUrl = "/v1/bi-report-type";
const uploadReportUrl = "/v1/bi-report/upload-report";

const getBiReport = (page, size, searchQuery = [], orderBy, token) => {
    let searchArray = [];
    if (searchQuery.length > 0) {
        searchQuery.forEach((key, i) => {
            searchArray.push(`${i === 0 ? "" : "&"}${key.param}=${key.value}`);
        });
    }
    let order = `${
        orderBy.field !== ""
            ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
            : ""
    }`;
    return request({
        url: `${BIReportUrl}?${
            searchArray.length > 0 ? searchArray.join("") : ""
        }&page=${page}&size=${size}${order}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getBiReportById = (id, page, size, searchQuery = [], orderBy, token) => {
    let searchArray = [];
    if (searchQuery.length > 0) {
        searchQuery.forEach((key, i) => {
            searchArray.push(`${i === 0 ? "" : "&"}${key.param}=${key.value}`);
        });
    }
    let order = `${
        orderBy.field !== ""
            ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
            : ""
    }`;
    return request({
        url: `${BIReportUrl}/${id}/details?${
            searchArray.length > 0 ? searchArray.join("") : ""
        }&page=${page}&size=${size}${order}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const uploadFileBI = (id, token) => {
    return request({
        url: `${BIReportUrl}/send-to-bi?biReportId=${id}`,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

const getTemplate = (token, url) => {
    return request({
        url: url,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/octet-stream",
        },
    });
};

const getReportTypes = (token) => {
    return request({
        url: getReportTypeUrl,
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

// const uploadReport = (file, token) => {
//     let formdt = new FormData();
//     formdt.append("file", file);
//     return request({
//       url: uploadReportUrl,
//       method: "POST",
//       data: formdt,
//       headers: {
//         Authorization: `Bearer ${token}`
//       }
//     });
//   };

const uploadReport = (data, token) => {
    return request({
        url: uploadReportUrl,
        method: "POST",
        data: data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const BIReportServices = {
    getBiReport,
    getBiReportById,
    uploadFileBI,
    getTemplate,
    getReportTypes,
    uploadReport,
};
