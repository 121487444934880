import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory} from "react-router-dom";
import DataTables from "../../components/datatables/datatables.component";
import MultipleSearch from "../../components/multiple-search/multiple-search.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import {
  getBiReportList,
  uploadToBI,
  defaultUploadState,
} from "../../redux/bi-report/bi-report.action";

const BIReport = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState([]);
  const [orderBy, setOrderBy] = useState({
    field: "createdDate",
    direction: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const tableHeaders = [
    {
      text: "File Date",
      sort: true,
      name: "createdDate",
      search: true,
      type: "date",
    },
    {
      text: "Total File(s)",
      sort: true,
      name: "totalFiles",
      search: false,
      type: "text",
    },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-search" aria-hidden="true"></span>,
      description: "View",
      action: (val) => history.push(`/bi-report-files/${val.biReportId}`),
      condition: (val) => true,
    },
    {
      icon: <span className="fas fa-file-upload" aria-hidden="true"></span>,
      description: "Upload File",
      action: (val) => {
        dispatch(uploadToBI(val.biReportId));
      },
      condition: (val) => true,
    },
  ];

  const {
    biReportList,
    first,
    last,
    totalData,
    totalPage,
    empty,
    uploadStatus,
  } = useSelector((state) => state.biReport);

  useEffect(() => {
    dispatch(getBiReportList(page, size, searchQuery, orderBy));
  }, [page, searchQuery, orderBy, dispatch, size]);

  useEffect(() => {
    if(uploadStatus === "uploaded"){
      dispatch(
        getBiReportList(0, 5, [], {
          field: "createdDate",
          direction: false,
        })
      );
    }
  }, [dispatch, uploadStatus]);

  useEffect(() => {
    // set action button
    dispatch(
      setHeaderButton({
        titleHeader: "BI Report",
      })
    );
    dispatch(defaultUploadState());

  }, [dispatch]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div className="container-fluid">
      <MultipleSearch
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        headers={tableHeaders}
      />
      <DataTables
        headers={tableHeaders}
        listData={biReportList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default BIReport;
