const ConsumerTypes = {
  UPDATE_DATA_CONSUMER: "UPDATE_DATA_CONSUMER",
  UPDATE_DATA_CONSUMER_SUCCESS: "UPDATE_DATA_CONSUMER_SUCCESS",
  UPDATE_DATA_CONSUMER_FAILURE: "UPDATE_DATA_CONSUMER_FAILURE",
  GET_DATA_CONSUMER: "GET_DATA_CONSUMER",
  GET_DATA_CONSUMER_SUCCESS: "GET_DATA_CONSUMER_SUCCESS",
  GET_DATA_CONSUMER_FAILURE: "GET_DATA_CONSUMER_FAILURE",
  GET_CONSUMER_DETAIL: "GET_CONSUMER_DETAIL",
  GET_CONSUMER_DETAIL_SUCCESS: "GET_CONSUMER_DETAIL_SUCCESS",
  GET_CONSUMER_DETAIL_FAILURE: "GET_CONSUMER_DETAIL_FAILURE",
  GET_CONSUMER_MASTER_DATA: "GET_CONSUMER_MASTER_DATA",
  GET_CONSUMER_MASTER_DATA_SUCCESS: "GET_CONSUMER_MASTER_DATA_SUCCESS",
  GET_CONSUMER_MASTER_DATA_FAILURE: "GET_CONSUMER_MASTER_DATA_FAILURE",
  GET_CONSUMER_CITIES: "GET_CONSUMER_CITIES",
  GET_CONSUMER_CITIES_SUCCESS: "GET_CONSUMER_CITIES_SUCCESS",
  GET_CONSUMER_CITIES_FAILURE: "GET_CONSUMER_CITIES_FAILURE",
  DEFAULT_CONSUMER_STATE: "DEFAULT_CONSUMER_STATE",
}

export default ConsumerTypes;