import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { getDataDisbursement } from "../../redux/disbursement/disbursement.actions";

const DisbursementPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [orderBy, setOrderBy] = useState({
    field: "createdDate",
    direction: false,
  });
  const { disbursementList, first, last, totalData, totalPage, empty } =
    useSelector((state) => state.dataDisbursement);

  useEffect(() => {
    dispatch(setHeaderButton({ titleHeader: "Disbursement" }));
    dispatch(getDataDisbursement(page, size, [], orderBy));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Search</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Disbursement No</label>
                </div>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="trxNo" />
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-2">
                  <label>Start Disb. Date</label>
                </div>
                <div className="col-sm-3">
                  <input type="date" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Disbursement Status</label>
                </div>
                <div className="col-sm-3">
                  <div className="select2-primary">
                    <select className="select2" multiple="multiple" data-dropdown-css-className="select2-primary" style={{ width: "100%" }}>
                      <option>PENDING</option>
                      <option>PROCESSING</option>
                      <option>SUCCESS</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-2">
                  <label>End Disb. Date</label>
                </div>
                <div className="col-sm-3">
                  <input type="date" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-10" style={{ textAlign: "center" }}>
                  <button type="submit" className="btn btn-primary">
                    <i className="fas fa-search"></i>
                    Search
                  </button>
                  <button type="submit" className="btn btn-info">
                    <i className="fas fa-ban"></i>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <table id="example1" className="table table-bordered table-striped">
                <thead style={{ backgroundColor: "#007bff" }}>
                  <tr>
                    <strong><i className="fas fa-search"></i>View</strong>
                    <i className="mr-3"></i>
                  </tr>
                  <tr>
                    <th className="no-sort"></th>
                    <th>No</th>
                    <th>Disbursement No</th>
                    <th>Disbursement Date</th>
                    <th>Disbursement Status</th>
                    <th>Total(Rp)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a href="disbursement_view.html">
                        <button type="button" className="btn btn-default btn-xs">
                          <span className="fas fa-search" aria-hidden="true"></span>
                        </button>
                      </a>
                    </td>
                    <td>1</td>
                    <td>DISB-202110292830-1992</td>
                    <td>2021-04-01 10:30:21</td>
                    <td>PENDING</td>
                    <td>18.000,00</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="disbursement_view.html">
                        <button type="button" className="btn btn-default btn-xs">
                          <span className="fas fa-search" aria-hidden="true"></span>
                        </button>
                      </a>
                    </td>
                    <td>2</td>
                    <td>DISB-202110292830-1992</td>
                    <td>2021-04-01 10:30:21</td>
                    <td>PAID</td>
                    <td>18.000,00</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="disbursement_view.html">
                        <button type="button" className="btn btn-default btn-xs">
                          <span className="fas fa-search" aria-hidden="true"></span>
                        </button>
                      </a>
                    </td>
                    <td>3</td>
                    <td>DISB-202110292830-1992</td>
                    <td>2021-04-01 10:30:21</td>
                    <td>PROCESSING</td>
                    <td>100.000,00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisbursementPage;