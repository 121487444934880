import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { getConsumerTransactionDetail } from "../../redux/consumer-transaction/consumer-transaction.action";

const ConsumerTransactionForm = () => {
  const dispatch = useDispatch();
  const { consumerTransactionDetail } = useSelector(state => state.dataConsumerTransaction);
  const params = useParams();

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "View Transaction",
        isChild: true,
      })
    );
    dispatch(getConsumerTransactionDetail(params.id));
  }, []);
  
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Transaction Info</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Transaction ID</label>
                </div>
                <div className="col-sm-6">INV83423473287</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Transaction Date</label>
                </div>
                <div className="col-sm-6">2021-04-01 10:30:21</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Transaction Status</label>
                </div>
                <div className="col-sm-6">COMPLETE</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Payment Status</label>
                </div>
                <div className="col-sm-6">PAID</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Consumer</label>
                </div>
                <div className="col-sm-3">
                  <a href="consumer_edit.html">john.doe@gmail.com</a>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Total</label>
                </div>
                <div className="col-sm-6">Rp 51.500</div>
              </div>
            </div>
          </div>
          <div className="card card-outline card-primary collapsed-card">
            <div className="card-header">
              <h3 className="card-title">Invoice Info</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Product</label>
                </div>
                <div className="col-sm-6">Pulsa</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Sub Product</label>
                </div>
                <div className="col-sm-6">Indosat 5000</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Phone No</label>
                </div>
                <div className="col-sm-6">081582827277</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Serial Number</label>
                </div>
                <div className="col-sm-6">0233838388383829999</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Price</label>
                </div>
                <div className="col-sm-6">Rp 51.500</div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Payment Method</label>
                </div>
                <div className="col-sm-6">Duwit Cash</div>
              </div>
            </div>
          </div>
          <div className="card card-outline card-primary collapsed-card">
            <div className="card-header">
              <h3 className="card-title">Transaction Log</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <table
                id="example1"
                className="table table-bordered table-striped"
              >
                <thead style={{ backgroundColor: "#007bff" }}>
                  <tr>
                    <th>No</th>
                    <th>Activity Type</th>
                    <th>Activity Date</th>
                    <th>Device ID</th>
                    <th>Balance Before</th>
                    <th>Balance After</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Create Transaction</td>
                    <td>2021-04-01 10:30:21</td>
                    <td>android-194838838</td>
                    <td>200.000</td>
                    <td>200.000</td>
                    <td>SQL Query</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Payment</td>
                    <td>2021-04-01 10:30:21</td>
                    <td>android-194838838</td>
                    <td>200.000</td>
                    <td>148.500</td>
                    <td>SQL Query</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Processing</td>
                    <td>2021-04-01 10:30:21</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Response 3rd Party</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Complete</td>
                    <td>2021-04-01 10:30:21</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Response 3rd Party</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerTransactionForm;
