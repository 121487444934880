import OperatorTypes from "./operator.types";

const INITIAL_STATE = {
  operatorList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  error: false,

  operatorDetail: null,

  sendingStatus: "",
};

const OperatorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OperatorTypes.GET_OPERATORS:
      return {
        ...state,
        error: false,
      };
    case OperatorTypes.GET_OPERATORS_SUCCESS:
      return {
        ...state,
        operatorList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case OperatorTypes.GET_OPERATORS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case OperatorTypes.GET_OPERATOR_DETAIL:
      return {
        ...state,
        operatorDetail: null,
      };
    case OperatorTypes.GET_OPERATOR_DETAIL_SUCCESS:
      return {
        ...state,
        operatorDetail: action.payload,
      };
    case OperatorTypes.GET_OPERATOR_DETAIL_FAILURE:
      return {
        ...state,
        operatorDetail: null,
      };
    case OperatorTypes.CREATE_OPERATOR:
      return {
        ...state,
        sendingStatus: "send",
      };
    case OperatorTypes.CREATE_OPERATOR_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case OperatorTypes.CREATE_OPERATOR_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case OperatorTypes.UPDATE_OPERATOR:
      return {
        ...state,
        sendingStatus: "send",
      };
    case OperatorTypes.UPDATE_OPERATOR_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case OperatorTypes.UPDATE_OPERATOR_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case OperatorTypes.DELETE_OPERATOR:
      return {
        ...state,
        sendingStatus: "send",
      };
    case OperatorTypes.DELETE_OPERATOR_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      };
    case OperatorTypes.DELETE_OPERATOR_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      };
    case OperatorTypes.DEFAULT_OPERATOR_STATE:
      return {
        ...state,
        sendingStatus: "",
        operatorDetail: null,
      };
    default:
      return state;
  }
};

export default OperatorReducer;
