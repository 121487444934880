import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Select, { components } from "react-select";
import { useLocation } from "react-router-dom";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import {
  getDataList,
  getMerchantGroup,
} from "../../redux/merchat-management/merchant-management.action";
import {
  createUser,
  editUser,
  getRoles,
} from "../../redux/user-management/user-management.action";

import { getPartnerList, createUserPartner } from "../../redux/partner/partner.actions";

const AddDuwit = () => {
  const params = useParams();
  const path = useLocation();
  const dispatch = useDispatch();
  const { roles, createUserSuccess, userList } = useSelector(
    (state) => state.userManagement
  );
  const { merchantList, merchantGroupList } = useSelector(
    (state) => state.merchantManagement
  );
  const { user } = useSelector((state) => state.auth);
  const {
    partnerList,
  } = useSelector((state) => state.partnerList);

//   const { data, status }  = useSelector((state) => state.createPartner)
 
  const [formUser, setFormUser] = useState({
    username: "",
    email: "",
    role: {},
    merchant: {
      merchantId: "",
    },
    merchantGroup: {
      merchantGroupId: "",
    },
    active: "",
  });

  const[formPartner, setFormPartner] = useState({
    email: "",
    name:"",
    isActive:true,
    partnerId:"",
    username:""
  })
  const [options, setOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([])
  useEffect(() => {
    let roleName;
    let formTemp = { ...formUser };
    if (params.id) {
      const matched = userList.filter((val) => val.userId === params.id);
      formTemp = matched[0];
    }
    if (path.pathname.includes("merchant")) {
      if (path.pathname.includes("group")) {
        if (user.role.roleName === "MERCHANT_GROUP") {
          formTemp.merchantGroup.merchantGroupId =
            user.merchantGroup.merchantGroupId;
        }
        if (params.id) {
          dispatch(getMerchantGroup(formTemp.merchantGroup.merchantGroupName));
        } else {
          dispatch(getMerchantGroup(""));
        }
        roleName = "MERCHANT_GROUP";
      } else {
        let filter;
        if (user.merchant?.merchantId) {
          filter = [
            {
              param: "merchantId",
              value: user.merchant ? user.merchant.merchantId : "",
            },
            { param: "active", value: "true" },
          ];
        } else {
          if (params.id) {
            filter = [
              {
                param: "merchantId",
                value: formTemp?.merchant ? formTemp.merchant.merchantId : "",
              },
              { param: "active", value: "true" },
            ];
          } else {
            filter = [{ param: "active", value: "true" }];
          }
        }
        dispatch(
          getDataList(0, 20, filter, {
            field: "merchantName",
            direction: true,
          })
        );
        roleName = "MERCHANT";
        if (params.id === undefined) {
          formTemp.merchant = {
            merchantId: "",
          };
          formTemp.merchant.merchantId = user.merchant
            ? user.merchant.merchantId
            : "";
        }
      }
    }
    if (path.pathname.includes("duwit")) roleName = "DUWIT";
    if (path.pathname.includes("switching")) roleName = "SWITCHING";

    const findMerchant = roles.filter((value) => value.roleName === roleName);
    if (findMerchant.length > 0 && formTemp) {
      formTemp.role = {
        roleId: findMerchant[0].roleId,
        roleName: findMerchant[0].roleName,
      };
    }
    setFormUser(formTemp);
  }, [roles, user, params.id]);

  useEffect(() => {
    dispatch(getPartnerList("","","","",""))
  }, []);

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  useEffect(() => {
    if (createUserSuccess === "success") window.history.back();
  }, [createUserSuccess]);

  useEffect(() => {
    let optionTemp = [];
    if (path.pathname.includes("group")) {
      merchantGroupList?.forEach((mg) => {
        optionTemp.push({
          value: mg.merchantGroupId,
          label: mg.merchantGroupName,
        });
      });
    } else {
      merchantList?.forEach((mg) => {
        optionTemp.push({
          value: mg.merchantId,
          label: mg.merchantName,
        });
      });
    }
    setOptions(optionTemp);
  }, [merchantList, merchantGroupList]);

  useEffect(() => {
    let optionTemp = [];
    if (path.pathname.includes("partner")) {
      partnerList?.forEach((mg) => {
        optionTemp.push({
          value: mg.partnerId,
          label: mg.businessName,
        });
      });
    }
    setPartnerOptions(optionTemp);
  }, [partnerList]);

  useEffect(() => {
    dispatch(
      setHeaderButton({
        buttonWithFunction: true,
        buttonFunction: path.pathname.includes("partner") ? onSave2 : onSave,
        buttonText: "Save",
        buttonIcon: <i className="fas fa-save"></i>,
        titleHeader: params.id ? "Edit User" : "Add User",
        isChild: true,
      })
    );
  }, [dispatch, formUser,formPartner]);

  const onSave = () => {
    if (params.id) {
      if (window.confirm("Are you sure want to save edit?")) {
        dispatch(editUser(params.id, formUser));
      }
    } else {
      if (formUser?.merchantGroup?.merchantGroupId === "")
        delete formUser.merchantGroup;
      dispatch(createUser(formUser));
    }
    
  };

  const onSave2 = () => {
    dispatch(createUserPartner(formPartner))
    window.history.back()
  }

  const handleChange = (e) => {
    let formTemp = { ...formUser };
    let tempPartner = {...formPartner};
    formTemp[e.target.name] = e.target.value;
    tempPartner[e.target.name] = e.target.value
    tempPartner.username = e.target.value
    setFormUser(formTemp);
    setFormPartner(tempPartner)
  };

  const handleChangeSelect = (e) => {
    let formTemp = { ...formUser };
    let tempPartner = {...formPartner};
    formTemp.active = e.target.checked;
    tempPartner.isActive = e.target.checked
    setFormUser(formTemp);
    setFormPartner(tempPartner);
  };

  const generateSelectVal = (val, options) => {
    if (options) {
      const selected = options.filter((option) => option.value === val);
      return selected[0];
    }
  };

  const handleSelectChange = (e, name) => {
    let newForm = { ...formUser };
    if (name === "merchantId") {
      newForm.merchant = {
        merchantId: "",
      };
      newForm.merchant[name] = e === null ? null : e.value;
    } else {
      newForm.merchantGroup = {
        merchantGroupId: "",
      };
      newForm.merchantGroup[name] = e === null ? null : e.value;
    }
    setFormUser(newForm);
  };

  const handleSelectPartner = (e,name) => {
    let newForm = {...formPartner};
    newForm[name] = e === null ? null : e.value;
    setFormPartner(newForm)
  }

  

  const getFilterMerchant = (e) => {
    dispatch(
      getDataList(
        0,
        20,
        [
          { param: "merchantName", value: e },
          { param: "active", value: "true" },
        ],
        {
          field: "merchantName",
          direction: true,
        }
      )
    );
  };

  const getFilterMerchantGroup = (e) => {
    dispatch(getMerchantGroup(e));
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-sm-12">
          <div className="card card-primary card-outline card-tabs">
            <div className="card-header p-0 pt-1 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-three-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-three-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-home"
                    role="tab"
                    aria-controls="custom-tabs-three-home"
                    aria-selected="true"
                  >
                    Info User
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-three-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-three-home"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-home-tab"
                >
                  <div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputName">Name*</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          name={path.pathname.includes("partner") ? "name" : "fullName"}
                          placeholder="Name"
                          value={path.pathname.includes("partner") ? formPartner.name : formUser?.fullName}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputEmail">Email*</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email"
                          value={path.pathname.includes("partner") ? formPartner.email : formUser?.email}
                          onChange={handleChange}
                          disabled={params.id ? true : false}
                          required
                        />
                      </div>
                    </div>
                    {path.pathname.includes("partner") &&(
                      <>
                      <div className="form-group row">
                      <div className="col-sm-2">
                        <label>
                          {path.pathname.includes("partner")
                            ? "Partner"
                            : ""}
                          *
                        </label>
                      </div>
                      <div className="col-sm-6">
                        
                        <Select
                          isSearchable={true}
                          isClearable={true}
                          isLoading={false}
                          onInputChange={(e) => {
                            if (e !== "") {
                              if (path.pathname.includes("group")) {
                                getFilterMerchantGroup(e);
                              } else if (path.pathname.includes("partner")) {
                                console.log("partner");
                              } else {
                                getFilterMerchant(e);
                              }
                            }
                          }}
                          value={ path.pathname.includes("partner") ? 
                            formPartner?.partnerId ? generateSelectVal(formPartner.partnerId,options) : 
                            (formUser?.merchant
                              ? generateSelectVal(
                                  path.pathname.includes("group")
                                    ? formUser.merchantGroup.merchantGroupId
                                    : formUser.merchant?.merchantId,
                                  options
                                )
                              : null) : null
                              
                          }
                          onChange={(e) => {
                            if (path.pathname.includes("group")) {
                              handleSelectChange(e, "merchantGroupId");
                            } else if (path.pathname.includes("partner")) {
                              
                              handleSelectPartner(e,"partnerId")
                            } else {
                              handleSelectChange(e, "merchantId");
                            }
                          }}
                          options={path.pathname.includes("partner") ? partnerOptions : options}
                          isDisabled={
                            user.role.roleName === "MERCHANT" || params.id
                              ? true
                              : user.role.roleName === "MERCHANT_GROUP"
                              ? path.pathname.includes("partner")
                              : false
                            // if(user.role.roleName === "MERCHANT"){
                            //   return true
                            // } else if(user.role.roleName === "MERCHANT_GROUP") {
                            //   return path.pathname.includes("group");
                            // } else {
                            //   return false
                            // }
                            // }
                            // user.merchant || params.id ? true : false
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Status</label>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="isActive"
                            checked={formPartner.isActive}
                            onChange={handleChangeSelect}
                          />
                          <label className="form-check-label">Active</label>
                        </div>
                      </div>
                    </div>
                    </>                 
                    )
                    }
                    {path.pathname.includes("merchant") && (
                      <div className="form-group row">
                        <div className="col-sm-2">
                          <label>
                            {path.pathname.includes("group")
                              ? "Merchant Group"
                              : "Merchant"}{" "}
                            *
                          </label>
                        </div>
                        <div className="col-sm-6">
                          <Select
                            isSearchable={true}
                            isClearable={true}
                            isLoading={false}
                            onInputChange={(e) => {
                              if (e !== "") {
                                if (path.pathname.includes("group")) {
                                  getFilterMerchantGroup(e);
                                } else {
                                  getFilterMerchant(e);
                                }
                              }
                            }}
                            value={
                              formUser?.merchant
                                ? generateSelectVal(
                                    path.pathname.includes("group")
                                      ? formUser.merchantGroup.merchantGroupId
                                      : formUser.merchant?.merchantId,
                                    options
                                  )
                                : null
                            }
                            onChange={(e) => {
                              if (path.pathname.includes("group")) {
                                handleSelectChange(e, "merchantGroupId");
                              } else {
                                handleSelectChange(e, "merchantId");
                              }
                            }}
                            options={options}
                            isDisabled={
                              user.role.roleName === "MERCHANT" || params.id
                                ? true
                                : user.role.roleName === "MERCHANT_GROUP"
                                ? path.pathname.includes("group")
                                : false
                              // if(user.role.roleName === "MERCHANT"){
                              //   return true
                              // } else if(user.role.roleName === "MERCHANT_GROUP") {
                              //   return path.pathname.includes("group");
                              // } else {
                              //   return false
                              // }
                              // }
                              // user.merchant || params.id ? true : false
                            }
                          />
                        </div>
                      </div>
                    )}
                    {/* <div className="form-group row">
                        <div className="col-sm-2">
                          <label for="inputRole">Role*</label>
                        </div>
                        <div className="col-sm-6">
                          <select
                            className="form-control select2"
                            name="role"
                            onChange={handleChangeSelect}
                            style={{ width: "100%" }}
                            value={JSON.stringify(formUser?.role)}
                            disabled={params.id ? true : false}
                            required
                          >
                            <option key={100} value="">
                              Select Role
                            </option>
                            {roles.map((role) => (
                              <option
                                key={role.roleId}
                                value={JSON.stringify({
                                  roleId: role.roleId,
                                  roleName: role.roleName,
                                })}
                              >
                                {role.roleName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div> */}
                    {params.id && (
                      <div className="form-group row">
                        <div className="col-sm-2">
                          <label htmlFor="inputStatus">Status</label>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-check">
                            <input
                              value="active"
                              checked={formUser?.active}
                              className="form-check-input"
                              type="checkbox"
                              onChange={handleChangeSelect}
                            />
                            <label className="form-check-label">Active</label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDuwit;
