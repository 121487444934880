import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../components/input-field/input-field.component";
import {
    setHeaderButton,
    showAlert
} from "../../redux/actions/actions.actions";
import {
    getDataById,
    updateUrlCallback
} from "../../redux/merchat-management/merchant-management.action";
import { useParams } from "react-router-dom";

const MerchantSettingForm = () => {
    const params = useParams();

    const dispatch = useDispatch();
    const {
        updateUrlCallbackSuccess,
        dataById,
    } = useSelector((state) => state.merchantManagement);
    const {user } = useSelector((state) => state.auth);
    const [managementForm, setManagementForm] = useState({
        merchantId: "",
        urlCallbackQris: "",
    });
    const [name, setName] = useState({
        imageSiup: "",
        imageNpwp: "",
        imageIdentity: "",
    });

    const onSaveUrlCallback = useCallback(() => {
        if (window.confirm("Are you sure want to save url?")) {
            if (user.role.roleName === "MERCHANT_GROUP" &&  params.id) {
                dispatch(updateUrlCallback(params.id, managementForm))
            } if (user.role.roleName === "MERCHANT" && (user.merchant.id ||user.merchant.merchantId) ){
                const id = user.merchant.id || user.merchant.merchantId;
                dispatch(updateUrlCallback(id, managementForm))
            } else {
                return;
            }
        }
      }, [managementForm]);

    useEffect(() => {
        if (user.role.roleName === "MERCHANT_GROUP" &&  params.id) {
            dispatch(getDataById(params.id))
        } if (user.role.roleName === "MERCHANT" && (user.merchant.id ||user.merchant.merchantId) ){
            const id = user.merchant.id || user.merchant.merchantId;
            dispatch(getDataById(id))
        } else {
            return;
        }
    }, [dispatch]);


    useEffect(() => {
        if (dataById) setManagementForm({merchantId: dataById.id, urlCallbackQris: dataById.urlCallbackQris});
    }, [dataById]);

    useEffect(() => {
        dispatch(
            setHeaderButton({
                buttonWithFunction: true,
                buttonFunction: onSaveUrlCallback,
                buttonText: "Save",
                buttonIcon: <i className="fas fa-save"></i>,
                titleHeader: "Merchant Settings",
                isChild: params.id,
            })
        );
    }, [dispatch, onSaveUrlCallback]);


    useEffect(() => {
        if (updateUrlCallbackSuccess === "success") {
            if (params.id) window.history.back();
        }
    }, [updateUrlCallbackSuccess]);

    const handleChange = async (e) => {
        let formTemp = { ...managementForm };
        let imageNameTemp = { ...name };
        if (e.target.type === "file") {
            if (e.target.files[0].size > 5242880) {
                dispatch(showAlert("error", "Max file size allowed 5MB!"));
            } else {
                formTemp[e.target.name] = e.target.files[0];
                imageNameTemp[e.target.name] = e.target.files[0].name;
                setName(imageNameTemp);
            }
        } else {
            formTemp[e.target.name] = e.target.value;
        }
        setManagementForm(formTemp);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-sm-12">
                    <div className="card card-primary card-outline card-tabs">
                        <div className="card-header p-0 pt-1 border-bottom-0">
                            <ul
                                className="nav nav-tabs"
                                id="custom-tabs-three-tab"
                                role="tablist"
                            >
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        id="custom-tabs-three-url-qris-tab"
                                        data-toggle="pill"
                                        href="#custom-tabs-three-url-qris"
                                        role="tab"
                                        aria-controls="custom-tabs-three-url-qris"
                                        aria-selected="true"
                                    >
                                        Settings
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div
                                className="tab-content"
                                id="custom-tabs-three-tabContent"
                            >
                                <div
                                    className="tab-pane fade show active"
                                    id="custom-tabs-three-url-qris"
                                    role="tabpanel"
                                    aria-labelledby="custom-tabs-three-url-qris-tab"
                                >
                                    <div className="form-horizontal">
                                        <InputField
                                            type="text"
                                            label="URL Callback  Payment QRIS"
                                            name="urlCallbackQris"
                                            value={
                                                managementForm.urlCallbackQris
                                            }
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MerchantSettingForm;
