import ParameterTypes from "./parameter.types";

const INITIAL_STATE = {
  parameterList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  error: false,

  parameterDetail: null,
  sendingStatus: "",
};

const ParameterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ParameterTypes.GET_DATA_PARAMETER:
      return {
        ...state,
        error: false,
      };
    case ParameterTypes.GET_DATA_PARAMETER_SUCCESS:
      return {
        ...state,
        parameterList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case ParameterTypes.GET_DATA_PARAMETER_FAILURE:
      return {
        ...state,
        error: true,
      };
    case ParameterTypes.GET_DETAIL_PARAMETER:
      return {
        ...state,
        parameterDetail: null,
      }
    case ParameterTypes.GET_DETAIL_PARAMETER_SUCCESS:
      return {
        ...state,
        parameterDetail: action.payload,
      }
    case ParameterTypes.GET_DETAIL_PARAMETER_FAILURE:
      return {
        ...state,
        parameterDetail: null,
      }
    case ParameterTypes.UPDATE_PARAMETER:
      return {
        ...state,
        sendingStatus: "sending",
      }
    case ParameterTypes.UPDATE_PARAMETER_SUCCESS:
      return {
        ...state,
        sendingStatus: "success",
      }
    case ParameterTypes.UPDATE_PARAMETER_FAILURE:
      return {
        ...state,
        sendingStatus: "failure",
      }
    case ParameterTypes.DEFAULT_PARAMETER_STATE:
      return {
        ...state,
        sendingStatus: "",
        parameterDetail: null,
      };
    default:
      return state;
  }
};

export default ParameterReducer;