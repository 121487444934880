import { batch } from "react-redux";
import { SettlementJalinService } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import SettlementJalinTypes from "./settlement-jalin.types";

export const getDataSettlement = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({
          type: SettlementJalinTypes.GET_JALIN_SETTLEMENT,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING_TABLE,
        });
      });

      const listMerchant = await SettlementJalinService.getSettlement(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: SettlementJalinTypes.GET_JALIN_SETTLEMENT_SUCCESS,
          payload: listMerchant.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    } catch (err) {
      batch(() => {
        dispatch({
          type: SettlementJalinTypes.GET_JALIN_SETTLEMENT_FAILURE,
          payload: err.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING_TABLE,
        });
      });
    }
  };
};

export const getJalinReconById = (
  id,
  page = 0,
  size = 100,
  searchQuery = [],
  order = { field: "", direction: "" },
  token
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;

    try {
      batch(() => {
        dispatch({
          type: SettlementJalinTypes.GET_SETTLEMENT_BY_ID,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_LOADING,
        });
      });

      const dataById = await SettlementJalinService.getReconHeader(
        id,
        // page,
        // size,
        // searchQuery,
        // order,
        token
      );

      batch(() => {
        dispatch({
          type: SettlementJalinTypes.GET_SETTLEMENT_BY_ID_SUCCESS,
          payload: dataById.data,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: SettlementJalinTypes.GET_SETTLEMENT_BY_ID_FAILURE,
        });
        dispatch({
          type: ActionsActionTypes.HIDE_LOADING,
        });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: error.message,
            alertType: "error",
          },
        });
      });
    }
  };
};
