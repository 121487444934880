import request from "../_network/request";
const productsUrl = "/v1/awd-products";
const agentsUrl = "/v1/agents";

const getProductList = (page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${
    orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;

  return request({
    url: `${productsUrl}?${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getAgentList = (agentName, token) => {
  return request({
    url: `${agentsUrl}?agentName=${agentName}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    },
  });
}

const getProductDetail = (id, token) => {
  return request({
    url: `${productsUrl}/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createProduct = (data, token) => {
  return request({
    url: productsUrl,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateProduct = (id, data, token) => {
  return request({
    url: `${productsUrl}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteProduct = (id, token) => {
  return request({
    url: `${productsUrl}/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const uploadFile = (file, token) => {
  let formdt = new FormData();
  formdt.append("file", file);
  return request({
    url: `${productsUrl}/import`,
    method: "POST",
    data: formdt,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const ProductServices = {
  getProductList,
  getProductDetail,
  createProduct,
  updateProduct,
  deleteProduct,
  getAgentList,
  uploadFile,
};
