import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";
import DataTables from "../../components/datatables/datatables.component";
import MultipleSearch from "../../components/multiple-search/multiple-search.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import {
    getBiReportDetails,
} from "../../redux/bi-report/bi-report.action";

const BIReportFiles = () => {
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(100);
    const [searchQuery, setSearchQuery] = useState([]);
    const [orderBy, setOrderBy] = useState({
        field: "",
        direction: "false",
    });
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const tableHeaders = [
        {
            text: "File Name",
            sort: true,
            name: "fileName",
            search: true,
            type: "text",
        },
        {
            text: "Report Type",
            sort: true,
            name: "reportType",
            search: false,
            type: "text",
        },
        {
            text: "Status",
            sort: true,
            name: "statusName",
            search: true,
            type: "text",
        },
    ];

    const headerButton = () => {
        // eslint-disable-next-line eqeqeq
        const list = biReportList.filter(item => item.biReportId == params.id);
        if (!list.length) return null;
        const item = list[0];
        const date = new Date(item.createdDate).setHours(0,0,0,0);
        const today = new Date().setHours(0,0,0,0)
        if (date !== today) return null;
        

        return [
            <Link to={"/upload-bi-report"}>
                <button type="button" name="save" className="btn btn-success">
                    <i className="fas fa-plus-square"></i>
                    Create Report
                </button>
            </Link>,
        ];
    };

    const actionButton = [
        {
            icon: (
                <span
                    className="fas fa-file-download"
                    aria-hidden="true"
                ></span>
            ),
            description: "Download File",
            action: (val) => {
                // dispatch(updateSettlement(val.transactionId));
                window.open(
                    `${process.env.REACT_APP_BASE_URL}v1/bi-report/document?fileName=${val.fileName}`
                );
            },
            condition: (val) => true,
        },
    ];

    const {
        biReportListById,
        firstById,
        lastById,
        totalDataById,
        totalPageById,
        emptyById,
        biReportList
    } = useSelector((state) => state.biReport);

    useEffect(() => {
        dispatch(
            getBiReportDetails(params.id, page, size, searchQuery, orderBy)
        );
    }, [page, searchQuery, orderBy, dispatch, size, params.id]);

    useEffect(() => {
        dispatch(
            setHeaderButton({
                titleHeader: "BI Report Files",
                isChild: true,
                hasCustomButton: true,
                customButton: headerButton(),
            })
        );
    }, [dispatch]);

    const handleAction = (pages, order) => {
        setPage(pages);
        if (order.field !== "") {
            setOrderBy(order);
        }
    };

    return (
        <div className="container-fluid">
            <DataTables
                headers={tableHeaders}
                listData={biReportListById}
                first={firstById}
                last={lastById}
                totalData={totalDataById}
                totalPage={totalPageById}
                empty={emptyById}
                page={page}
                handleAction={handleAction}
                size={size}
                listAction={actionButton}
                pagination={false}
            />
        </div>
    );
};

export default BIReportFiles;
