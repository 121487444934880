import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import InputField from "../../components/input-field/input-field.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { createOperator, deleteOperator, getDetailOperator, updateOperator } from "../../redux/operator/operator.actions";
import request from "../../_network/request";
import Select from "react-select";
import { getCategoryByName } from "../../redux/product-category/product-category.actions";

const OperatorForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { operatorDetail, sendingStatus } = useSelector((state) => state.operators);
  const { categoryByName, loading } = useSelector((state) => state.categories);
  const { token } = useSelector((state) => state.auth);
  const [iconImage, setIconImage] = useState("");
  const [formData, setFormData] = useState({
    providerName: "",
    assocNo: "",
    icon: "",
    category: {
      categoryId: "",
    },
    // isActive: false,
  });
  const [textCategory, setTextCategory] = useState("");
  const [options, setOptions] = useState([]);

  const headerActionButton =
    params.id !== "null"
      ? [
        <button
          type="button"
          name="save"
          className="btn btn-danger"
          onClick={() => {
            if (window.confirm("Are you sure want to delete?")) {
              dispatch(deleteOperator(params.id));
            }
          }}
        >
          <i className="fas fa-trash"></i>
          Delete
        </button>,
        <button
          type="button"
          name="save"
          className="btn btn-success"
          onClick={() => onSave()}
        >
          <i className="fas fa-save"></i>
          Save
        </button>,
      ]
      : [
        <button
          type="button"
          name="save"
          className="btn btn-success"
          onClick={() => onSave()}
        >
          <i className="fas fa-save"></i>
          Save
        </button>,
      ];

  const onSave = useCallback(() => {
    if (params.id !== "null") {
      dispatch(updateOperator(formData));
    } else {
      dispatch(createOperator(formData));
    }
  }, [formData]);

  const gettingImage = async (fileName) => {
    try {
      const fetchImage = await request({
        url: `/v1/upload-image/?filename=${fileName}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      });
      const icon = new Buffer(fetchImage).toString("base64");
      setIconImage(icon);
    } catch (error) {
      setFormData((temp) => ({ ...temp, icon: "" }));
    }
  };

  useEffect(() => {
    if (params.id !== "null") dispatch(getDetailOperator(params.id));
  }, []);

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: params.id !== "null" ? "Edit Provider" : "Create Provider",
        isChild: true,
        hasCustomButton: true,
        customButton: headerActionButton,
      })
    );
  }, [onSave]);

  useEffect(() => {
    if (operatorDetail && params.id !== "null") {
      const operatorTemp = { ...operatorDetail };
      setTextCategory(operatorDetail.category.categoryName);
      setFormData(operatorTemp);
      gettingImage(operatorTemp.icon);
    }
  }, [operatorDetail]);

  useEffect(() => {
    if (sendingStatus === "success") window.history.back();
  }, [sendingStatus]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(getCategoryByName(textCategory));
    }, 500);
    return () => clearTimeout(timeout);
  }, [textCategory]);

  useEffect(() => {
    let optionTemp = [];
    categoryByName?.forEach(item => {
      optionTemp.push({
        value: item.categoryId.toString(),
        label: item.categoryName
      })
    });
    setOptions(optionTemp);
  }, [categoryByName]);

  const handleChange = (e) => {
    setFormData((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  };

  const handleCheck = (e) => {
    setFormData((temp) => ({ ...temp, isActive: e.target.checked }));
  };

  const deleteImage = () => {
    setFormData((temp) => ({ ...temp, icon: "" }));
  };

  const handleChangeImage = (e) => {
    setFormData((temp) => ({ ...temp, icon: e.target.files[0] }));
  };

  const handleSelectChange = (e) => {
    setFormData((temp) => ({ ...temp, category: { categoryId: e ? e.value : null } }));
  }

  const generateSelectVal = (val) => {
    const selected = options.filter(item => item.value == val)[0];
    if (selected) return selected;
    return null;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Operator Info</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Category</label>
                </div>
                <div className="col-sm-6">
                  <Select
                    isSearchable={true}
                    isClearable={true}
                    isLoading={loading}
                    onInputChange={setTextCategory}
                    value={generateSelectVal(formData.category.categoryId)}
                    onChange={handleSelectChange}
                    options={options}
                  />
                </div>
              </div>
              <InputField
                type="text"
                label="Operator"
                name="providerName"
                value={formData?.providerName}
                onChange={handleChange}
                required={true}
              />
              <InputField
                type="text"
                label="Associated No"
                name="assocNo"
                value={formData?.assocNo}
                onChange={handleChange}
              />
              <div className="form-group row">
                <div className="col-sm-2">
                  <label for="inputStatus">Icon</label>
                </div>
                <div className="col-sm-10">
                  <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                    <li>
                      <span
                        className="mailbox-attachment-icon has-img"
                        style={{ minHeight: "132.25px" }}
                      >
                        {formData?.icon === null ||
                          formData?.icon === "" ? (
                          <i
                            className="fas fa-camera"
                            style={{ marginTop: "30px" }}
                          ></i>
                        ) : (
                          <img
                            src={
                              formData?.icon
                                ? typeof formData?.icon === "string"
                                  ? `data:image/png;base64,${iconImage}`
                                  : URL.createObjectURL(formData?.icon)
                                : ""
                            }
                            style={{
                              objectFit: "cover",
                              maxHeight: "132.25px",
                            }}
                            alt="Attachment"
                          />
                        )}
                      </span>

                      <div className="mailbox-attachment-info">
                        {formData?.icon === null ||
                          formData?.icon === "" ? (
                          <input
                            className="form-control-sm"
                            type="file"
                            name="icon"
                            onChange={handleChangeImage}
                            accept="image/*"
                            required
                          />
                        ) : (
                          <a
                            onClick={() => {
                              deleteImage("icon");
                            }}
                            className="mailbox-attachment-name"
                          >
                            <i className="fas fa-camera"></i>{" "}
                            {formData.icon.name}
                          </a>
                        )}
                        <span className="mailbox-attachment-size clearfix mt-1">
                          <span>Operator</span>
                        </span>
                        <span className="mailbox-attachment-size clearfix mt-1">
                          <span>Max 5MB</span>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="form-group row">
                <div className="col-sm-2">
                  <label>Status</label>
                </div>
                <div className="col-sm-6">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" onClick={handleCheck} checked={formData.isActive} />
                    <label className="form-check-label">Active</label>
                  </div>
                </div>
              </div> */}
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Created On</label>
                </div>
                <div className="col-sm-6">
                  <label>02-10-2021 10:34:23</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperatorForm;
