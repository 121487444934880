import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { setHeaderButton } from "../../redux/actions/actions.actions";

const TransactionForm = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { transactionList } = useSelector((state) => state.transactions);
  const [dataTransaction, setDataTransaction] = useState({});

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "View Transaction",
        isChild: true,
      })
    );
  }, [dispatch, dataTransaction]);

  useEffect(() => {
    const transactionSearched = transactionList.filter((data) => data.transactionId == params.id);
    setDataTransaction(transactionSearched[0]);
  }, [transactionList, params]);

  const formatDate = (rawDate) => {
    let date = new Date(rawDate);
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const seconds = date.getSeconds();
    return `${day}-${month}-${year} ${hour}:${minute}:${seconds}`;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-sm-12">
          <div className="card card-primary card-outline card-tabs">
            <div className="card-header p-0 pt-1 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-three-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-three-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-home"
                    role="tab"
                    aria-controls="custom-tabs-three-home"
                    aria-selected="true"
                  >
                    Info Transaction
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-three-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-three-home"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-home-tab"
                >
                  <form className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label>Transaction ID</label>
                      </div>
                      <div className="col-sm-6">
                        <label>{params.id}</label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label for="inputMerchantName">Merchant Name</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inputMerchantName"
                          placeholder="Merchant Name"
                          value={dataTransaction.merchantName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label for="inputMerchantPAN">Merchant PAN</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inputMerchantPAN"
                          placeholder="Merchant PAN"
                          value={dataTransaction.merchantPan}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label for="inputInvoiceNo">Invoice No</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inputInvoiceNo"
                          placeholder="Invoice No"
                          value={dataTransaction.invoiceNo}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label for="inputTrxDate">Transaction Date</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inputTrxDate"
                          placeholder="Transaction Date"
                          value={formatDate(dataTransaction.trxDate)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label for="inputTrxStatus">Transaction Status</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inputTrxStatus"
                          placeholder="PENDING/PAID/REFUND/CANCEL"
                          value={dataTransaction.status}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label for="inputAmount">Amount(Rp)</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inputAmount"
                          placeholder="Amount"
                          value={dataTransaction.amount}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label for="inputMDR">MDR(%)</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inputMDR"
                          placeholder="MDR"
                          value={dataTransaction.settlement?.mdr}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label for="inputMDRAmount">MDR Amount(Rp)</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inputMDRAmount"
                          placeholder="MDR Amount"
                          value={dataTransaction.settlement?.mdrAmount}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label for="inputStlDate">Settlement Date</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inputStlDate"
                          placeholder="Settlement Date"
                          value={dataTransaction.settlement ? formatDate(dataTransaction.settlement?.settlementDate) : ""}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label for="inputStlStatus">Settlement Status</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inputStlStatus"
                          placeholder="SETTLED/PAID"
                          value={dataTransaction.settlement?.settlementStatus}
                          disabled
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionForm;
