/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import InputField from "../../components/input-field/input-field.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { createCategory, getDetailCategory, updateCategory } from "../../redux/product-category/product-category.actions";
import request from "../../_network/request";

const CategoryForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { categoryDetail, sendingStatus } = useSelector((state) => state.categories);
  const { token } = useSelector((state) => state.auth);
  const [categoryImage, setCategoryImage] = useState("");
  const [formData, setFormData] = useState({
    categoryName: "",
    isActive: false,
    icon: "",
  });
  
  const headerActionButton = [
    <button
      type="button"
      name="save"
      className="btn btn-success"
      onClick={() => onSave()}
    >
      <i className="fas fa-save"></i>
      Save
    </button>
  ];

  const onSave = useCallback(() => {
    if (params.id !== "null") {
      dispatch(updateCategory(formData));
    } else {
      dispatch(createCategory(formData));
    }
  }, [formData]);

  const gettingImage = async (fileName) => {
    try {
      const fetchImage = await request({
        url: `/v1/upload-image/?filename=${fileName}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      });
      const icon = new Buffer(fetchImage).toString("base64");
      setCategoryImage(icon);
    } catch (error) {
      setFormData((temp) => ({ ...temp, icon: "" }));
    }
  };

  useEffect(() => {
    if(params.id !== "null") dispatch(getDetailCategory(params.id));
  }, [])

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Edit Categories",
        isChild: true,
        hasCustomButton: true,
        customButton: headerActionButton,
      })
    );
  }, [onSave]);

  useEffect(() => {
    if(categoryDetail) {
      setFormData(categoryDetail);
      gettingImage(categoryDetail.icon);
    }
  }, [categoryDetail]);

  useEffect(() => {
    if (sendingStatus === "success") window.history.back();
  }, [sendingStatus]);

  const handleChange = (e) => {
    setFormData((temp) => ({...temp, [e.target.name]: e.target.value }));
  }

  const deleteImage = () => {
    setFormData((temp) => ({...temp, icon: "" }));
  }

  const handleChangeImage = (e) => {
    setFormData((temp) => ({ ...temp, icon: e.target.files[0] }));
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-body">
              <InputField
                type="text"
                label="Category Name"
                name="categoryName"
                value={formData?.categoryName}
                onChange={handleChange}
                required={true}
              />
              <div className="form-group row">
                <div className="col-sm-2">
                  <label for="inputStatus">Icon</label>
                </div>
                <div className="col-sm-10">
                  <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                    <li>
                      <span
                        className="mailbox-attachment-icon has-img"
                        style={{ minHeight: "132.25px" }}
                      >
                        {formData?.icon === null ||
                        formData?.icon === "" ? (
                          <i
                            className="fas fa-camera"
                            style={{ marginTop: "30px" }}
                          ></i>
                        ) : (
                          <img
                            src={
                              formData?.icon
                                ? typeof formData?.icon === "string"
                                  ? `data:image/png;base64,${categoryImage}`
                                  : URL.createObjectURL(formData?.icon)
                                : ""
                            }
                            style={{
                              objectFit: "cover",
                              maxHeight: "132.25px",
                            }}
                            alt="Attachment"
                          />
                        )}
                      </span>

                      <div className="mailbox-attachment-info">
                        {formData?.icon === null ||
                        formData?.icon === "" ? (
                          <input
                            className="form-control-sm"
                            type="file"
                            name="icon"
                            onChange={handleChangeImage}
                            accept="image/*"
                            required
                          />
                        ) : (
                          <a
                            onClick={() => {
                              deleteImage("icon");
                            }}
                            className="mailbox-attachment-name"
                          >
                            <i className="fas fa-camera"></i>{" "}
                            {formData.icon.name}
                          </a>
                        )}
                        <span className="mailbox-attachment-size clearfix mt-1">
                          <span>Banner</span>
                        </span>
                        <span className="mailbox-attachment-size clearfix mt-1">
                          <span>Max 5MB</span>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Status</label>
                </div>
                <div className="col-sm-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      name="isActive"
                      checked={formData.isActive}
                      onChange={(e) => setFormData(temp => ({...temp, isActive: e.target.checked}))}
                      type="checkbox"
                    />
                    <label className="form-check-label">Active</label>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Apply on Version</label>
                </div>
                <div className="col-sm-2">
                  <input type="text" className="form-control" id="fullname" />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Created On</label>
                </div>
                <div className="col-sm-6">
                  <label>02-10-2021 10:34:23</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryForm;
