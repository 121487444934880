import ReportTypes from "./report.types";

const INITIAL_STATE = {
  reportMerchantList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  errorMessage: "",
  error: false,
};

const ReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportTypes.GET_REPORT_MERCHANT:
      return {
        ...state,
      };
    case ReportTypes.GET_REPORT_MERCHANT_SUCCESS:
      return {
        ...state,
        status: 200,
        reportMerchantList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case ReportTypes.GET_REPORT_MERCHANT_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        errorMessage: action.payload.message,
        error: true,
      };
    default:
      return state;
  }
};

export default ReportReducer;
