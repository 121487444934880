import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { createProduct, deleteProduct, getAgentList, getDetailProduct, getProviderList, updateProduct } from "../../redux/product/product.actions";
import Select from "react-select";
import InputField from "../../components/input-field/input-field.component";

const ProductEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [formData, setFormData] = useState({
    agent: {
      agentId: "",
    },
    productCode: "",
    productName: "",
    description: "",
    provider: {
      providerId: "",
    },
    denom: 0,
    buyPrice: 0,
    buyFee: 0,
    sellPrice: 0,
    sellFee: 0,
    isActive: false,
  });
  const { productDetail, sendingStatus, agentList, loadingAgent, providerList, loadingProvider } = useSelector((state) => state.products);
  const [textAgent, setTextAgent] = useState("");
  const [textProvider, setTextProvider] = useState("");
  const [options, setOptions] = useState([]);
  const [optionsProvider, setOptionsProvider] = useState([]);

  const headerActionButton =
    params.id !== "null"
      ? [
        <button
          type="button"
          name="save"
          className="btn btn-danger"
          onClick={() => {
            if (window.confirm("Are you sure want to delete?")) {
              dispatch(deleteProduct(params.id));
            }
          }}
        >
          <i className="fas fa-trash"></i>
          Delete
        </button>,
        <button
          type="button"
          name="save"
          className="btn btn-success"
          onClick={() => onSave()}
        >
          <i className="fas fa-save"></i>
          Save
        </button>,
      ]
      : [
        <button
          type="button"
          name="save"
          className="btn btn-success"
          onClick={() => onSave()}
        >
          <i className="fas fa-save"></i>
          Save
        </button>,
      ];

  const onSave = useCallback(() => {
    if (params.id !== "null") {
      dispatch(updateProduct(formData));
    } else {
      dispatch(createProduct(formData));
    }
  }, [formData]);

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: params.id !== "null" ? "Edit Product" : "Create Product",
        isChild: true,
        hasCustomButton: true,
        customButton: headerActionButton,
      })
    );
  }, [onSave]);

  useEffect(() => {
    if (params.id !== "null") dispatch(getDetailProduct(params.id));
  }, []);

  useEffect(() => {
    if (productDetail && params.id !== "null") {
      const operatorTemp = { ...productDetail };
      setFormData(operatorTemp);
      setTextAgent(productDetail?.agent?.agentName);
      setTextProvider(productDetail?.provider?.providerName || "");
    }
  }, [productDetail]);

  useEffect(() => {
    if (sendingStatus === "success") window.history.back();
  }, [sendingStatus]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(getAgentList(textAgent));
    }, 500);
    return () => clearTimeout(timeout);
  }, [textAgent]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(getProviderList(textProvider));
    }, 500);
    return () => clearTimeout(timeout);
  }, [textProvider]);

  useEffect(() => {
    let optionTemp = [];
    agentList?.forEach(item => {
      optionTemp.push({
        value: item.agentId,
        label: item.agentName
      })
    });
    setOptions(optionTemp);
  }, [agentList]);

  useEffect(() => {
    let optionTemp = [];
    providerList?.forEach(item => {
      optionTemp.push({
        value: item.providerId,
        label: item.providerName
      })
    });
    setOptionsProvider(optionTemp);
  }, [providerList]);

  const handleSelectChange = (e, type) => {
    setFormData((temp) => ({
      ...temp,
      [type]: {
        [type === "agent" ? "agentId" : "providerId"]: e ? e.value : null
      }
    }));
  }

  const generateSelectVal = (val, type) => {
    const selected = type === "agent"
      ? options.filter(item => item.value == val)[0]
      : optionsProvider.filter(item => item.value == val)[0];
    if (selected) return selected;
  }

  const handleChange = (e) => setFormData(temp => ({ ...temp, [e.target.name]: e.target.value }));

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Product Info</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Agent</label>
                </div>
                <div className="col-sm-6">
                  <Select
                    isSearchable={true}
                    isClearable={true}
                    isLoading={loadingAgent}
                    onInputChange={setTextAgent}
                    value={generateSelectVal(formData?.agent?.agentId, "agent")}
                    onChange={(e) => handleSelectChange(e, "agent")}
                    options={options}
                  />
                </div>
              </div>
              <InputField
                type="text"
                label="Product Code"
                name="productCode"
                value={formData?.productCode}
                onChange={handleChange}
                required={true}
              />
              <InputField
                type="text"
                label="Product Name"
                name="productName"
                value={formData?.productName}
                onChange={handleChange}
                required={true}
              />
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Description</label>
                </div>
                <div className="col-sm-6">
                  <textarea
                    className="form-control"
                    rows="3"
                    name="description"
                    value={formData?.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Provider</label>
                </div>
                <div className="col-sm-6">
                  <Select
                    isSearchable={true}
                    isClearable={true}
                    isLoading={loadingProvider}
                    onInputChange={setTextProvider}
                    value={generateSelectVal(formData.provider?.providerId || "", "provider")}
                    onChange={(e) => handleSelectChange(e, "provider")}
                    options={optionsProvider}
                  />
                </div>
              </div>
              <InputField
                type="number"
                label="Denom"
                name="denom"
                value={formData?.denom}
                onChange={handleChange}
              />
              <InputField
                type="number"
                label="Buy Price"
                name="buyPrice"
                value={formData?.buyPrice}
                onChange={handleChange}
              />
              <InputField
                type="number"
                label="Buy Fee"
                name="buyFee"
                value={formData?.buyFee}
                onChange={handleChange}
              />
              <InputField
                type="number"
                label="Sell Price"
                name="sellPrice"
                value={formData?.sellPrice}
                onChange={handleChange}
              />
              <InputField
                type="number"
                label="Sell Fee"
                name="sellFee"
                value={formData?.sellFee}
                onChange={handleChange}
              />
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Status</label>
                </div>
                <div className="col-sm-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={formData?.isActive}
                      onChange={(e) => setFormData(temp => ({ ...temp, isActive: e.target.checked }))}
                    />
                    <label className="form-check-label">Active</label>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Created On</label>
                </div>
                <div className="col-sm-6">
                  <label>02-10-2021 10:34:23</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductEdit;
