import { batch } from "react-redux";
import { ConsumerTransactionServices } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import ConsumerTransactionTypes from "./consumer-transaction.types";

export const getDataConsumerTransaction = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const consumerRequest = await ConsumerTransactionServices.getConsumerTransactionData(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION_SUCCESS,
          payload: consumerRequest.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION_FAILURE });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    }
  };
};

export const getConsumerTransactionDetail = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION_DETAIL });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const detailReq = await ConsumerTransactionServices.getConsumerTransactionDetail(id, token);

      batch(() => {
        dispatch({
          type: ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION_DETAIL_SUCCESS,
          payload: detailReq,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION_DETAIL_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    }
  }
}

export const getStatusTransaction = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({ type: ConsumerTransactionTypes.GET_STATUS_CONSUMER_TRANSACTION });

      const getStatus = await ConsumerTransactionServices.getConsumerTransactionStatus(token);

      dispatch({
        type: ConsumerTransactionTypes.GET_STATUS_CONSUMER_TRANSACTION_SUCCESS,
        payload: getStatus.data,
      });
    } catch (error) {
      dispatch({ type: ConsumerTransactionTypes.GET_STATUS_CONSUMER_TRANSACTION_FAILURE, payload: error });

    }
  }
}

export const getAgents = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({ type: ConsumerTransactionTypes.GET_AGENT_SELECTIONS });

      const getAgents = await ConsumerTransactionServices.getAgents(token);

      dispatch({
        type: ConsumerTransactionTypes.GET_AGENT_SELECTIONS_SUCCESS,
        payload: getAgents.data.content,
      });
    } catch (error) {
      dispatch({ type: ConsumerTransactionTypes.GET_AGENT_SELECTIONS_FAILURE, payload: error });

    }
  }
}