import MerchantGroupTypes from "./merchant-group.types";

const INITIAL_STATE = {
  merchantGroupList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  errorMessage: "",
  error: false,

  dataById: {},

  createMerchantGroupSuccess: "",
  updateMerchantGroupSuccess: "",

  errorDelete: false,
};

const MerchantGroupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MerchantGroupTypes.GET_MERCHANT_GROUP:
      return {
        ...state,
        createMerchantGroupSuccess: "",
        updateMerchantGroupSuccess: "",
      };
    case MerchantGroupTypes.GET_MERCHANT_GROUP_SUCCESS:
      return {
        ...state,
        status: 200,
        merchantGroupList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case MerchantGroupTypes.GET_MERCHANT_GROUP_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        errorMessage: action.payload.message,
        error: true,
      };
    case MerchantGroupTypes.DELETE_MERCHANT_GROUP:
      return {
        ...state,
        errorDelete: "",
      };
    case MerchantGroupTypes.DELETE_MERCHANT_GROUP_FAILURE:
      return {
        ...state,
        errorDelete: true,
      };
    case MerchantGroupTypes.DELETE_MERCHANT_GROUP_SUCCESS:
      return {
        ...state,
        errorDelete: false,
      };
    case MerchantGroupTypes.CREATE_MERCHANT_GROUP:
      return {
        ...state,
        createMerchantGroupSuccess: "creating",
      };
    case MerchantGroupTypes.CREATE_MERCHANT_GROUP_FAILURE:
      return {
        ...state,
        createMerchantGroupSuccess: "failed",
      };
    case MerchantGroupTypes.CREATE_MERCHANT_GROUP_SUCCESS:
      return {
        ...state,
        createMerchantGroupSuccess: "success",
      };
    case MerchantGroupTypes.UPDATE_MERCHANT_GROUP:
      return {
        ...state,
        updateMerchantGroupSuccess: "updating",
      };
    case MerchantGroupTypes.UPDATE_MERCHANT_GROUP_FAILURE:
      return {
        ...state,
        updateMerchantGroupSuccess: "failed",
      };
    case MerchantGroupTypes.UPDATE_MERCHANT_GROUP_SUCCESS:
      return {
        ...state,
        updateMerchantGroupSuccess: "success",
      };
    case MerchantGroupTypes.GET_GROUP_BY_ID:
      return {
        ...state,
      };
    case MerchantGroupTypes.GET_GROUP_BY_ID_FAILURE:
      return {
        ...state,
      };
    case MerchantGroupTypes.GET_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        dataById: action.payload,
      };
    default:
      return state;
  }
};

export default MerchantGroupReducer;
