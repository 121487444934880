import { batch } from "react-redux";
import { ImageServices, OperatorServices } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import OperatorTypes from "./operator.types";

export const getDataOperator = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: OperatorTypes.GET_OPERATORS });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const consumerRequest = await OperatorServices.getOperatorList(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: OperatorTypes.GET_OPERATORS_SUCCESS,
          payload: consumerRequest.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: OperatorTypes.GET_OPERATORS_FAILURE });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    }
  };
};

export const getDetailOperator = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: OperatorTypes.GET_OPERATOR_DETAIL });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const operatorDetail =
        await OperatorServices.getOperatorDetail(id, token);

      batch(() => {
        dispatch({
          type: OperatorTypes.GET_OPERATOR_DETAIL_SUCCESS,
          payload: operatorDetail.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: OperatorTypes.GET_OPERATOR_DETAIL_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const createOperator = (dataForm) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const data = { ...dataForm };
    try {
      batch(() => {
        dispatch({ type: OperatorTypes.CREATE_OPERATOR });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const uploadImage = await ImageServices.uploadImage(
        data.icon,
        "provider",
        token
      );
      data.icon = uploadImage.data.fullPath;
      const newOperator = await OperatorServices.createOperator(
        data,
        token
      );

      if (newOperator.status === "Error") {
        batch(() => {
          dispatch({
            type: OperatorTypes.CREATE_OPERATOR_FAILURE,
            payload: newOperator.data,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: newOperator.data,
              alertType: "error",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: OperatorTypes.CREATE_OPERATOR_SUCCESS,
            payload: newOperator,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Add new operator success",
              alertType: "success",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: OperatorTypes.CREATE_OPERATOR_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Add new operator failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const updateOperator = (dataForm) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const data = { ...dataForm };
    const id = data.providerId;
    try {
      batch(() => {
        dispatch({ type: OperatorTypes.UPDATE_OPERATOR });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });
      delete data.providerId;
      delete data.createdDate;
      delete data.isDeleted;
      delete data.modifiedDate;

      if (data.icon && typeof data.icon !== "string") {
        const uploadImage = await ImageServices.uploadImage(
          data.icon,
          "provider",
          token
        );
        data.icon = uploadImage.data.fullPath;
      }
      const update = await OperatorServices.updateOperator(
        id,
        data,
        token
      );

      if (update.status === "Error") {
        batch(() => {
          dispatch({
            type: OperatorTypes.UPDATE_OPERATOR_FAILURE,
            payload: update.data,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: update.data,
              alertType: "error",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({
            type: OperatorTypes.UPDATE_OPERATOR_SUCCESS,
            payload: update,
          });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Update operator success",
              alertType: "success",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({
          type: OperatorTypes.UPDATE_OPERATOR_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update operator failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const deleteOperator = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: OperatorTypes.DELETE_OPERATOR });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const deleteOperator = await OperatorServices.deleteOperator(
        id,
        token
      );

      batch(() => {
        dispatch({
          type: OperatorTypes.DELETE_OPERATOR_SUCCESS,
          payload: deleteOperator,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete operator success",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: OperatorTypes.DELETE_OPERATOR_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete operator failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const defaultOperator = () => async (dispatch) =>
  dispatch({ type: OperatorTypes.DEFAULT_OPERATOR_STATE });
