import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ActionsReducer from './actions/actions.reducer';
import MerchantReducer from './merchat-management/merchant-management.reducer';
import authReducer from './auth/auth.reducer';
import TransactionReducer, { MerchantBalanceReducer, ExportTransactionReducer } from './transaction/transaction.reducer';
import MasterDataReducer from './masterData/masterData.reducer';
import UserManagementReducer from './user-management/user-management.reducer';
import MerchantGroupReducer from './merchant-group/merchant-group.reducer';
import SettlementJalinReducer from './settlement-jalin/settlement-jalin.reducer';
import ReportReducer from './report/report.reducer';
import BIReportReducer from './bi-report/bi-report.reducer';
import DashboardReducer from './dashboard/dashboard.reducer';
import RefCodeReducer from './refcode/refCode.reducer';
import ConsumerReducer from './consumer/consumer.reducer';
import BannerReducer from './banner/banner.reducer';
import NotificationReducer from './notification/notification.reducer';
import CategoryReducer from './product-category/product-category.reducer';
import OperatorReducer from './operator/operator.reducer';
import ProductReducer from './product/product.reducer';
import ConsumerTransactionReducer from './consumer-transaction/consumer-transaction.reducer';
import ParameterReducer from './parameter/parameter.reducer';
import DisbursementReducer from './disbursement/disbursement.reducer';
import { partnerBalanceReducer, getPartnerSettingReducer, updatePartnerSettingReducer, getPartnerTransactionReducer, getUserPartnerReducer, createUserPartnerReducer, partnerListReducer } from './partner/partner.reducer';
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

const rootReducer = combineReducers({
  auth: authReducer,
  actionsUser: ActionsReducer,
  merchantManagement: MerchantReducer,
  transactions: TransactionReducer,
  masterData: MasterDataReducer,
  userManagement: UserManagementReducer,
  merchantGroup: MerchantGroupReducer,
  settlementJalin: SettlementJalinReducer,
  report: ReportReducer,
  biReport: BIReportReducer,
  dashboard: DashboardReducer,
  refCodes: RefCodeReducer,
  dataConsumer: ConsumerReducer,
  banners: BannerReducer,
  notifications: NotificationReducer,
  categories: CategoryReducer,
  operators: OperatorReducer,
  products: ProductReducer,
  dataConsumerTransaction: ConsumerTransactionReducer,
  dataParameter: ParameterReducer,
  dataDisbursement: DisbursementReducer,
  merchantBalance: MerchantBalanceReducer,
  exportTransaction: ExportTransactionReducer,
  partnerBalance: partnerBalanceReducer,
  partnerSetting: getPartnerSettingReducer,
  updatePartnerSetting : updatePartnerSettingReducer,
  partnerTransaction : getPartnerTransactionReducer,
  userPartner : getUserPartnerReducer,
  createUserPartner : createUserPartnerReducer,
  partnerList : partnerListReducer,
});

export default persistReducer(persistConfig, rootReducer);
