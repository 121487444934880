import { MasterDataService } from "../../_services";
import MasterDataTypes from "./masterData.types";

export const getMasterData = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({
        type: MasterDataTypes.GET_MASTER_DATA,
      });

      const provinces = await MasterDataService.getProvinces(token);
      const identity = await MasterDataService.getRefCode("IDENTITY", token);
      const bank = await MasterDataService.getRefCode("BANK", token);
      const businessEntity = await MasterDataService.getRefCode(
        "BUSINESS_ENTITY",
        token
      );
      const businessSector = await MasterDataService.getRefCode(
        "BUSINESS_SECTOR",
        token
      );
      const businessCategory = await MasterDataService.getRefCode(
        "BUSINESS_CATEGORY",
        token
      );
      const refGroup = await MasterDataService.getRefCodeGroupPhase2("IDENTITY", token);
      const marriageStatus = await MasterDataService.getRefCode("MARRIAGE_STATUS", token);
      
      let refCodesReq = [];
      refGroup.data.content.forEach(item => {
        const refCode = MasterDataService.getRefCodePhase2(item.refGroup, token);
        refCodesReq.push(refCode);
      });
      const refCodes = await Promise.all(refCodesReq);
      let refCodesPhase2 = {}; 
      refCodes.forEach(item => {
        refCodesPhase2 = {...refCodesPhase2, [item.data.content[0].refGroup]: item.data.content};
      });

      dispatch({
        type: MasterDataTypes.GET_MASTER_DATA_SUCCESS,
        payload: {
          provinces: provinces.data.content,
          identity: identity.data.content,
          bank: bank.data.content,
          businessEntity: businessEntity.data.content,
          businessSector: businessSector.data.content,
          businessCategory: businessCategory.data.content,
          refCodesPhase2: refCodesPhase2,
          refGroup: refGroup.data.content,
          marriageStatus: marriageStatus.data.content,
        },
      });
    } catch (err) {
      dispatch({
        type: MasterDataTypes.GET_MASTER_DATA_FAILURE,
        payload: err.status,
      });
    }
  };
};
