import request from "../_network/request";
const consumerURL = "/v1/consumers";
const selectURL = "/v1";

const getConsumerData = (page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      if (key.value.includes("+")) key.value = key.value.replace("+", "%2B");
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${orderBy.field !== ""
    ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
    : ""
    }`;

  return request({
    url: `${consumerURL}?${searchArray.length > 0 ? searchArray.join("") : ""
      }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getConsumerDetail = (id, token) => {
  return request({
    url: `${consumerURL}/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

const updateConsumer = (id, data, token) => {
  return request({
    url: `${consumerURL}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
}

const affectConsumer = (id, action, reason, token) => {
  let data = {};
  if (action === "ban" || action === "close") data = { reason: reason };
  return request({
    url: `${consumerURL}/${id}/${action}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
}

const rolesConsumer = (token) => {
  return request({
    url: `${selectURL}/select-roles`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

const statusConsumer = (token) => {
  return request({
    url: `${selectURL}/select-status-consumers`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

const upgradeStatusConsumer = (token) => {
  return request({
    url: `${selectURL}/select-upgrade-status`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const ConsumerServices = {
  getConsumerData,
  getConsumerDetail,
  updateConsumer,
  affectConsumer,
  rolesConsumer,
  statusConsumer,
  upgradeStatusConsumer,
}