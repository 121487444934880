import { batch } from "react-redux";
import { DisbursementServices } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import DisbursementTypes from "./disbursement.types";

export const getDataDisbursement = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: DisbursementTypes.GET_DISBURSEMENT });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const consumerRequest = await DisbursementServices.getDisbursementData(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: DisbursementTypes.GET_DISBURSEMENT_SUCCESS,
          payload: consumerRequest.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: DisbursementTypes.GET_DISBURSEMENT_FAILURE, payload: error });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    }
  };
};