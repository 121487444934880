import { batch } from "react-redux";
import { CategoryServices, ImageServices } from "../../_services";
import ActionsActionTypes from "../actions/actions.types";
import CategoryTypes from "./product-category.types";

export const getDataCategory = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: CategoryTypes.GET_CATEGORIES });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const categoryRequest = await CategoryServices.getCategoryList(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: CategoryTypes.GET_CATEGORIES_SUCCESS,
          payload: categoryRequest.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: CategoryTypes.GET_CATEGORIES_FAILURE });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    }
  };
};

export const getCategoryByName = (name) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({ type: CategoryTypes.GET_CATEGORY_BY_NAME });

      const categoryReq = await CategoryServices.getCategoryList(
        0,
        10,
        [{ param: "categoryName", value: name }],
        {
          field: "categoryName",
          direction: true,
        },
        token
      );

      dispatch({
        type: CategoryTypes.GET_CATEGORY_BY_NAME_SUCCESS,
        payload: categoryReq.data.content,
      });
    } catch (error) {
      dispatch({ type: CategoryTypes.GET_CATEGORY_BY_NAME_FAILURE });

    }
  }
}

export const getDetailCategory = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: CategoryTypes.GET_CATEGORY_DETAIL });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const notificationDetail =
        await CategoryServices.getCategoryDetail(id, token);

      batch(() => {
        dispatch({
          type: CategoryTypes.GET_CATEGORY_DETAIL_SUCCESS,
          payload: notificationDetail.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: CategoryTypes.GET_CATEGORY_DETAIL_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    }
  };
};

export const createCategory = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: CategoryTypes.CREATE_CATEGORY });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const uploadImage = await ImageServices.uploadImage(
        data.icon,
        "category",
        token
      );
      data.icon = uploadImage.data.fullPath;
      const newCategory = await CategoryServices.createCategory(
        data,
        token
      );

      batch(() => {
        dispatch({
          type: CategoryTypes.CREATE_CATEGORY_SUCCESS,
          payload: newCategory,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Add new notification success",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: CategoryTypes.CREATE_CATEGORY_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Add new notification failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const updateCategory = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const id = data.categoryId;
    try {
      batch(() => {
        dispatch({ type: CategoryTypes.UPDATE_CATEGORY });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });
      delete data.createdDate;
      delete data.modifiedDate;

      if (typeof data.icon !== "string") {
        const uploadImage = await ImageServices.uploadImage(
          data.icon,
          "category",
          token
        );
        data.icon = uploadImage.data.fullPath;
      }
      const update = await CategoryServices.updateCategory(
        id,
        data,
        token
      );

      batch(() => {
        dispatch({
          type: CategoryTypes.UPDATE_CATEGORY_SUCCESS,
          payload: update,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update notification success",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: CategoryTypes.UPDATE_CATEGORY_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update notification failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const deleteCategory = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: CategoryTypes.DELETE_CATEGORY });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const deleteCategory = await CategoryServices.deleteCategory(
        id,
        token
      );

      batch(() => {
        dispatch({
          type: CategoryTypes.DELETE_CATEGORY_SUCCESS,
          payload: deleteCategory,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete notification success",
            alertType: "success",
          },
        });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: CategoryTypes.DELETE_CATEGORY_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Delete notification failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const defaultCategory = () => async (dispatch) =>
  dispatch({ type: CategoryTypes.DEFAULT_CATEGORY_STATE });
