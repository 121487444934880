import request from "../_network/request";
const bannerUrl = "/v1/banners";

const getBannerList = (page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${
    orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;

  return request({
    url: `${bannerUrl}?${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getBannerDetail = (id, token) => {
  return request({
    url: `${bannerUrl}/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createBanner = (data, token) => {
  return request({
    url: bannerUrl,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateBanner = (id, data, token) => {
  return request({
    url: `${bannerUrl}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteBanner = (id, token) => {
  return request({
    url: `${bannerUrl}/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const BannerServices = {
  getBannerList,
  getBannerDetail,
  createBanner,
  updateBanner,
  deleteBanner,
};
