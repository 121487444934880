const BIReportTypes = {
    GET_BI_REPORT: "GET_BI_REPORT",
    GET_BI_REPORT_SUCCESS: "GET_BI_REPORT_SUCCESS",
    GET_BI_REPORT_FAILURE: "GET_BI_REPORT_FAILURE",
    GET_BI_REPORT_BY_ID: "GET_BI_REPORT_BY_ID",
    GET_BI_REPORT_BY_ID_SUCCESS: "GET_BI_REPORT_BY_ID_SUCCESS",
    GET_BI_REPORT_BY_ID_FAILURE: "GET_BI_REPORT_BY_ID_FAILURE",
    UPLOAD_BI_REPORT: "UPLOAD_BI_REPORT",
    UPLOAD_BI_REPORT_SUCCESS: "UPLOAD_BI_REPORT_SUCCESS",
    UPLOAD_BI_REPORT_FAILURE: "UPLOAD_BI_REPORT_FAILURE",
    GET_TEMPLATE: "GET_TEMPLATE",
    GET_TEMPLATE_SUCCESS: "GET_TEMPLATE_SUCCESS",
    GET_TEMPLATE_FAILURE: "GET_TEMPLATE_FAILURE",
    GET_REPORT_TYPES: "GET_REPORT_TYPES",
    GET_REPORT_TYPES_SUCCESS: "GET_REPORT_TYPES_SUCCESS",
    GET_REPORT_TYPES_FAILURE: "GET_REPORT_TYPES_FAILURE",

    UPLOAD_CSV: "UPLOAD_CSV",
    UPLOAD_CSV_SUCCESS: "UPLOAD_CSV_SUCCESS",
    UPLOAD_CSV_FAILURE: "UPLOAD_CSV_FAILURE",
    UPLOAD_DEFAULT: "UPLOAD_DEFAULT",
};

export default BIReportTypes;
