import ConsumerTransactionTypes from "./consumer-transaction.types";

const INITIAL_STATE = {
  consumerTransactionList: [],
  first: true,
  last: true,
  totalData: 0,
  totalPage: 0,
  empty: true,
  status: null,
  error: false,
  consumerTransactionDetail: {},
  statusTransaction: [],
  agents: [],
};

const ConsumerTransactionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION:
      return {
        ...state,
        error: false,
      };
    case ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION_SUCCESS:
      return {
        ...state,
        consumerTransactionList: action.payload.content,
        first: action.payload.first,
        last: action.payload.last,
        totalData: action.payload.totalElements,
        totalPage: action.payload.totalPages,
        empty: action.payload.empty,
        error: false,
      };
    case ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION_FAILURE:
      return {
        ...state,
        error: true,
      };
    case ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION_DETAIL:
      return {
        ...state,
        error: false,
      };
    case ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION_DETAIL_SUCCESS:
      return {
        ...state,
        consumerTransactionDetail: action.payload,
        error: false,
      };
    case ConsumerTransactionTypes.GET_DATA_CONSUMER_TRANSACTION_DETAIL_FAILURE:
      return {
        ...state,
        consumerTransactionDetail: {},
        error: true,
      };
    case ConsumerTransactionTypes.GET_STATUS_CONSUMER_TRANSACTION:
      return {
        ...state,
        error: false,
      };
    case ConsumerTransactionTypes.GET_STATUS_CONSUMER_TRANSACTION_SUCCESS:
      return {
        ...state,
        statusTransaction: action.payload,
        error: false,
      };
    case ConsumerTransactionTypes.GET_STATUS_CONSUMER_TRANSACTION_FAILURE:
      return {
        ...state,
        statusTransaction: [],
        error: true,
      };
    case ConsumerTransactionTypes.GET_AGENT_SELECTIONS:
      return {
        ...state,
        error: false,
      };
    case ConsumerTransactionTypes.GET_AGENT_SELECTIONS_SUCCESS:
      return {
        ...state,
        agents: action.payload,
        error: false,
      };
    case ConsumerTransactionTypes.GET_AGENT_SELECTIONS_FAILURE:
      return {
        ...state,
        agents: [],
        error: true,
      };
    default:
      return state;
  }
};

export default ConsumerTransactionReducer;