import request from "../_network/request";

const url = "/v1/transactions";
const paymentUrl = "/v1/payment/refund";
const merchantBalanceUrl = "/v1/merchant-balance";
const getListTransaction = (merchantId, page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  let filterMerchant = merchantId === "" ? "" : `merchantId=${merchantId}`;
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`${i === 0 ? "" : "&"}${key.param}=${key.value}`);
    });
  }
  const field =
    orderBy.field === "merchantName" || orderBy.field === "merchantPan"
      ? `merchant.${orderBy.field}`
      : orderBy.field;
  let order = `${
    orderBy.field !== ""
      ? `&sort=${field},${orderBy.direction ? "asc" : "desc"}`
      : ""
  }`;
  return request({
    url: `${url}?${filterMerchant}${
      searchArray.length > 0 ? searchArray.join("") : ""
    }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const updateSettlementPaid = (transactionId, token) => {
  return request({
    url: `${paymentUrl}`,
    method: "PATCH",
    data: {
      transactionId: transactionId,
    },
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

const merchantBalance = (token) => {
  return request({
    url: `${merchantBalanceUrl}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

const exportTransaction = (token, transactionStatusList, startTrxDate, endTrxDate, settlementStatusList, invoiceNo) => {
  return request({
    url: `${url}/export?transactionStatusList=${transactionStatusList}&startTrxDate=${startTrxDate}&endTrxDate=${endTrxDate}&settlementStatusList=${settlementStatusList}&invoiceNo=${invoiceNo}`,
    method: "GET",
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export const TransactionService = {
  getListTransaction,
  updateSettlementPaid,
  merchantBalance,
  exportTransaction,
};
