/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import InputField from "../../components/input-field/input-field.component";
import ReasonPopup from "../../components/reason-popup/reason-popup.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { affectConsumer } from "../../redux/consumer/consumer.action";
import request from "../../_network/request";
import useFlightForm from "./useFlightForm";

const EditFlight = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { sendingStatus } = useSelector(state => state.dataConsumer);
  const [data, getData, saveData, deleteData] = useFlightForm();
  const { token } = useSelector(state => state.auth);
  const [formData, setFormData] = useState({
    // airlineCode: "",
    airlineType: "",
    amountFee: 0,
    // flightNumber: "",
    isActive: true,
    percentFee: 0
  });
  const [images, setImages] = useState({
    airlineIcon: '',
  });
  const [popupData, setPopupData] = useState({
    show: false,
    header: "",
    type: "",
  })
  const [isPercent, setIsPercent] = useState(false);

  const onSave = useCallback(() => {
    saveData(formData, params.id);
  }, [formData, params.id]);

  const headerActionButton = [
    <button
      type="button"
      name="save"
      className="btn btn-primary"
      onClick={() => {
        onSave();
      }}
    >
      <i className="fas fa-floppy-o"></i>
      Save
    </button>,
    <button
      type="button"
      name="save"
      className="btn btn-danger"
      onClick={() => {
        // setPopupData({ show: true, header: "Delete Data", type: "close" });
        deleteData(params.id);
      }}
      style={{ display: params.id === "null" ? "none" : "block" }}
    >
      <i className="fas fa-door-closed"></i>
      Delete
    </button>,
  ]

  useEffect(() => {
    dispatch(setHeaderButton({
      titleHeader: "Edit Flight Tier",
      isChild: true,
      hasCustomButton: true,
      customButton: headerActionButton,
    }));
  }, [onSave]);

  useEffect(() => {
    if (params.id !== 'null')
      getData(params.id);
  }, []);

  useEffect(() => {
    if (params.id !== 'null') {
      setFormData(data);
      gettingImage(data);
    }
  }, [data]);

  useEffect(() => {
    if (sendingStatus === "success") window.history.back();
  }, [sendingStatus]);

  const gettingImage = async (data) => {
    try {
      const url = "/v1/upload-image/?filename="
      const fetchAirlineIcon = await request({
        url: `${url}${data?.airlineIcon}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: "arraybuffer",
      });
      const airlineIcon = new Buffer(fetchAirlineIcon).toString("base64");
      setImages({ airlineIcon: airlineIcon });
    } catch (error) {
      console.log(error);
    }

  }

  const handleChange = (e) => {
    setFormData((temp) => ({ ...temp, [e.target.name]: e.target.type === "file" ? e.target.files[0] : e.target.value }));
  };

  const submitReason = (reason) => {
    dispatch(affectConsumer(formData, popupData.type, reason));
    setPopupData(temp => ({ ...temp, show: false }));
    // dispatch(affectConsumer(formData, "ban"));
  }

  const handleCheck = (e) => {
    setFormData(temp => ({ ...temp, isActive: e.target.checked }));
  }

  const handleIsPercent = (e) => {
    setIsPercent(e.target.checked);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Airline Info</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Airline Type*</label>
                </div>
                <div className="col-sm-6">
                  <select
                    className="form-control select2"
                    id="airlineType"
                    name="airlineType"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    value={formData?.airlineType}
                    required
                  >
                    <option value="">Choose One</option>
                    <option value="domestic">Domestik</option>
                    <option value="international">International</option>
                  </select>
                </div>
              </div>
              {/* <InputField
                type="text"
                label="Carrier Code"
                name="airlineCode"
                value={formData?.airlineCode}
                maxLength={2}
                onChange={handleChange}
                required={true}
              />
              <InputField
                type="text"
                label="Flight Number"
                name="flightNumber"
                value={formData?.flightNumber}
                onChange={handleChange}
                required={true}
              /> */}
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Is Percentage</label>
                </div>
                <div className="col-sm-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="percentage"
                      checked={formData?.percentage}
                      onChange={handleIsPercent}
                    />
                    <label className="form-check-label">Is Percentage</label>
                  </div>
                </div>
              </div>
              {isPercent ?
                <InputField
                  type="text"
                  label="Amount Fee"
                  name="percentFee"
                  value={formData?.percentFee}
                  maxLength={50}
                  onChange={handleChange}
                  required={true}
                />
                :
                <InputField
                  type="text"
                  label="Amount Fee"
                  name="amountFee"
                  value={formData?.amountFee}
                  maxLength={50}
                  onChange={handleChange}
                  required={true}
                />
              }
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Status</label>
                </div>
                <div className="col-sm-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="status"
                      checked={formData?.isActive}
                      onChange={handleCheck}
                    />
                    <label className="form-check-label">Active</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupData.show &&
        <ReasonPopup
          header={popupData.header}
          handleSave={submitReason}
          handleClose={() => setPopupData(temp => ({ ...temp, show: false }))}
        />
      }
    </div>
  );
};

export default EditFlight;
