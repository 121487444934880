import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoadingTable, showLoadingTable } from "../../redux/actions/actions.actions";
import { AirlinesService } from "../../_services/airlines.services";

const useAirlines = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);
  const [data, setData] = useState({});

  const getData = async (page, size, searchValue, orderBy) => {
    try {
      dispatch(showLoadingTable());
      const res = await AirlinesService.getListData(page, size, searchValue, orderBy, token);

      setData(res.data);
      dispatch(hideLoadingTable());
    } catch (error) {
      dispatch(hideLoadingTable());
    }
  }

  return [data, getData];
}

export default useAirlines;