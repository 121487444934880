import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTables from "../../components/datatables/datatables.component";
import MultipleSearch from "../../components/multiple-search/multiple-search.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import {
  activateMerchant,
  deleteMerchant,
  getDataList,
  getMasterData,
  initCreate,
} from "../../redux/merchat-management/merchant-management.action";

const MerchantManagement = () => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const tableHeaders = [
    {
      text: "Merchant Name",
      sort: true,
      name: "merchantName",
      search: true,
      type: "text",
    },
    {
      text: "Merchant PAN",
      sort: true,
      name: "merchantPan",
      search: true,
      type: "text",
    },
    { text: "Email", sort: true, name: "email", search: true, type: "text" },
    {
      text: "City",
      sort: true,
      name: "city.cityName",
      search: true,
      type: "text",
    },
    {
      text: "Province",
      sort: true,
      name: "province.provinceName",
      search: true,
      type: "text",
    },
    {
      text: "Status",
      sort: true,
      name: "status",
      search: true,
      type: "text",
      specialFunc: (val) => (val ? "Active" : "Not Active"),
    },
    {
      text: "Modified Date",
      sort: true,
      name: "lastModifiedDate",
      search: false,
      type: "date",
    },
  ];

  const actionButton =
    user?.role.roleName === "MERCHANT_GROUP"
      ? [
          {
            icon: <span className="fas fa-edit" aria-hidden="true"></span>,
            description: "Edit",
            action: (val) => history.push(`/edit-merchant/${val.merchantId}`),
            condition: (val) => true,
          },
          {
            icon: <span className="fas fa-trash" aria-hidden="true"></span>,
            description: "Delete",
            action: (val) => {
              if (window.confirm("Are you sure want to delete?")) {
                dispatch(deleteMerchant(val.merchantId));
              }
            },
            condition: (val) => true,
          },
        ]
      : [
          {
            icon: <span className="fas fa-edit" aria-hidden="true"></span>,
            description: "Edit",
            action: (val) => history.push(`/edit-merchant/${val.merchantId}`),
            condition: (val) => true,
          },
          {
            icon: <span className="fas fa-trash" aria-hidden="true"></span>,
            description: "Delete",
            action: (val) => {
              if (window.confirm("Are you sure want to delete?")) {
                dispatch(deleteMerchant(val.merchantId));
              }
            },
            condition: (val) => true,
          },
          {
            icon: (
              <span className="fas fa-user-check" aria-hidden="true"></span>
            ),
            description: "Activate Sub Merchant",
            action: (val) => dispatch(activateMerchant(val.merchantId)),
            condition: (val) => !val.activationDate,
          },
        ];

  const {
    // status,
    merchantList,
    first,
    last,
    totalData,
    totalPage,
    // error,
    empty,
    errorDelete,
    errorActivate,
  } = useSelector((state) => state.merchantManagement);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState([]);
  const [orderBy, setOrderBy] = useState({
    field: "lastModifiedDate",
    direction: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDataList(page, size, searchQuery, orderBy));
  }, [page, searchQuery, orderBy, dispatch, size]);

  useEffect(() => {
    if (errorDelete === false || errorActivate === false) {
      dispatch(getDataList(page, size, searchQuery, orderBy));
    }
  }, [errorDelete, errorActivate]);

  useEffect(() => {
    // set action button
    dispatch(
      setHeaderButton({
        add: true,
        pathAdd: "/add-merchant",
        upload: true,
        pathUpload: "/upload-merchant",
        titleHeader: "Merchant",
      })
    );
    dispatch(getMasterData());
    dispatch(initCreate());
  }, [dispatch]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setPage(0);
  };

  return (
    <div className="container-fluid">
      <MultipleSearch
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        headers={tableHeaders}
        showStatus={true}
      />
      <DataTables
        headers={tableHeaders}
        listData={merchantList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default MerchantManagement;
