import { useDispatch } from "react-redux";
import Alert from "../../components/alert/alert.component";
import { doActivate } from "../../redux/auth/auth.actions";
import "./activate.style.scss";

const Activate = () => {
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    const param = window.location.search.split("=");
    dispatch(doActivate(param[1], e["password"].value, e["retype"].value));
  };

  return (
    <>
      <Alert />
      <div className="activate-container">
        <div className="register-box">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <a href="/" className="h1">
                <b>Duwit</b>
              </a>
            </div>
            <form
              className="card-body"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e.target.elements);
              }}
            >
              <p className="login-box-msg">
                To activate your account, please input password to register
              </p>

              <div className="input-group mb-3">
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  name="retype"
                  type="password"
                  className="form-control"
                  placeholder="Retype password"
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8"></div>
                <div className="col-4">
                  <button type="submit" className="btn btn-primary btn-block">
                    Activate
                  </button>
                </div>
              </div>

              <a href="/" className="text-center">
                Login
              </a>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activate;
