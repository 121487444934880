import { useState } from "react";
import "./reason-popup.scss";

const ReasonPopup = ({ header, handleSave, handleClose }) => {
  const [formData, setFormData] = useState("");

  const handleChange = (e) => {
    setFormData(e.target.value);
  }

  return (
    <div className="popup-background">
      <div className="popup-reason">
        <div className="popup-header">
          Reason {header}
        </div>
        <div className="popup-body">
          <textarea
            className="form-control"
            rows="3"
            name="description"
            value={formData}
            onChange={handleChange}
          ></textarea>
          <div className="button-group-popup">
            <button
              type="button"
              name="save"
              className="btn btn-danger"
              onClick={handleClose}
            >
              <i className="fas fa-door-closed"></i>
              Cancel
            </button>
            <button
              type="button"
              name="save"
              className="btn btn-success"
              onClick={() => handleSave(formData)}
            >
              <i className="fas fa-floppy-o"></i>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReasonPopup;