import { useEffect, useState } from "react";
import Select from "react-select";

const MultipleSelect = ({ item, handleChange, value, searchable = false, handleSearch, loading = false, isMulti = false }) => {
  const [rawValue, setRawValue] = useState([]);

  useEffect(() => {
    if (value === "") {
      setRawValue(isMulti ? [] : {});
    }
  }, [value]);

  useEffect(() => {
    if (isMulti) {
      let val = [];
      rawValue.forEach(d => {
        val.push(d.value);
      });
      handleChange({
        target: {
          value: val.join(","),
          name: item.searchName || item.name
        }
      });
    } else {
      handleChange({
        target: {
          value: rawValue?.value || "",
          name: item.searchName || item.name
        }
      });
    }
  }, [rawValue]);

  return (
    <>
      <div className="col-sm-1">
        <label>{item.text}</label>
      </div>
      <div className="col-sm-3">
        <Select
          isSearchable={searchable}
          isLoading={loading}
          onInputChange={(e) => {
            if (searchable) handleSearch(e);
          }}
          isClearable={true}
          options={item.options}
          isMulti={isMulti}
          onChange={setRawValue}
          value={rawValue}
        />
      </div>
    </>
  )
}

export default MultipleSelect;