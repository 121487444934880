const TextField = ({ label, name, value, type, handleChange }) => {
  return (
    <>
      <div className="col-sm-1">
        <label>{label}</label>
      </div>
      <div className="col-sm-3">
        <input type={type} className="form-control" name={name} onChange={handleChange} value={value} />
      </div>
    </>
  )
}

export default TextField;