import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTables from "../../components/datatables/datatables.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { getReportMerchant } from "../../redux/report/report.action";
import SearchMerchant from "./search-merchant.component";

const MerchantList = () => {
  const history = useHistory();
  const tableHeaders = [
    {
      text: "Merchant Name",
      sort: true,
      name: "merchantName",
      search: true,
      type: "text",
    },
    {
      text: "Merchant PAN",
      sort: true,
      name: "merchantPan",
      search: true,
      type: "text",
    },
    { text: "Email", sort: true, name: "email", search: true, type: "text" },
    {
      text: "City",
      sort: true,
      name: "city.cityName",
      search: true,
      type: "text",
    },
    {
      text: "Province",
      sort: true,
      name: "province.provinceName",
      search: true,
      type: "text",
    },
    {
      text: "Phone Owner",
      sort: true,
      name: "phoneOwner",
      search: true,
      type: "text",
    },
    {
      text: "Phone Store",
      sort: true,
      name: "phoneStore",
      search: true,
      type: "text",
    },
    {
      text: "Bank Name",
      sort: true,
      name: "bankName",
      search: true,
      type: "text",
    },
    {
      text: "Bank Account",
      sort: true,
      name: "bankAccount",
      search: true,
      type: "text",
    },
    {
      text: "MDR(%)",
      sort: true,
      name: "mdr",
      search: true,
      type: "text",
    },
    {
      text: "Status",
      sort: true,
      name: "status",
      search: true,
      type: "text",
      specialFunc: (val) => (val ? "Active" : "Not Active"),
    },
    {
      text: "Activation Date",
      sort: true,
      name: "lastModifiedDate",
      search: false,
      type: "date",
    },
  ];

  const actionButton = [];

  const {
    // status,
    reportMerchantList,
    first,
    last,
    totalData,
    totalPage,
    // error,
    empty,
    errorDelete,
    errorActivate,
  } = useSelector((state) => state.report);
  const [bottom, setBottom] = useState("");
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState([]);
  const [orderBy, setOrderBy] = useState({
    field: "",
    direction: "false",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportMerchant(page, size, searchQuery, orderBy));
  }, [page, searchQuery, orderBy, dispatch, size, errorDelete, errorActivate]);

  useEffect(() => {
    if (bottom !== "")
      bottom.scrollIntoView({ behavior: "smooth" });
  }, [reportMerchantList]);
  
  useEffect(() => {
    // set action button
    dispatch(setHeaderButton({ titleHeader: "Report Merchant List" }));
  }, [dispatch]);
  
  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (query) => {
    setPage(0);
    setSearchQuery(query);
  };

  return (
    <div className="container-fluid">
      {/* <MultipleSearch
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        headers={tableHeaders}
      /> */}
      <SearchMerchant searchQuery={searchQuery} handleSearch={handleSearch} />
      <DataTables
        headers={tableHeaders}
        listData={reportMerchantList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
        scroll={"scroll-able"}
      />
      <div ref={(e) => setBottom(e)}></div>
    </div>
  );
};

export default MerchantList;
