const AuthActionTypes = {
    LOGIN: "LOGIN",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    LOGOUT: "LOGOUT",
    ACTIVATE: "ACTIVATE",
    ACTIVATE_SUCCESS: "ACTIVATE_SUCCESS",
    ACTIVATE_FAILURE: "ACTIVATE_FAILURE",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  };
  
  export default AuthActionTypes;
  