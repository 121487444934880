const ConsumerTransactionTypes = {
  GET_DATA_CONSUMER_TRANSACTION: "GET_DATA_CONSUMER_TRANSACTION",
  GET_DATA_CONSUMER_TRANSACTION_SUCCESS: "GET_DATA_CONSUMER_TRANSACTION_SUCCESS",
  GET_DATA_CONSUMER_TRANSACTION_FAILURE: "GET_DATA_CONSUMER_TRANSACTION_FAILURE",
  GET_DATA_CONSUMER_TRANSACTION_DETAIL: "GET_DATA_CONSUMER_TRANSACTION_DETAIL",
  GET_DATA_CONSUMER_TRANSACTION_DETAIL_SUCCESS: "GET_DATA_CONSUMER_TRANSACTION_DETAIL_SUCCESS",
  GET_DATA_CONSUMER_TRANSACTION_DETAIL_FAILURE: "GET_DATA_CONSUMER_TRANSACTION_DETAIL_FAILURE",
  GET_STATUS_CONSUMER_TRANSACTION: "GET_STATUS_CONSUMER_TRANSACTION",
  GET_STATUS_CONSUMER_TRANSACTION_SUCCESS: "GET_STATUS_CONSUMER_TRANSACTION_SUCCESS",
  GET_STATUS_CONSUMER_TRANSACTION_FAILURE: "GET_DATA_CONSUMER_TRANSACTION_FAILURE",
  GET_AGENT_SELECTIONS: "GET_AGENT_SELECTIONS",
  GET_AGENT_SELECTIONS_SUCCESS: "GET_AGENT_SELECTIONS_SUCCESS",
  GET_AGENT_SELECTIONS_FAILURE: "GET_AGENT_SELECTIONS_FAILURE",
}

export default ConsumerTransactionTypes;