/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select, { components } from "react-select";
import DataTables from "../../components/datatables/datatables.component";
import SearchGrid from "../../components/search-grid/index.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { defaultConsumer, getConsumerMasterData, getDataConsumer } from "../../redux/consumer/consumer.action";

const ConsumerPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [orderBy, setOrderBy] = useState({
    field: "modifiedDate",
    direction: false,
  });
  const [optionRole, setOptionRole] = useState([]);
  const [optionStatus, setOptionStatus] = useState([]);
  const [optionUpgrade, setOptionUpgrade] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const {
    consumerList,
    first,
    last,
    totalData,
    totalPage,
    empty,
    selectRoles,
    selectStatus,
    selectUpgrade,
  } = useSelector((state) => state.dataConsumer);
  const tableHeaders = [
    {
      text: "Full Name",
      sort: true,
      name: "fullName",
      search: true,
      type: "text",
      position: "left",
      index: 0,
      searchType: "text",
    },
    {
      text: "Phone",
      sort: true,
      name: "msisdn",
      search: true,
      type: "text",
      position: "left",
      index: 2,
      searchType: "text",
    },
    {
      text: "Email",
      sort: true,
      name: "email",
      search: true,
      type: "text",
      position: "left",
      index: 1,
      searchType: "text",
    },
    {
      text: "Roles",
      sort: true,
      name: "rolesName",
      searchName: "roles",
      search: true,
      type: "text",
      position: "right",
      index: 0,
      searchType: 'select',
      options: optionRole,
    },
    {
      text: "Status",
      sort: true,
      name: "statusName",
      searchName: "statusList",
      search: true,
      type: "text",
      position: "right",
      index: 1,
      searchType: 'multiple',
      options: optionStatus,
      // specialFunc: (val) => (val ? "Active" : "Not Active"),
    },
    {
      text: "Registered On",
      sort: false,
      name: "createdDate",
      search: false,
      type: "date",
    },
    {
      text: "Upgrade",
      sort: false,
      name: "upgradeStatusName",
      searchName: "upgradeStatusList",
      search: false,
      type: "text",
      position: "right",
      index: 2,
      searchType: 'multiple',
      options: optionUpgrade,
    },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-edit" aria-hidden="true"></span>,
      description: "Edit",
      action: (val) => history.push(`/edit-consumer/${val.consumerId}`),
      condition: (val) => true,
    },
  ];

  useEffect(() => {
    if (selectRoles.length > 0) {
      let temp = [];
      selectRoles.forEach(item => {
        temp.push({ value: item.roleId, label: item.roleName });
      });
      setOptionRole(temp);
    }
  }, [selectRoles])

  useEffect(() => {
    if (selectStatus.length > 0) {
      let temp = [];
      selectStatus.forEach(item => {
        temp.push({ value: item.statusId, label: item.statusName });
      });
      setOptionStatus(temp);
    }
  }, [selectStatus])

  useEffect(() => {
    if (selectUpgrade.length > 0) {
      let temp = [];
      selectUpgrade.forEach(item => {
        temp.push({ value: item.upgradeStatusId, label: item.upgradeStatusName });
      });
      setOptionUpgrade(temp);
    }
  }, [selectUpgrade])

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Consumer",
      })
    );
    dispatch(getConsumerMasterData());
    dispatch(defaultConsumer());
  }, []);

  useEffect(() => {
    dispatch(getDataConsumer(page, size, searchValue, orderBy));
  }, [page, size, orderBy, searchValue]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (val) => {
    setPage(0);
    if (val.length > 0) setSearchValue(val)
    else setSearchValue([]);
  }

  return (
    <div className="container-fluid">
      <SearchGrid
        searchField={tableHeaders}
        handleSearch={handleSearch}
        value={searchValue}
      />
      <DataTables
        headers={tableHeaders}
        listData={consumerList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default ConsumerPage;
