import request from "../_network/request";
const parameterURL = "/v1/parameters/";

const getParameterData = (page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${orderBy.field !== ""
    ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
    : ""
    }`;

  return request({
    url: `${parameterURL}?${searchArray.length > 0 ? searchArray.join("") : ""
      }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

const getDetailParameter = (id, token) => {
  return request({
    url: `${parameterURL}/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
}

const updateParameter = (id, data, token) => {
  return request({
    url: `${parameterURL}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
}

export const ParameterServices = {
  getParameterData,
  getDetailParameter,
  updateParameter,
}