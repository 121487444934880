import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { getTransactionPartnerById, approveRefund } from "../../redux/partner/partner.actions";
const PartnerTransactionForm = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const { transactionPartnerByid, approveRefundSuccess} =
        useSelector((state) => state.partnerTransaction);

    const onApproveRefund = () => {
        if (window.confirm("Are you sure want to Approve Refund?")) {
            const data = {
                // "errorReason": "string",
                "isFeePercent": false,
                "refundFee": transactionPartnerByid.chargeFee,
                "partnerId": transactionPartnerByid.partnerId,
                "refundStatus": "SUCCEED",
                "transactionId": transactionPartnerByid.transactionId
            }
            dispatch(approveRefund(data));
        }
    }

    const onRejectRefund = () => {
        if (window.confirm("Are you sure want to Reject Refund?")) {
            const data = {
                // "errorReason": "string",
                "isFeePercent": false,
                "refundFee": transactionPartnerByid.chargeFee,
                "partnerId": transactionPartnerByid.partnerId,
                "refundStatus": "FAILED",
                "transactionId": transactionPartnerByid.transactionId
            }
            dispatch(approveRefund(data));
        }
    }

    useEffect(() => {
        if (
            approveRefundSuccess === "success" 
        )
            window.history.back();
    }, [approveRefundSuccess]);

    const headerActionButton = [
        <button
          type="button"
          name="save"
          className="btn btn-primary"
          onClick={() => {
            onApproveRefund();
          }}
          style={{ display: (transactionPartnerByid.transactionType === "REFUND" && transactionPartnerByid.status === "PENDING") ? "block" : "none" }}
        >
          <i className="fas fa-user-lock"></i>
          Approve Refund
        </button>,
        <button
          type="button"
          name="save"
          className="btn btn-warning"
          onClick={() => {
            onRejectRefund();
          }}
          style={{ display: (transactionPartnerByid.transactionType === "REFUND" && transactionPartnerByid.status === "PENDING") ? "block" : "none" }}
        >
          <i className="fas fa-user-lock"></i>
          Reject Refund
        </button>,
      ]

    useEffect(() => {
        dispatch(
            setHeaderButton({
                titleHeader: transactionPartnerByid.businessName,
                isChild: true,
                hasCustomButton: true,
                customButton: headerActionButton,
            })
        );
    }, [dispatch, transactionPartnerByid]);

    useEffect(() => {
        if (params.id) dispatch(getTransactionPartnerById(params.id));
    }, [dispatch, params.id]);


    const formatDate = (rawDate) => {
        let date = new Date(rawDate);
        const year = date.getFullYear();
        const month =
            date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1;
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const seconds = date.getSeconds();
        return `${day}-${month}-${year} ${hour}:${minute}:${seconds}`;
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-sm-12">
                    <div className="card card-primary card-outline card-tabs">
                        <div className="card-header p-0 pt-1 border-bottom-0">
                            <ul
                                className="nav nav-tabs"
                                id="custom-tabs-three-tab"
                                role="tablist"
                            >
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        id="custom-tabs-three-home-tab"
                                        data-toggle="pill"
                                        href="#custom-tabs-three-home"
                                        role="tab"
                                        aria-controls="custom-tabs-three-home"
                                        aria-selected="true"
                                    >
                                        Transaction Info
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div
                                className="tab-content"
                                id="custom-tabs-three-tabContent"
                            >
                                <div
                                    className="tab-pane fade show active"
                                    id="custom-tabs-three-home"
                                    role="tabpanel"
                                    aria-labelledby="custom-tabs-three-home-tab"
                                >
                                    <form className="form-horizontal">
                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Status
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        transactionPartnerByid.status
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Partner Name
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        transactionPartnerByid.businessName
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Transaction Time
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={formatDate(
                                                        transactionPartnerByid.transactionTime
                                                    )}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Transaction Id
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        transactionPartnerByid.transactionId
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Transaction Type
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        transactionPartnerByid.transactionType
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Request Id
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        transactionPartnerByid.requestId
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Transaction Amount (Rp)
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        transactionPartnerByid.amount
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Charge Fee (Rp)
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        transactionPartnerByid.chargeFee
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Void Status
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        transactionPartnerByid.voidStatus
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Voided At
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        formatDate(
                                                            transactionPartnerByid.voidAt
                                                        )
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Date Created
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        formatDate(
                                                            transactionPartnerByid.createdDate
                                                        )
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-2">
                                                <label >
                                                    Date Modified
                                                </label>
                                            </div>
                                            <div className="col-sm-6">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={
                                                        formatDate(
                                                            transactionPartnerByid.createdDate
                                                        )
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnerTransactionForm;
