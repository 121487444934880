import request from "../_network/request";

const urlAirlines = "/v1/airlines";
const urlRoles = "/role/list?offset=0&limit=10000";

const getListData = (
  page,
  size,
  searchQuery = [],
  orderBy,
  token
) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${orderBy.field !== ""
      ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
      : ""
    }`;

  return request({
    url: `${urlAirlines}?${searchArray.length > 0 ? searchArray.join("") : ""
      }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getAirlineDetail = (
  id,
  token
) => {
  return request({
    url: `${urlAirlines}/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createAirlines = (data, token) => {
  return request({
    url: `${urlAirlines}`,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateAirlines = (id, data, token) => {
  return request({
    url: `${urlAirlines}/${id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteAirlines = (id, token) => {
  return request({
    url: `${urlAirlines}/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const AirlinesService = {
  getListData,
  createAirlines,
  getAirlineDetail,
  updateAirlines,
  deleteAirlines,
};
