import request from "../_network/request";
const consumerURL = "/v1/consumer-transactions";

const getConsumerTransactionData = (page, size, searchQuery = [], orderBy, token) => {
  let searchArray = [];
  if (searchQuery.length > 0) {
    searchQuery.forEach((key, i) => {
      searchArray.push(`&${key.param}=${key.value}`);
    });
  }
  let order = `${orderBy.field !== ""
    ? `&sort=${orderBy.field},${orderBy.direction ? "asc" : "desc"}`
    : ""
    }`;

  return request({
    url: `${consumerURL}/header?${searchArray.length > 0 ? searchArray.join("") : ""
      }&page=${page}&size=${size}${order}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getConsumerTransactionStatus = (token) => {
  return request({
    url: `${consumerURL}/status`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

const getTransactionHistories = (id, token) => {
  return request({
    url: `${consumerURL}/histories?consumerId=${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    },
  });
}

const getAgents = (token) => {
  return request({
    url: `/v1/agents`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
}

export const ConsumerTransactionServices = {
  getConsumerTransactionData,
  getConsumerTransactionStatus,
  getTransactionHistories,
  getAgents
}