import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./datatables.style.scss";
import moment from "moment";

const DataTables = ({
  headers,
  listData,
  first,
  last,
  totalData,
  totalPage,
  empty,
  handleAction,
  page,
  size,
  listAction,
  scroll = "fixed",
  pagination = true,
}) => {
  const formatDate = (rawDate) => {
    // let date = new Date(rawDate);
    // const year = date.getFullYear();
    // const month =
    //   date.getMonth() + 1 < 10
    //     ? `0${date.getMonth() + 1}`
    //     : date.getMonth() + 1;
    // const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    // const hour = date.getHours();
    // const minute = date.getMinutes();
    // const seconds = date.getSeconds();
    // return `${day}-${month}-${year} ${hour}:${minute}:${seconds}`;
    const gmt = moment(rawDate);
    return gmt.local().format("DD-MM-YYYY HH:mm:ss");
  };
  const { loadingTable } = useSelector((state) => state.actionsUser);
  const [order, setOrder] = useState({
    field: "",
    direction: false,
  });
  const [tablesData, setTablesData] = useState([]);
  const [reqPage, setReqPage] = useState(2);

  useEffect(() => {
    if (listData) setTablesData(listData);
  }, [listData]);

  const onNext = () => {
    handleAction(page + 1, order);
  };

  const onPrev = () => {
    handleAction(page - 1, order);
  };

  const onClickPage = (clicked) => {
    handleAction(clicked - 1, order);
  };

  const onOrder = (selected, dir) => {
    let newOrder = {
      field: selected,
      direction: selected === order.field ? !dir : true,
    };
    setOrder(newOrder);
    handleAction(0, newOrder);
  };

  const generateInnerData = (data, header) => {
    let splitted = header.name.split(".");
    let useData = "";
    if(splitted.length > 2) {
      useData = data[splitted[0]]?.[splitted[1]]?.[splitted[2]]
    } else if (splitted.length > 1) {
      useData = data[splitted[0]]?.[splitted[1]]
    } else {
      useData = data[header.name];
    }
    return header.specialFunc
      ? header.specialFunc(useData)
      : header.type === "date"
      ? formatDate(useData)
      : useData;
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <tr>
              {listAction?.map((action, i) => (
                <>
                  <strong>
                    {action.icon} {action.description}
                  </strong>
                  <i className="mr-3"></i>
                </>
              ))}
            </tr>
            <table
              id="example1"
              className={`table ${scroll} table-bordered table-striped table-responsive`}
            >
              <thead style={{ backgroundColor: "#007bff" }}>
                <tr>
                  {listAction?.length > 0 && <th className="icons"></th>}
                  <th>No</th>
                  {headers.map((header) => (
                    <th
                      key={header.name}
                      className={`${header.sort ? "sorting" : ""} ${
                        header.name === order.field
                          ? order.direction
                            ? "asc"
                            : "desc"
                          : ""
                      }`}
                      onClick={() => {
                        onOrder(header.name, order.direction);
                      }}
                    >
                      {header.text}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loadingTable && (
                  <tr>
                    <td colSpan={headers.length + 2}>
                      <div className="d-flex justify-content-center">
                        <div
                          className="spinner-grow text-primary"
                          role="status"
                        ></div>
                      </div>
                    </td>
                  </tr>
                )}
                {empty && !loadingTable && (
                  <tr>
                    <td
                      colSpan={headers.length + 2}
                      style={{ textAlign: "center" }}
                    >
                      No Data
                    </td>
                  </tr>
                )}
                {!loadingTable &&
                  !empty &&
                  tablesData.map((data, i) => (
                    <tr key={i}>
                      {listAction?.length > 0 && (
                        <td>
                          {listAction.map((action, ii) => {
                            return (
                              action.condition(data) && (
                                <button
                                  key={ii}
                                  type="button"
                                  className="btn btn-default btn-xs"
                                  onClick={() => {
                                    action.action(data);
                                  }}
                                >
                                  {action.icon}
                                </button>
                              )
                            );
                          })}
                        </td>
                      )}
                      <td>{page * size + i + 1}</td>
                      {headers.map((header) => (
                        <td key={`${header.name}-${i}`}>
                          {generateInnerData(data, header)}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
            {pagination && (
              <div className="pagination-container">
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example1_paginate"
                >
                  <ul className="pagination">
                    <li
                      className={`paginate_button page-item previous ${
                        first ? "disabled" : ""
                      }`}
                    >
                      <button className="page-link" onClick={onPrev}>
                        Previous
                      </button>
                    </li>
                    <li
                      className={`paginate_button page-item ${
                        page + 1 === 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => onClickPage(1)}
                      >
                        1
                      </button>
                    </li>
                    {totalPage > 2 && (
                      <li
                        className={`paginate_button page-item ${
                          page + 1 > 1 && page < totalPage ? "active" : ""
                        }`}
                      >
                        <div className="page-link more-pages">
                          ...
                          <div className="container-pages">
                            <input
                              onChange={(e) => setReqPage(e.target.value)}
                              type="number"
                              placeholder="Page"
                              className="form-control form-control-sm"
                              min="2"
                              max={totalPage - 1}
                              style={{ width: "100px" }}
                              value={reqPage}
                            />
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => {
                                if(reqPage > 1 && reqPage < totalPage){
                                  onClickPage(reqPage);
                                  setReqPage("");
                                }
                              }}
                            >
                              Go
                            </button>
                          </div>
                        </div>
                      </li>
                    )}
                    {totalPage > 1 && (
                      <li
                        className={`paginate_button page-item ${
                          page + 1 === totalPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => {
                            onClickPage(totalPage);
                          }}
                        >
                          {totalPage}
                        </button>
                      </li>
                    )}
                    <li
                      className={`paginate_button page-item next ${
                        last ? "disabled" : ""
                      }`}
                    >
                      <button className="page-link" onClick={onNext}>
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTables;
