import { useCallback, useEffect, useState } from "react"
import SelectField from "./select-field.component";
import TextField from "./text-field.component";
import Select from "react-select";
import MultipleSelect from "./multiple-select.component";

const SearchGrid = ({ searchField = [], customSearchField = [], handleSearch, value = [] }) => {
  const [leftField, setLeftField] = useState([]);
  const [rightField, setRightField] = useState([]);
  const [searchValue, setSearchValue] = useState({});

  useEffect(() => {
    const left = searchField.filter(item => item.position === "left");
    const right = searchField.filter(item => item.position === "right");
    const tempLeft = [];
    const tempRight = [];
    if (left.length > 0) left.forEach(item => {
      tempLeft.splice(item.index, 0, item);
    });
    if (right.length > 0) right.forEach(item => {
      tempRight.splice(item.index, 0, item);
    });
    setLeftField(tempLeft);
    setRightField(tempRight);
  }, [searchField]);

  useEffect(() => {
    let tempSearch = {};
    searchField.forEach(item => {
      if (item.defaultValue) tempSearch[item.searchName || item.name] = item.defaultValue;
    });
    value.forEach(item => {
      tempSearch[item.param] = item.value;
    });
    if(Object.keys(tempSearch).length > 0) setSearchValue(temp => ({ ...temp, ...tempSearch }));
  }, [value]);

  const handleChange = (e) => {
    setSearchValue(temp => ({ ...temp, [e.target.name]: e.target.value }));
  }

  const handleOnSearch = useCallback(
    () => {
      let processSearch = [];
      for (const key in searchValue) {
        if (Object.hasOwnProperty.call(searchValue, key)) {
          processSearch.push({
            param: key,
            value: searchValue[key],
          });
        }
      }
      handleSearch(processSearch);
    },
    [searchValue, handleSearch],
  );

  return (
    <div className="row">
      <div className="col-12">
        <div className="card card-outline card-primary">
          <div className="card-header">
            <h3 className="card-title">Search</h3>

            <div className="card-tools">
              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="collapse"
              >
                <i className="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div className="card-body">
            {leftField.map((item, i) =>
              <div key={i} className="form-group row">
                <SearchField item={item} searchValue={searchValue} handleChange={handleChange} />
                <div className="col-sm-1"></div>
                {rightField[i] && (
                  <SearchField item={rightField[i]} searchValue={searchValue} handleChange={handleChange} />
                )}
              </div>
            )}

            <div className="form-group row">
              <div className="col-sm-10" style={{ textAlign: "center" }}>
                <button onClick={() => handleOnSearch()} className="btn btn-primary">
                  <i className="fas fa-search"></i>
                  Search
                </button>
                <button onClick={() => { setSearchValue({}); handleSearch([]) }} type="submit" className="btn btn-info">
                  <i className="fas fa-ban"></i>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SearchField = ({ item, searchValue, handleChange }) => {
  if (item.searchType === "select")
    return <SelectField
      label={item.text}
      name={item.searchName || item.name}
      value={searchValue[item.searchName || item.name] || ""}
      options={item.options}
      handleChange={handleChange}
    />
  if (item.searchType === "multiple")
    return <MultipleSelect
      item={item}
      handleChange={handleChange}
      value={searchValue[item.searchName || item.name] || ""}
      isMulti={true}
    />
  if (item.searchType === "searchable")
    return <MultipleSelect
      item={item}
      handleChange={handleChange}
      value={searchValue[item.searchName || item.name] || ""}
      searchable={true}
      handleSearch={item.handleSearch}
      loading={item.loading}
      isMulti={false}
    />
  return <TextField
    label={item.text}
    name={item.searchName || item.name}
    value={searchValue[item.searchName || item.name] || ""}
    type={item.searchType}
    handleChange={handleChange}
  />

}

export default SearchGrid;