import request from "../_network/request";
import {load} from "react-cookies";

const getUserList = (payload) => {
  let token = load("tc-sm");
  return request({
    url: `/plc-msa-auth/user/list?${payload}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const updateUserList = (id, payload) => {
  let token = load("tc-sm");
  return request({
    url: `/plc-msa-auth/user/update/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: payload
  });
};

const deleteUserList = (id) => {
  let token = load("tc-sm");
  return request({
    url: `/plc-msa-auth/user/delete/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export const MasterUserService = {
  getUserList,
  updateUserList,
  deleteUserList,
};
