const TransactionTypes = {
  GET_DATA_TRANSACTION: "GET_DATA_TRANSACTION",
  GET_DATA_TRANSACTION_SUCCESS: "GET_DATA_TRANSACTION_SUCCESS",
  GET_DATA_TRANSACTION_FAILURE: "GET_DATA_TRANSACTION_FAILURE",
  UPDATE_SETTLEMENT: "UPDATE_SETTLEMENT",
  UPDATE_SETTLEMENT_SUCCESS: "UPDATE_SETTLEMENT_SUCCESS",
  UPDATE_SETTLEMENT_FAILURE: "UPDATE_SETTLEMENT_FAILURE",
  GET_DATA_BALANCE: "GET_DATA_BALANCE",
  GET_DATA_BALANCE_SUCCESS : "GET_DATA_BALANCE_SUCCESS",
  GET_DATA_BALANCE_FAILURE : "GET_DATA_BALANCE_FAILURE",
  EXPORT_TRANSACTION : "EXPORT_TRANSACTION",
  EXPORT_TRANSACTION_SUCCESS: "EXPORT_TRANSACTION_SUCCESS",
  EXPORT_TRANSACTION_FAILURE: "EXPORT_TRANSACTION_FAILURE",
}

export default TransactionTypes;