import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { changePassword, logout } from "../../redux/auth/auth.actions";

const Profile = (props) => {
  const dispatch = useDispatch();
  const { user, changePasswordState, kc } = useSelector((state) => state.auth);
  const [formUser, setFormUser] = useState({
    oldPassword: "",
    newPassword: "",
    retype: "",
  });
  const [pwdPassed, setPwdPassed] = useState(true);

  useEffect(() => {
    dispatch(
      setHeaderButton({
        titleHeader: "Profile",
      })
    );
  }, []);

  useEffect(() => {
    if (changePasswordState === "success") {
      dispatch(logout());
      kc.logout({ redirectUri: window.location.origin })
    }
  }, [changePasswordState]);

  const handleChange = (e) => {
    let tempForm = { ...formUser };
    tempForm[e.target.name] = e.target.value;
    setFormUser(tempForm);
  };

  const setNewPassword = () => {
    if (
      formUser.retype !== "" &&
      formUser.newPassword !== "" &&
      formUser.oldPassword !== ""
    ) {
      var reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
      if (reg.test(formUser.newPassword)) {
        dispatch(changePassword(formUser));
      } else {
        setPwdPassed(false);
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-sm-12">
          <div className="card card-primary card-outline card-tabs">
            <div className="card-header p-0 pt-1 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-three-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-three-home-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-home"
                    role="tab"
                    aria-controls="custom-tabs-three-home"
                    aria-selected="true"
                  >
                    Info User
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-three-password-tab"
                    data-toggle="pill"
                    href="#custom-tabs-three-password"
                    role="tab"
                    aria-controls="custom-tabs-three-owner"
                    aria-selected="true"
                  >
                    Change Password
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-three-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-three-home"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-home-tab"
                >
                  <div className="form-horizontal">
                    {user.merchant ? (
                      <div className="form-group row">
                        <div className="col-sm-2">
                          <label>Merchant ID</label>
                        </div>
                        <div className="col-sm-6">
                          <label>{user.merchant.merchantId}</label>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputName">Name</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          id="inputName"
                          placeholder="Name"
                          value={user.fullName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputEmail">Email</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="email"
                          className="form-control"
                          id="inputEmail"
                          placeholder="Email"
                          value={user.email || "no email"}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputRole">Role</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="email"
                          className="form-control"
                          id="inputRole"
                          placeholder="Role"
                          value={user.role.roleName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputStatus">Status</label>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={user.active}
                            disabled
                          />
                          <label className="form-check-label">Active</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-three-password"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-three-password-tab"
                >
                  <div className="form-horizontal">
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputOldPassword">Old Password *</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="password"
                          className="form-control"
                          id="inputOldPassword"
                          placeholder="Old Password"
                          name="oldPassword"
                          value={formUser.oldPassword}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputNewPassword">New Password *</label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="password"
                          className="form-control"
                          id="inputNewPassword"
                          placeholder="New Password"
                          name="newPassword"
                          value={formUser.newPassword}
                          onChange={handleChange}
                          required
                        />
                        {!pwdPassed && (
                          <p style={{ margin: 0, color: "hsl(0, 50%, 50%)" }}>
                            Password should contain alphabet and number, min
                            length 8
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2">
                        <label htmlFor="inputConfirmPassword">
                          Confirm New Password *
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="password"
                          className="form-control"
                          id="inputConfirmPassword"
                          placeholder="Confirm New Password"
                          name="retype"
                          value={formUser.retype}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <button
                        onClick={setNewPassword}
                        name="save"
                        className="btn btn-success"
                        disabled={
                          !formUser.retype ||
                          !formUser.oldPassword ||
                          !formUser.newPassword
                        }
                      >
                        <i className="fas fa-floppy-o"></i>
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
