/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import DataTables from "../../components/datatables/datatables.component";
import InputField from "../../components/input-field/input-field.component";
import ReasonPopup from "../../components/reason-popup/reason-popup.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { affectConsumer, getAllCities, getConsumerDetail, updateConsumer } from "../../redux/consumer/consumer.action";
import request from "../../_network/request";

const EditConsumer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { identity, refCodesPhase2, marriageStatus } = useSelector(state => state.masterData);
  const { COUNTRY } = refCodesPhase2;
  const { consumerDetail, histories, sendingStatus, cities } = useSelector(state => state.dataConsumer);
  const [identityPeriod, setIdentityPeriod] = useState(0);
  const { token } = useSelector(state => state.auth);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    msisdn: "",
    consumerPremium: {
      address: "",
      approvalDate: "",
      city: "",
      consumerPremiumId: "",
      country: "",
      dateOfBirth: "",
      gender: "",
      identityName: "",
      identityNo: "",
      identityPeriod: "",
      identityType: "",
      imageIdentity: "",
      imageSelfie: "",
      kecamatan: "",
      kelurahan: "",
      occupation: "",
      placeOfBirth: "",
      postal: "",
      reason: "",
      status: "",
      submitDate: "",
      upgradeStatus: "",
    },
  });
  const [images, setImages] = useState({
    identity: '',
    selfie: '',
  });
  const [popupData, setPopupData] = useState({
    show: false,
    header: "",
    type: "",
  })

  const onSave = useCallback(() => {
    dispatch(updateConsumer(formData));
  }, [formData]);

  const headerActionButton = [
    <button
      type="button"
      name="save"
      className="btn btn-primary"
      onClick={() => {
        onSave();
      }}
    >
      <i className="fas fa-floppy-o"></i>
      Save
    </button>,
    <button
      type="button"
      name="save"
      className="btn btn-info"
      onClick={() => dispatch(affectConsumer(formData, "open"))}
      style={{ display: formData?.status !== 203 && formData?.status !== 204 ? "none" : "block" }}
    >
      <i className="fas fa-lock-open"></i>
      Open Lock / Ban
    </button>,
    <button
      type="button"
      name="save"
      className="btn btn-success"
      onClick={() => dispatch(affectConsumer(formData, "approve"))}
      style={{ display: formData?.consumerPremium?.status !== 301 ? "none" : "block" }}
    >
      <i className="fas fa-thumbs-up"></i>
      Approve Upgrade
    </button>,
    <button
      type="button"
      name="save"
      className="btn btn-danger"
      onClick={() => dispatch(affectConsumer(formData, "reject"))}
      style={{ display: formData?.consumerPremium?.status !== 301 ? "none" : "block" }}
    >
      <i className="fas fa-thumbs-down"></i>
      Reject Upgrade
    </button>,
    <button
      type="button"
      name="save"
      className="btn btn-warning"
      onClick={() => setPopupData({ show: true, header: "Ban Account", type: "ban" })}
      style={{ display: formData?.status === 205 || formData?.status === 203 ? "none" : "block" }}
    >
      <i className="fas fa-user-lock"></i>
      Ban
    </button>,
    <button
      type="button"
      name="save"
      className="btn btn-danger"
      onClick={() => setPopupData({ show: true, header: "Close Account", type: "close" })}
      style={{ display: formData?.status === 205 ? "none" : "block" }}
    >
      <i className="fas fa-door-closed"></i>
      Close Account
    </button>,
  ]

  useEffect(() => {
    dispatch(setHeaderButton({
      titleHeader: "Edit Consumer",
      isChild: true,
      hasCustomButton: true,
      customButton: headerActionButton,
    }));
  }, [onSave]);

  useEffect(() => {
    dispatch(getConsumerDetail(params.id));
    dispatch(getAllCities());
  }, []);

  useEffect(() => {
    if (sendingStatus === "success") window.history.back();
  }, [sendingStatus]);

  useEffect(() => {
    setFormData(consumerDetail);
    if (consumerDetail?.consumerPremium) gettingImage(consumerDetail);
    if (consumerDetail?.consumerPremium?.identityPeriod) {
      setIdentityPeriod(1);
      const feDateFormat = new Date(consumerDetail?.consumerPremium?.identityPeriod).toISOString().split("T")[0];
      setFormData(temp => ({ ...temp, consumerPremium: { ...temp.consumerPremium, identityPeriod: feDateFormat } }))
    }
  }, [consumerDetail]);

  const gettingImage = async (data) => {
    try {
      const url = "/v1/upload-image/?filename="
      const fetchIdentity = await request({
        url: `${url}${data?.consumerPremium?.imageIdentity}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: "arraybuffer",
      });
      const fetchSelfie = await request({
        url: `${url}${data?.consumerPremium?.imageSelfie}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        },
        responseType: "arraybuffer",
      });
      const imageIdentity = new Buffer(fetchIdentity).toString("base64");
      const imageSelfie = new Buffer(fetchSelfie).toString("base64");
      setImages(temp => ({ identity: imageIdentity, selfie: imageSelfie }));
    } catch (error) {
      console.log(error);
    }

  }

  const handleChange = (e) => {
    setFormData((temp) => ({ ...temp, [e.target.name]: e.target.value }));
  };

  const handleChangePremium = (e) => {
    setFormData(temp => ({
      ...temp,
      consumerPremium: {
        ...temp.consumerPremium,
        [e.target.name]: e.target.value,
      }
    }));
  }

  const handleChangeRadio = (e) => {
    setFormData(temp => ({
      ...temp,
      consumerPremium: {
        ...temp.consumerPremium,
        [e.target.name]: e.target.checked,
      },
    }));
  }

  const submitReason = (reason) => {
    dispatch(affectConsumer(formData, popupData.type, reason));
    setPopupData(temp => ({ ...temp, show: false }));
    // dispatch(affectConsumer(formData, "ban"));
  }

  const tableHeaders = [
    {
      text: "Trx Status",
      sort: false,
      name: "transactionStatusName",
      search: true,
      type: "text",
    },
    {
      text: "Payment Status",
      sort: false,
      name: "transactionStatus",
      search: true,
      type: "text",
    },
    {
      text: "Trx Date",
      sort: false,
      name: "transactionDate",
      search: false,
      type: "date",
    },
    {
      text: "Trx No",
      sort: false,
      name: "referenceNo",
      search: false,
      type: "text",
    },
    {
      text: "Product",
      sort: false,
      name: "order.agentDisplayName",
      search: true,
      type: "text",
    },
    {
      text: "Total",
      sort: false,
      name: "amount",
      search: true,
      type: "text",
      specialFunc: (val) => val.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })
    },
    {
      text: "Reason",
      sort: false,
      name: "reason",
      search: true,
      type: "text",
      // specialFunc: (val) => (val ? "Active" : "Not Active"),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Consumer Info</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Consumer ID</label>
                </div>
                <div className="col-sm-6">
                  <label>{params.id}</label>
                </div>
              </div>
              <InputField
                type="text"
                label="Full Name"
                name="fullName"
                value={formData?.fullName}
                onChange={handleChange}
                required={true}
              />
              <InputField
                type="text"
                label="Email"
                name="email"
                value={formData?.email}
                onChange={handleChange}
                required={true}
              />
              <InputField
                type="text"
                label="Phone"
                name="msisdn"
                value={formData?.msisdn}
                onChange={handleChange}
                required={true}
              />
              {formData?.consumerPremium && (
                <>
                  <div className="form-group row">
                    <div className="col-sm-2">
                      <label>Identity Type</label>
                    </div>
                    <div className="col-sm-6">
                      <select
                        className="form-control select2"
                        id="identityType"
                        style={{ width: "100%" }}
                        name="identityType"
                        value={formData?.consumerPremium?.identityType}
                        onChange={handleChangePremium}
                      >
                        <option value="">Choose one</option>
                        {identity.map((val, i) => (
                          <option value={val.refCode} key={val.id}>
                            {val.refName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <InputField
                    type="number"
                    label="Identity No"
                    name="identityNo"
                    value={formData?.consumerPremium?.identityNo}
                    onChange={handleChangePremium}
                  />
                  <div className="form-group row">
                    <div className="col-sm-2">
                      <label>Validity Period</label>
                    </div>
                    <div className="col-sm-3">
                      <select
                        className="form-control select2"
                        id="identityType"
                        style={{ width: "100%" }}
                        value={identityPeriod}
                        onChange={(e) => setIdentityPeriod(e.target.value)}
                      >
                        <option value={0}>Seumur Hidup</option>
                        <option value={1}>Choose Date</option>
                      </select>
                    </div>
                    {identityPeriod == 1 &&
                      <div className="col-sm-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                          <input
                            type="date"
                            className="form-control"
                            data-inputmask-alias="datetime"
                            data-inputmask-inputformat="dd/mm/yyyy"
                            data-mask
                            name="identityPeriod"
                            value={formData?.consumerPremium?.identityPeriod}
                            onChange={handleChangePremium}
                          />
                        </div>
                      </div>
                    }
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-2">
                      <label>Country</label>
                    </div>
                    <div className="col-sm-6">
                      <select
                        className="form-control select2"
                        style={{ width: "100%" }}
                        name="country"
                        value={formData?.consumerPremium?.country}
                        onChange={handleChangePremium}
                      >
                        <option value="">Choose one</option>
                        {COUNTRY?.map((val, i) => (
                          <option value={`${val.refCode}-${val.refName}`} key={val.id}>
                            {val.refName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-2">
                      <label>Gender</label>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          value="L"
                          checked={formData?.consumerPremium?.gender == "L"}
                          onChange={handleChangePremium}
                        />
                        <label className="form-check-label">Male</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          value="P"
                          checked={formData?.consumerPremium?.gender == "P"}
                          onChange={handleChangePremium}
                        />
                        <label className="form-check-label">Female</label>
                      </div>
                    </div>
                  </div>
                  <InputField
                    type="text"
                    label="Place of Birth"
                    name="placeOfBirth"
                    value={formData?.consumerPremium?.placeOfBirth}
                    onChange={handleChangePremium}
                  />
                  <div className="form-group row">
                    <div className="col-sm-2">
                      <label>Date of Birth</label>
                    </div>
                    <div className="col-sm-3">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="far fa-calendar-alt"></i>
                          </span>
                        </div>
                        <input
                          type="date"
                          className="form-control"
                          data-inputmask-alias="datetime"
                          data-inputmask-inputformat="dd/mm/yyyy"
                          data-mask
                          name="dateOfBirth"
                          value={formData?.consumerPremium?.dateOfBirth}
                          onChange={handleChangePremium}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-2">
                      <label>Marriage Status</label>
                    </div>
                    <div className="col-sm-6">
                      <select
                        className="form-control select2"
                        id="identityType"
                        style={{ width: "100%" }}
                        name="marriageStatus"
                        onChange={handleChangePremium}
                        value={formData?.consumerPremium?.marriageStatus}
                      >
                        <option value="">Select One</option>
                        {marriageStatus.map(item =>
                          <option value={item.refCode}>{item.refName}</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <InputField
                    type="text"
                    label="Address (Identity)"
                    name="address"
                    value={formData?.consumerPremium?.address}
                    onChange={handleChangePremium}
                  />
                  <InputField
                    type="text"
                    label="Postal (Identity)"
                    name="postal"
                    value={formData?.consumerPremium?.postal}
                    onChange={handleChangePremium}
                  />
                  <InputField
                    type="text"
                    label="Kelurahan (Identity)"
                    name="kelurahan"
                    value={formData?.consumerPremium?.kelurahan}
                    onChange={handleChangePremium}
                  />
                  <InputField
                    type="text"
                    label="Kecamatan (Identity)"
                    name="kecamatan"
                    value={formData?.consumerPremium?.kecamatan}
                    onChange={handleChangePremium}
                  />
                  <div className="form-group row">
                    <div className="col-sm-2">
                      <label>City (Identity)</label>
                    </div>
                    <div className="col-sm-6">
                      <select
                        className="form-control select2"
                        id="city"
                        style={{ width: "100%" }}
                        name="city"
                        value={formData?.consumerPremium?.city}
                        onChange={handleChangePremium}
                      >
                        <option value="">Choose one</option>
                        {cities.map(item => <option value={item.cityId}>{item.cityName}</option>)}
                      </select>
                    </div>
                  </div>
                  <InputField
                    type="text"
                    label="Occupation (Identity)"
                    name="occupation"
                    value={formData?.consumerPremium?.occupation}
                    onChange={handleChangePremium}
                  />
                  <div className="form-group row">
                    <div className="col-sm-2">
                      <label for="inputStatus">Image Identity</label>
                    </div>
                    <div className="col-sm-10">
                      <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                        <li>
                          <span className="mailbox-attachment-icon has-img">
                            <img
                              src={`data:image/png;base64,${images.identity}`}
                              alt="Attachment"
                              style={{
                                objectFit: "cover",
                                maxHeight: "132.25px"
                              }}
                            />
                          </span>

                          <div className="mailbox-attachment-info">
                            <a href="#" className="mailbox-attachment-name">
                              <i className="fas fa-camera"></i> {formData?.consumerPremium?.imageIdentity}
                            </a>
                            <span className="mailbox-attachment-size clearfix mt-1">
                              <span>Identity</span>
                            </span>
                          </div>
                        </li>
                        <li>
                          <span className="mailbox-attachment-icon has-img">
                            <img
                              src={`data:image/png;base64,${images.selfie}`}
                              alt="Attachment"
                              style={{
                                objectFit: "cover",
                                maxHeight: "132.25px"
                              }}
                            />
                          </span>

                          <div className="mailbox-attachment-info">
                            <a href="#" className="mailbox-attachment-name">
                              <i className="fas fa-camera"></i> {formData?.consumerPremium?.imageSelfie}
                            </a>
                            <span className="mailbox-attachment-size clearfix mt-1">
                              <span>Identity Selfie</span>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>)}
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Status</label>
                </div>
                <div className="col-sm-6">
                  <label>{formData?.statusName}</label>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Registered On</label>
                </div>
                <div className="col-sm-6">
                  <label>{formData?.createdDate}</label>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Last Activity</label>
                </div>
                <div className="col-sm-6">
                  <label>02-10-2021 10:34:23</label>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-outline card-primary collapsed-card">
            <div className="card-header">
              <h3 className="card-title">Transaction History</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <DataTables
                headers={tableHeaders}
                listData={histories}
                first={true}
                last={true}
                totalData={histories.length || 0}
                totalPage={1}
                empty={histories.length === 0}
                page={0}
                handleAction={(page, action) => { }}
                size={20}
                listAction={[]}
              />
            </div>
          </div>
        </div>
      </div>
      {popupData.show &&
        <ReasonPopup
          header={popupData.header}
          handleSave={submitReason}
          handleClose={() => setPopupData(temp => ({ ...temp, show: false }))}
        />
      }
    </div>
  );
};

export default EditConsumer;
