import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DataTables from "../../components/datatables/datatables.component";
import MultipleSearch from "../../components/multiple-search/multiple-search.component";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import {
    getMerchantSetting,
    getMasterData,
    initCreate
} from "../../redux/merchat-management/merchant-management.action";

const MerchantGroupSetting = () => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const tableHeaders = [
    {
      text: "Merchant Name",
      sort: true,
      name: "merchantName",
      search: true,
      type: "text",
    },
    {
      text: "Merchant PAN",
      sort: true,
      name: "merchantPan",
      search: true,
      type: "text",
    },
    { text: "URL Callback QRIS", sort: true, name: "urlCallbackQris", search: true, type: "text" },
  ];

  const actionButton = [
    {
      icon: <span className="fas fa-edit" aria-hidden="true"></span>,
      description: "Edit",
      action: (val) => {console.log('push', `/merchant-settings/${val.merchantId}`); history.push(`/merchant-settings/${val.merchantId}`)},
      condition: (val) => true,
    },
  ]

  const {
    // status,
    merchantSettingList,
    first,
    last,
    totalData,
    totalPage,
    // error,
    empty,
    errorDelete,
    errorActivate,
  } = useSelector((state) => state.merchantManagement);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState([]);
  const [orderBy, setOrderBy] = useState({
    field: "merchantName",
    direction: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMerchantSetting(page, size, searchQuery, orderBy));
  }, [page, searchQuery, orderBy, dispatch, size]);

  useEffect(() => {
    if (errorDelete === false || errorActivate === false) {
      dispatch(getMerchantSetting(page, size, searchQuery, orderBy));
    }
  }, [errorDelete, errorActivate]);

  useEffect(() => {
    // set action button
    dispatch(
      setHeaderButton({
        titleHeader: "Merchant Setting",
      })
    );
    dispatch(getMasterData());
    dispatch(initCreate());
  }, [dispatch]);

  const handleAction = (pages, order) => {
    setPage(pages);
    if (order.field !== "") {
      setOrderBy(order);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setPage(0);
  };

  return (
    <div className="container-fluid">
      <MultipleSearch
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        headers={tableHeaders}
        showStatus={true}
      />
      <DataTables
        headers={tableHeaders}
        listData={merchantSettingList}
        first={first}
        last={last}
        totalData={totalData}
        totalPage={totalPage}
        empty={empty}
        page={page}
        handleAction={handleAction}
        size={size}
        listAction={actionButton}
      />
    </div>
  );
};

export default MerchantGroupSetting;
