import { batch } from "react-redux";
import { ConsumerTransactionServices } from "../../_services/consumer-transaction.services";
import { ConsumerServices } from "../../_services/consumer.services";
import { MasterDataService } from "../../_services/master-data.services";
import ActionsActionTypes from "../actions/actions.types";
import ConsumerTypes from "./consumer.types";

export const getDataConsumer = (page, size, searchQuery, order) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ConsumerTypes.GET_DATA_CONSUMER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING_TABLE });
      });

      const consumerRequest = await ConsumerServices.getConsumerData(
        page,
        size,
        searchQuery,
        order,
        token
      );

      batch(() => {
        dispatch({
          type: ConsumerTypes.GET_DATA_CONSUMER_SUCCESS,
          payload: consumerRequest.data,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    } catch (error) {
      batch(() => {
        dispatch({ type: ConsumerTypes.GET_DATA_CONSUMER_FAILURE });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING_TABLE });
      });
    }
  };
};

export const getConsumerDetail = (id) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      batch(() => {
        dispatch({ type: ConsumerTypes.GET_CONSUMER_DETAIL });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const details = await ConsumerServices.getConsumerDetail(id, token);
      const histories = await ConsumerTransactionServices.getTransactionHistories(id, token);
      console.log("histories", histories);

      batch(() => {
        dispatch({
          type: ConsumerTypes.GET_CONSUMER_DETAIL_SUCCESS,
          payload: {
            detail: details.data,
            histories: histories.data.content,
          },
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    } catch (error) {
      batch(() => {
        dispatch({
          type: ConsumerTypes.GET_CONSUMER_DETAIL_FAILURE,
          payload: error,
        });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
      });
    }
  }
}

export const getConsumerMasterData = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({ type: ConsumerTypes.GET_CONSUMER_MASTER_DATA });

      const selectRole = await ConsumerServices.rolesConsumer(token);
      const statusConsumer = await ConsumerServices.statusConsumer(token);
      const upgradeStatus = await ConsumerServices.upgradeStatusConsumer(token);

      dispatch({
        type: ConsumerTypes.GET_CONSUMER_MASTER_DATA_SUCCESS,
        payload: {
          selectRoles: selectRole.data,
          selectStatus: statusConsumer.data,
          selectUpgrade: upgradeStatus.data
        }
      });
    } catch (error) {
      dispatch({
        type: ConsumerTypes.GET_CONSUMER_MASTER_DATA_FAILURE,
        payload: error,
      });
    }
  }
}

export const getAllCities = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    try {
      dispatch({ type: ConsumerTypes.GET_CONSUMER_CITIES })
      const city = await MasterDataService.getAllCity(token);
      dispatch({
        type: ConsumerTypes.GET_CONSUMER_CITIES_SUCCESS,
        payload: city.data.content
      })
    } catch (error) {
      dispatch({
        type: ConsumerTypes.GET_CONSUMER_CITIES_FAILURE,
        payload: error
      });
    }
  }
}

export const updateConsumer = (dataFromComponent) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const data = { ...dataFromComponent };
    const id = data.consumerId;
    try {
      batch(() => {
        dispatch({ type: ConsumerTypes.UPDATE_DATA_CONSUMER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });
      delete data.consumerId;
      delete data.createdDate;
      delete data.modifiedDate;

      const update = await ConsumerServices.updateConsumer(id, data, token);

      if (update.status === "Error") {
        batch(() => {
          dispatch({ type: ConsumerTypes.UPDATE_DATA_CONSUMER_FAILURE, payload: update.data });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: update.data,
              alertType: "error",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({ type: ConsumerTypes.UPDATE_DATA_CONSUMER_SUCCESS, payload: update });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: "Update consumer success",
              alertType: "success",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({ type: ConsumerTypes.UPDATE_DATA_CONSUMER_FAILURE, payload: error });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: "Update consumer failure",
            alertType: "error",
          },
        });
      });
    }
  };
};

export const affectConsumer = (data, action, reason) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const id = data.consumerId;
    try {
      batch(() => {
        dispatch({ type: ConsumerTypes.UPDATE_DATA_CONSUMER });
        dispatch({ type: ActionsActionTypes.SHOW_LOADING });
      });

      const ban = await ConsumerServices.affectConsumer(id, action, reason, token);

      if (ban.status === "Error") {
        batch(() => {
          dispatch({ type: ConsumerTypes.UPDATE_DATA_CONSUMER_FAILURE, payload: ban.data });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: ban.data,
              alertType: "error",
            },
          });
        });
      } else {
        batch(() => {
          dispatch({ type: ConsumerTypes.UPDATE_DATA_CONSUMER_SUCCESS, payload: ban });
          dispatch({ type: ActionsActionTypes.HIDE_LOADING });
          dispatch({
            type: ActionsActionTypes.SHOW_ALERT,
            payload: {
              alertMessage: `${action} consumer success`,
              alertType: "success",
            },
          });
        });
      }
    } catch (error) {
      batch(() => {
        dispatch({ type: ConsumerTypes.UPDATE_DATA_CONSUMER_FAILURE, payload: error });
        dispatch({ type: ActionsActionTypes.HIDE_LOADING });
        dispatch({
          type: ActionsActionTypes.SHOW_ALERT,
          payload: {
            alertMessage: `${action} consumer failure`,
            alertType: "error",
          },
        });
      });
    }
  };
};

export const defaultConsumer = () => async (dispatch) =>
  dispatch({ type: ConsumerTypes.DEFAULT_CONSUMER_STATE });
