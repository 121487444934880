/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderButton } from "../../redux/actions/actions.actions";
import { createGroupRefCode } from "../../redux/refcode/refCode.actions";

const RefGroupForm = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    refGroup: "",
  });
  const { sendingStatus } = useSelector(state => state.refCodes)

  const headerActionButton = [
    <button
      type="button"
      name="save"
      className="btn btn-success"
      onClick={() => onSave()}
    >
      <i className="fas fa-save"></i>
      Save
    </button>
  ];

  const onSave = useCallback(() => {
    dispatch(createGroupRefCode(formData))
  }, [formData]);

  useEffect(() => {
    dispatch(setHeaderButton({ 
      itleHeader: "Group Ref Code",
      isChild: true,
      hasCustomButton: true,
      customButton: headerActionButton,
    }));
  }, [onSave]);

  useEffect(() => {
    if (sendingStatus === "success") window.history.back();
  }, [sendingStatus]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Group Ref Code Info</h3>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-sm-2">
                  <label>Group Ref Code</label>
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="fullname"
                    name="refGroup"
                    value={formData.refGroup}
                    onChange={(e) => setFormData((temp) => ({...temp, refGroup: e.target.value}))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefGroupForm;
